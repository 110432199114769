import { Alert } from "react-bootstrap";

const EmptyStateHandler = ({
  title = "No data found",
  description = "There are currently no data items to show.",
  className = "",
  children,
}) => {
  return (
    <Alert variant="info" className={`mb-3 d-flex flex-row ${className}`}>
      <div className="me-3">
        <span translate="no" className="material-symbols-outlined md-18">
          info
        </span>
      </div>
      <div>
        <h5 className="mb-1">
          <small>{title}</small>
        </h5>
        <p className={children ? "mb-3" : "mb-1"}>
          <small>{description}</small>
        </p>
        {children}
      </div>
    </Alert>
  );
};

export default EmptyStateHandler;
