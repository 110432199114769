import { useContext } from "react";

import { useSearchParams } from "react-router-dom";

import { OrganizationContext } from "contexts/OrganizationProvider";

/**
 * FilePreviewMiddleware Middleware
 * @param {children} param0
 * @returns
 */
const FilePreviewMiddleware = ({ children }) => {
  const currentOrganization = useContext(OrganizationContext);

  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get("organizationId");

  if (organizationId && organizationId !== currentOrganization.id) {
    return null; // the SwitchOrganization modal component should be rendered to allow user to switch organization
  }

  return children;
};

export default FilePreviewMiddleware;
