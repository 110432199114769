import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { addYears } from "features/actionItems/utils";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

const ActionItemDueDateField = () => {
  const { handleBlur, values, errors, touched, setFieldValue } =
    useFormikContext();
  const date = values.dueDate || new Date();
  const [dueDate, setDueDate] = useState(date);

  return (
    <Form.Group controlId="dueDate" className="mb-3">
      <Form.Label className="mb-1">
        Due Date <RequiredAsterisk />
      </Form.Label>
      <DatePicker
        dateFormat="MM/dd/yyyy"
        type="date"
        name="dueDate"
        popperPlacement="top-start"
        selected={dueDate}
        onChange={(date) => {
          setDueDate(date);
          setFieldValue("dueDate", moment(date).format("YYYY-MM-DD"));
        }}
        onBlur={handleBlur}
        isValid={values.dueDate && !errors.dueDate}
        isInvalid={!(values.dueDate && !errors.dueDate) && touched.dueDate}
        maxDate={addYears(new Date(), 2)}
        minDate={new Date()}
        isClearable
        placeholderText="Enter Due Date"
        className="form-control"
      />
      <Form.Control.Feedback type="invalid">
        {errors.dueDate && touched.dueDate ? (
          <small>{errors.dueDate}</small>
        ) : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default ActionItemDueDateField;
