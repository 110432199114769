import { useContext, useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { download } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

import { downloadFileBlob } from "../utils/downloadUtils";

const useExportFacilitiesAsCSV = () => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const toast = useToast();

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const exportEntitiesAsCSV = () => {
    setIsLoading(true);
    const entitiesCsvPromise = download(
      `/organizations/${organization?.id}/facilities/export`
    );

    let fileName = `${organization?.name} - Entities.csv`;

    entitiesCsvPromise.promise
      .then((resp) => {
        downloadFileBlob(resp, fileName);
        toast.success("Success", "File has been downloaded");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          toast.error(
            "Error",
            error?.data?.message || "Failed to download file"
          );
          setError(error);
        }
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(entitiesCsvPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, exportEntitiesAsCSV };
};

export default useExportFacilitiesAsCSV;
