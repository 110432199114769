import * as yup from "yup";

const memoValidationSchema = yup.object().shape({
  key: yup.string().required("This field is required"),
  value: yup
    .string()
    .required("This field is required")
    .min(2, "Text is too Short!"),
});

export default memoValidationSchema;
