function createTreeArray(flatArray) {
  // Use reduce to create a nodeMap
  const nodeMap = flatArray.reduce((acc, item) => {
    acc[item.id] = { ...item, nestedFields: [] };
    return acc;
  }, {});

  // Recursive function to build nodes
  const buildNode = (id) => {
    const node = nodeMap[id];
    if (!node) return null;

    // Filter flatArray for items with parentId === id
    node.nestedFields = flatArray
      .filter((item) => item.triggerFlexibleQuestionId === id)
      .map((item) => buildNode(item.id));

    return node;
  };

  // Filter flatArray for root nodes
  return flatArray
    .filter((item) => item.triggerFlexibleQuestionId === null)
    .map((item) => buildNode(item.id));
}

export { createTreeArray };
