import { UserContext } from "contexts/UserProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { useRoles } from "hooks";

const useFetchActionItems = ({ params }) => {
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const { isCertifier } = useRoles();

  const [actionItems, setActionItems] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState();

  const fetchActionItems = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const subscribersPromise = get(
      `subscribers/${user?.de?.subscriberId}/action-items`,
      {
        params: { ...params() },
      }
    );

    subscribersPromise.promise
      .then(({ data, meta }) => {
        setMeta(meta);
        setActionItems(data);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(subscribersPromise);
  }, [user?.de?.subscriberId, params, setMeta]);

  useEffect(() => {
    if (!isCertifier) fetchActionItems();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchActionItems, isCertifier]);

  return {
    actionItems,
    error,
    isLoading,
    meta,
    setMeta,
  };
};

export default useFetchActionItems;
