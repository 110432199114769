import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from "react";

import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import PropTypes from "prop-types";
import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { Alert, Form, Table } from "react-bootstrap";
import { put } from "utils/DeApi";

const MemberPortfolioList = forwardRef(
  ({ memberId, setIsExpanding, setIsError, setIsSuccess, role }, ref) => {
    const organization = useContext(OrganizationContext);
    const subscribedPromises = useRef([]);
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const [portfolios, setPortfolios] = useState();
    const [accessiblePortfolios, setAccessiblePortfolios] = useState([]);

    useImperativeHandle(ref, () => {
      return {
        updateMemberPortfolios() {
          const ids = accessiblePortfolios.map(({ id }) => id);
          setError(null);
          setIsError(false);
          setIsExpanding(true);
          const auditPromise = put(`/members/${memberId}/portfolios`, {
            organization_id: organization?.id,
            portfolio_ids: ids || [],
          });
          auditPromise.promise
            .then((response) => {
              setIsExpanding(false);
              setIsSuccess(true);
            })
            .catch((error) => {
              !error.isCanceled && setError(error) && setIsError(true);
              setIsExpanding(false);
            });

          subscribedPromises.current.push(auditPromise);
        },
      };
    });

    useEffect(() => {
      const fetchMemberPortfolios = () => {
        setError(null);
        setIsError(false);
        setIsLoading(true);
        const memberPortfoliosPromise = get(`members/${memberId}/portfolios`, {
          params: {
            organization_id: organization?.id,
          },
        });

        const portfoliosPromise = get(
          `/organizations/${organization?.id}/portfolios`,
          {
            params: {
              organizationId: organization?.id,
            },
          }
        );

        Promise.all([
          memberPortfoliosPromise.promise,
          portfoliosPromise.promise,
        ])
          .then((response) => {
            const accessible = response[0].data;
            const portfolios = response[1].data;
            setIsLoading(false);
            setAccessiblePortfolios(role === "Admin" ? [] : accessible);
            setPortfolios(portfolios);
          })
          .catch((error) => {
            if (!error.isCanceled) {
              setError(error);
              setIsError(true);
              setIsLoading(false);
            }
          });
        subscribedPromises.current.push(
          portfoliosPromise,
          memberPortfoliosPromise
        );
      };

      if (
        typeof organization?.id === "string" &&
        typeof memberId === "string"
      ) {
        fetchMemberPortfolios();
      }

      const promises = subscribedPromises.current;
      return () => {
        promises.forEach((promise) => {
          promise.cancel();
        });
      };
    }, [memberId, organization?.id]);

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Portfolios</th>
              <th className="text-end">Can Access</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {isLoading ? (
              <tr>
                <td colSpan={2}>
                  <Loader />
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={2}>
                  <ErrorHandler error={error} />
                </td>
              </tr>
            ) : Array.isArray(portfolios) ? (
              !portfolios.length ? (
                <tr>
                  <td colSpan={2}>
                    <Alert variant="info" className={`my-3 d-flex flex-row`}>
                      <div className="me-3">
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18 text-primary text-opacity-75"
                        >
                          info
                        </span>
                      </div>
                      <div>
                        <h5 className="mb-1">
                          <small>No Portfolios</small>
                        </h5>
                        <p className="mb-1">
                          <small>
                            There are currently no portfolios to show.
                          </small>
                        </p>
                      </div>
                    </Alert>
                  </td>
                </tr>
              ) : (
                <>
                  <tr className="border text-primary bg-light">
                    <td>All Portfolios</td>
                    <td>
                      <Form.Check
                        type="switch"
                        className="float-end"
                        checked={portfolios.every(
                          ({ id }) =>
                            !!accessiblePortfolios.find((f) => id === f.id)
                        )}
                        onChange={(e) => {
                          setAccessiblePortfolios((prevState) => {
                            const allAccessible = portfolios.every(
                              ({ id }) =>
                                !!accessiblePortfolios.find((f) => id === f.id)
                            );

                            const allPortfolios = allAccessible
                              ? []
                              : portfolios;
                            return allPortfolios;
                          });
                        }}
                      />
                    </td>
                  </tr>
                  {Array.isArray(portfolios) &&
                    portfolios.map((f) => {
                      const isAccessible = !!accessiblePortfolios.find(
                        ({ id }) => id === f.id
                      );
                      return (
                        <tr key={f.id}>
                          <td>{f.name}</td>
                          <td>
                            <Form.Check
                              className="float-end"
                              onChange={(e) => {
                                setAccessiblePortfolios((prevState) => {
                                  const portfolios = isAccessible
                                    ? prevState.filter(({ id }) => id !== f.id)
                                    : prevState.concat(f);

                                  return portfolios;
                                });
                              }}
                              checked={isAccessible}
                              type="switch"
                              id="custom-switch"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </>
              )
            ) : (
              <Fragment />
            )}
          </tbody>
        </Table>
      </>
    );
  }
);

MemberPortfolioList.propTypes = {
  memberId: PropTypes.string.isRequired,
};

export default MemberPortfolioList;
