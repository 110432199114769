import { Fragment } from "react";
import React, { useContext } from "react";

import moment from "moment";
import { Table } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import {
  complianceMap,
  conformityLevelMap,
  riskMap,
  showCasualFactor,
  showCompliance,
  showConformityLevel,
  showCorrectiveAction,
  showNotes,
  showRecommendation,
  showRisk,
} from "features/assessment/components/Audit/helper";

import QuestionResponseTableRows from "./QuestionResponseTableRows";

import "./QuestionResponseTable.scss";

const QuestionResponseTable = ({ observation }) => {
  const { audit } = useContext(AuditContext);

  const { conformity, riskLevels, questionOptions, flexibleQuestions } =
    audit?.protocol || {};

  const [
    riskLabel,
    complianceLabel,
    conformityLabel,
    notesLabel,
    recommendationLabel,
    causalFactorsLabel,
    correctiveActionLabel,
  ] = questionOptions || [];

  const _showRisk = showRisk(conformity, questionOptions);
  const _showNotes = showNotes(conformity, questionOptions);
  const _showCompliance = showCompliance(conformity, questionOptions);
  const _showCasualFactor = showCasualFactor(conformity, questionOptions);
  const _showRecommendation = showRecommendation(conformity, questionOptions);
  const _showConformityLevel = showConformityLevel(conformity, questionOptions);
  const _showCorrectiveAction = showCorrectiveAction(
    conformity,
    questionOptions
  );

  const copyTextToClipboard = async (prompt, answer) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(`${prompt} : ${answer}`);
    } else {
      return document.execCommand("copy", true, `${prompt} : ${answer}`);
    }
  };

  return (
    <Table size="sm">
      <tbody>
        {Array.isArray(flexibleQuestions) &&
          flexibleQuestions
            .sort((a, b) => a.order - b.order)
            .map((response) => {
              let { id, prompt, properties } = response || {};
              let choices = [];
              let notes = "";
              let choice = {};
              let responseDatetime = "";
              const matchResponse = observation?.responses.filter(
                (elem) => elem.flexibleQuestionId === id
              );
              if (properties?.fieldType === "checkbox") {
                choices = matchResponse.map((elem) => elem?.choice);
              } else {
                notes = matchResponse[0]?.notes ?? "";
                responseDatetime = matchResponse[0]?.responseDatetime ?? "";
                choice = matchResponse[0]?.choice ?? {};
              }

              if (properties?.fieldType === "text") {
                return (
                  <QuestionResponseTableRows
                    id={id}
                    question={prompt}
                    response={notes || "-"}
                    copyTextToClipboard={copyTextToClipboard}
                  />
                );
              }
              if (properties?.fieldType === "datetime") {
                const datetime = responseDatetime
                  ? moment.utc(responseDatetime).format("MMMM D, yyyy h:mm a")
                  : "";
                return (
                  <QuestionResponseTableRows
                    id={id}
                    question={prompt}
                    response={datetime || "-"}
                    copyTextToClipboard={copyTextToClipboard}
                  />
                );
              }
              if (properties?.fieldType === "numeric") {
                return (
                  <QuestionResponseTableRows
                    id={id}
                    question={prompt}
                    response={`Test ${notes || "-"}`}
                    copyTextToClipboard={copyTextToClipboard}
                  />
                );
              }
              if (properties?.fieldType === "radio" && choice?.label) {
                return (
                  <QuestionResponseTableRows
                    id={id}
                    question={prompt}
                    response={choice?.label}
                    copyTextToClipboard={copyTextToClipboard}
                  />
                );
              }
              if (properties?.fieldType === "checkbox" && !!choices?.length) {
                return (
                  <QuestionResponseTableRows
                    id={id}
                    question={prompt}
                    response={
                      !!choices?.length
                        ? choices?.map(({ label = "" }, key) => (
                            <span
                              key={key}
                              className="d-flex flex-column mb-1 text-capitalize-first"
                            >{`- ${label} `}</span>
                          ))
                        : ""
                    }
                    copyTextToClipboard={copyTextToClipboard}
                  />
                );
              }

              return <Fragment key={id} />;
            })}

        {_showRisk && riskMap(observation?.risk, riskLevels) && (
          <QuestionResponseTableRows
            question={riskLabel?.name || "Risk Rating"}
            response={riskMap(observation?.risk, riskLevels)}
            copyTextToClipboard={copyTextToClipboard}
          />
        )}

        {_showCompliance && complianceMap(observation?.compliance) && (
          <QuestionResponseTableRows
            question={complianceLabel?.name || "Compliance Observed"}
            response={complianceMap(observation?.compliance)}
            copyTextToClipboard={copyTextToClipboard}
          />
        )}
        {_showCorrectiveAction && observation?.correctiveAction && (
          <QuestionResponseTableRows
            question={correctiveActionLabel?.name || "Corrective Action"}
            response={observation?.correctiveAction}
            copyTextToClipboard={copyTextToClipboard}
          />
        )}
        {_showConformityLevel &&
          conformityLevelMap(observation?.conformityLevel, conformity) && (
            <QuestionResponseTableRows
              question={conformityLabel?.name || "Conformity Level"}
              response={conformityLevelMap(
                observation?.conformityLevel,
                conformity
              )}
              copyTextToClipboard={copyTextToClipboard}
            />
          )}

        {_showCasualFactor && observation?.causalFactors && (
          <QuestionResponseTableRows
            question={causalFactorsLabel?.name || "Causal Factors"}
            response={observation?.causalFactors}
            copyTextToClipboard={copyTextToClipboard}
          />
        )}
        {_showRecommendation && observation?.recommendation && (
          <QuestionResponseTableRows
            question={recommendationLabel?.name || "Best Practice"}
            response={observation?.recommendation}
            copyTextToClipboard={copyTextToClipboard}
          />
        )}
        {_showNotes && observation?.notes && (
          <QuestionResponseTableRows
            question={notesLabel?.name || "Notes"}
            response={observation?.notes}
            copyTextToClipboard={copyTextToClipboard}
          />
        )}
      </tbody>
    </Table>
  );
};

export default QuestionResponseTable;
