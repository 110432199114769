import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { destroy } from "utils/DeApi";

const useDeleteObservation = ({ observationId = "", onObservationDeleted }) => {
  const toast = useToast();

  const subscribedPromises = useRef([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const deleteObservation = (observation) => {
    setError("");
    setIsLoading(true);

    const reponsesPromises = observation?.responses.map((response) => {
      const responsePromise = destroy(`responses/${response.id}`);
      subscribedPromises.current.push(responsePromise);
      return responsePromise.promise;
    });

    const observationPromise = destroy(`observations/${observationId}`);

    Promise.all([...reponsesPromises, observationPromise.promise])
      .then(() => {
        if (typeof onObservationDeleted === "function")
          onObservationDeleted({ observationId });
        toast.success("Sucess", "Observation deleted successfully");
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(observationPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    deleteObservation,
  };
};

export default useDeleteObservation;
