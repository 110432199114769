import { camelCase } from "lodash";
import moment from "moment";
const defaultRisk = [
  {
    id: 0,
    value: "low",
  },
  {
    id: 1,
    value: "medium",
  },
  {
    id: 2,
    value: "high",
  },
];

const dynamicLabels = (labels = []) => {
  return Array.isArray(labels)
    ? labels.reduce(
        (a, v) => ({
          ...a,
          [`${camelCase(v.value)}.`]: v.value,
        }),
        {}
      )
    : {};
};

const aggregationOfRisk = (riskStats, riskLevels) => {
  const properties = riskLevels
    .map(({ value }) => value)
    .reduce((accumulator, value) => {
      return { ...accumulator, [`${camelCase(value)}.`]: 0 };
    }, {});

  const aggregation = Array.isArray(riskStats)
    ? riskStats.map(({ risk }) => risk)
    : [];

  const summationOfRisk = aggregation.reduce((aggregator, iterator) => {
    const accumulator = Array.isArray(aggregator)
      ? aggregator.reduce((aa, ii) => Object.assign(aa, ii), {})
      : aggregator;
    return Object.keys(accumulator).map((key) => {
      return {
        [key]: accumulator[key] + iterator[key],
      };
    });
  }, properties ?? {});

  const flatRiskAggregation = Array.isArray(summationOfRisk)
    ? summationOfRisk.reduce(
        (aggregator, iterator) => Object.assign(aggregator, iterator),
        {}
      )
    : {};

  return Object.keys(flatRiskAggregation).map((key) => {
    return {
      name: key,
      value: flatRiskAggregation[key],
    };
  });
};

const aggregationOfRiskTrends = (riskStats, riskLevels, filterPeriod) => {
  if (!riskLevels) return {};
  const trends = riskLevels
    .map(({ value }) => value)
    .reduce(
      (accumulator, value) => {
        return {
          ...accumulator,
          [`${camelCase(value)}.`]: Array(
            filterPeriod?.timeline?.length ?? 0
          ).fill(0),
        };
      },
      { timeline: filterPeriod?.timeline ?? [] }
    );

  trends.timeline.forEach((month, index) => {
    const aggregationMonth =
      Array.isArray(riskStats) &&
      riskStats.find(({ period }) => {
        return moment(period, filterPeriod?.remoteDateFormat).isSame(
          moment(month),
          filterPeriod?.aggregationPeriod
        );
      });

    if (aggregationMonth) {
      const { risk } = aggregationMonth;
      Object.keys(risk)
        .map((key) => {
          return key;
        })
        .forEach((key) => {
          if (trends[key]) trends[key][index] = risk[key];
        });
    }
  });

  return {
    ...trends,
    timeline: trends.timeline.map((date) =>
      moment(date, "YYYY-MM-DD").format(filterPeriod?.localDateFormat)
    ),
  };
};

const aggregationOfConfromity = (conformityStats, conformityLevel) => {
  const properties = conformityLevel
    .map(({ value }) => value)
    .filter((v = "") => v.toLowerCase() !== "n/a")
    .reduce((accumulator, value) => {
      return { ...accumulator, [`${camelCase(value)}.`]: 0 };
    }, {});

  const aggregation = Array.isArray(conformityStats)
    ? conformityStats.map(({ conformity }) => conformity)
    : [];

  const summationOfConformity = aggregation.reduce((aggregator, iterator) => {
    const accumulator = Array.isArray(aggregator)
      ? aggregator.reduce((aa, ii) => Object.assign(aa, ii), {})
      : aggregator;
    return Object.keys(accumulator).map((key) => {
      return {
        [key]: accumulator[key] + iterator[key],
      };
    });
  }, properties ?? {});

  const flatConformityAggregation = Array.isArray(summationOfConformity)
    ? summationOfConformity.reduce(
        (aggregator, iterator) => Object.assign(aggregator, iterator),
        {}
      )
    : {};

  return Object.keys(flatConformityAggregation).map((key) => {
    return {
      name: key,
      value: flatConformityAggregation[key],
    };
  });
};

const aggregationOfConformityTrends = (
  conformityStats,
  conformityLevel,
  filterPeriod
) => {
  if (!conformityLevel) return {};
  const trends = conformityLevel
    .map(({ value }) => value)
    .filter((v = "") => v.toLowerCase() !== "n/a")
    .reduce(
      (accumulator, value) => {
        return {
          ...accumulator,
          [`${camelCase(value)}.`]: Array(
            filterPeriod?.timeline?.length ?? 0
          ).fill(0),
        };
      },
      { timeline: filterPeriod?.timeline ?? [] }
    );

  trends.timeline.forEach((month, index) => {
    const aggregationMonth =
      Array.isArray(conformityStats) &&
      conformityStats.find(({ period }) => {
        return moment(period, filterPeriod?.remoteDateFormat).isSame(
          moment(month),
          filterPeriod?.aggregationPeriod
        );
      });

    if (aggregationMonth) {
      const { conformity } = aggregationMonth;
      Object.keys(conformity)
        .map((key) => {
          return key;
        })
        .forEach((key) => {
          if (trends[key]) trends[key][index] = conformity[key];
        });
    }
  });

  return {
    ...trends,
    timeline: trends.timeline.map((date) =>
      moment(date, "YYYY-MM-DD").format(filterPeriod?.localDateFormat)
    ),
  };
};

const aggregationOfCompliance = (complianceStats) => {
  const aggregation = Array.isArray(complianceStats)
    ? complianceStats.map(({ compliance }) => compliance)
    : [];

  const summationOfCompliance = aggregation.reduce(
    ({ compliant, nonCompliant }, iterator) => {
      return {
        compliant: compliant + iterator.compliant,
        nonCompliant: nonCompliant + iterator.nonCompliant,
      };
    },
    {
      compliant: 0,
      nonCompliant: 0,
    }
  );

  return Object.keys(summationOfCompliance).map((key) => {
    return {
      name: key,
      value: summationOfCompliance[key],
    };
  });
};

const aggregationOfComlianceTrends = (complianceStats, filterPeriod) => {
  const trends = {
    timeline: filterPeriod?.timeline ?? [],
    compliant: Array(filterPeriod?.timeline?.length ?? 0).fill(0),
    nonCompliant: Array(filterPeriod?.timeline?.length ?? 0).fill(0),
  };

  trends.timeline.forEach((month, index) => {
    const aggregationMonth =
      Array.isArray(complianceStats) &&
      complianceStats.find(({ period }) => {
        return moment(period, filterPeriod?.remoteDateFormat).isSame(
          moment(month),
          filterPeriod?.aggregationPeriod
        );
      });

    if (aggregationMonth) {
      const {
        compliance: { compliant, nonCompliant },
      } = aggregationMonth;

      trends.compliant[index] = compliant;
      trends.nonCompliant[index] = nonCompliant;
    }
  });

  return {
    ...trends,
    timeline: trends.timeline.map((date) =>
      moment(date, "YYYY-MM-DD").format(filterPeriod?.localDateFormat)
    ),
  };
};

const aggregationOfPrincipleRiskTrends = (
  principleRiskStats,
  riskLevels,
  filterPeriod
) => {
  if (!principleRiskStats) return {};
  const trends = riskLevels
    .map(({ value }) => value)
    .reduce(
      (accumulator, value) => {
        return {
          ...accumulator,
          [`${camelCase(value)}.`]: Array(
            filterPeriod?.timeline?.length ?? 0
          ).fill(0),
        };
      },
      { timeline: principleRiskStats.map(({ title }) => title) ?? [] }
    );

  trends.timeline.forEach((timelineTitle, index) => {
    const aggregationMonth =
      Array.isArray(principleRiskStats) &&
      principleRiskStats.find(({ title }) => {
        return title === timelineTitle;
      });

    if (aggregationMonth) {
      const { risk } = aggregationMonth;
      Object.keys(risk)
        .map((key) => {
          return key;
        })
        .forEach((key) => {
          if (trends[key]) trends[key][index] = risk[key];
        });
    }
  });

  return trends ?? {};
};

const aggregationOfPrincipleConformityTrends = (
  principleConformityStats,
  conformity,
  filterPeriod
) => {
  if (!conformity || !principleConformityStats) return {};
  const trends = conformity
    .map(({ value }) => value)
    .filter((v = "") => v.toLowerCase() !== "n/a")
    .reduce(
      (accumulator, value) => {
        return {
          ...accumulator,
          [`${camelCase(value)}.`]: Array(
            filterPeriod?.timeline?.length ?? 0
          ).fill(0),
        };
      },
      { timeline: principleConformityStats.map(({ title }) => title) ?? [] }
    );

  trends.timeline.forEach((timelineTitle, index) => {
    const aggregationMonth =
      Array.isArray(principleConformityStats) &&
      principleConformityStats.find(({ title }) => {
        return title === timelineTitle;
      });

    if (aggregationMonth) {
      const { conformity } = aggregationMonth;
      Object.keys(conformity)
        .map((key) => {
          return key;
        })
        .forEach((key) => {
          if (trends[key]) trends[key][index] = conformity[key];
        });
    }
  });

  return trends ?? {};
};

const aggregationOfPrincipleComlianceTrends = (principleComplianceStats) => {
  if (!principleComplianceStats) return {};
  const timeline = principleComplianceStats.map(({ title }) => title) ?? [];

  const trends = {
    timeline: timeline,
    compliant: Array(timeline.length ?? 0).fill(0),
    nonCompliant: Array(timeline.length ?? 0).fill(0),
  };
  trends.timeline.forEach((timelineTitle, index) => {
    const aggregationMonth =
      Array.isArray(principleComplianceStats) &&
      principleComplianceStats.find(({ title }) => {
        return title === timelineTitle;
      });
    if (aggregationMonth) {
      const {
        compliance: { compliant, nonCompliant },
      } = aggregationMonth;

      trends.compliant[index] = compliant;
      trends.nonCompliant[index] = nonCompliant;
    }
  });

  return trends ?? {};
};

const aggregationOfBestPracticesAndFindings = (
  bestPracticesAndFindingsData
) => {
  const currentData =
    bestPracticesAndFindingsData.results || bestPracticesAndFindingsData;
  if (currentData && currentData.length > 0) {
    const timeline = currentData.map(({ name }) => name) ?? [];

    const trends = {
      timeline: timeline,
      finding: Array(timeline.length ?? 0).fill(0),
      bestPractise: Array(timeline.length ?? 0).fill(0),
    };

    trends.timeline.forEach((timelineTitle, index) => {
      const aggregationMonth =
        Array.isArray(currentData) &&
        currentData.find(({ name }) => {
          return name === timelineTitle;
        });
      if (aggregationMonth) {
        const {
          stats: { finding, bestPractise },
        } = aggregationMonth;

        trends.finding[index] = finding;
        trends.bestPractise[index] = bestPractise;
      }
    });
    return trends ?? {};
  }
};

export {
  dynamicLabels,
  defaultRisk,
  aggregationOfRisk,
  aggregationOfRiskTrends,
  aggregationOfConfromity,
  aggregationOfConformityTrends,
  aggregationOfCompliance,
  aggregationOfComlianceTrends,
  aggregationOfPrincipleRiskTrends,
  aggregationOfPrincipleComlianceTrends,
  aggregationOfPrincipleConformityTrends,
  aggregationOfBestPracticesAndFindings,
};
