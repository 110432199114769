import PropTypes from "prop-types";

import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

import "./OverlayTriggerButton.scss";

const OverlayTriggerButton = ({
  onClickHandler,
  toolTipMessage,
  label,
  className = "",
  variant = "success",
  size = "sm",
  placement = "top",
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id="tooltip">{toolTipMessage}</Tooltip>}
    >
      <Button
        size={size}
        variant={variant}
        className={className}
        onClick={onClickHandler}
      >
        {label}
      </Button>
    </OverlayTrigger>
  );
};

OverlayTriggerButton.propTypes = {
  onClickHandler: PropTypes.func,
  toolTipMessage: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  placement: PropTypes.string,
};

export default OverlayTriggerButton;
