import { uniq } from "lodash";

export const first = (array) => {
  if (!Array.isArray(array)) return null;
  if (array.length === 0) return null;
  return array[0];
};

export const isArray = (array) => {
  return Array.isArray(array);
};

export function createUniqArray(data) {
  if (!data) return [];
  if (!Array.isArray(data)) return [];
  return uniq(data);
}

export function sortArrayByDate(data) {
  if (!data) return [];
  if (!Array.isArray(data)) return [];
  return data
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .reverse();
}

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
