import { useToast } from "hooks";
import { useState } from "react";
import { Toast } from "react-bootstrap";

const toastTypes = {
  success: {
    icon: "check_circle",
    iconClass: "text-primary",
    progressBarClass: "success",
  },
  warning: {
    icon: "warning",
    iconClass: "text-warning",
    progressBarClass: "warning",
  },
  info: {
    icon: "info",
    iconClass: "text-info",
    progressBarClass: "info",
  },
  error: {
    icon: "error",
    iconClass: "text-danger",
    progressBarClass: "error",
  },
};

const ToastNotification = ({ id, type, title = "title", body = "message" }) => {
  const toast = useToast();
  const [show, setShow] = useState(true);
  const { icon, iconClass } = toastTypes[type];

  const onToastClose = () => {
    setShow(false);
  };

  return (
    <Toast
      autohide
      delay={3000}
      animation
      onClose={onToastClose}
      onExited={() => toast.remove(id)}
      show={show}
      className={`text-bg-light border-1 border-opacity-25`}
    >
      <Toast.Body className={`d-flex flex-row`}>
        <div className="me-3">
          <span
            translate="no"
            className={`material-symbols-outlined md-24 ${iconClass}`}
          >
            {icon}
          </span>
        </div>
        <div className="flex-fill">
          <h5>{title}</h5>
          <p className="mb-3">{body}</p>
        </div>
        <div>
          <span
            translate="no"
            onClick={onToastClose}
            className="material-symbols-outlined md-18 cursor-pointer"
          >
            close
          </span>
        </div>
      </Toast.Body>
    </Toast>
  );
};

export default ToastNotification;
