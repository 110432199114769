import React, { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { useFormikContext } from "formik";
import { useRoles } from "hooks";
import { useCreateDataLink, useExportData } from "services";

const DataExportFooter = ({ setLinks }) => {
  const {
    values: { protocolId, facilityId, assessments },
    setFieldValue,
  } = useFormikContext();

  const { isAdmin, isMember } = useRoles();

  const {
    isLoading: exportLoading,
    error: exportError,
    exportData,
  } = useExportData();

  const {
    isLoading: dataLinkLoading,
    error: dataLinkError,
    createDataLink,
  } = useCreateDataLink({ setLinks });

  const isDisable = () => {
    if (exportLoading || dataLinkLoading) return true;
    if (isAdmin) return !protocolId || !assessments.length;
    if (isMember) return !protocolId || !assessments.length || !facilityId;
  };

  useEffect(() => {
    setFieldValue("error", exportError);
  }, [exportError, setFieldValue]);

  useEffect(() => {
    setFieldValue("error", dataLinkError);
  }, [dataLinkError, setFieldValue]);

  return (
    <div className="sticky-bottom w-100 p-3 bg-light text-end">
      <Button
        variant="primary"
        onClick={() => exportData(protocolId, assessments)}
        disabled={isDisable()}
      >
        {exportLoading ? (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="light"
          />
        ) : (
          <span className="material-symbols-outlined md-18">download</span>
        )}{" "}
        Export To Excel (CSV)
      </Button>

      <Button
        className="ms-2"
        onClick={() => createDataLink(protocolId, assessments)}
        disabled={isDisable()}
      >
        {dataLinkLoading && (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="light"
          />
        )}{" "}
        Generate Data Link
      </Button>
    </div>
  );
};

DataExportFooter.propTypes = {
  setLinks: PropTypes.func,
};

export default DataExportFooter;
