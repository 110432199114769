const sortBy = [
  {
    index: 0,
    name: "Oldest To Newest",
    value: "oldestToNewest",
  },
  {
    index: 1,
    name: "Newest To Oldest",
    value: "newestToOldest",
  },
];

export default sortBy;
