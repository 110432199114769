import React, { useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useRoles, useToast } from "hooks";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";

import "./FileUpdate.scss";

const FileUpdate = ({ file, onFileUpdate }) => {
  const { isCertifier } = useRoles();
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const updateFile = (formData) => {
    setError(null);
    setIsLoading(true);

    const URL =
      file?.modelType === "assessment" || file?.modelType === "question"
        ? `files/${file?.id}`
        : `media/${file?.id}`;

    const auditFileUpdatePromise = put(URL, formData);

    auditFileUpdatePromise.promise
      .then(({ data }) => {
        handleClose();
        const mapped =
          file?.modelType === "assessment" || file?.modelType === "question"
            ? {
                id: data.fileId,
                fileName: data.name,
                name: data.name,
                mimeType: "",
                modelType: file?.modelType,
                humanReadableSize: "",
                url: data.url,
                description: data.description,
                observationId: data.observationId,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt,
              }
            : data;
        onFileUpdate(mapped);
        toast.success("Success", "The file has been updated successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(auditFileUpdatePromise);
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .min(2, "Name is too Short!")
      .max(255, "Name is too Long!"),
    description: yup
      .string()
      .required("Description is required")
      .min(2, "Description is too Short!")
      .max(255, "Description is too Long!"),
  });

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        title="update"
        variant="outline-primary"
        size="sm"
        className="ms-2"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          edit
        </span>
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="w-fixed-640"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Update File</Offcanvas.Title>
        </Offcanvas.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateFile(values)}
          initialValues={{
            name: file?.name || file?.id,
            description: file?.description,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Body>
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label>
                    Name
                    <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    rows={3}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                    isValid={values.name && !errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>
                    Description
                    <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !(values.description && !errors.description) &&
                      touched.description
                    }
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Offcanvas.Body>
              <div className="d-flex flex-row px-3 bg-light pb-5 pt-3 position-absolute bottom-0  w-100">
                <div className="flex-fill"></div>
                <div>
                  <Button
                    size="sm"
                    className="me-2"
                    variant="outline-secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={isLoading || !isValid}
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Update File
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

FileUpdate.propTypes = {
  file: PropTypes.object.isRequired,
  onFileUpdate: PropTypes.func.isRequired,
};

export default FileUpdate;
