import moment from "moment";

const createTimeline = (period, aggregationPeriod) => {
  if (!period || !aggregationPeriod) return [];
  return Array(period)
    .fill(0)
    .map((date, index) => moment().subtract(index, aggregationPeriod))
    .reverse();
};

const dateFilter = [
  {
    index: 1,
    period: "day",
    text: "Last day",
    localDateFormat: "LT",
    remoteDateFormat: "H:mm",
    aggregationPeriod: "hour",
    timeline: createTimeline(24, "hours"),
  },
  {
    index: 2,
    period: "week",
    text: "Last 7 days",
    localDateFormat: "ddd Do",
    remoteDateFormat: "DD/MM/YYYY",
    aggregationPeriod: "day",
    timeline: createTimeline(7, "days"),
  },
  {
    index: 3,
    period: "month",
    text: "Last 30 days",
    localDateFormat: "L",
    remoteDateFormat: "DD/MM/YYYY",
    aggregationPeriod: "day",
    timeline: createTimeline(30, "days"),
  },
  {
    index: 4,
    period: "year",
    text: "Last year",
    localDateFormat: "MMM, YYYY",
    remoteDateFormat: "MM/YYYY",
    aggregationPeriod: "month",
    timeline: createTimeline(12, "months"),
  },
];

export default dateFilter;
