import React from "react";
import { Button, Table } from "react-bootstrap";

export default function AssessCredentialsTable({
  assessApiKeys,
  isUserAdmin,
  onEditClick,
  onDeleteClick,
}) {
  const copyToClipboard = (token) => {
    navigator.clipboard.writeText(token).then(
      () => {
        alert("Token copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };
  return (
    <Table className="cursor-default mb-5">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>token</th>
          <th></th>
          <th>Updated</th>
          <th>isActive</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {assessApiKeys &&
          assessApiKeys.map((element) => (
            <tr key={element.id}>
              <td className="align-middle">{element.id}</td>
              <td className="align-middle text-start text-break">
                {element.name}
              </td>
              <td className="align-middle max-width-200 text-truncate">
                {element.token}
              </td>
              <td>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  className="ms-2"
                  onClick={() => copyToClipboard(element.token)}
                >
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18"
                  >
                    content_copy
                  </span>
                </Button>
              </td>
              <td className="align-middle">
                <small>
                  {new Date(element.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </td>
              <td className="align-middle">{String(element.isActive)}</td>
              <td className="align-middle">
                {isUserAdmin && (
                  <div className="d-flex">
                    <Button
                      className="ms-2"
                      variant="outline-primary"
                      size="sm"
                      onClick={() => onEditClick(element)}
                    >
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18"
                      >
                        edit
                      </span>
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      className="ms-2"
                      onClick={() => onDeleteClick(element)}
                    >
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18"
                      >
                        delete
                      </span>
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
