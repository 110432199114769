import { useFetchPortfolioFacilities } from "features/organization/services";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useRef, useState } from "react";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import MetaPagination from "components/ui/MetaPagination";
import { FacilityListItem } from "features/entity";

const PortfolioFacilities = ({ portfolioId }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const { isLoading, error, meta, facilities, setFacilities } =
    useFetchPortfolioFacilities({
      page,
      perPage,
      endDate,
      startDate,
      portfolioId,
      debouncedQuery,
    });

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const sendSearchQuery = useRef(
    debounce((q) => {
      setDebouncedQuery(q);
    }, 1000)
  );

  return (
    <Card>
      <Card.Body>
        <h4>Entities</h4>
        <Form as={Row} className="mt-3">
          <Form.Group as={Col} lg={4} md={12} sm={12} xs={12} className="mb-3">
            <Form.Control
              type="text"
              value={query}
              onChange={(e) => {
                const q = e.target.value;
                setQuery(q);
                sendSearchQuery.current(q);
              }}
              placeholder="Search"
            />
          </Form.Group>

          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              placeholderText="Filter by date range"
              monthsShown={2}
              className="form-control w-100"
              isClearable
            >
              <Button
                size="sm"
                className="mb-1"
                onClick={() => {
                  setStartDate();
                  setEndDate();
                }}
              >
                Clear
              </Button>
            </DatePicker>
          </Form.Group>
        </Form>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Assessments</th>
              <th>Action Items</th>
              <th>updated</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            <FacilityListItem
              error={error}
              isLoading={isLoading}
              facilities={facilities}
              setFacilities={setFacilities}
            />
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <MetaPagination
          meta={meta}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card.Footer>
    </Card>
  );
};

export default PortfolioFacilities;
