import React from "react";

import { useRoles } from "hooks";
import { Button, Spinner } from "react-bootstrap";

import { useExportFacilitiesAsCSV } from "features/entity/services";

const FacilityExport = () => {
  const { isCertifier } = useRoles();
  const { isLoading, exportEntitiesAsCSV } = useExportFacilitiesAsCSV({});

  if (isCertifier) return <></>;

  return (
    <Button
      variant="outline-primary"
      size="sm"
      onClick={exportEntitiesAsCSV}
      className="me-2"
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner className="me-1" animation="border" size="sm" />
      ) : (
        <span translate="no" className="material-symbols-outlined md-18">
          file_download
        </span>
      )}{" "}
      Export Entities
    </Button>
  );
};

export default FacilityExport;
