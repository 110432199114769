import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useRoles, useToast } from "hooks";
import { Button, Modal, Spinner } from "react-bootstrap";

import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";

import "./FileDelete.scss";

const FileDelete = ({ file, onFileDelete }) => {
  const subscribedPromises = useRef([]);
  const { isCertifier } = useRoles();
  const toast = useToast();

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const fileURL = file?.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  const deleteFile = () => {
    setError(null);
    setIsLoading(true);

    const URL =
      file?.modelType === "assessment" || file?.modelType === "question"
        ? `files/${file?.id}`
        : `media/${file?.id}`;

    const auditFileDeletePromise = destroy(URL);

    auditFileDeletePromise.promise
      .then((resp) => {
        handleClose();
        onFileDelete(file);
        toast.success("Success", "The file has been deleted successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditFileDeletePromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        title="delete"
        variant="outline-danger"
        size="sm"
        className="ms-2"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{`Are you sure you want to delete this file?`}</h5>
          <p className="my-3 d-flex flex-row">
            <span
              className={`fiv-sqo fiv-size-md fiv-icon-${fileEXT} me-2`}
            ></span>
            <small className="flex-fill">{`${
              file?.name || `${file?.id}`
            }.${fileEXT}`}</small>
          </p>
          {error && show && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={deleteFile}
            size="sm"
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FileDelete.propTypes = {
  file: PropTypes.object.isRequired,
  onFileDelete: PropTypes.func.isRequired,
};

export default FileDelete;
