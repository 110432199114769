import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchMemos = ({ auditId, fetchByDefault = true }) => {
  const subscribedPromises = useRef([]);

  const [memos, setMemos] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMemos = () => {
      setMemos();
      setError(null);
      setIsLoading(true);

      const memoPromise = get(`audits/${auditId}/memos`);
      memoPromise.promise
        .then(({ data }) => setMemos(data))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(memoPromise);
    };

    if (auditId && fetchByDefault) fetchMemos();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [auditId, fetchByDefault]);

  return { error, isLoading, memos, setMemos };
};

export default useFetchMemos;
