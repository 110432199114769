import React from "react";

import { Form, Pagination } from "react-bootstrap";

import { sanitizeHTML } from "utils/StringUtils";

const MetaPagination = ({ meta, setPage, perPage, setPerPage }) => {
  if (meta?.total <= 0) return <span />;

  const getPaginationItems = () => {
    if (!Array.isArray(meta?.links)) return [];

    const currentIndex = meta.links.findIndex((link) => link?.active === true);
    const totalLinks = meta.links.length;
    const items = [];

    if (totalLinks <= 7) {
      return meta.links.filter((link) => link);
    }

    if (meta.links[0]) items.push(meta.links[0]);

    if (currentIndex <= 3) {
      for (let i = 1; i <= 5 && i < totalLinks - 1; i++) {
        if (meta.links[i]) items.push(meta.links[i]);
      }
      if (totalLinks > 6) items.push({ label: "...", disabled: true });
    } else if (currentIndex >= totalLinks - 4) {
      if (totalLinks > 6) items.push({ label: "...", disabled: true });
      for (let i = totalLinks - 6; i < totalLinks - 1; i++) {
        if (meta.links[i]) items.push(meta.links[i]);
      }
    } else {
      items.push({ label: "...", disabled: true });

      for (let i = currentIndex - 2; i <= currentIndex + 2; i++) {
        if (meta.links[i]) items.push(meta.links[i]);
      }

      items.push({ label: "...", disabled: true });
    }

    if (meta.links[totalLinks - 1]) items.push(meta.links[totalLinks - 1]);

    return items;
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex gap-2 align-items-end justify-content-between">
        <Pagination className="mb-1">
          {getPaginationItems()?.map((link) => {
            return (
              <Pagination.Item
                key={crypto.randomUUID()}
                active={link?.active}
                disabled={!link?.url}
                onClick={() => {
                  if (link?.url) {
                    const searchableURL = new URL(link?.url);
                    const URLParams = new URLSearchParams(searchableURL.search);
                    setPage(URLParams.get("page"));
                  }
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(link?.label),
                  }}
                />
              </Pagination.Item>
            );
          })}
        </Pagination>
        <div className="d-flex flex-row mb-1">
          <div className="me-3 pt-1">
            <small>
              Showing<span className="mx-1">{meta?.from}</span>-
              <span className="mx-1">{meta?.to}</span>of
              <span className="ms-1">{meta?.total}</span>
            </small>
          </div>
          <Form.Group className="mb-1">
            <Form.Select
              size="sm"
              onChange={(e) => {
                setPage(1);
                setPerPage(e.target.value);
              }}
              value={perPage}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
            </Form.Select>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default MetaPagination;
