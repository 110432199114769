import React, { useState } from "react";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import ErrorHandler from "components/ui/ErrorHandler";
import { useDeleteFacility } from "features/entity/services";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import useFetchFacilityActionItems from "features/entity/services/useFetchFacilityActionItems";

const FacilityDelete = ({ facility, onFacilityDeleted }) => {
  const { auditCount = 0, documents = [], facilityId = null } = facility;

  const { isAdmin } = useRoles();
  const {
    meta: { total: actionItemsTotal = 0 },
  } = useFetchFacilityActionItems({
    facilityId,
  });

  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setIsChecked(false);
  };

  const { error, isLoading, deleteFacility } = useDeleteFacility({
    facilityId,
    onFacilityDeleted: (data) => {
      handleClose();
      onFacilityDeleted(data);
    },
  });

  const hasRelationships =
    auditCount > 0 || documents.length || actionItemsTotal > 0;

  if (!isAdmin) return <></>;

  return (
    <>
      <Button variant="outline-danger" size="sm" onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18">
          delete
        </span>{" "}
        Delete
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">
            Delete Entity -{" "}
            <small translate="no" className="text-muted">
              {facility?.name}
            </small>
          </h2>
          {hasRelationships ? (
            <>
              <p className="my-3 text-center">
                The following data in this Entity must be deleted before
                proceeding:
              </p>
              <ol>
                {auditCount > 0 ? (
                  <li>
                    <small>
                      Assessments: <i>{auditCount}</i>
                    </small>
                  </li>
                ) : null}
                {documents.length ? (
                  <li>
                    <small>
                      Files & Photos: <i>{documents.length}</i>
                    </small>
                  </li>
                ) : null}
                {actionItemsTotal ? (
                  <li>
                    <small>
                      Action Items: <i>{actionItemsTotal}</i>
                    </small>
                  </li>
                ) : null}
              </ol>
            </>
          ) : (
            <div className="mx-4 mt-2">
              <Form.Group>
                <Form.Check
                  label={
                    <small>
                      By checking the box, I confirm that I want to delete this
                      Entity. I understand that this cannot be undone.
                    </small>
                  }
                  onClick={() => {
                    setIsChecked(!isChecked);
                  }}
                />
              </Form.Group>
            </div>
          )}

          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            className="px-3"
            variant="outline-secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="sm"
            variant="danger"
            className="px-3"
            onClick={deleteFacility}
            disabled={hasRelationships || !isChecked || isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FacilityDelete.propTypes = {
  facility: PropTypes.object.isRequired,
  onFacilityDeleted: PropTypes.func.isRequired,
};

export default FacilityDelete;
