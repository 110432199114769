import { useContext, useEffect, useRef, useState } from "react";

import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useFetchOrganizationStats = () => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [stats, setStats] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    function fetchOrgStats() {
      setError(null);
      setIsLoading(true);
      const statsPromise = get(`organizations/${organization?.id}/stats`);
      statsPromise.promise
        .then((stats) => {
          setStats(stats);
        })
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(statsPromise);
    }

    fetchOrgStats();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id]);

  return {
    stats,
    isLoading,
    error,
  };
};

export default useFetchOrganizationStats;
