import { useContext, useRef, useState, useEffect } from "react";
import { get } from "utils/DeApi";
import { useRoles } from "hooks";
import { UserContext } from "contexts/UserProvider";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useFetchFacilityActionItems = ({ facilityId }) => {
  const { isCertifier } = useRoles();
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const { id: organizationId } = organization;

  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [actionItems, setActionItems] = useState([]);
  const [meta, setMeta] = useState({});

  const subscribedPromises = useRef([]);

  useEffect(() => {
    const fetchActionItems = () => {
      setIsLoading(true);
      setError(null);
      const subscribersPromise = get(
        `subscribers/${user?.de?.subscriberId}/action-items`,
        {
          params: {
            perPage: 5,
            "filter[type]": "facility",
            "filter[facility_id]": facilityId,
            "filter[status]": "0,1,2",
            organizationId,
          },
        }
      );

      subscribersPromise.promise
        .then((response) => {
          setActionItems(response?.data);
          setMeta(response?.meta);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (!isCertifier) fetchActionItems();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organizationId, user, isCertifier, facilityId]);

  return { error, isLoading, meta, actionItems, setMeta, setActionItems };
};

export default useFetchFacilityActionItems;
