import React, { useCallback, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context";
import "./EntityAndAssessmentFilter.scss";
import TypeAheadInput from "../TypeAheadInput";
import { useFetchFacilities } from "features/organization";
import { useFetchAudits } from "features/entity";
import { isFilterActive } from "features/actionItems/utils";

const EntityAndAssessmentFilter = () => {
  const {
    filters: { entity, audit, level },
    query: { entity: entityQuery, audit: auditQuery },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const taskableType = urlParams.get("taskableType");

  const taskableId = urlParams.get("taskableId");
  const facilityId = urlParams.get("facilityId") || "";
  const auditIdFromParam = urlParams.get("auditId");

  const { entities, isLoading: isExpanding } = useFetchFacilities();
  const { audits, isLoading } = useFetchAudits({
    facilityId: entity?.facilityId || auditIdFromParam || facilityId,
  });

  const selectingFacility = useCallback(
    (facilities, facilityId) => {
      const [entity] = facilities.filter((f) => f.facilityId === facilityId);
      dispatch({ type: "SET_FILTERS", payload: { entity } });
    },
    [dispatch]
  );

  const selectingQuestion = useCallback(
    (audits, auditId) => {
      const [audit] = audits.filter((a) => a.auditId === auditId);
      dispatch({ type: "SET_FILTERS", payload: { audit } });
    },
    [dispatch]
  );

  const updatingFacilityAndAudits = useCallback(
    (taskableId, facilities) => {
      selectingFacility(facilities, facilityId || taskableId);
    },
    [facilityId, selectingFacility]
  );

  const onEntityClear = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: { entity: "" },
    });
    dispatch({
      type: "SET_QUERY",
      payload: { entity: "" },
    });
  };

  const onAuditClear = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: { audit: "" },
    });
    dispatch({
      type: "SET_QUERY",
      payload: { audit: "" },
    });
  };

  const handleEntityChange = (entity) => {
    if (!entity) {
      return dispatch({
        type: "SET_FILTERS",
        payload: { audit: "", entity: "" },
      });
    }
    dispatch({ type: "SET_FILTERS", payload: { entity } });
  };

  useEffect(() => {
    if (["facility", "question"].includes(taskableType) && !!entities?.length) {
      updatingFacilityAndAudits(taskableId, entities);
    }
  }, [entities, taskableId, taskableType, updatingFacilityAndAudits]);

  useEffect(() => {
    if (taskableType === "question") {
      selectingQuestion(audits, auditIdFromParam, taskableId);
    }
  }, [auditIdFromParam, audits, selectingQuestion, taskableId, taskableType]);

  useEffect(() => {
    if (level.filter === "facility") {
      dispatch({
        type: "SET_FILTERS",
        payload: { audit: "" },
      });
    }
  }, [dispatch, level.filter]);

  return (
    <Col lg={5}>
      <Row className="border border-dashed px-3 py-2">
        <Col lg={6}>
          <small className="text-muted">Filter By Entity</small>
          <Typeahead
            className="my-2"
            id="entity"
            name="entity"
            isLoading={isExpanding}
            labelKey={(option) => `${option?.name}`}
            placeholder="Select an entity..."
            onInputChange={(val) =>
              dispatch({ type: "SET_QUERY", payload: { entity: val } })
            }
            onChange={(e) => handleEntityChange(e[0])}
            options={entity ? [entity] : entities || []}
            selected={entity?.facilityId ? [entity] : []}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
              <TypeAheadInput
                {...inputProps}
                value={entity?.name || entityQuery || ""}
                onClear={onEntityClear}
              />
            )}
            renderMenu={(results, { style, id }) => (
              <Menu className="typehead-menu-alignment" style={style} id={id}>
                {results.map((result, index) => (
                  <MenuItem key={index} option={result} position={index}>
                    {result.name || "Load more..."}
                    <br />
                    <small className="text-muted">{result.address}</small>
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
        </Col>
        <Col lg={6}>
          <small className="text-muted">Filter By Assessment</small>
          <Typeahead
            id="audit"
            className={`my-2 ${isFilterActive(audit?.auditId, true)}`}
            name="audit"
            isLoading={isLoading}
            labelKey={(option) => `${option?.name}`}
            disabled={
              !entity ||
              taskableType === "facility" ||
              level.filter === "facility"
            }
            placeholder="Select an Assessment..."
            onInputChange={(val) =>
              dispatch({ type: "SET_QUERY", payload: { audit: val } })
            }
            onChange={(a) => {
              dispatch({ type: "SET_FILTERS", payload: { audit: a[0] } });
            }}
            options={audit ? [audit] : audits || []}
            selected={audit ? [audit] : []}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
              <TypeAheadInput
                {...inputProps}
                value={audit?.name || auditQuery || ""}
                onClear={onAuditClear}
              />
            )}
            renderMenu={(results, { style, id }) => (
              <Menu className="typehead-menu-alignment" style={style} id={id}>
                {results.map((result, index) => (
                  <MenuItem
                    key={result.auditId}
                    option={result}
                    position={index}
                  >
                    {result.name || "Load more..."}
                    <br />
                    <small className="text-muted">{result.address}</small>
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default EntityAndAssessmentFilter;
