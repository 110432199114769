import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useRef, useEffect, useState, useCallback } from "react";
import { get } from "utils/DeApi";

const useFetchAuditTags = ({ fetchByDefault = true }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [auditTags, setAuditTags] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const fetchAuditTags = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const auditTagsPromise = get(
      `organizations/${organization?.id}/audit-labels`
    );
    auditTagsPromise.promise
      .then(({ data: tags }) => {
        tags.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setAuditTags(tags);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditTagsPromise);
  }, [organization]);

  useEffect(() => {
    if (fetchByDefault) fetchAuditTags();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchAuditTags, fetchByDefault, organization?.id]);

  return { error, isLoading, auditTags, setAuditTags, fetchAuditTags };
};

export default useFetchAuditTags;
