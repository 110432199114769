const SlickArrowNext = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-next-arrow bg-primary bg-opacity-10 rounded"
      onClick={onClick}
    >
      <span
        translate="no"
        className="material-symbols-outlined md-18 text-muted "
      >
        {" "}
        arrow_forward
      </span>
    </div>
  );
};

export default SlickArrowNext;
