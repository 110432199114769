import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import React, { useEffect, useState } from "react";
import { Alert, Table } from "react-bootstrap";
import { setAccentColor } from "utils/EntityTagUtils";
import ActionItemTagCreate from "./ActionItemTagCreate";
import ActionItemTagUpdate from "./ActionItemTagUpdate";
import ActionItemTagDelete from "./ActionItemTagDelete";
import { useFetchTags } from "features/organization";
import { useRoles } from "hooks";

const ActionItemTags = () => {
  const [tags, setTags] = useState([]);
  const { tags: data, isLoading, error } = useFetchTags({ show: true });
  const { isAdmin } = useRoles();

  useEffect(() => {
    if (!!data?.length) {
      setTags(
        data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      );
    }
  }, [data]);

  if (!isAdmin) return <span />;

  return (
    <>
      <div className="d-flex flex-row mt-5">
        <div className="flex-fill">
          <h4>Action Item tags</h4>
        </div>
        <div>
          <ActionItemTagCreate
            onTagCreated={(tag) => setTags((prevState) => [tag, ...prevState])}
          />
        </div>
      </div>
      <hr />
      <Table hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Color</th>
            <th>Description</th>
            <th>Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {isLoading ? (
            <tr>
              <td colSpan={5}>
                <Loader />
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan={5}>
                <ErrorHandler error={error} />
              </td>
            </tr>
          ) : Array.isArray(tags) && !tags.length ? (
            <tr>
              <td colSpan={5}>
                <Alert variant="info" className={`my-3 d-flex flex-row`}>
                  <div className="me-3">
                    <span
                      translate="no"
                      className="material-symbols-outlined md-18 text-primary text-opacity-75"
                    >
                      info
                    </span>
                  </div>
                  <div>
                    <h5 className="mb-1">
                      <small>No tags </small>
                    </h5>
                    <p className="mb-1">
                      <small>Tags will appear here once added</small>
                    </p>
                  </div>
                </Alert>
              </td>
            </tr>
          ) : (
            <></>
          )}
          {tags.map((tag) => {
            return (
              <tr key={tag.labelId}>
                <td>{tag.name}</td>
                <td>
                  <small
                    className="px-2 py-1"
                    style={{
                      backgroundColor: tag?.color || "#C1C1C1",
                      color: setAccentColor(tag?.color),
                    }}
                  >
                    {tag?.color || "#C1C1C1"}
                  </small>
                </td>
                <td>{tag.description || "-"}</td>
                <td className="text-nowrap">
                  <small>
                    {new Date(tag.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                </td>
                <td className="text-nowrap">
                  <ActionItemTagUpdate
                    tag={tag}
                    onTagUpdated={(tag) => {
                      setTags((prevTags) => {
                        return prevTags.map((item) =>
                          tag.labelId === item.labelId ? tag : item
                        );
                      });
                    }}
                  />
                  <ActionItemTagDelete
                    tag={tag}
                    onTagDeleted={(tag) =>
                      setTags((prevStatus) =>
                        prevStatus.filter(
                          ({ labelId }) => labelId !== tag.labelId
                        )
                      )
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ActionItemTags;
