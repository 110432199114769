import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import ExpandFullScreen from "components/ui/ExpandFullScreen";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ReportPrincipleChartLarge from "components/common/ReportPrincipleChartLarge";
import { CONFORMITY_OPTION } from "features/organization/constants/statsFilterOptions";
import {
  aggregationOfPrincipleConformityTrends,
  dynamicLabels,
} from "helper/reporting";
import { useFetchOrganizationProtocolPrincipleStats } from "features/organization/services";

import "./OrganizationPrincipleReportConformity.scss";

function OrganizationPrincipleReportConformity({ protocol, filterPeriod }) {
  const { error, isLoading, organizationStats } =
    useFetchOrganizationProtocolPrincipleStats({
      option: CONFORMITY_OPTION,
      period: filterPeriod?.period,
      protocolId: protocol?.protocolId,
    });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!organizationStats) return <></>;

  const allHaveZeroValues = organizationStats?.every(
    (item) => item?.observationsCount === 0
  );

  if (!organizationStats.length || allHaveZeroValues)
    return (
      <EmptyStateHandler
        title="No data to visualize"
        description="You have no data to visualize."
      />
    );

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
        <div className="d-flex flex-row">
          <p className="text-center w-100 text-truncate text-capitalize mt-2">
            Distribution of Findings across Principles &{" "}
            {protocol?.questionOptions[2]?.name || "Conformity Level"}
          </p>
          <span>
            <ExpandFullScreen
              title={`Distribution of Findings across Principles & ${
                protocol?.questionOptions[2]?.name || "Conformity Level"
              }`}
            >
              <ReportPrincipleChartLarge
                size="square-container"
                labels={dynamicLabels(protocol?.conformity)}
                data={aggregationOfPrincipleConformityTrends(
                  organizationStats,
                  protocol?.conformity,
                  filterPeriod
                )}
              />
            </ExpandFullScreen>
          </span>
        </div>
        <hr />
        <ReportTrend
          size="square-container"
          labels={dynamicLabels(protocol?.conformity)}
          data={aggregationOfPrincipleConformityTrends(
            organizationStats,
            protocol?.conformity,
            filterPeriod
          )}
          chartHeight={350}
        />
      </Col>
    </Row>
  );
}

export default OrganizationPrincipleReportConformity;
