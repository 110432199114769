import React, { useState } from "react";

import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import ErrorHandler from "components/ui/ErrorHandler";
import { groupValidationSchema } from "features/entity/schemas";
import { useCreateFacilityAuditGroup } from "features/entity/services";

const GroupCreate = ({ onGroupCreated }) => {
  const { isCertifier } = useRoles();
  const { facilityId } = useParams();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { error, isLoading, createFacilityAuditGroup } =
    useCreateFacilityAuditGroup({
      facilityId,
      onGroupCreated: (group) => {
        handleClose();
        onGroupCreated(group);
      },
    });

  if (isCertifier) return <></>;

  return (
    <>
      <Button size="sm" variant="primary" onClick={handleShow} className="px-3">
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          add
        </span>
        New Group
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Assessment Group</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={groupValidationSchema}
          onSubmit={(values) =>
            createFacilityAuditGroup({ title: values.title })
          }
          initialValues={{
            title: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="title" className="mb-3">
                  <Form.Label className="mb-1">
                    Title <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="title"
                    onBlur={handleBlur}
                    value={values.title}
                    onChange={handleChange}
                    isValid={values.title && !errors.title}
                    isInvalid={
                      !(values.title && !errors.title) && touched.title
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title && touched.title ? (
                      <small>{errors.title}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  className="px-3"
                  onClick={handleClose}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={
                    Object.keys(errors).length > 0 || isLoading || !isValid
                  }
                >
                  {isLoading && (
                    <Spinner
                      size="sm"
                      variant="light"
                      className="me-2"
                      animation="border"
                    />
                  )}{" "}
                  Create Group
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

GroupCreate.propTypes = {
  onGroupCreated: PropTypes.func.isRequired,
};

export default GroupCreate;
