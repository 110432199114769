import { Badge } from "react-bootstrap";

import "./AchivedBadge.scss";

const AchivedBadge = ({ achieved }) => {
  if (!achieved) return <></>;
  return (
    <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
      <small className="text-warning">
        <span translate="no" className="material-symbols-outlined md-13">
          archive
        </span>{" "}
        Archived
      </small>
    </Badge>
  );
};

export default AchivedBadge;
