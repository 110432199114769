import moment from "moment";
const formatDate = (date) => {
  const [startDate, endDate] = date;
  if (!startDate || !endDate) return "";
  const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
  const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
  return `${formattedStartDate},${formattedEndDate}`;
};

export default formatDate;
