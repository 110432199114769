import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const formatDate = (startDate, endDate) => {
  if (!startDate && !endDate) return [];
  const start = moment(startDate).format("YYYY-MM-DD");
  const end = moment(endDate).format("YYYY-MM-DD");
  return [start, end];
};

const useFetchFacilityAudits = ({
  page,
  group,
  query,
  perPage,
  protocol,
  facilityId,
  endDueDate,
  startDueDate,
  endCreateDate,
  startCreateDate,
  sortState = {
    sortAsc: false,
    sortColumn: "updated_at",
  },
  fetchByDefault = true,
}) => {
  const subscribedPromises = useRef([]);
  const [meta, setMeta] = useState("");
  const [error, setError] = useState("");
  const [audits, setAudits] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const fetchAudits = useCallback(() => {
    if (!facilityId) return;

    setError(null);
    setIsLoading(true);

    const [start, end] = formatDate(startCreateDate, endCreateDate);
    const [startDue, endDue] = formatDate(startDueDate, endDueDate);

    const auditsPromise = get(`facilities/${facilityId}/audits`, {
      params: {
        "filter[created_at]":
          startCreateDate && endCreateDate ? `${start},${end}` : "",
        "filter[due_date]":
          startDueDate && endDueDate ? `${startDue},${endDue}` : "",
        "filter[protocol]":
          Array.isArray(protocol) && protocol[0]
            ? `${protocol[0]?.protocolId}`
            : "",
        "filter[audit_group]":
          Array.isArray(group) && group[0] ? `${group[0]?.id}` : "",
        "filter[name]": query ? query : "",
        page: page || 1,
        perPage: perPage || 10,
        include: "protocol,facility,labels",
        sort: !!sortState?.sortAsc
          ? sortState?.sortColumn
          : `-${sortState?.sortColumn}`,
      },
    });

    auditsPromise.promise
      .then(({ data: audits, meta }) => {
        setMeta(meta);
        setAudits(audits);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setTimeout(() => setIsLoading(false), 500));

    subscribedPromises.current.push(auditsPromise);
  }, [
    page,
    group,
    query,
    perPage,
    protocol,
    facilityId,
    endDueDate,
    startDueDate,
    endCreateDate,
    startCreateDate,
    sortState?.sortAsc,
    sortState?.sortColumn,
  ]);

  useEffect(() => {
    if (fetchByDefault) {
      fetchAudits();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchAudits, fetchByDefault]);

  return {
    meta,
    error,
    audits,
    setAudits,
    isLoading,
    fetchAudits,
  };
};

export default useFetchFacilityAudits;
