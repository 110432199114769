import React, { Fragment, useContext, useState } from "react";

import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import ActionItemHistory from "../../ActionItems/ActionItemHistory";
import ActionItemUpdate from "../ActionItemUpdate";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context";
import { getDashboardData } from "features/actionItems/utils";
import ActionItemCollapseView from "../../ActionItems/ActionItemCollapseView";

const ActionItemListItem = () => {
  const { actionItems, isLoading, error, tableData } =
    useContext(ActionItemsContext);
  const [expandableId, setExpandableId] = useState("");

  const dispatch = useContext(ActionItemsDispatchContext);

  const handleExpandleId = (id) => setExpandableId(id);

  const onActionItemUpdated = (updatedActionItem) => {
    dispatch({ type: "UPDATE_ACTION_ITEM", payload: updatedActionItem });
  };

  const onActionItemDeleted = (deletedActionItem) => {
    dispatch({ type: "DELETE_ACTION_ITEM", payload: deletedActionItem });
  };

  if (isLoading)
    return (
      <tbody className="table-group-divider">
        <tr>
          <td colSpan={12}>
            <Loader />
          </td>
        </tr>
      </tbody>
    );

  if (error)
    return (
      <tbody className="table-group-divider">
        <tr>
          <td colSpan={12}>
            <ErrorHandler error={error} />
          </td>
        </tr>
      </tbody>
    );

  if (!Array.isArray(actionItems) || !actionItems?.length)
    return (
      <tbody className="table-group-divider">
        <tr>
          <td colSpan={12}>
            <EmptyStateHandler
              title="No action items"
              description="Try using a different filter."
            />
          </td>
        </tr>
      </tbody>
    );
  return (
    <tbody className="table-group-divider">
      {actionItems.map((actionItem) => {
        const { actionItemId, item } = actionItem;
        const values = getDashboardData(actionItem, tableData);

        return (
          <Fragment key={actionItemId}>
            <tr>
              <td className="cursor-pointer">
                {!(expandableId === actionItemId) ? (
                  <span
                    translate="no"
                    className="material-symbols-outlined md-20"
                    onClick={() => handleExpandleId(actionItemId)}
                  >
                    expand_more
                  </span>
                ) : (
                  <span
                    translate="no"
                    onClick={() => handleExpandleId("")}
                    className="material-symbols-outlined md-20"
                  >
                    expand_less
                  </span>
                )}
              </td>
              <td
                className="text-primary cursor-pointer"
                onClick={() =>
                  !(expandableId === actionItemId)
                    ? handleExpandleId(actionItemId)
                    : handleExpandleId("")
                }
              >
                {item} <br />
                <small className="text-secondary">{actionItemId}</small>
              </td>
              {values
                .filter(({ hidden }) => !hidden)
                .map(
                  ({ component, val, key }) =>
                    typeof component === "function" && (
                      <td key={key} className="text-wrap">
                        {component(val)}
                      </td>
                    )
                )}
              <td className="text-end">
                <div className="d-flex flex-row">
                  <ActionItemHistory actionItemId={actionItem.actionItemId} />
                  <ActionItemUpdate
                    actionItem={actionItem}
                    onActionItemUpdated={onActionItemUpdated}
                    onActionItemDeleted={onActionItemDeleted}
                  />
                </div>
              </td>
            </tr>
            <ActionItemCollapseView
              key={actionItemId}
              actionItem={actionItem}
              expandableId={expandableId}
              onActionItemUpdated={onActionItemUpdated}
            />
          </Fragment>
        );
      })}
    </tbody>
  );
};

export default ActionItemListItem;
