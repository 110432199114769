import { createContext, useReducer } from "react";
import { ToastContainer } from "react-bootstrap";
import ToastNotification from "components/ui/ToastNotification";

export const ToastContext = createContext();

const initialState = {
  toasts: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case "DELETE_TOAST":
      const updatedToasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
      return {
        ...state,
        toasts: updatedToasts,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const ToastContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addToast = (type, title, body) => {
    const id = Math.floor(Math.random() * 10000000);
    dispatch({ type: "ADD_TOAST", payload: { id, title, body, type } });
  };

  const value = {
    success: (title, body) => {
      addToast("success", title, body);
    },
    warning: (title, body) => {
      addToast("warning", title, body);
    },
    info: (title, body) => {
      addToast("info", title, body);
    },
    error: (title, body) => {
      addToast("error", title, body);
    },
    remove: (id) => {
      dispatch({ type: "DELETE_TOAST", payload: id });
    },
  };

  return (
    <ToastContext.Provider value={value}>
      <ToastContainer
        position="bottom-end"
        className="position-fixed bottom-0 end-0 p-3"
      >
        {state?.toasts.map((toast) => (
          <ToastNotification key={toast.id} {...toast} />
        ))}
      </ToastContainer>
      {children}
    </ToastContext.Provider>
  );
};
