import React, { useContext, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import { FacilityTypeahead } from "features/entity";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { OrganizationContext } from "contexts/OrganizationProvider";

const ManagePortfolio = ({ portfolio, onManagePortfolio }) => {
  const { isAdmin } = useRoles();
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updatePortofolio = (name, description, facilityIds = []) => {
    setError(null);
    setIsLoading(true);
    const portfolioPromise = put(
      `organizations/${organization?.id}/portfolios/${portfolio?.id}`,
      {
        name: name,
        description: description,
        facility_ids: facilityIds,
      }
    );
    portfolioPromise.promise
      .then(({ data }) => {
        handleClose();
        onManagePortfolio(data);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(portfolioPromise);
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Name is too Short!")
      .max(100, "Name is Long!")
      .required("Name is required"),
    description: yup
      .string()
      .min(2, "Description is too Short!")
      .max(255, "Description is too Long!")
      .notRequired(),
    facilities: yup.array().notRequired(),
  });

  if (!isAdmin) return <></>;

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} size="sm">
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          tune
        </span>
        Manage Portfolio
      </Button>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Manage Portfolio</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { name, description, facilities } = values;
            const facilityIds = facilities.map(({ facilityId }) => facilityId);
            updatePortofolio(name, description, facilityIds);
          }}
          initialValues={{
            name: portfolio?.name || "",
            description: portfolio?.description || "",
            facilities: Array.isArray(portfolio?.facilities)
              ? portfolio?.facilities
              : [],
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label className="mb-1">
                    Portfolio Name <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                    isValid={values.name && !errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label className="mb-1">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.description && touched.description}
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="facility" className="mb-3">
                  <Form.Label className="mb-1">Select Entities</Form.Label>
                  <FacilityTypeahead
                    multiple
                    selected={values.facilities}
                    onChange={(facility) =>
                      setFieldValue("facilities", facility)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.facilities && touched.facilities ? (
                      <small>{errors.facilities}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  className="px-3"
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  className="px-3"
                  disabled={isLoading}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ManagePortfolio.propTypes = {
  portfolio: PropTypes.object.isRequired,
  onManagePortfolio: PropTypes.func.isRequired,
};

export default ManagePortfolio;
