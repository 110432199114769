import EmptyStateHandler from "components/ui/EmptyStateHandler";
import Loader from "components/ui/Loader";
import { useFormikContext } from "formik";
import { useRoles } from "hooks";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFetchAudits } from "services";

const AssessmentList = ({ query }) => {
  const {
    values: {
      protocolId,
      assessments,
      search,
      sortValue,
      updatedAt,
      facilityId,
      reset,
    },
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();
  const { isMember, isAdmin } = useRoles();

  const isfetch = isMember ? protocolId && facilityId : protocolId;

  const { isLoading, error, audits } = useFetchAudits({
    protocolId,
    facilityId,
    search,
    updatedAt,
    sortValue,
    isfetch,
  });

  const isQueryWitOutAssessments = !!query && !audits?.length;
  const assessmentsList =
    (Array.isArray(audits) && !!audits?.length) || isQueryWitOutAssessments;

  const adminAssessmentNotFound =
    (!assessmentsList && isAdmin) || isQueryWitOutAssessments;
  const memberAssessmentNotFound =
    (!assessmentsList && isMember && facilityId) || isQueryWitOutAssessments;

  const handleChange = (checked, auditId) => {
    reset();
    setFieldValue(
      "assessments",
      checked
        ? [...assessments, auditId]
        : assessments.filter((id) => id !== auditId)
    );
  };

  useEffect(() => {
    setFieldValue("error", error);
  }, [error, setFieldValue]);

  if (isLoading) return <Loader />;

  if (adminAssessmentNotFound) {
    return (
      <EmptyStateHandler
        title="No Assessments Found"
        description="Try using a different protocol."
      />
    );
  }

  if (memberAssessmentNotFound) {
    return (
      <EmptyStateHandler
        title="No Assessments Found"
        description="Try using a different Entity or Protocol."
      />
    );
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.Check
            className="mb-2"
            disabled={isLoading}
            onChange={(e) => {
              reset();
              const { checked } = e.target;
              setFieldValue(
                "assessments",
                !checked ? [] : audits.map(({ auditId }) => auditId)
              );
            }}
            inline
            label="Select All"
            name="select-all"
            type="checkbox"
            id="select-all"
          />
        </Col>
        {audits?.map(({ auditId, name, facility }) => (
          <Col xs={12} className={"mb-2"} key={auditId}>
            <Form.Check
              disabled={isLoading}
              checked={assessments.includes(auditId)}
              onChange={(e) => handleChange(e.target.checked, auditId)}
              inline
              label={
                <div>
                  {name}
                  <br />
                  <small className="text-muted">{facility?.name}</small>
                </div>
              }
              name="assessments"
              type="checkbox"
              id={auditId}
            />
          </Col>
        ))}
      </Row>
      {errors.assessments && touched.assessments ? (
        <small className="text-danger">{errors.assessments}</small>
      ) : null}
    </>
  );
};

export default AssessmentList;
