import React, { useState } from "react";

import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import useDeleteEntityTag from "features/account/services/useDeleteEntityTag";

const TagDelete = ({ tag, organizationTagNames, onTagDeleted }) => {
  const { error, isLoading, deleteTag } = useDeleteEntityTag({
    tagId: tag?.organizationLabelId,
    onTagDeleted: () => {
      handleClose();
      onTagDeleted(tag);
    },
  });

  const isEntityTag = organizationTagNames.includes(tag?.name);

  const [show, setShow] = useState(false);
  const [isConfirmationChecked, setIsConfirmationChecked] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setIsConfirmationChecked(false);
  };

  return (
    <>
      <Button
        size="sm"
        className="ms-2"
        onClick={handleShow}
        variant="outline-danger"
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        {isEntityTag ? (
          <Modal.Body className="mt-2">
            <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
              <span className="material-symbols-outlined md-24">warning</span>
            </div>
            <div className="text-center pt-2">
              Tag {tag?.name + " "}
              is associated with an entity and cannot be deleted.
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body className="mt-2">
            <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
              <span className="material-symbols-outlined md-24">warning</span>
            </div>
            <h2 className="text-center pt-2">
              Delete Tag -{" "}
              <small translate="no" className="text-muted">
                {tag?.name}
              </small>
            </h2>
            <p className="mx-4 mt-2">
              <Form.Group>
                <Form.Check
                  label={
                    <small>
                      By checking the box, I confirm that I want to delete this
                      Tag. I understand that this cannot be undone.
                    </small>
                  }
                  onClick={() => {
                    setIsConfirmationChecked(!isConfirmationChecked);
                  }}
                />
              </Form.Group>
            </p>
          </Modal.Body>
        )}
        {error && <ErrorHandler error={error} />}
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            className="px-3"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {!isEntityTag ? (
            <Button
              type="submit"
              variant="danger"
              className="px-3"
              onClick={(values) => {
                deleteTag();
              }}
              initialValues={{
                name: "",
              }}
              size="sm"
              disabled={!isConfirmationChecked || isLoading}
            >
              {isLoading && (
                <Spinner
                  className="me-2"
                  animation="border"
                  size="sm"
                  variant="light"
                />
              )}{" "}
              Delete
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

TagDelete.propTypes = {
  tag: PropTypes.object.isRequired,
  organizationTagNames: PropTypes.array.isRequired,
  onTagDeleted: PropTypes.func.isRequired,
};

export default TagDelete;
