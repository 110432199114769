const chartStatus = [
  {
    index: 0,
    name: "All",
    filter: "",
  },
  {
    index: 1,
    name: "Assigned",
    filter: "assigned",
    value: 0,
  },
  {
    index: 2,
    name: "Submitted",
    filter: "submitted",
    value: 1,
  },
  {
    index: 3,
    name: "Incomplete",
    filter: "incomplete",
    value: 2,
  },
  {
    index: 4,
    name: "Accepted",
    filter: "accepted",
    value: 3,
  },
];

export default chartStatus;
