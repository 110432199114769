const getInAppFileURL = (file, organizationId) => {
  const { isFiles, fileId } = file;

  const _isFiles = isFiles || fileId;

  const _fileId = _isFiles ? file?.id || file?.fileId : file?.id;
  let fileURL = `${window.location.origin}/files/${_fileId}?fileType=${
    _isFiles ? "file" : "media"
  }`;

  if (organizationId) {
    fileURL += `&organizationId=${organizationId}`;
  }

  return fileURL;
};

export { getInAppFileURL };
