import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const ActionItemDueDateHistory = ({ previousDueDate, currentDueDate }) => {
  return (
    <Row className="py-2">
      <Col className="border-0">
        <span className="text-secondary fw-semibold">Previous Due Date:</span>
        <span className="ms-2 fw-semibold">
          <Badge className="bg-danger bg-opacity-10 text-danger">
            {previousDueDate
              ? new Date(previousDueDate).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              : "-"}
          </Badge>
        </span>
      </Col>
      <Col className="border-0">
        <span className="text-secondary fw-semibold">Current Due Date:</span>
        <span className="ms-2 fw-semibold">
          <Badge className="bg-primary bg-opacity-10 text-primary">
            {currentDueDate
              ? new Date(currentDueDate).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              : "-"}
          </Badge>
        </span>
      </Col>
    </Row>
  );
};

ActionItemDueDateHistory.propTypes = {
  previousDueDate: PropTypes.number,
  currentDueDate: PropTypes.number,
};

export default ActionItemDueDateHistory;
