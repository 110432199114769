import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Collapse, Row } from "react-bootstrap";
import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import ActionItemCollapseDescription from "./ActionItemCollapseDescription";
import ActionItemCollapseEvidenceFiles from "./ActionItemCollapseEvidenceFiles";
import ActionItemCollapseComments from "./ActionItemCollapseComments";
import ActionItemLevelRedirect from "./ActionItemLevelRedirect";
import { useFetchActionItemComment } from "features/actionItems/services";

const ActionItemCollapseView = ({
  actionItem,
  expandableId = "",
  onActionItemUpdated,
}) => {
  const { actionItemId, description, evidence } = actionItem;

  const [actionItemComments, setActionItemComments] = useState();

  const {
    isLoading,
    error,
    actionItemComments: data,
  } = useFetchActionItemComment({
    actionItemId,
    expand: !!expandableId,
  });

  useEffect(() => {
    if (!!data?.length) {
      setActionItemComments(
        data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    }
  }, [data]);

  return (
    <>
      <tr className="bg-light">
        <td colSpan={12} className="border-0 m-0 p-0">
          <Collapse
            in={expandableId === actionItemId}
            className="p-3 border-bottom"
          >
            <div>
              <div className="text-center">{isLoading && <Loader />}</div>
              <Row className="px-2">
                <Col xs={12}>
                  <ActionItemLevelRedirect
                    {...actionItem}
                    show={expandableId === actionItemId}
                  />
                </Col>
              </Row>
              <Row className="px-2">
                <Col xs={6} className="d-flex">
                  <Row className="mt-2 w-100 d-flex flex-column justify-content-start">
                    <ActionItemCollapseDescription description={description} />
                    <ActionItemCollapseEvidenceFiles
                      key={actionItemId}
                      evidence={evidence}
                    />
                  </Row>
                </Col>
                <Col xs={6}>
                  <ActionItemCollapseComments
                    actionItem={actionItem}
                    actionItemComments={actionItemComments}
                    onActionItemUpdated={onActionItemUpdated}
                  />
                </Col>
              </Row>
              {error && <ErrorHandler error={error} />}
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

ActionItemCollapseView.propTypes = {
  actionItem: PropTypes.object.isRequired,
  expandableId: PropTypes.string.isRequired,
  onActionItemUpdated: PropTypes.func.isRequired,
};

export default ActionItemCollapseView;
