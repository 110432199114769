import { useContext, useEffect, useRef, useState } from "react";

import { useReactToPrint } from "react-to-print";

import { post } from "utils/MlWord";
import { OrganizationContext } from "contexts/OrganizationProvider";

import { useVertical } from "../hooks";
// import { saveAs } from "file-saver";

const useDownloadAuditWord = ({ toPrintRef, auditName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const { generatedFileNameShouldOnlyHaveAssessmentName } = useVertical();

  const downloadAudit = useReactToPrint({
    content: () => toPrintRef.current,
    print: async (printIframe) => {
      setIsLoading(true);

      const document = printIframe.contentDocument;
      if (document) {
        var base = document.createElement("base");
        base.href = window.location.origin;
        document.getElementsByTagName("head")[0].appendChild(base);

        var head = document.getElementsByTagName("head")[0];

        fetch("/report.css")
          .then((response) => response.text())
          .then((css) => {
            document
              .querySelectorAll("head link[rel='stylesheet']")
              .forEach((linkTag) => linkTag.remove());

            const html = document.getElementsByTagName("html")[0];
            document
              .querySelectorAll(" head style")
              .forEach((styleTag) => styleTag.remove());

            const style = document.createElement("style");
            style.innerHTML = css;
            head.appendChild(style);

            const lang = window.document
              .getElementsByTagName("html")[0]
              .getAttribute("lang");
            html.setAttribute("lang", lang);
            const metadatas = window.document.getElementsByTagName("meta");

            for (let i = 0; i < metadatas.length; i++) {
              const metadata = metadatas[i];
              const name = metadata.getAttribute("name");
              const content = metadata.getAttribute("content");
              if (name && content) {
                const meta = document.createElement("meta");
                meta.setAttribute("name", name);
                meta.setAttribute("content", content);
                head.appendChild(meta);
              }
            }

            const htmlFile = new Blob([html.outerHTML]);
            const formData = new FormData();
            let filename = generatedFileNameShouldOnlyHaveAssessmentName
              ? `${auditName}.docx`
              : `${organization.name} ${auditName} Assessment Report.docx`;
            // saveAs(htmlFile, "audit.html");

            formData.append("file", htmlFile);
            const responsePromise = post(
              `${process.env.REACT_APP_HTML_TO_PDF_TO_WORD}`,
              formData
            );
            responsePromise.promise
              .then((response) => {
                const url = URL.createObjectURL(response);

                const link1 = document.createElement("a");
                link1.href = url;

                link1.download = filename;
                document.body.appendChild(link1);
                link1.click();
                document.body.removeChild(link1);

                URL.revokeObjectURL(url);
              })
              .catch((err) => setError(err))
              .finally(() => {
                setIsLoading(false);
              });

            subscribedPromises.current.push(responsePromise);
          });
      }
    },
  });

  useEffect(() => {
    const promises = subscribedPromises?.current || [];
    return () => {
      promises?.forEach((promise) => {
        promise?.cancel?.();
      });
    };
  }, []);

  return { error, isLoading, downloadAudit };
};

export default useDownloadAuditWord;
