const IMAGE_EXTENSIONS = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "bmp",
  "tiff",
  "svg",
  "webp",
];
export default IMAGE_EXTENSIONS;
