import { useRoles } from "hooks";
import { Dropdown, Spinner } from "react-bootstrap";

import { useDownloadAuditListCSV } from "features/assessment/services";

const AuditListExport = ({ filters }) => {
  const { isCertifier } = useRoles();

  const { isLoading, downloadAuditListCSV } = useDownloadAuditListCSV({
    search: filters?.search,
    dueDate: filters?.dueDate,
    updatedAt: filters?.updatedAt,
    locked: filters?.lockStatus?.locked,
    status: filters?.lockStatus?.status,
    protocolId: filters?.protocols?.protocolId,
    facilityId: filters?.entity?.facilityId || filters?.facilityId,
  });

  if (isCertifier) return <></>;

  return (
    <Dropdown.Item onClick={() => downloadAuditListCSV()}>
      <span>Assessment List</span>{" "}
      {isLoading ? (
        <Spinner className="me-1" animation="border" size="sm" />
      ) : (
        <span translate="no" className="material-symbols-outlined md-18">
          file_download
        </span>
      )}{" "}
    </Dropdown.Item>
  );
};

export default AuditListExport;
