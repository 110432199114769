import { useEffect, useRef, useState } from "react";
import { put } from "utils/DeApi";

const useUpdateTag = ({ tag, onTagUpdated }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const updateTag = (values) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = put(`/labels/${tag?.labelId}`, {
      name: values.name,
      color: values.color,
      description: values.description,
    });
    auditPromise.promise
      .then(({ data: tag }) => {
        onTagUpdated(tag);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return {
    isLoading,
    error,
    updateTag,
  };
};

export default useUpdateTag;
