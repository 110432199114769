import { useContext } from "react";

import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ReportAggregation from "components/common/ReportAggregation";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { RISK_OPTION } from "features/organization/constants/statsFilterOptions";
import { useFetchOrganizationProtocolStats } from "features/organization/services";
import {
  aggregationOfRisk,
  aggregationOfRiskTrends,
  defaultRisk,
  dynamicLabels,
} from "helper/reporting";

import "./OrganizationReportRisk.scss";

function OrganizationReportRisk({ protocol, filterPeriod }) {
  const organization = useContext(OrganizationContext);
  const riskLevels =
    protocol?.riskLevels && protocol?.riskLevels.length
      ? protocol?.riskLevels
      : defaultRisk;

  const { error, isLoading, organizationStats } =
    useFetchOrganizationProtocolStats({
      option: RISK_OPTION,
      period: filterPeriod?.period,
      protocolId: protocol?.protocolId,
    });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!organizationStats) return <></>;
  if (!organizationStats.length)
    return (
      <EmptyStateHandler
        title="No data to visualize"
        description="You have no data to visualize."
      />
    );

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={4} className="mb-3 border-end">
        <p className="text-center w-100 text-truncate text-capitalize">
          Distribution of {protocol?.questionOptions[0]?.name ?? "Risk Rating"}
        </p>
        <hr />
        <ReportAggregation
          size="square-container"
          labels={dynamicLabels(riskLevels)}
          data={aggregationOfRisk(organizationStats, riskLevels)}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={8} className="mb-4">
        <p className="text-center w-100 text-truncate text-capitalize">
          {protocol?.questionOptions[0]?.name ?? "Risk Rating"} trends across ·{" "}
          <small className="text-muted">{organization?.name}</small>
        </p>
        <hr />
        <ReportTrend
          size="square-container"
          labels={dynamicLabels(riskLevels)}
          data={aggregationOfRiskTrends(
            organizationStats,
            riskLevels,
            filterPeriod
          )}
        />
      </Col>
    </Row>
  );
}

export default OrganizationReportRisk;
