import { useEffect, useRef } from "react";
import { post } from "utils/DeApi";

const usePostOrganizationSelected = () => {
  const subscribedPromises = useRef([]);

  const postOrganizationSelected = (orgId) => {
    const orgPromise = post(`/current-organization`, {
      organization_id: orgId,
    });
    orgPromise.promise.catch(
      (error) => !error.isCanceled && console.error(error)
    );
    subscribedPromises.current.push(orgPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { postOrganizationSelected };
};

export default usePostOrganizationSelected;
