import React, { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";

import { get, put } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";

import JoinOrganization from "../JoinOrganization";

const Invitations = () => {
  const toast = useToast();
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isExpanding, setIsExpanding] = useState();
  const [invitations, setInvitations] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleJoinAll = () => {
    if (invitations && invitations?.length) {
      setError(null);
      setIsExpanding(true);

      const invitationPromises = invitations.map(({ id }) => {
        const invitationPromise = put(`/invitations/${id}`);
        subscribedPromises.current.push(invitationPromise);
        return invitationPromise.promise;
      });

      Promise.all(invitationPromises)
        .then((response) => {
          response.forEach(({ data }) => {
            setInvitations((prevState) =>
              prevState.filter(({ id }) => id !== data.id)
            );
          });
          handleClose();
          toast.success("Success", "Joined Successfully!");
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsExpanding(false));
    }
  };

  useEffect(() => {
    const fetchInvitations = () => {
      setError(null);
      setIsLoading(true);
      const invitationPromise = get("/invitations", {
        params: { verticalId: process.env.REACT_APP_VERTICAL_ID },
      });
      invitationPromise.promise
        .then(({ data: invitations }) => {
          setIsLoading(false);
          setInvitations(invitations);
          if (invitations && invitations?.length) handleShow();
        })
        .catch((error) => {
          setIsLoading(false);
          !error.isCanceled && setError(error);
        });
      subscribedPromises.current.push(invitationPromise);
    };

    fetchInvitations();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Organizations access invitations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {Array.isArray(invitations) &&
          invitations.map((invitation) => (
            <Row key={invitation.id} className="justify-content-between my-2">
              <Col>
                <span>{invitation?.organization?.name}</span>
              </Col>
              <Col className="text-end">
                <JoinOrganization
                  invitationId={invitation?.id}
                  onJoined={(data) => {
                    setInvitations((prevState) => {
                      const invitations = prevState.filter(({ id }) => {
                        return id !== data.id;
                      });
                      if (invitations.length === 0) handleClose();
                      return invitations;
                    });
                  }}
                />
              </Col>
            </Row>
          ))}
        {error && <ErrorHandler error={error} />}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          disabled={isExpanding}
          onClick={handleJoinAll}
        >
          {isExpanding && (
            <Spinner
              className="me-2"
              animation="border"
              size="sm"
              variant="light"
            />
          )}
          Join All
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Invitations.propTypes = {};

export default Invitations;
