import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const ActionItemAssigneesHistory = ({
  removedAssignee,
  addedAssignee,
  type,
}) => {
  return (
    <Row className="py-2">
      {type === "deleted" ? (
        <Col className="border-0">
          <span className="text-secondary fw-semibold">Removed Assignee:</span>
          <span className="ms-2 fw-semibold">
            <Badge className="bg-info bg-opacity-10 text-info">
              {removedAssignee || "-"}
            </Badge>
          </span>
        </Col>
      ) : null}
      {type === "created" ? (
        <Col className="border-0">
          <span className="text-secondary fw-semibold">Added Assignee:</span>
          <span className="ms-2 fw-semibold">
            <Badge className="bg-info bg-opacity-10 text-info">
              {addedAssignee}
            </Badge>
          </span>
        </Col>
      ) : null}
    </Row>
  );
};

ActionItemAssigneesHistory.propTypes = {
  removedAssignee: PropTypes.string.isRequired,
  addedAssignee: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ActionItemAssigneesHistory;
