import { useEffect, useState, useRef } from "react";
import { get } from "utils/BeeApi";
const useFetchContent = (contentId) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [content, setContent] = useState();
  useEffect(() => {
    function fetchContent() {
      setIsLoading(true);
      const contentsPromise = get(`contents/${contentId}`);
      contentsPromise.promise
        .then(({ data: content }) => {
          setContent(content);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
          }
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(contentsPromise);
    }

    fetchContent();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [contentId]);
  return {
    error,
    content,
    isLoading,
  };
};
export default useFetchContent;
