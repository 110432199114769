import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { put } from "utils/DeApi";
import { Button, Spinner, Toast } from "react-bootstrap";

const ResendInvitation = ({ subscriber }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [show, setShow] = useState(false);

  const handleResendInvitation = () => {
    setError(null);
    setIsLoading(true);
    const resendInvitationPromise = put(
      `/invitations/${subscriber?.invitation?.id}/resend`
    );
    resendInvitationPromise.promise
      .then(() => {
        setShow(true);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setShow(true);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(resendInvitationPromise);
  };

  return (
    <Button
      variant="outline-dark"
      className="me-1"
      size="sm"
      onClick={handleResendInvitation}
    >
      {isLoading ? (
        <Spinner size="sm" animation="border" />
      ) : (
        <span translate="no" className="material-symbols-outlined md-16">
          refresh
        </span>
      )}
      {!error
        ? createPortal(
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              bg="primary"
              className="position-fixed bottom-0 end-0 me-3 mb-3 border-0"
              autohide
            >
              <Toast.Body
                className={`d-flex flex-row text-white text-opacity-75`}
              >
                <div className="me-3">
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18"
                  >
                    info
                  </span>
                </div>
                <div>
                  <h5 className="mb-1">success</h5>
                  <p className="mb-1">
                    Invitation was successfully sent to <br />
                    <strong>{`${subscriber.firstName} ${subscriber.lastName}`}</strong>
                  </p>
                </div>
              </Toast.Body>
            </Toast>,
            document.body
          )
        : createPortal(
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={5000}
              bg="warning"
              className="position-fixed bottom-0 end-0 me-3 mb-3 border-0"
              autohide
            >
              <Toast.Body className={`d-flex flex-row`}>
                <div className="me-3">
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18"
                  >
                    warning
                  </span>
                </div>
                <div>
                  <h5 className="mb-1">{"Oh snap! You got an error!"}</h5>
                  <p className="mb-1">
                    {"Something unexpected happened. Please try again."}
                  </p>
                  <small>{error?.status}</small>
                </div>
              </Toast.Body>
            </Toast>,
            document.body
          )}
    </Button>
  );
};

ResendInvitation.propTypes = {
  subscriber: PropTypes.object.isRequired,
};

export default ResendInvitation;
