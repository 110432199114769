import { useContext, useEffect, useState } from "react";
import { AuditContext } from "../contexts/AuditProvider";

const useNextAndPrevious = (sectionId, questionId) => {
  const { audit } = useContext(AuditContext);

  const [next, setNext] = useState();
  const [previous, setPrevous] = useState();

  useEffect(() => {
    const findNextAndPreviousQuestions = (sectionId, questionId) => {
      const currentSections = audit?.protocol?.sections.filter((section) =>
        section.principles.find((principle) => {
          if (!audit?.selectedPrinciple) return true;
          return (
            principle.principleId === audit?.selectedPrinciple[0]?.principleId
          );
        })
      );

      const sectionIndex = currentSections.findIndex(
        (section) => section.sectionId === sectionId
      );

      const questionIndex = currentSections[sectionIndex]?.questions?.findIndex(
        (question) => question?.questionId === questionId
      );

      let previousQuestion =
        currentSections[sectionIndex]?.questions[questionIndex - 1];

      let previousSectionId = sectionId;
      if (!previousQuestion) {
        let previousSection = currentSections[sectionIndex - 1];
        if (previousSection) {
          previousQuestion =
            previousSection?.questions[previousSection.questions.length - 1];
          previousSectionId = previousSection?.sectionId;
        }
      }

      let nextQuestion =
        currentSections[sectionIndex]?.questions[questionIndex + 1];
      let nextSectionId = sectionId;
      if (!nextQuestion) {
        let nextSection = currentSections[sectionIndex + 1];
        if (nextSection) {
          nextQuestion = nextSection?.questions[0];
          nextSectionId = nextSection?.sectionId;
        }
      }

      let next = nextQuestion
        ? `/audits/${audit.auditId}/sections/${nextSectionId}/questions/${nextQuestion.questionId}`
        : "";

      let previous = previousQuestion
        ? `/audits/${audit.auditId}/sections/${previousSectionId}/questions/${previousQuestion.questionId}`
        : "";

      setNext(next);
      setPrevous(previous);
    };

    if (sectionId && questionId)
      findNextAndPreviousQuestions(sectionId, questionId);
  }, [audit, sectionId, questionId]);

  return { next: next, previous: previous };
};

export default useNextAndPrevious;
