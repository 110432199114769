import { OrganizationContext } from "contexts/OrganizationProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { getActionItemsTrends, getChartData } from "../utils";

const useFetchActionItemStats = ({ filters }) => {
  const { filterPeriod, filterStatus } = filters;
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [reportTrend, setReportTrend] = useState({});
  const [statusChartData, setStatusChartData] = useState([]);
  const [dueAndOverDueChartData, setDueAndOverDueCharData] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchActionItemStats = useCallback(() => {
    setError("");
    setIsLoading(true);
    const actionItemStatsPromise = get(
      `/organizations/${organization?.id}/action-items-stats`,
      {
        params: {
          period: filterPeriod?.period,
          status: filterStatus.value,
        },
      }
    );
    actionItemStatsPromise.promise
      .then(({ data }) => {
        const { statusChartData, dueAndOverDueChartData, actionItemStats } =
          getChartData(data, filterStatus);
        setReportTrend(getActionItemsTrends(actionItemStats, filterPeriod));
        setStatusChartData(statusChartData);
        setDueAndOverDueCharData(dueAndOverDueChartData);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(actionItemStatsPromise);
  }, [filterPeriod, filterStatus, organization?.id]);

  useEffect(() => {
    fetchActionItemStats();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchActionItemStats]);

  return {
    statusChartData,
    dueAndOverDueChartData,
    reportTrend,
    error,
    isLoading,
  };
};

export default useFetchActionItemStats;
