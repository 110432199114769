import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";
import PropTypes from "prop-types";

import { put } from "utils/DeApi";

const useUpdateFacilityAuditGroup = ({ groupId, group, onGroupUpdated }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const updateFacilityAuditGroup = (group) => {
    setError(null);
    setIsLoading(true);
    const facilityGroupPromise = put(`/audit-groups/${groupId}`, {
      ...group,
    });
    facilityGroupPromise.promise
      .then(({ data }) => {
        if (typeof onGroupUpdated === "function") onGroupUpdated(data);
        toast.success("Sucess", "Entity assessment group updated successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(facilityGroupPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    updateFacilityAuditGroup,
  };
};

useUpdateFacilityAuditGroup.propTypes = {
  onGroupUpdated: PropTypes.func.isRequired,
};

export default useUpdateFacilityAuditGroup;
