import React from "react";

import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import OverlayTriggerButton from "components/ui/OverlayTriggerButton";
import { useDeleteActionItemComment } from "features/actionItems/services";

const ActionItemCommentDelete = ({
  commentId,
  actionItemId,
  setActionItemComments,
}) => {
  const { isLoading, deleteActionItemComment } = useDeleteActionItemComment({
    actionItemId,
    commentId,
    onActionItemCommentDeleted: (commentId) => {
      setActionItemComments((prevActionItemComments) => {
        const comments = prevActionItemComments.filter((comment) => {
          return commentId !== comment?.id;
        });
        return comments;
      });
    },
  });

  return (
    <OverlayTriggerButton
      toolTipMessage="Delete"
      variant="outline-danger"
      className="ms-2"
      onClickHandler={() => deleteActionItemComment()}
      label={
        isLoading ? (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="light"
          />
        ) : (
          <span translate="no" className="material-symbols-outlined md-18">
            delete
          </span>
        )
      }
    />
  );
};

ActionItemCommentDelete.propTypes = {
  commentId: PropTypes.string.isRequired,
  actionItemId: PropTypes.string.isRequired,
  setActionItemComments: PropTypes.func.isRequired,
};

export default ActionItemCommentDelete;
