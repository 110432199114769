import React from "react";
import { Link } from "react-router-dom";
import "./TextLogo.scss";

const TextLogo = () => (
  <div className="d-flex align-items-center justify-content-center TextLogo">
    <div className="py-4 text-end">
      <Link to="/">
        {!!process.env.REACT_APP_VERTICAL ? (
          <img src={`/erm-logo-green-empty.svg`} alt="Logo" height="72" />
        ) : (
          <img src={"/logo-online.svg"} alt="Logo" height="72" />
        )}
      </Link>
    </div>
    {!!process.env.REACT_APP_VERTICAL && (
      <div className="text-nowrap text-center">
        <h1 className="mb-0 ms-2 align-middle">
          <big>{process.env.REACT_APP_VERTICAL}</big>
        </h1>
      </div>
    )}
  </div>
);

export default TextLogo;
