import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import OverlayTriggerButton from "components/ui/OverlayTriggerButton";

import FacilityUpdate from "../../common/FacilityUpdate";
import { setAccentColor } from "utils/EntityTagUtils";

const FacilityListItem = ({ error, isLoading, facilities, setFacilities }) => {
  const handleFacilityUpdated = (updatedFacility) => {
    setFacilities((prevFacilities) =>
      prevFacilities.map((facility) => {
        if (facility?.facilityId === updatedFacility?.facilityId)
          return {
            ...updatedFacility,
            auditCount: facility?.auditCount,
          };
        return facility;
      })
    );
  };

  if (isLoading)
    return (
      <tr>
        <td colSpan={8}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={8}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (!facilities) return <></>;

  if (!facilities.length)
    return (
      <tr>
        <td colSpan={8}>
          <EmptyStateHandler
            title="No entities found"
            description="There are currently no entities to show"
          />
        </td>
      </tr>
    );

  return facilities.map((facility) => {
    const {
      facilityId,
      name,
      address,
      updatedAt,
      labels,
      auditCount = 0,
      actionItemsCount = 0,
    } = facility || {};

    labels.sort((a, b) => (a.name > b.name ? 1 : -1));

    return (
      <tr key={facilityId}>
        <td>
          <p className="p-0 m-0 mb-2">
            <Link
              to={`/facilities/${facilityId}`}
              size="sm"
              className="text-decoration-none"
              variant="secondary"
            >
              {name}
            </Link>
          </p>
          {Array.isArray(labels) &&
            labels.map(({ organizationLabelId, name, color }) => (
              <small
                key={organizationLabelId}
                className="me-2 rounded-2 badge fw-normal"
                style={{
                  borderWidth: 1,
                  borderColor: color,
                  backgroundColor: `${color}`,
                  borderStyle: "solid",
                  color: setAccentColor(color),
                }}
              >
                {name}
              </small>
            ))}
        </td>
        <td>
          <small className="me-2 text-wrap">{address}</small>
        </td>
        <td className="text-center">{auditCount}</td>
        <td className="text-center">{actionItemsCount}</td>
        <td>
          <small>
            {new Date(updatedAt).toLocaleString([], {
              timeZone: "UTC",
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        <td className="text-end">
          <div className="d-flex flex-row">
            <div className="flex-fill"></div>
            <div className="me-2">
              <FacilityUpdate
                facility={facility}
                onFacilityUpdated={handleFacilityUpdated}
                children={
                  <OverlayTriggerButton
                    toolTipMessage="Edit"
                    label={
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18"
                      >
                        edit
                      </span>
                    }
                    variant="outline-primary"
                  />
                }
              />
            </div>
          </div>
        </td>
      </tr>
    );
  });
};

FacilityListItem.propTypes = {
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  facilities: PropTypes.array,
  setFacilities: PropTypes.func.isRequired,
};

export default FacilityListItem;
