const doneStatus = [
  {
    index: 0,
    name: "Assigned",
    filter: 0,
  },
  {
    index: 1,
    name: "Submitted",
    filter: 1,
  },
  {
    index: 2,
    name: "Incomplete",
    filter: 2,
  },
  {
    index: 3,
    name: "Accepted",
    filter: 3,
  },
];
export default doneStatus;
