const colorMap = {
  0: {
    color: "#c65911",
    label: "Critical",
  },
  1: {
    color: "#ff0000",
    label: "High",
  },
  2: {
    color: "#bf8f00",
    label: "Medium",
  },
  3: {
    color: "#ffc000",
    label: "Low",
  },
  4: {
    color: "#964707",
    label: "Meets",
  },
  5: {
    color: "#0070c0",
    label: "Leading",
  },

  6: {
    color: "#525252",
    label: "N/A",
  },

  7: {
    color: "#525252",
    label: "Best Practices Suggestions",
  },

  8: {
    color: "#525252",
    label: "Best Practice Suggestions",
  },

  9: {
    color: "#ed7d31",
    label: "Industry Best Practice",
  },
  10: {
    color: "#a9d18e",
    label: "Contractual",
  },
  11: {
    color: "#2e75b6",
    label: "Regulatory",
  },
};

export default colorMap;
