import { useState, useEffect, useRef, useContext } from "react";

import { post } from "utils/DeApi";
import { AuditContext } from "../contexts/AuditProvider";

const usePostMLConformityLevelValidation = (observationId, isDone) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [conformityLevelLabel, setConformityLevelLabel] = useState("");

  const [conformityLevelMLValidation, setConformityLevelMLValidation] =
    useState(null);

  const { audit } = useContext(AuditContext);

  const fetchMLConformityLevelValidation = () => {
    if (observationId === "default") return;

    setIsLoading(true);
    setError(false);

    const observations = audit?.observations;

    // Find the observation that matches the provided observationId
    const observation = observations?.find(
      (obs) => obs.observationId === observationId
    );

    // Get text(only textfield in an observation)
    const notesValue =
      observation?.responses?.find(
        (response) => response.flexibleQuestion?.questionType === "Text Field"
      )?.notes || "";

    // Extract conformity level value
    const conformityLevelLabel =
      observation?.responses?.find(
        (response) =>
          response.flexibleQuestion?.prompt.toLowerCase() === "conformity level"
      )?.choice?.label || "";
    setConformityLevelLabel(conformityLevelLabel);

    // Get category(from question name)
    const category =
      audit.questions.find(
        (question) => question.questionId === observation?.questionId
      )?.prompt || "";
    const formattedCategory = category.replace(/<[^>]+>/g, "").toLowerCase();
    const validCategory = ["program", "training", "implementation"].find(
      (term) => formattedCategory.includes(term)
    );

    const payload = {
      text: notesValue,
      conformityLevel: conformityLevelLabel,
      category: validCategory,
    };

    const conformityLevelPromise = post(
      "ml/responses/conformity-levels/validate",
      payload
    );
    conformityLevelPromise.promise
      .then((response) => {
        if (!conformityLevelPromise.isCanceled) {
          setConformityLevelMLValidation(response);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(true);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(conformityLevelPromise);
  };

  useEffect(() => {
    fetchMLConformityLevelValidation();

    return () => {
      subscribedPromises.current.forEach(
        (promise) => promise.cancel && promise.cancel()
      );
      subscribedPromises.current = [];
    };
  }, [observationId, isDone]);

  return {
    isLoading,
    error,
    conformityLevelLabel,
    conformityLevelMLValidation,
  };
};

export default usePostMLConformityLevelValidation;
