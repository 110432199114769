import { useRef, useState } from "react";

import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import DataExport from "components/DataExport";
import SortColumn from "components/ui/SortColumn";
import MetaPagination from "components/ui/MetaPagination";
import { useFetchFacilityAudits } from "features/assessment/services";
import {
  sortingColumns,
  FacilityAuditTableFilter,
} from "features/assessment/constants/auditTablesFilters";

import AuditListItem from "./AuditListItem";
import GroupTypeahead from "../GroupTypeahead";
import AuditListExport from "./AuditListExport";
import AuditCreate from "../AuditCreate/AuditCreate";
import ProtocolTypeahead from "../ProtocolTypeahead";

const AuditList = () => {
  const { facilityId } = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const [filters, setFilters] = useState(FacilityAuditTableFilter);

  const [tableSortState, setTableSortState] = useState(
    FacilityAuditTableFilter?.sortState
  );

  const { error, isLoading, meta, audits, setAudits } = useFetchFacilityAudits({
    facilityId,
    page: filters?.page,
    group: filters?.group,
    query: filters?.query,
    perPage: filters?.perPage,
    protocol: filters?.protocol,
    endDueDate: filters?.endDueDate,
    startDueDate: filters?.startDueDate,
    endCreateDate: filters?.endCreateDate,
    startCreateDate: filters?.startCreateDate,
    sortState: tableSortState,
  });

  const sendSearchQuery = useRef(
    debounce((q) => {
      setFilters((prev) => ({ ...prev, query: q, page: 1 }));
    }, 1000)
  );

  const resetFilters = () => {
    setQuery("");
    sendSearchQuery.current("");
    setFilters(FacilityAuditTableFilter);
    setTableSortState(FacilityAuditTableFilter?.sortState);
  };

  const sortAssessments = (sortingvalue) => {
    if (isLoading) return false;
    setTableSortState({
      sortAsc: !tableSortState.sortAsc,
      sortColumn: sortingvalue,
    });
  };

  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-row mb-2 flex-wrap">
          <div className="flex-grow-1">
            <h2 className="pt-1 mb-0">Assessments</h2>
          </div>
          <Button
            size="sm"
            as={Link}
            to={`groups`}
            className="me-2"
            variant="outline-primary"
          >
            Assessment Groups{" "}
            <span translate="no" className="material-symbols-outlined md-18">
              arrow_forward
            </span>
          </Button>
          <Dropdown translate="no" autoClose="outside" className="me-2 ">
            <Dropdown.Toggle
              size="sm"
              variant="outline-primary"
              className="w-100 text-start"
            >
              {isLoading ? (
                <Spinner className="me-2" animation="border" size="sm" />
              ) : (
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 me-2"
                >
                  download
                </span>
              )}{" "}
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DataExport variant="" level={"entity"} />
              <Dropdown.Divider className="my-2" />
              <AuditListExport
                filters={{
                  ...filters,
                  facilityId,
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
          <AuditCreate
            facilityId={facilityId}
            onAuditCreated={(audit) => {
              navigate(`/audits/${audit?.auditId}`);
            }}
          />
        </div>
        <Form as={Row}>
          <Form.Group as={Col} lg={4} md={12} sm={12} xs={12} className="mb-3">
            <Form.Control
              type="text"
              value={query}
              onChange={(e) => {
                const q = e.target.value;
                setQuery(q);
                sendSearchQuery.current(q);
              }}
              placeholder="Search"
            />
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <ProtocolTypeahead
              onChange={(protocol) => {
                setFilters((prev) => ({ ...prev, protocol, page: 1 }));
              }}
              selected={filters?.protocol}
            />
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <GroupTypeahead
              facilityId={facilityId}
              onChange={(group) => {
                setFilters((prev) => ({ ...prev, group, page: 1 }));
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={filters?.startCreateDate}
              onChange={(date) => {
                const [start, end] = date;
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  endCreateDate: end,
                  startCreateDate: start,
                }));
              }}
              startDate={filters?.startCreateDate}
              endDate={filters?.endCreateDate}
              selectsRange
              placeholderText="Filter by date range"
              monthsShown={2}
              className="form-control"
              isClearable
            >
              <Button
                size="sm"
                className="mb-1"
                onClick={() => {
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    endCreateDate: "",
                    startCreateDate: "",
                  }));
                }}
              >
                Clear
              </Button>
            </DatePicker>
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={filters?.startDueDate}
              onChange={(date) => {
                const [start, end] = date;
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  endDueDate: end,
                  startDueDate: start,
                }));
              }}
              startDate={filters?.startDueDate}
              endDate={filters?.endDueDate}
              selectsRange
              placeholderText="Filter by due date range"
              monthsShown={2}
              className="form-control"
              isClearable
            >
              <Button
                size="sm"
                className="mb-1"
                onClick={() => {
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    endDueDate: "",
                    startDueDate: "",
                  }));
                }}
              >
                Clear
              </Button>
            </DatePicker>
          </Form.Group>
          <Form.Group as={Col} lg={2} md={6} xs={12} sm={12} className="mb-3">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-sorting"
                className="w-100 text-start border border-gray-900 bg-white text-secondary"
              >
                {tableSortState.sortColumn.includes("name")
                  ? "Name"
                  : "Updated"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortingColumns.map((s) => (
                  <Dropdown.Item
                    key={s.index}
                    onClick={() => sortAssessments(s.value)}
                    active={s.value === tableSortState.sortColumn}
                  >
                    {s.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Col lg={2} md={6} sm={12} xs={12} className="mb-3">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => resetFilters()}
            >
              <span className="material-symbols-outlined md-16">
                restart_alt
              </span>{" "}
              <small>Reset Filters</small>
            </Button>
          </Col>
        </Form>

        <Table hover>
          <thead>
            <tr>
              <th
                className="w-30 pointer"
                onClick={() => sortAssessments("name")}
              >
                Name
                <SortColumn column={"name"} tableSortState={tableSortState} />
              </th>
              <th>Protocol</th>
              <th>Group</th>
              <th
                className="pointer"
                onClick={() => sortAssessments("updated_at")}
              >
                Updated
                <SortColumn
                  column={"updated_at"}
                  tableSortState={tableSortState}
                />
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            <AuditListItem
              error={error}
              audits={audits}
              setAudits={setAudits}
              isLoading={isLoading}
            />
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <MetaPagination
          meta={meta}
          perPage={filters?.perPage}
          setPage={(page) => setFilters({ ...filters, page })}
          setPerPage={(perPage) => setFilters({ ...filters, perPage })}
        />
      </Card.Footer>
    </Card>
  );
};

AuditList.propTypes = {};

export default AuditList;
