import { useCallback } from "react";
const useSetResponsivess = () => {
  const setResponsiveness = useCallback((bodyNode) => {
    if (bodyNode !== null) {
      let aTags = bodyNode.getElementsByTagName("a");
      for (let aTag of aTags) {
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("rel", "noopener noreferrer");
      }

      let imgTags = bodyNode.getElementsByTagName("img");
      for (let img of imgTags) {
        img.setAttribute("class", "img-fluid");
      }

      let iframeTags = bodyNode.getElementsByTagName("iframe");
      for (let iframe of iframeTags) {
        iframe.removeAttribute("height");
        iframe.removeAttribute("width");
        const parentNode = iframe.parentNode;
        // Only wrap iframe if it does not have a wrapper.
        if (!parentNode.classList.contains("ratio-16x9")) {
          let wrapper = document.createElement("div");
          wrapper.className = "ratio ratio-16x9";
          // insert wrapper before el in the DOM tree
          parentNode.insertBefore(wrapper, iframe);
          wrapper.appendChild(iframe);
        }
      }

      let figureTags = bodyNode.getElementsByTagName("figureTags");
      for (let figure of figureTags) {
        figure.setAttribute("class", "img-fluid");
      }
    }
  }, []);

  return { setResponsiveness };
};
export default useSetResponsivess;
