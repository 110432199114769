import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { post } from "utils/DeApi";

const useCreateTag = ({ onTagCreated }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const createTag = (values) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = post(`/organizations/${organization?.id}/tags`, {
      name: values.name,
      color: values.color,
      description: values.description,
    });
    auditPromise.promise
      .then(({ data: tag }) => {
        onTagCreated(tag);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return {
    isLoading,
    error,
    createTag,
  };
};

export default useCreateTag;
