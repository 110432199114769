import { useContext, useEffect, useRef, useState } from "react";

import { post } from "utils/DeApi";
import { sanitizeHTML } from "utils/StringUtils";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useCreateAudit = ({ facilityId, onAuditCreated }) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const createAudit = (audit = {}) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = post("audits", {
      ...audit,

      facilityId: facilityId || audit?.facilityId,
      organizationId: organization?.id,
      executiveSummary: sanitizeHTML(audit?.executiveSummary),
    });
    auditPromise.promise
      .then(({ data }) => {
        onAuditCreated(data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, createAudit };
};
export default useCreateAudit;
