import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchActionItemNotification = () => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [notification, setNotification] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchNotification = () => {
      setError(null);
      setIsLoading(true);
      const notificationPromise = get(
        `organizations/${organization?.id}/profile`
      );
      notificationPromise.promise
        .then(({ data }) => {
          const {
            actionItemCommentAlerts = true,
            actionItemEmailsAlert = true,
            actionItemStatusAlerts = true,
            actionItemCreateAlerts = true,
            actionItemOverDueAlerts = true,
            actionItemDigestAlerts = true,
          } = data || {};
          setNotification({
            actionItemCreateAlerts,
            actionItemCommentAlerts,
            actionItemEmailsAlert,
            actionItemStatusAlerts,
            actionItemOverDueAlerts,
            actionItemDigestAlerts,
            all:
              actionItemCreateAlerts &&
              actionItemCommentAlerts &&
              actionItemStatusAlerts &&
              actionItemOverDueAlerts,
          });
        })
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(notificationPromise);
    };

    fetchNotification();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization?.id]);

  return {
    notification,
    setIsLoading,
    isLoading,
    error,
  };
};

export default useFetchActionItemNotification;
