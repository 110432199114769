import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchScreeningResponses = ({ auditId }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState(null);
  const [responses, setResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchApplicabilityResponses = () => {
      setIsLoading(true);
      const responsesPromise = get(
        `/audits/${auditId}/screening-responses`,
        {}
      );

      responsesPromise.promise
        .then(({ data: responses }) => {
          setResponses(responses);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      subscribedPromises.current.push(responsesPromise);
    };

    if (auditId) fetchApplicabilityResponses();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((p) => {
        p.cancel();
      });
    };
  }, [auditId]);

  return { error, isLoading, responses, setResponses };
};

export default useFetchScreeningResponses;
