import { capitalize } from "lodash";

const getActionItemsStats = (actionItemStats) => {
  const aggregatedData = {};

  actionItemStats.forEach((item) => {
    for (const key in item.actionItems) {
      if (aggregatedData[key]) {
        aggregatedData[key] += item.actionItems[key];
      } else {
        aggregatedData[key] = item.actionItems[key];
      }
    }
  });

  const result = Object.keys(aggregatedData).map((key) => ({
    name: capitalize(key),
    value: aggregatedData[key],
  }));
  return result;
};

export default getActionItemsStats;
