import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const ActionItemDueDateTimeLine = ({ dueDate }) => {
  const date = moment(dueDate, "YYYY-MM-DD");

  const formatDueDate = () => {
    if (date.isBefore(moment.now())) return `${date.fromNow()}`;
    return `${date.fromNow().replace("in", "")}`;
  };

  if (!date.isValid())
    return (
      <>
        <span
          translate="no"
          className="material-symbols-outlined md-12 text-secondary me-1"
        >
          fiber_manual_record
        </span>
        <small>N/A</small>
      </>
    );

  if (moment(dueDate).isBefore(moment().utc())) {
    return (
      <>
        <span
          translate="no"
          className="material-symbols-outlined md-12 text-danger me-1"
        >
          fiber_manual_record
        </span>
        <small className="text-danger">{formatDueDate(dueDate)}</small>
      </>
    );
  }
  if (moment().add(15, "days").utc().isSameOrAfter(moment(dueDate))) {
    return (
      <>
        <span
          translate="no"
          className="material-symbols-outlined md-12 text-warning-emphasis me-1"
        >
          fiber_manual_record
        </span>
        <small className="text-warning">{formatDueDate(dueDate)}</small>
      </>
    );
  }

  return (
    <>
      <span
        translate="no"
        className="material-symbols-outlined md-12 text-secondary me-1"
      >
        fiber_manual_record
      </span>
      <small>{formatDueDate(dueDate) || "N/A"}</small>
    </>
  );
};

ActionItemDueDateTimeLine.propTypes = {
  dueDate: PropTypes.string,
};

export default ActionItemDueDateTimeLine;
