import React from "react";

import { useRoles } from "hooks";
import { Table } from "react-bootstrap";

import { useFetchAuditTags } from "features/account/services";

import TagCreate from "./TagCreate";
import AssessmentTagList from "./AssessmentTagList/AssessmentTagList";

import "./AssessmentTags.scss";

function AssessmentTags() {
  const { isAdmin } = useRoles();

  const { error, isLoading, auditTags, setAuditTags } = useFetchAuditTags({
    fetchByDefault: true,
  });

  if (!isAdmin) return <></>;

  return (
    <>
      <div className="d-flex flex-row mt-5">
        <div className="flex-fill">
          <h4>Assessment tags</h4>
        </div>
        <div>
          <TagCreate
            onTagCreated={(tag) =>
              setAuditTags((prevStatus) => [tag, ...prevStatus])
            }
          />
        </div>
      </div>
      <hr />
      <Table hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Color</th>
            <th>Description</th>
            <th>Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          <AssessmentTagList
            error={error}
            isLoading={isLoading}
            auditTags={auditTags}
            setAuditTags={setAuditTags}
          />
        </tbody>
      </Table>
    </>
  );
}

export default AssessmentTags;
