import { useToast } from "hooks";
import PropTypes from "prop-types";
import { useContext, useRef, useState, useEffect } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { put } from "utils/DeApi";

const useUpdateFacility = ({ facilityId, onFacilityUpdated }) => {
  const toast = useToast();

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const updateFacility = (facility) => {
    setError(null);
    setIsLoading(true);
    const facilityPromise = put(`facilities/${facilityId}`, {
      ...facility,
      organizationId: organization?.id,
    });
    facilityPromise.promise
      .then(({ data }) => {
        if (typeof onFacilityUpdated === "function") onFacilityUpdated(data);
        toast.success("Sucess", "Entity updated successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(facilityPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    updateFacility,
  };
};

useUpdateFacility.propTypes = {
  facilityId: PropTypes.string.isRequired,
  onFacilityUpdated: PropTypes.func.isRequired,
};

export default useUpdateFacility;
