import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { truncate } from "lodash";
import { saveAs } from "file-saver";
import { Button, Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import { getURLFileExtension } from "utils/StringUtils";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

const ActionItemCollapseEvidenceFiles = ({ evidence }) => {
  return (
    <Col xs={12}>
      <span className="fw-semibold mb-2">
        Evidence Files · {evidence?.length ?? 0}
      </span>
      {!evidence?.length ? (
        <div className="mt-2">
          <EmptyStateHandler
            title="No Evidence Files"
            description="Files will appear here once added"
          />
        </div>
      ) : (
        <Card className="p-3 mt-2">
          {evidence?.map((item, index, arr) => {
            const { id, url, fileName } = item;
            const fileURL = url || "";
            const fileEXT = getURLFileExtension(fileURL);
            return (
              <Fragment key={id}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      {fileName || `${id}.${fileEXT}`}
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      type="button"
                      variant="link"
                      size="sm"
                      ref={ref}
                      className="text-start p-0"
                      {...triggerHandler}
                      onClick={() => {
                        saveAs(fileURL, fileName || `${id}.${fileEXT}`);
                      }}
                    >
                      <div className="d-flex flex-row">
                        <div>
                          <span
                            className={`fiv-sqo fiv-size-md fiv-icon-${fileEXT} me-2`}
                          ></span>
                        </div>

                        <span className="text-wrap">
                          {truncate(fileName || `${id}.${fileEXT}`, {
                            length: 25,
                          })}
                        </span>
                      </div>
                    </Button>
                  )}
                </OverlayTrigger>
                {arr.length > ++index && <hr className="mt-3" />}
              </Fragment>
            );
          })}
        </Card>
      )}
    </Col>
  );
};

ActionItemCollapseEvidenceFiles.propTypes = {
  evidence: PropTypes.array,
};

export default ActionItemCollapseEvidenceFiles;
