import { removeHtmlTags } from "features/actionItems";
import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { useRoles } from "hooks";

const useFetchQuestions = ({ audit, taskableId, path, taskableType, show }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [questionData, setQuestionData] = useState();

  const { isContributor } = useRoles();

  useEffect(() => {
    const fetchQuestions = () => {
      setIsLoading(true);
      const questionPromise = get(`questions/${taskableId}`);
      questionPromise.promise
        .then(({ data: { prompt } }) =>
          setQuestionData([
            {
              taskableType: "Entity",
              path: `/facilities/${audit?.facility?.facilityId}`,
              name: audit?.facility?.name,
            },
            {
              taskableType: "Assessment",
              path: `/audits/${audit?.auditId}`,
              name: audit?.name,
            },
            !isContributor && {
              taskableType: "Question",
              path,
              name: removeHtmlTags(prompt) || "N/A",
            },
          ])
        )
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(questionPromise);
    };

    if (taskableType === "question" && show) fetchQuestions();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [audit, path, show, taskableId, taskableType, isContributor]);

  return { isLoading, error, questionData };
};

export default useFetchQuestions;
