import React from "react";

import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import PropTypes from "prop-types";

const ActionItemTypeField = ({ taskableType }) => {
  const { handleChange, handleBlur, values, errors, touched } =
    useFormikContext();

  if (taskableType === "question") return <></>;

  return (
    <Form.Group controlId="type" className="mb-3">
      <Form.Label className="mb-1">
        Type <RequiredAsterisk />
      </Form.Label>
      <Form.Select
        onChange={handleChange}
        onBlur={handleBlur}
        name="type"
        placeholder="Select Type"
        value={values.type}
        isValid={values.type && !errors.type}
        isInvalid={errors.type && touched.type}
      >
        <option value={0}>Administrative</option>
        <option value={1}>Corrective</option>
        <option value={2}>Certification </option>
      </Form.Select>
    </Form.Group>
  );
};

ActionItemTypeField.propTypes = {
  taskableType: PropTypes.string,
};

export default ActionItemTypeField;
