const formattedDate =
  new Intl.DateTimeFormat("en-US", { month: "short" }).format(new Date()) +
  " " +
  new Date().getDate() +
  " " +
  new Date().getFullYear();

const formattedTime = new Intl.DateTimeFormat("en-US", {
  timeStyle: "short",
}).format(new Date());

export { formattedTime, formattedDate };
