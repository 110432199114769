import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./ItemWithTooltip.scss";

const ItemWithTooltip = ({ displayText = "" }) => {
  return (
    <OverlayTrigger placement="left" overlay={<Tooltip>{displayText}</Tooltip>}>
      <span>{displayText}</span>
    </OverlayTrigger>
  );
};

export default ItemWithTooltip;
