import { useContext, useEffect, useRef, useState } from "react";

import { post } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useCreateAuditTag = ({ onTagCreated }) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * @param {name, color, description} formData
   */
  const createAuditTag = (formData) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = post(
      `/organizations/${organization?.id}/audit-labels`,
      {
        ...formData,
      }
    );
    auditPromise.promise
      .then(({ data: tag }) => {
        if (typeof onTagCreated === "function") onTagCreated(tag);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return { error, isLoading, createAuditTag };
};

export default useCreateAuditTag;
