import { OrganizationContext } from "contexts/OrganizationProvider";
import { UserContext } from "contexts/UserProvider";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useToast } from "hooks";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { post } from "utils/DeApi";
import { sanitizeHTML } from "utils/StringUtils";

const useCreateActionItem = ({
  onActionItemCreated,
  taskableType,
  taskableId,
}) => {
  const organization = useContext(OrganizationContext);
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const location = useLocation();
  const toast = useToast();

  const { audit } = useContext(AuditContext) || {};
  const auditId = audit?.auditId;

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const createActionItem = (values) => {
    const { item, description, assignedTo, tags, dueDate, type, entity } =
      values;
    const [{ facilityId } = {}] = entity || [];

    setError(null);
    setIsLoading(true);
    const actionItemPromise = post("action-items", {
      item,
      status: 0,
      description: sanitizeHTML(description),
      assignedTo: assignedTo?.length
        ? assignedTo.map(({ subscriberId }) => subscriberId)
        : [],
      assignedBy: user?.de?.subscriberId,
      taskableType: facilityId ? "facility" : taskableType ?? null,
      taskableId: facilityId || (taskableId ?? null),
      organizationId: organization?.id,
      dueDate: moment(dueDate).format("YYYY-MM-DD HH:mm:ss"),
      tags: tags?.length ? tags.map(({ name = "" }) => name.toLowerCase()) : [],
      path: location?.pathname,
      ...(auditId
        ? {
            audit_id: auditId,
          }
        : {}),
      action_item_type: parseInt(type),
    });

    actionItemPromise.promise
      .then((response) => {
        onActionItemCreated(response.data);
        toast.success(
          "Success",
          "The action Item has been created successfully"
        );
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(actionItemPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return { error, setError, isLoading, createActionItem };
};

export default useCreateActionItem;
