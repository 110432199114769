import SlickArrowNext from "components/ui/SlickArrowNext";
import SlickArrowPrev from "components/ui/SlickArrowPrev";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <SlickArrowNext />,
  prevArrow: <SlickArrowPrev />,
  className: "card",
};

export { settings };
