import { useFetchAuditFiles } from "features/assessment/services";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useContext, useRef, useState } from "react";
import { download } from "utils/DeApi";
import { getURLFileExtension } from "utils/StringUtils";
import { AuditContext } from "../contexts/AuditProvider";
import IMAGE_EXTENSIONS from "../constants/ImageTypeExtensions";
import useVertical from "./useVertical";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useDownloadZipAttachmentFiles = () => {
  const [isZipReadyForDownload, setIsZipReadyForDownload] = useState(false);
  const [zippingFilesError, setZippingFilesError] = useState(null);
  const subscribedPromises = useRef([]);
  const { audit } = useContext(AuditContext);
  const { generatedFileNameShouldOnlyHaveAssessmentName } = useVertical();
  const organization = useContext(OrganizationContext);

  const zipFiles = (files) => {
    setIsZipReadyForDownload(true);
    setZippingFilesError(null);

    const removeImages = files.filter((file) => {
      const fileEXT = getURLFileExtension(file.url || "");
      return !IMAGE_EXTENSIONS.includes(fileEXT);
    });

    const promisesMap = removeImages.map((file) => {
      const p = download(file.url);
      subscribedPromises.current.push(p);
      return p.promise;
    });

    Promise.allSettled(promisesMap)
      .then((response) => {
        const zip = new JSZip();
        const assessmentFolder = zip.folder("Assessment level attachments");
        const questionFolder = zip.folder("Question level attachments");

        response.forEach((file, index) => {
          if (file.status === "rejected") return;

          const {
            id,
            name = "",
            url,
            observationId,
          } = removeImages[index] || {};
          const fileEXT = getURLFileExtension(url || "");

          const fileName = () => {
            if (!name) return `${++index}_${id}.${fileEXT}`;
            const hasFileEXT = name.split(".").length > 1;
            return hasFileEXT
              ? `${++index}_${name}`
              : `${++index}_${name}.${fileEXT}`;
          };

          const targetFolder = observationId
            ? questionFolder
            : assessmentFolder;
          targetFolder.file(fileName(), file?.value, { base64: true });
        });

        zip.generateAsync({ type: "blob" }).then((content) => {
          let filename = generatedFileNameShouldOnlyHaveAssessmentName
            ? `${audit?.name}.docx`
            : `${organization.name} ${audit?.name} Assessment Report.docx`;

          saveAs(content, `${filename}-attachments.zip`);
        });
      })
      .catch((error) => {
        if (!error.isCanceled) setZippingFilesError(error);
      })
      .finally(() => setIsZipReadyForDownload(false));
  };

  const { error, isLoading, fetchFiles } = useFetchAuditFiles({
    fetchByDefault: false,
    auditId: audit?.auditId,
    onSuccess: (data) => {
      zipFiles(data);
    },
  });

  const downloadZipAttachmentFiles = () => {
    fetchFiles(zipFiles);
  };

  return {
    downloadZipAttachmentFiles,
    isZipReadyForDownload,
    zippingFilesError,
    error,
    isLoading,
  };
};

export default useDownloadZipAttachmentFiles;
