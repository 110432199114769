import { unionBy } from "lodash";
import moment from "moment";
import { useEffect, useCallback, useState, useRef } from "react";
import { get } from "utils/DeApi";

const useFetchPortfolioFacilities = ({
  portfolioId,
  page = 1,
  perPage = 10,
  startDate,
  endDate,
  debouncedQuery = "",
}) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [meta, setMeta] = useState();

  const subscribedPromises = useRef([]);

  const fetchFacilities = useCallback(() => {
    setError(null);
    setIsLoading(true);
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const facilitiesPromise = get(`portfolios/${portfolioId}/facilities`, {
      params: {
        "filter[created_at]": startDate && endDate ? `${start},${end}` : "",
        "filter[search]": debouncedQuery,
        include: "labels",
        page: page,
        perPage: perPage,
      },
    });
    facilitiesPromise.promise
      .then(({ data: facilities, meta }) => {
        setIsLoading(false);
        setMeta(meta);
        setFacilities(unionBy(facilities, "facilityId"));
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
  }, [page, perPage, endDate, startDate, portfolioId, debouncedQuery]);

  useEffect(() => {
    fetchFacilities();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchFacilities]);

  return { error, isLoading, meta, facilities, setFacilities };
};

export default useFetchPortfolioFacilities;
