import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useFetchActionItems } from "features/actionItems/services";
import { OrganizationContext } from "contexts/OrganizationProvider";

const ActionItemTypeahead = ({ onChange, selected }) => {
  const organization = useContext(OrganizationContext);

  const params = useCallback(() => {
    return {
      perPage: 1000,
      organizationId: organization?.id,
    };
  }, [organization?.id]);

  const { isLoading, error, actionItems } = useFetchActionItems({
    params,
  });

  const [actionItem, setActionItem] = useState([]);
  error && console.error(error);

  useEffect(() => {
    setActionItem(selected);
  }, [selected]);

  return (
    <Typeahead
      id="action-item-typeahead"
      name="action-item"
      clearButton
      isLoading={isLoading}
      labelKey={(option) => `${option.item}`}
      placeholder="Select an action item..."
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps}>
          {results.map((result, index) => (
            <MenuItem
              key={result.actionItemId}
              option={result}
              position={index}
            >
              {result.item}
            </MenuItem>
          ))}
        </Menu>
      )}
      onChange={(data) => {
        onChange(data);
        setActionItem(data);
      }}
      options={actionItems}
      selected={actionItem}
    />
  );
};

ActionItemTypeahead.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default ActionItemTypeahead;
