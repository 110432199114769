import { useEffect, useState, useRef } from "react";
import { get } from "utils/BeeApi";

const useFetchContentByCategory = (categoryId) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [contentSummaryList, setContentSummaryList] = useState();
  useEffect(() => {
    function fetchContent() {
      setIsLoading(true);
      const contentsPromise = get(`contents`, {
        params: {
          category: categoryId,
        },
      });
      contentsPromise.promise
        .then(({ data: content }) => {
          setContentSummaryList(content);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
          }
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(contentsPromise);
    }

    fetchContent();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [categoryId]);
  return {
    error,
    isLoading,
    contentSummaryList,
  };
};
export default useFetchContentByCategory;
