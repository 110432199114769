import React, { useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormikContext } from "formik";
import { Col, Dropdown, Form, Row } from "react-bootstrap";

import { sortBy } from "constants";
import AssessmentList from "./AssessmentList";
import { useRoles } from "hooks";

const Assessments = () => {
  const {
    values: { protocolId, sortValue, facilityId },
    setFieldValue,
  } = useFormikContext();
  const { isMember } = useRoles();

  const [query, setQuery] = useState("");

  const sendSearchQuery = useRef(
    debounce(({ q, key }) => {
      setFieldValue(key, q);
    }, 1000)
  );

  if (!protocolId) return <></>;
  if (isMember && !facilityId) return <></>;

  return (
    <Row>
      <Col xs={12}>
        <Form.Group controlId="assessments" className="mt-0 mb-1">
          <>
            <Row className="d-flex align-items-center">
              <Col xs={4}>
                <Form.Label className="mt-1 me-2 fw-semibold ">
                  Select Assessments
                </Form.Label>
              </Col>
              <Col
                xs={8}
                className="d-flex align-items-center justify-content-end"
              >
                Sort By:
                <Dropdown className="">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="dropdown-done"
                    className="ms-2 text-start border border-gray-900 bg-white text-secondary"
                  >
                    {sortValue?.name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="min-width-auto w-fixed-84 ms-2 text-center">
                    {sortBy.map((s) => (
                      <Dropdown.Item
                        key={s.index}
                        active={s.index === sortValue.index}
                        onClick={() => setFieldValue("sortValue", s)}
                        className="w-100"
                      >
                        {s.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="assessments" className="mt-2 mb-3">
                  <Form.Control
                    type="text"
                    value={query}
                    onChange={(e) => {
                      const q = e.target.value;
                      setQuery(q);
                      sendSearchQuery.current({ q, key: "search" });
                    }}
                    placeholder="Search By Assessment"
                  />
                </Form.Group>
              </Col>
            </Row>
            <AssessmentList query={query} />
          </>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Assessments;
