import React, { useEffect } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import {
  useExportActionItem,
  useCreateActionItemDataLink,
  useExportMyActionItem,
} from "features/actionItems/services";

const ActionItemFileExport = ({
  isMyActionItemDashboard = false,
  filters,
  handleError,
}) => {
  const { auditId } = useParams();

  const {
    exportActionItems,
    isLoading: exportLoading,
    error: exportError,
  } = useExportActionItem({
    filters,
  });

  const {
    exportMyActionItems,
    isLoading: myActionItemsLoading,
    error: myActionItemsError,
  } = useExportMyActionItem({
    filters,
  });

  const {
    isLoading: linkLoading,
    error: linkError,
    createActionItemDataLink,
  } = useCreateActionItemDataLink({
    filters,
  });

  const exportData = isMyActionItemDashboard
    ? exportMyActionItems
    : exportActionItems;
  const isLoading = isMyActionItemDashboard
    ? myActionItemsLoading
    : exportLoading;
  const error = isMyActionItemDashboard ? myActionItemsError : exportError;

  useEffect(() => {
    if (error) handleError(error);
    if (linkError) handleError(linkError);
  }, [error, handleError, linkError]);

  return (
    <>
      {auditId ? (
        <Button
          size="sm"
          variant="outline-secondary"
          className="me-2"
          disabled={isLoading}
          onClick={() => exportData()}
        >
          {isLoading ? (
            <Spinner className="me-1" animation="border" size="sm" />
          ) : (
            <span translate="no" className="material-symbols-outlined md-18">
              file_download
            </span>
          )}{" "}
          <span>Export Excel</span>
        </Button>
      ) : (
        <Dropdown autoClose="outside" className="me-2 ">
          <Dropdown.Toggle
            variant="outline-secondary"
            id="protocol-filter"
            className=" w-100 text-start "
            size="sm"
          >
            {isLoading || linkLoading ? (
              <Spinner className="me-2" animation="border" size="sm" />
            ) : (
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                download
              </span>
            )}{" "}
            <span>Export</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-2 w-100">
            <Dropdown.Item onClick={() => exportData()}>
              Export Excel{" "}
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                download
              </span>
            </Dropdown.Item>{" "}
            <Dropdown.Divider className="my-2" />
            <Dropdown.Item onClick={() => createActionItemDataLink()}>
              Get Link{" "}
              <span
                translate="no"
                className="material-symbols-outlined md-16 cursor-pointer ms-1"
              >
                content_copy
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

ActionItemFileExport.propTypes = {
  handleError: PropTypes.func,
  filters: PropTypes.object,
  isMyActionItemDashboard: PropTypes.bool,
};

export default ActionItemFileExport;
