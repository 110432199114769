import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useFormikContext } from "formik";
import { useRoles } from "hooks";
import React from "react";
import { Form } from "react-bootstrap";

const ActionItemStatusField = () => {
  const { handleChange, handleBlur, values, errors, touched } =
    useFormikContext();
  const { isAdmin } = useRoles();

  return (
    <Form.Group controlId="status" className="mt-0 mb-3">
      <Form.Label className="mb-1">
        Status <RequiredAsterisk />
      </Form.Label>
      <Form.Select
        onChange={handleChange}
        onBlur={handleBlur}
        name="status"
        placeholder="Select Status"
        value={values.status}
        isValid={values.status && !errors.status}
        isInvalid={errors.status && touched.status}
      >
        <option value={0}>Assigned</option>
        <option value={1}>Submitted</option>
        <option hidden={!isAdmin} disabled={!isAdmin} value={2}>
          Incomplete
        </option>
        <option hidden={!isAdmin} disabled={!isAdmin} value={3}>
          Accepted
        </option>
      </Form.Select>
    </Form.Group>
  );
};

export default ActionItemStatusField;
