import React from "react";

import { Outlet, ScrollRestoration } from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react";
import { UserProvider } from "contexts/UserProvider";
import { ToastContextProvider } from "contexts/ ToastContext";
import NewVersionUpdate from "./NewVersionUpdate";
import { OrganizationProvider } from "contexts/OrganizationProvider";

function Layout() {
  const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

  return (
    <ToastContextProvider>
      <div className="Main">
        <ScrollRestoration />
        <NewVersionUpdate />
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <UserProvider>
            <OrganizationProvider>
              <Outlet />
            </OrganizationProvider>
          </UserProvider>
        </Auth0Provider>
      </div>
    </ToastContextProvider>
  );
}

export default Layout;
