import { useState, useRef, useEffect } from "react";
import { destroy } from "utils/DeApi";

const useDeleteEntityTag = ({ tagId, onTagDeleted }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const deleteTag = () => {
    setError(null);
    setIsLoading(true);

    const tagPromise = destroy(`/organization-labels/${tagId}`);

    tagPromise.promise
      .then(({ data }) => {
        if (typeof onTagDeleted === "function") onTagDeleted();
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(tagPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return { error, isLoading, deleteTag };
};

export default useDeleteEntityTag;
