import { useContext, useState } from "react";

import { useRoles } from "hooks";
import { Form, Spinner } from "react-bootstrap";

import { useUpdateAuditArchive } from "features/assessment/services";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import "./AuditAchived.scss";
import ErrorHandler from "components/ui/ErrorHandler";

export default function AuditAchived() {
  const { isAdmin } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);
  const { error, isLoading, updateAuditArchived } = useUpdateAuditArchive({
    auditId: audit?.auditId,
    onAuditUpdated: (data) => {
      setAudit((prevAudit) => ({
        ...prevAudit,
        archived: data.archived,
      }));
    },
  });

  const [isAchived, setIsAchived] = useState(audit?.archived);

  if (!isAdmin) return <></>;

  return (
    <>
      <div className="d-flex gap-3 align-items-center">
        <div className="flex-fill">
          <Form.Switch
            value={isAchived}
            checked={isAchived}
            disabled={isLoading || audit?.locked}
            label={<h5>Archive Assessment</h5>}
            onChange={() => {
              setIsAchived((prevStatus) => !prevStatus);
              updateAuditArchived(!isAchived);
            }}
          />
        </div>
        {isLoading && (
          <Spinner
            animation="border"
            className="float-end"
            variant="primary"
            size="sm"
          />
        )}
      </div>
      <p className="text-muted">
        <small>
          Members, certifiers, and contributors cannot see or access archived
          assessments.
        </small>
      </p>
      {error && <ErrorHandler error={error} />}
      <hr />
    </>
  );
}
