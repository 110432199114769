import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Copyright from "components/ui/Copyright";

const Footer = () => {
  return (
    <div className="Footer">
      <Container fluid>
        <Row>
          <Col
            xs={12}
            className="my-3 fs-6 text-center text-white text-opacity-50"
          >
            <Copyright />
            <span className="px-2">
              <span className="vr align-middle"></span>
            </span>
            <a
              href="https://www.erm.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <span className="px-2">
              <span className="vr align-middle"></span>
            </span>

            <span>v {process.env.REACT_APP_RELEASE_VERSION}</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
