import React, { useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import JSZip from "jszip";

import { saveAs } from "file-saver";
import { download } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";

import "./ZipFileDownload.scss";
import { useRoles } from "hooks";
import { useFetchFiles } from "features/files/services";

const ZipFileDownload = ({ page, level, entity, assessment }) => {
  const { isCertifier } = useRoles();
  const {
    isLoading: isExpanding,
    error: isExpandingError,
    files,
    fetchFiles,
  } = useFetchFiles({
    page,
    level,
    entity,
    assessment,
    fetchByDefault: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);

  const subscribedPromises = useRef([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    fetchFiles();
  };

  const downloadFiles = () => {
    setError(null);
    setIsLoading(true);

    const promisesMap = files.map((file) => {
      const p = download(file.url);
      subscribedPromises.current.push(p);
      return p.promise;
    });

    Promise.allSettled(promisesMap)
      .then((response) => {
        const zip = new JSZip();

        response.forEach((file, index) => {
          if (file.status === "rejected") {
            return;
          }

          const { id, name = "", url } = files[index] || {};
          const fileEXT = getURLFileExtension(url || "");

          const fileName = () => {
            if (!name) return `${++index}_${id}.${fileEXT}`;

            const hasFileEXT = name.split(".").length > 1;

            if (hasFileEXT) return `${++index}_${name}`;
            return `${++index}_${name}.${fileEXT}`;
          };

          zip.file(fileName(), file?.value, { base64: true });
        });

        zip.generateAsync({ type: "blob" }).then(function (content) {
          const dateIndex = new Date().toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          });
          saveAs(content, `files-${dateIndex}.zip`);
          setIsLoading(false);
          handleClose();
        });
      })
      .catch((error) => {
        setIsLoading(false);
        !error.isCanceled && setError(error);
      });
  };

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        size="sm"
        title="download"
        variant="outline-secondary"
        className="me-2"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          download
        </span>{" "}
        Download Files
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Download {files?.length} files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Due to the number of files, the download will take time</p>
          {(error || isExpandingError) && (
            <ErrorHandler error={error || isExpandingError} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" size="sm" onClick={handleClose}>
            Close
          </Button>
          <Button
            size="sm"
            variant="primary"
            disabled={isLoading || isExpanding}
            onClick={downloadFiles}
          >
            {(isLoading || isExpanding) && (
              <Spinner
                size="sm"
                variant="light"
                className="me-2"
                animation="border"
              />
            )}{" "}
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ZipFileDownload.propTypes = {
  files: PropTypes.array.isRequired,
};

export default ZipFileDownload;
