import { useContext } from "react";
import { truncate } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

import { getURLFileExtension } from "utils/StringUtils";

import "./FileLink.scss";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { getInAppFileURL } from "features/files/utils/permanentFileLink";

const FileLink = ({ file }) => {
  const { id, name } = file;
  const fileURL = file.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  const organization = useContext(OrganizationContext);

  const fileLink = getInAppFileURL(file, organization.id);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip-2">{name || `${id}.${fileEXT}`}</Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <div ref={ref} size="sm" className="text-start p-0" {...triggerHandler}>
          <Link to={fileLink} className="text-decoration-none">
            <div className="d-flex flex-row">
              <div>
                <span
                  className={`fiv-sqo fiv-size-md fiv-icon-${fileEXT} me-2`}
                ></span>
              </div>
              <span className="text-wrap">
                {truncate(name || `${id}.${fileEXT}`, {
                  length: 25,
                })}
              </span>
            </div>
          </Link>
        </div>
      )}
    </OverlayTrigger>
  );
};

export default FileLink;
