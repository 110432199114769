import { useEffect, useRef, useState } from "react";

import { put } from "utils/DeApi";

const useUpdateAuditTag = ({ auditTagId, onTagUpdated }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * @param {name, color, description} formData
   */
  const updateAuditTag = (formData) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = put(`/audit-labels/${auditTagId}`, {
      ...formData,
    });
    auditPromise.promise
      .then(({ data: tag }) => {
        if (typeof onTagUpdated === "function") onTagUpdated(tag);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return { error, isLoading, updateAuditTag };
};

export default useUpdateAuditTag;
