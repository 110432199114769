import React, { useContext, useEffect } from "react";

import TagManager from "react-gtm-module";
import { Outlet, useLocation } from "react-router-dom";

import { UserContext } from "contexts/UserProvider";
import { OrganizationContext } from "contexts/OrganizationProvider";

/** Google Tag Manager Middleware */
const GTMMiddleware = () => {
  const pmfGroupId = process.env.REACT_APP_PMF_GROUP_ID;
  const location = useLocation();
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const userRole = organization?.invitation?.role;
  const { categories = [] } = user || {};

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "Pageview",
        pagePath: location?.pathname,
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);

    let categoryId = "";
    let categoryName = "";
    const categoryFound = categories.find(({ groups }) =>
      groups.some((tmpGroup) => tmpGroup.groupId === pmfGroupId)
    );
    if (categoryFound) {
      categoryId = categoryFound.categoryId;
      categoryName = categoryFound.categoryName;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Product-Market Fit",
        visitorType: categoryName,
        visitorTypeId: categoryId,
        visitorRole: userRole,
      });
    }
  }, [categories, location.pathname, pmfGroupId, userRole]);

  return <Outlet />;
};

export default GTMMiddleware;
