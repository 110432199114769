import { OrganizationContext } from "contexts/OrganizationProvider";
import { useToast } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { put } from "utils/DeApi";

const useUpdateActionItemNotification = ({
  notification,
  setIsFieldToggled,
}) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const toast = useToast();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedNotification, setUpdatedNotification] = useState({});

  const updateActionItemNotification = () => {
    let notificationBody = {
      action_item_comment_alerts: notification.actionItemCommentAlerts,
      action_item_emails: notification.actionItemEmailsAlert,
      action_item_status_alerts: notification.actionItemStatusAlerts,
      action_item_create: notification.actionItemCreateAlerts,
      action_item_overdue_alerts: notification.actionItemOverDueAlerts,
      actionItemDigestAlerts: notification.actionItemDigestAlerts,
    };

    const notificationPromise = put(
      `organizations/${organization?.id}/profile`,
      notificationBody
    );
    notificationPromise.promise
      .then(({ data }) => {
        setIsLoading(false);
        toast.success(
          "Success",
          "Your notification preference has been updated"
        );
        const {
          actionItemCommentAlerts = true,
          actionItemEmailsAlert = true,
          actionItemStatusAlerts = true,
          actionItemCreateAlerts = true,
          actionItemOverDueAlerts = true,
          actionItemDigestAlerts = true,
        } = data || {};
        setUpdatedNotification({
          actionItemCreateAlerts,
          actionItemCommentAlerts,
          actionItemEmailsAlert,
          actionItemStatusAlerts,
          actionItemOverDueAlerts,
          actionItemDigestAlerts,
          all:
            actionItemCreateAlerts &&
            actionItemCommentAlerts &&
            actionItemStatusAlerts &&
            actionItemOverDueAlerts,
        });
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsFieldToggled(false);
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization.id]);

  return {
    isLoading,
    error,
    updatedNotification,
    updateActionItemNotification,
  };
};

export default useUpdateActionItemNotification;
