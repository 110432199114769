const types = [
  {
    index: 1,
    name: "Administrative",
    filter: 0,
  },
  {
    index: 2,
    name: "Corrective",
    filter: 1,
  },
  {
    index: 3,
    name: "Certification",
    filter: 2,
  },
];

export default types;
