import { useEffect, useRef, useState } from "react";

import { destroy } from "utils/DeApi";

const useDeleteAuditTag = ({ auditTagId, onTagDeleted }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * @param {name, color, description} formData
   */
  const deleteAuditTag = () => {
    setError(null);
    setIsLoading(true);
    const auditPromise = destroy(`/audit-labels/${auditTagId}`);
    auditPromise.promise
      .then(({ data: tag }) => {
        if (typeof onTagDeleted === "function") onTagDeleted(tag);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return { error, isLoading, deleteAuditTag };
};

export default useDeleteAuditTag;
