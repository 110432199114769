import { useCallback, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchFileById = ({
  fileId,
  fetchByDefault = true,
  onSuccess,
  basePath = "files",
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();

  const subscribedPromises = useRef([]);
  const onSuccessRef = useRef(onSuccess);

  const fetchFile = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const filePromise = get(`${basePath}/${fileId}`);

    filePromise.promise
      .then(({ data }) => {
        setFile(data);
        if (typeof onSuccessRef?.current === "function") {
          onSuccessRef.current(data);
        }
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(filePromise);
  }, [fileId]);

  useEffect(() => {
    if (fetchByDefault) {
      fetchFile();
    }
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchFile, fetchByDefault]);

  return {
    error,
    file,
    isLoading,
    fetchFile,
  };
};

export default useFetchFileById;
