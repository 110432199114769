const { Modal, Button } = require("react-bootstrap");

const DeleteCredentials = ({
  assessApiKeys,
  showDeleteModal,
  setShowDeleteModal,
  deleteCredentials,
}) => {
  return (
    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{assessApiKeys.tokenValue}</p>
        <p>Are you sure you want to delete this Key?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Cancel
        </Button>
        <Button size="sm" variant="danger" onClick={deleteCredentials}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCredentials;
