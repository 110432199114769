import { ActionItemsDispatchContext } from "features/actionItems/context";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";

const ClearFilters = () => {
  const dispatch = useContext(ActionItemsDispatchContext);

  const resettingFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  return (
    <Button
      size="sm"
      variant="outline-primary"
      onClick={() => resettingFilters()}
    >
      <span translate="no" className="material-symbols-outlined md-18">
        restart_alt
      </span>{" "}
      Clear Filters
    </Button>
  );
};

export default ClearFilters;
