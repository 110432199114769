import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useFetchFacilityProtocolStats = ({
  period,
  option,
  facilityId,
  protocolId,
}) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [error, setError] = useState();
  const [facilityStats, setFacilityStats] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchFacilityProtocolStats = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const riskPromise = get(
      `facilities/${facilityId}/protocols/${protocolId}/stats`,
      {
        params: {
          option: option,
          period: period,
          organizationId: organization?.id,
        },
      }
    );
    riskPromise.promise
      .then(({ data }) => {
        setFacilityStats(data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));
    subscribedPromises.current.push(riskPromise);
  }, [period, option, organization?.id, protocolId, facilityId]);

  useEffect(() => {
    fetchFacilityProtocolStats();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchFacilityProtocolStats]);
  return {
    error,
    isLoading,
    facilityStats,
    fetchFacilityProtocolStats,
  };
};

export default useFetchFacilityProtocolStats;
