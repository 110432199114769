import { useEffect, useState, useRef, useContext } from "react";

import { OrganizationContext } from "contexts/OrganizationProvider";
import { get } from "utils/DeApi";

export default function useFetchFileAssociations(file, isActive = true) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [isLoading, setIsLoading] = useState("");
  const [error, setError] = useState("");
  const [audit, setAudit] = useState("");
  const [facility, setFacility] = useState("");
  const [question, setQuestion] = useState("");

  useEffect(() => {
    const fetchAudit = (auditId) => {
      setIsLoading(true);
      setError("");
      const auditsPromise = get(`audits/${auditId}`);
      const observationPromise = get(`audits/${auditId}/observations`);

      subscribedPromises.current.push(auditsPromise);
      subscribedPromises.current.push(observationPromise);

      Promise.all([auditsPromise.promise, observationPromise.promise])
        .then(([{ data: audit }, { data: observations }]) => {
          setFacility(audit?.facility);
          setAudit({
            ...audit,
          });

          if (file?.observationId) {
            const { questionId } =
              observations.find(({ observationId }) => {
                return observationId === file?.observationId;
              }) || {};

            const questions =
              audit?.protocol?.sections
                .map((section) => ({
                  ...section,
                  questions: section.questions.map((questions) => ({
                    ...questions,
                    href: `/audits/${audit.auditId}/sections/${section.sectionId}/questions/${questions.questionId}`,
                  })),
                }))
                .filter(({ principles }) => principles.length > 0)
                .reduce((prev, { questions: curr }) => {
                  return [...prev, ...curr];
                }, []) || [];

            const question = questions.find(({ questionId: id }) => {
              return id === questionId;
            });

            setQuestion(question);
          }
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
    };

    const modelType =
      file?.modelType || (file?.observationId ? "question" : "assessment");

    if (modelType === "facility" && isActive) {
      setFacility({
        facilityId: file?.model?.id,
        ...file?.model,
      });
    }

    if (modelType === "assessment" && isActive) {
      fetchAudit(file?.auditId);
    }
    if (modelType === "question" && isActive) {
      fetchAudit(file?.auditId);
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [file, organization, isActive]);

  return {
    isLoading,
    error,
    audit,
    facility,
    question,
  };
}
