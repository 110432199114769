import React, { createContext, useEffect, useRef, useState } from "react";

import { get } from "utils/BeeApi";
import Loader from "components/ui/Loader";
import { get as getDE } from "utils/DeApi";

const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);

const key = process.env.REACT_APP_LOCAL_STORAGE_KEY;

function UserProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState();
  const subscribedPromises = useRef([]);

  const fetchUser = () => {
    setUser(null);
    setIsLoading(true);
    const userPromise = get("/user/anon");

    userPromise.promise
      .then((response) => {
        setUser(response.data);
        const userPromiseDE = getDE("/login");
        subscribedPromises.current.push(userPromiseDE);
        return userPromiseDE.promise;
      })
      .then(({ data: userDE }) => {
        setUser((prevState) => {
          return {
            ...prevState,
            de: userDE,
            subscriberId: userDE?.subscriberId,
            prevSelectedOrgId: userDE?.selectedOrganizationId,
          };
        });
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          localStorage.removeItem(key);
          setUser(null);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(userPromise);
  };

  useEffect(() => {
    fetchUser();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isLoading) return <Loader />;
  if (user === undefined) return <></>;

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={fetchUser}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext };
