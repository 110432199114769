import { isEmpty } from "lodash";

import { FlexibleFields } from "features/assessment/constants";

import { validationMapping } from "../helper";
import { createTreeArray } from "./Question/FlexibleQuestions/nestingUtils";

const {
  CHECKBOX_FIELD,
  COMPOUND_RADIO,
  DATETIME_FIELD,
  NUMERIC_FIELD,
  RADIO_FIELD,
  TEXT_FIELD,
} = FlexibleFields;

export const questionIsAnswered = (audit, observation) => {
  const { auditId, protocol } = audit || {};
  const { conformity, questionOptions } = protocol || {};

  if (!observation || !auditId || observation?.auditId !== auditId)
    return false;

  if (!!observation.isInapplicable) return true;

  return (
    validationMapping
      .filter(({ showField }) => showField(conformity, questionOptions))
      .map(({ isValid }) => isValid(observation))
      .every(Boolean) &&
    questionIsResponsed(audit, observation.responses, observation)
  );
};

export const questionIsResponsed = (
  audit,
  Qresponses = [],
  observation = {}
) => {
  if (!!observation.isInapplicable) return true;

  const { protocol } = audit || {};
  const flexibleQuestions = audit?.flexibleFormFields || [];
  const requiredflexibleQuestions = flexibleQuestions
    .flatMap((item) => {
      const { children } = item || {};
      if (!children?.length) return item;
      return children;
    })
    .filter(({ questionTypeId, children }) => {
      if (questionTypeId === COMPOUND_RADIO && !children?.length) return false;
      return true;
    })
    .filter(({ required }) => required);

  const requiredAnswers = requiredflexibleQuestions.map((requiredField) => {
    return {
      ...requiredField,
      response:
        Qresponses.find(
          ({ flexibleQuestionId }) => flexibleQuestionId === requiredField.id
        ) || null,
    };
  });

  const { sectionId, title } =
    protocol.sections
      .filter(({ principles }) => principles.length > 0)
      .find(({ questions }) =>
        questions.find(
          ({ questionId }) => questionId === observation?.questionId
        )
      ) || {};

  if (!sectionId) return false;

  const sectionFormFields = audit?.sectionFormFields.map((fields) => {
    return {
      ...fields,
      visible: !!fields.sectionsAvailability.find(
        ({ sectionId: id, available }) => {
          return id === sectionId && available;
        }
      ),
    };
  });

  const flexibleQuestionsWithAnswers = requiredAnswers
    .map((f) => {
      const { visible } =
        sectionFormFields.find(
          ({ flexibleQuestionId }) => flexibleQuestionId === f?.id
        ) || {};

      return {
        ...f,
        visible: !!visible,
      };
    })
    .filter(({ visible }) => !!visible);

  const nestedFields = createTreeArray(flexibleQuestionsWithAnswers);

  const aggregatedValidation = [];
  const fieldResponses = [];

  const recursiveValidation = (field) => {
    if (!field.nestedFields.length) {
      fieldResponses.push(field);
      aggregatedValidation.push(isValidResponse(field.response));
      return;
    }

    //recursive call
    field.nestedFields
      .filter(
        ({ triggerAnswerId }) => triggerAnswerId === field?.response?.choiceId
      )
      .forEach((nestedField) => {
        recursiveValidation(nestedField);
      });
  };

  nestedFields.forEach((field) => {
    recursiveValidation(field);
  });

  return aggregatedValidation.every(Boolean);
};

function startsWithNumber(str) {
  return /^\d/.test(str);
}

export function compare(a, b) {
  const _a = a.title;
  const _b = b.title;

  if (startsWithNumber(_a) && startsWithNumber(_b))
    return parseInt(a.title) - parseInt(b.title);

  if (_a < _b) return -1;
  if (_a > _b) return 1;
  return 0;
}

const isValidResponse = (response) => {
  if (!response) return false;
  const { notes, choiceId, flexibleQuestion, responseDatetime } =
    response || {};
  const { questionTypeId } = flexibleQuestion || {};

  if (!!response?.isInapplicable) return true;
  if (
    isEmptyText(questionTypeId, notes) ||
    isEmptyNumeric(questionTypeId, notes) ||
    isEmptyCompound(questionTypeId, choiceId) ||
    isEmptyRadio(questionTypeId, choiceId) ||
    isEmptyCheckbox(questionTypeId, choiceId) ||
    isEmptyDatefield(questionTypeId, responseDatetime)
  )
    return false;
  return true;
};

const isEmptyText = (typeId, value) => {
  return typeId === TEXT_FIELD && isEmpty(value);
};

const isEmptyNumeric = (typeId, value) => {
  return typeId === NUMERIC_FIELD && isEmpty(value);
};

const isEmptyCheckbox = (typeId, value) => {
  return typeId === CHECKBOX_FIELD && isEmpty(value);
};

const isEmptyRadio = (typeId, value) => {
  return typeId === RADIO_FIELD && isEmpty(value);
};

const isEmptyCompound = (typeId, value) => {
  return typeId === COMPOUND_RADIO && isEmpty(value);
};

const isEmptyDatefield = (typeId, value) => {
  return typeId === DATETIME_FIELD && isEmpty(value);
};
