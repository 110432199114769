import { forwardRef } from "react";
import { cx, css } from "@emotion/css";

export const Button = forwardRef(
  ({ className, active, reversed, ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          cursor: pointer;
          background: ${active ? "#00821930" : "transparent"};
          color: "#000";
        `
      )}
    />
  )
);

export const Icon = forwardRef(({ className, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    translate="no"
    className={cx(
      "material-symbols-outlined",
      className,
      css`
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: -3px;
        vertical-align: text-bottom;
      `
    )}
  />
));

export const Menu = forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    data-test-id="menu"
    ref={ref}
    className={cx(
      className,
      css`
        display: flex;
        align: items-center;

        & > * {
          display: inline-block;
        }

        & > * + * {
          margin-left: 6px;
          width: 30px;
          text-align: center;
          border-radius: 3px;
        }
      `
    )}
  />
));

export const Toolbar = forwardRef(({ ...props }, ref) => (
  <Menu {...props} ref={ref} />
));
