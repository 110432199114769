import SlateEditor from "components/ui/SlateEditor";
import { useFormikContext } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

const ActionItemDescriptionField = () => {
  const {
    values: { description },
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();

  return (
    <Form.Group controlId="item" className="mb-3">
      <Form.Label className="mb-1">Description</Form.Label>
      <div className="bg-light border rounded">
        <SlateEditor
          oldValue={description}
          name="description"
          setFieldValue={setFieldValue}
          placeholder={"Enter Action Item Description"}
        />
      </div>
      <Form.Control.Feedback type="invalid">
        {errors.description && touched.description ? (
          <small>{errors.description}</small>
        ) : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default ActionItemDescriptionField;
