import { useToast } from "hooks";
import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
import { destroy } from "utils/DeApi";

const useDeleteFacilityFile = ({ fileId, onFacilityFileDeleted }) => {
  const toast = useToast();

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const subscribedPromises = useRef([]);

  const deleteFacilityFile = () => {
    setError(null);
    setIsLoading(true);
    const facilityPromise = destroy(`media/${fileId}`);
    facilityPromise.promise
      .then(() => {
        if (typeof onFacilityFileDeleted === "function")
          onFacilityFileDeleted({ fileId });
        toast.success("Sucess", "File deleted successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(facilityPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    deleteFacilityFile,
  };
};

useDeleteFacilityFile.propTypes = {
  fileId: PropTypes.string.isRequired,
  onFacilityFileDeleted: PropTypes.func.isRequired,
};

export default useDeleteFacilityFile;
