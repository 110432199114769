import React from "react";

import { Helmet } from "react-helmet";
import { Card, Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import BreadCrumbs from "components/ui/BreadCrumbs";

import WhatsNewBody from "../WhatsNewBody/WhatsNewBody";
import { useFetchContentByCategory } from "features/contents/services";

const WhatsNewArticles = () => {
  const category = process.env.REACT_APP_WHATSNEW_CATEGORY_ID;
  const { contentSummaryList, isLoading, error } =
    useFetchContentByCategory(category);

  const breadCrumbsPath = [
    { name: "Dashboard", link: "/" },
    { name: "What's New", active: false },
  ];

  return (
    <>
      <Helmet>
        <title>"What's New"</title>
      </Helmet>
      <BreadCrumbs breadcrumbs={[...breadCrumbsPath]} />
      <Row>
        <Col xs={12} className="mb-3">
          <Card>
            <Card.Body>
              <h1>What's New</h1>
              <hr />

              {error && <ErrorHandler error={error} />}
              {isLoading && <Loader />}
              {contentSummaryList && (
                <WhatsNewBody
                  whatsNewContents={contentSummaryList}
                  filtered={false}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WhatsNewArticles;
