import { useRoles } from "hooks";
import { Badge } from "react-bootstrap";

import "./AuditLockedBadge.scss";

const AuditLockedBadge = ({ locked }) => {
  const { isCertifier } = useRoles();
  if (!locked && !isCertifier) return <></>;
  return (
    <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
      <small className="text-dark">
        <span translate="no" className="material-symbols-outlined md-13">
          lock
        </span>{" "}
        View only
      </small>
    </Badge>
  );
};

export default AuditLockedBadge;
