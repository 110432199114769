import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

const FullScreenReport = ({ title, children }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button size="sm" variant="light" onClick={() => setShow(true)}>
        <span translate="no" className="material-symbols-outlined md-18">
          open_in_full
        </span>
      </Button>

      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton={show} className="border-0">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

FullScreenReport.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default FullScreenReport;
