import React, { useState, useRef, useContext, useEffect } from "react";
import { Button, Col, Modal, Row, Form, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import { get, put } from "utils/DeApi";

import { OrganizationContext } from "contexts/OrganizationProvider";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import ErrorHandler from "components/ui/ErrorHandler";
import { addYears } from "features/actionItems/utils";

export default function ActionItemBulkOperation({
  items,
  selectedItems,
  className = "",
  modalBtnVariant,
  onActionItemsUpdated,
  facilityId,
}) {
  return (
    <Row className={`${className} p-1`}>
      <Col>
        <div className="d-flex justify-content-center align-items-center">
          <span className="text-dark me-2">
            {selectedItems.length} selected
          </span>
          <ActionItemBulkAssignModal
            items={items}
            selectedItems={selectedItems}
            modalBtnVariant={modalBtnVariant}
            onActionItemsUpdated={onActionItemsUpdated}
            facilityId={facilityId}
            isBulkAssignDisabled={selectedItems.length < 1}
          />
        </div>
      </Col>
    </Row>
  );
}

function ActionItemBulkAssignModal({
  selectedItems,
  items: actionItems,
  modalBtnVariant,
  onActionItemsUpdated,
  isBulkAssignDisabled,
  facilityId,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [subscribers, setSubscribers] = useState([]);
  useEffect(() => {
    const fetchSubscribers = () => {
      setIsLoading(true);
      const subscribersPromise = get(`facilities/${facilityId}/subscribers`, {
        params: {
          organizationId: organization?.id,
        },
      });
      subscribersPromise.promise
        .then((response) => {
          setSubscribers(response.data ?? []);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    if (show) {
      fetchSubscribers();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization, show]);

  const schema = yup.object().shape({
    assignedTo: yup
      .array()
      .min(1, "Assigned To field must have atleast one subscriber")
      .required("Assigned To field must have atleast one subscriber"),
    dueDate: yup.date().required("Due date is a required field"),
  });

  const bulkUpdateActionItems = (assignedTo, dueDate) => {
    setError("");
    setIsLoading(true);
    const actionItemsUpdatePromise = put(
      `organizations/${organization?.id}/action-items-bulk-update`,
      {
        actionItemIds: selectedItems,
        assignedTo: assignedTo,
        dueDate: dueDate,
      }
    );

    actionItemsUpdatePromise.promise
      .then((response) => {
        setIsLoading(false);
        onActionItemsUpdated(response.data);
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        variant={modalBtnVariant}
        size="sm"
        onClick={handleShow}
        disabled={isBulkAssignDisabled}
      >
        Bulk Update
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Bulk Update {selectedItems.length} Selected Action Item
            {selectedItems.length > 1 ? "s" : ""}
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { assignedTo, dueDate } = values;
            bulkUpdateActionItems(
              assignedTo?.length
                ? assignedTo.map(({ subscriberId }) => subscriberId)
                : [],
              moment(dueDate).format("YYYY-MM-DD HH:mm:ss")
            );
          }}
          initialValues={{
            assignedTo: [],
            dueDate: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="dueDate" className="mb-3">
                  <Form.Label className="mb-1">
                    Due Date <RequiredAsterisk />
                  </Form.Label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    type="date"
                    name="dueDate"
                    selected={values.dueDate}
                    popperPlacement="top-start"
                    className="action-item-datepicker form-control"
                    onChange={(date) => setFieldValue("dueDate", date)}
                    maxDate={addYears(new Date(), 2)}
                    minDate={new Date()}
                    onBlur={handleBlur}
                    isValid={values.dueDate && !errors.dueDate}
                    isInvalid={
                      !(values.dueDate && !errors.dueDate) && touched.dueDate
                    }
                    isClearable
                    placeholderText="Enter Due Date"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dueDate && touched.dueDate ? (
                      <small>{errors.dueDate}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="assignedTo" className="mb-3">
                  <Form.Label className="mb-1">
                    Assigned To <RequiredAsterisk />
                  </Form.Label>
                  <Typeahead
                    id="assigned-to-typeahead"
                    clearButton
                    placeholder="Assigned to"
                    isLoading={false}
                    labelKey={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    isValid={
                      !!values.assignedTo.length &&
                      !errors.assignedTo &&
                      touched.assignedTo
                    }
                    isInvalid={
                      !(values.assignedTo.length && !errors.assignedTo) &&
                      touched.assignedTo
                    }
                    onChange={(assignedTo) => {
                      setFieldValue("assignedTo", assignedTo);
                    }}
                    options={subscribers}
                    selected={values.assignedTo}
                    multiple
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.assignedTo && touched.assignedTo ? (
                      <small>{errors.assignedTo}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error ? <ErrorHandler error={error} /> : null}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isLoading}
                  size="sm"
                >
                  <Spinner animation="border" size="sm" hidden={!isLoading} />{" "}
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
