import { useEffect, useRef, useState } from "react";

import { put } from "utils/DeApi";

const useUpdateEntityTag = ({ tagId, onTagUpdated }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * @param {name:"name", color: "#000", description: "" || null} values
   */
  const updateTag = (values) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = put(`/organization-labels/${tagId}`, {
      ...values,
    });
    auditPromise.promise
      .then(({ data: tag }) => {
        if (typeof onTagUpdated === "function") onTagUpdated(tag);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return {
    error,
    isLoading,
    updateTag,
  };
};

export default useUpdateEntityTag;
