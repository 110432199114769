import React from "react";

import { Container, Form } from "react-bootstrap";

function getLocalMidnightTime() {
  const midnight = new Date();
  midnight.setUTCHours(0, 0, 0, 0);
  return midnight.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
}

export default function EmailNotificationSettings({
  settings,
  handleSettingsChange,
  isLoading,
}) {
  return (
    <Container className="p-2">
      <p className="text-muted mt-2">
        Notification settings for the action items you are working on or you are
        following as the assignor.
      </p>
      <hr />

      <div className="px-2">
        <Form.Group className="d-flex justify-content-between mb-2">
          <Form.Check.Label>Send me real time updates</Form.Check.Label>
          <Form.Check
            type="checkbox"
            id="realtimeDigest"
            className="px-2"
            disabled={isLoading}
            checked={settings.actionItemEmailsAlert}
            onChange={(e) =>
              handleSettingsChange(e.target.checked, "actionItemEmailsAlert")
            }
          />
        </Form.Group>
        <Form.Group className="d-flex justify-content-between mb-2">
          <Form.Check.Label>
            Send me a daily digest at {getLocalMidnightTime()}
          </Form.Check.Label>
          <Form.Check
            type="checkbox"
            id="dailyDigest"
            className="px-2"
            disabled={isLoading}
            checked={settings.actionItemDigestAlerts}
            onChange={(e) =>
              handleSettingsChange(e.target.checked, "actionItemDigestAlerts")
            }
          />
        </Form.Group>
        <hr />

        <div className="mt-4">
          <Form.Group className="d-flex justify-content-between ">
            <Form.Check.Label>
              All
              <p className="small text-muted">
                Email me when all events for all action items I am following are
                triggered.
              </p>
            </Form.Check.Label>
            <Form.Check
              disabled={isLoading}
              onChange={(e) => handleSettingsChange(e.target.checked, "all")}
              type="switch"
              id="actionItemAllSwitch"
              checked={settings?.all}
            />
          </Form.Group>

          <Form.Group className="d-flex justify-content-between mb-2">
            <Form.Check.Label>
              Assigned To Me
              <p className="small text-muted">
                Email me when I am assigned to an action item.
              </p>
            </Form.Check.Label>
            <Form.Check
              disabled={isLoading}
              onChange={(e) =>
                handleSettingsChange(e.target.checked, "actionItemCreateAlerts")
              }
              type="switch"
              id="actionItemCreateAlerts"
              checked={settings.actionItemCreateAlerts}
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between mb-2">
            <Form.Check.Label>
              Comments
              <p className="small text-muted">
                Email me when an action item I am following is commented on.
              </p>
            </Form.Check.Label>
            <Form.Check
              disabled={isLoading}
              onChange={(e) =>
                handleSettingsChange(
                  e.target.checked,
                  "actionItemCommentAlerts"
                )
              }
              type="switch"
              id="actionItemCommentAlerts"
              checked={settings?.actionItemCommentAlerts}
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between mb-2">
            <Form.Check.Label>
              Status Update
              <p className="small text-muted">
                Email me when an action item I am following has an updated
                status.
              </p>
            </Form.Check.Label>
            <Form.Check
              disabled={isLoading}
              onChange={(e) =>
                handleSettingsChange(e.target.checked, "actionItemStatusAlerts")
              }
              type="switch"
              id="actionItemStatusAlerts"
              checked={settings?.actionItemStatusAlerts}
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between">
            <Form.Check.Label>
              Overdue
              <p className="small text-muted">
                Email me when an action item I have been assigned is overdue.
              </p>
            </Form.Check.Label>
            <Form.Check
              disabled={isLoading}
              onChange={(e) =>
                handleSettingsChange(
                  e.target.checked,
                  "actionItemOverDueAlerts"
                )
              }
              type="switch"
              id="actionItemOverDueAlerts"
              checked={settings?.actionItemOverDueAlerts}
            />
          </Form.Group>
        </div>
      </div>
    </Container>
  );
}
