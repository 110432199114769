import React from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import { FacilityTypeahead } from "features/entity";
import RequiredAsterisk from "components/ui/RequiredAsterisk";

const ActionItemEntityField = ({ isDashboard }) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext();

  if (!isDashboard) return <span />;

  return (
    <Form.Group controlId="entity" className="mb-3">
      <Form.Label>
        Entity <RequiredAsterisk />
      </Form.Label>
      <FacilityTypeahead
        isValid={
          (values?.entity?.length && !errors.entity) || values?.entity?.length
        }
        isInvalid={
          !values?.entity?.length &&
          errors?.entity &&
          touched?.entity &&
          !touched?.entity?.length
        }
        onChange={(entity) => {
          setFieldValue("entity", entity);
          setFieldTouched("entity", true);
        }}
      />
      <Form.Control.Feedback type="invalid">
        {errors?.entity && touched?.entity && !touched?.entity?.length ? (
          <small>{errors?.entity}</small>
        ) : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

ActionItemEntityField.propTypes = {
  isDashboard: PropTypes.bool,
};

export default ActionItemEntityField;
