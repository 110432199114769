import React, { useState } from "react";

import moment from "moment";
import { Formik } from "formik";
import { useRoles } from "hooks";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import SlateEditor from "components/ui/SlateEditor";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useCreateAudit } from "features/assessment/services";
import ErrorHandler from "components/ui/ErrorHandler";
import { auditValidationSchema } from "features/assessment/schemas";

import GroupTypeahead from "../GroupTypeahead";
import ProtocolTypeahead from "../ProtocolTypeahead";

const AuditCreate = ({ facilityId, onAuditCreated }) => {
  const { isCertifier, isContributor } = useRoles();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const { error, isLoading, createAudit } = useCreateAudit({
    facilityId,
    onAuditCreated: (data) => {
      handleClose();
      onAuditCreated(data);
    },
  });
  if (isCertifier || isContributor) return <></>;
  return (
    <>
      <Button size="sm" variant="primary" onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        New Assessment
      </Button>

      <Offcanvas
        scroll
        show={show}
        placement="end"
        onHide={handleClose}
        className="w-fixed-640"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>New Assessment</Offcanvas.Title>
        </Offcanvas.Header>
        <Formik
          validationSchema={auditValidationSchema}
          onSubmit={(values) => {
            createAudit({
              name: values.name,
              description: values.description,
              executiveSummary: values.executiveSummary,
              auditGroupId:
                Array.isArray(values.group) && values.group.length
                  ? values.group.pop().id
                  : "",
              protocolId: values.protocol.pop().protocolId,
              startedAt: values.startedAt,
              completedAt: values.completedAt,
              dueDate: values.dueDate
                ? moment(values.dueDate).format("YYYY-MM-DD hh:mm:ss")
                : "",
            });
          }}
          initialValues={{
            name: "",
            group: [],
            protocol: "",
            description: "",
            executiveSummary: "",
            startedAt: "2022-01-19 03:14:07",
            completedAt: "2022-01-19 03:14:07",
            dueDate: null,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Body className="h-100vh">
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label>
                    Assessment Name <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Assessment Name"
                    type="text"
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isValid={values.name && !errors.name}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="protocol" className="mb-3">
                  <Form.Label>
                    Assessment Protocol <RequiredAsterisk />
                  </Form.Label>
                  <ProtocolTypeahead
                    isInvalid={
                      !(values.protocol && !errors.protocol) && touched.protocol
                    }
                    isValid={values.protocol && !errors.protocol}
                    onChange={(protocol) => {
                      setFieldTouched("protocol", true);
                      setFieldValue("protocol", protocol);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.protocol && touched.protocol ? (
                      <small>{errors.protocol}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Assessment Description (Optional)</Form.Label>
                  <Form.Control
                    rows={3}
                    placeholder="Enter Description"
                    as="textarea"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    isValid={values.description && !errors.description}
                    isInvalid={!!errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="group" className="mb-3">
                  <Form.Label>
                    Group Assignment (Optional) <br />
                    <small>
                      An assessment can only be assigned to <strong>1</strong>{" "}
                      group
                    </small>
                  </Form.Label>
                  <GroupTypeahead
                    allowNew={true}
                    facilityId={facilityId}
                    selected={values?.group}
                    placeholder="Select or create a group"
                    isValid={
                      !isEmpty(values?.group) &&
                      !errors?.group &&
                      touched?.group
                    }
                    onChange={(group) => {
                      setFieldValue("group", group);
                      setFieldTouched("group", true, true);
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="visually-hidden mb-2"
                  controlId="executiveSummary"
                >
                  <Form.Label>Executive Summary</Form.Label>
                  <div className="bg-light rounded border">
                    <SlateEditor
                      name="executiveSummary"
                      setFieldValue={setFieldValue}
                      placeholder={"Enter Executive Summary"}
                    />
                  </div>
                </Form.Group>
                <Form.Group controlId="dueDate" className="mb-3">
                  <Form.Label className="mb-1">Due Date</Form.Label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    type="date"
                    name="dueDate"
                    popperPlacement="top-start"
                    selected={values.dueDate}
                    onChange={(date) => setFieldValue("dueDate", date)}
                    onBlur={handleBlur}
                    isValid={values.dueDate && !errors.dueDate}
                    isInvalid={
                      !(values.dueDate && !errors.dueDate) && touched.dueDate
                    }
                    minDate={new Date()}
                    isClearable
                    placeholderText="Enter Due Date"
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dueDate && touched.dueDate ? (
                      <small>{errors.dueDate}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Offcanvas.Body>
              <div className="d-flex flex-row px-3 bg-light pb-5 pt-3 position-absolute bottom-0  w-100">
                <div className="flex-fill"></div>
                <div>
                  <Button
                    size="sm"
                    className="me-2"
                    onClick={handleClose}
                    variant="outline-primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    disabled={!isValid || isLoading}
                  >
                    {isLoading && (
                      <Spinner
                        size="sm"
                        variant="light"
                        className="me-2"
                        animation="border"
                      />
                    )}{" "}
                    Create Assessment
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

AuditCreate.propTypes = {
  facilityId: PropTypes.string.isRequired,
  onAuditCreated: PropTypes.func.isRequired,
};

export default AuditCreate;
