import { isNotUndefined } from "utils/StringUtils";

const createBreadcrumb = (facility, location) => {
  const isGroupRoute = location.pathname.includes("groups");
  const isFilesRoute = location.pathname.includes("files");
  return [
    { name: "Dashboard", link: "/" },
    {
      name: facility?.name ?? "Entity",
      ...(isGroupRoute || isFilesRoute
        ? { link: `/facilities/${facility?.facilityId}` }
        : { active: false }),
    },
    isGroupRoute
      ? {
          name: "Assessment Groups",
          active: false,
        }
      : isFilesRoute
      ? {
          name: "Files",
          active: false,
        }
      : undefined,
  ].filter(isNotUndefined);
};

export { createBreadcrumb };
