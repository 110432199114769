import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useFetchFileById } from "features/files/services";
import MediaPreview from "./MediaPreview/MediaPreview";
import FilePreviewLayout from "./FilePreviewLayout";

import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";

import { getURLFileExtension } from "utils/StringUtils";
import { useFetchFileAssociations } from "features/files/services";

export default function FilePreview() {
  const { fileId } = useParams();
  const [searchParams] = useSearchParams();

  const fileType = searchParams.get("fileType");
  const isFile = fileType === "file";
  const isMedia = fileType === "media";

  const {
    file,
    isLoading: isFileLoading,
    error: fetchFileError,
  } = useFetchFileById({
    fileId,
    fetchByDefault: isFile,
  });

  const {
    isLoading: isFileAssociationsLoading,
    error: fetchFileAssociationsError,
    audit,
    question,
    facility,
  } = useFetchFileAssociations(file, !!(isFile && file));

  if (!isFile && !isMedia)
    return (
      <ErrorHandler
        error={{
          statusText: "Error",
          status: 400,
        }}
        message={"Sorry, we couldn't find that file"}
      />
    );

  if (isMedia) return <MediaPreview mediaId={fileId} />;

  if (fetchFileError || fetchFileAssociationsError)
    return (
      <ErrorHandler error={fetchFileError || fetchFileAssociationsError} />
    );

  if (isFileLoading || !file || isFileAssociationsLoading) return <Loader />;

  const { name, url, description, mimeType } = file;
  const fileEXT = getURLFileExtension(url);
  const fileName = name || `${fileId}.${fileEXT}`;

  return (
    <FilePreviewLayout
      fileName={fileName}
      description={description}
      fileSrcURL={url}
      mimeType={mimeType}
      fileEXT={fileEXT}
      audit={audit}
      facility={facility}
      question={question}
    />
  );
}
