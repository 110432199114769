import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { get } from "utils/DeApi";
import { formatDate } from "features/actionItems/utils";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useFetchOrganizationAudits = ({
  page,
  locked,
  status,
  search,
  perPage,
  dueDate,
  updatedAt,
  facilityId,
  protocolId,
  sortState = {},
}) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [error, setError] = useState("");
  const [assessments, setAssessments] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState();

  const fetchAssessments = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const activitiesPromise = get(`/audits`, {
      params: {
        organization_id: organization?.id,
        include: `protocol,facility,labels`,
        page: page ?? 1,
        perPage: perPage ?? 10,
        sort: !!sortState?.sortAsc
          ? sortState?.sortColumn
          : `-${sortState?.sortColumn}`,
        ...(facilityId
          ? {
              "filter[facility_id]": facilityId,
            }
          : {}),
        ...(updatedAt
          ? {
              "filter[updated_at]": formatDate(updatedAt),
            }
          : {}),
        ...(dueDate
          ? {
              "filter[due_date]": formatDate(dueDate),
            }
          : {}),
        ...(protocolId
          ? {
              "filter[protocol_id]": protocolId,
            }
          : {}),
        ...(locked
          ? {
              "filter[locked]": locked,
            }
          : {}),
        ...(status
          ? {
              "filter[status]": status,
            }
          : {}),
        ...(search
          ? {
              "filter[search]": search,
            }
          : {}),
      },
    });

    activitiesPromise.promise
      .then(({ data, meta }) => {
        setMeta(meta);
        setAssessments(data);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(activitiesPromise);
  }, [
    page,
    locked,
    status,
    search,
    perPage,
    dueDate,
    updatedAt,
    facilityId,
    protocolId,
    organization?.id,
    sortState?.sortAsc,
    sortState?.sortColumn,
  ]);

  useEffect(() => {
    fetchAssessments();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchAssessments]);

  return {
    meta,
    error,
    assessments,
    isLoading,
    setError,
    fetchAssessments,
  };
};

export default useFetchOrganizationAudits;
