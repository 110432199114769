import React from "react";

import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { useFetchContentByCategory } from "features/contents/services";

import WhatsNewBody from "./WhatsNewBody/WhatsNewBody";

const WhatsNew = () => {
  const category = process.env.REACT_APP_WHATSNEW_CATEGORY_ID;
  const { error, isLoading, contentSummaryList } =
    useFetchContentByCategory(category);

  return (
    <NavDropdown title="What's New" id="whatsNew" align="end">
      {error && <ErrorHandler error={error} />}
      {isLoading && <Loader />}{" "}
      {contentSummaryList && (
        <div className="px-3 pt-3 m-2" style={{ width: 380 }}>
          <Link
            to={"/whats-new-articles"}
            className="float-end text-decoration-none "
          >
            <small>View all updates</small>
          </Link>
          <h4>What's New</h4>
          <NavDropdown.Divider className="my-3" />
          <WhatsNewBody whatsNewContents={contentSummaryList} filtered={true} />
        </div>
      )}
    </NavDropdown>
  );
};

export default WhatsNew;
