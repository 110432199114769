import { useToast } from "hooks";
import { useEffect, useRef, useState } from "react";
import { destroy } from "utils/DeApi";

const useDeleteActionItem = ({ actionItemId, onActionItemDeleted }) => {
  const toast = useToast();

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const deleteActionItem = (actionItem) => {
    setError(null);
    setIsLoading(true);

    const actionItemPromise = destroy(`/action-items/${actionItemId}`);

    actionItemPromise.promise
      .then((resp) => {
        onActionItemDeleted(actionItem);
        toast.success(
          "Success",
          "The action Item has been deleted successfully"
        );
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(actionItemPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return {
    deleteActionItem,
    isLoading,
    error,
  };
};

export default useDeleteActionItem;
