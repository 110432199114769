import * as yup from "yup";

const entityValidationSchema = yup.object().shape({
  //General Information
  name: yup
    .string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Name is required"),
  address: yup.string().min(2, "Too Short!").max(100, "Too Long!"),
  customId: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .notRequired(),
  description: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),

  // Location/Contact Information
  county: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
  latitude: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
  longitude: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
  mailingAddress: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
  entityPhoneNumber: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
  entity24hrPhoneNumber: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),

  // Advanced Information
  maxOccupancy: yup.number().nullable().notRequired(),
  numberOfEmployees: yup.number().nullable().notRequired(),
  entityType: yup.number().nullable().notRequired(),
  fireDepartment: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
  lepc: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
  dunAndBradstreetNumber: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
  naicsCode: yup
    .string()
    .min(2, "Too Short!")
    .max(255, "Too Long!")
    .nullable()
    .notRequired(),
});

export default entityValidationSchema;
