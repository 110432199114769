import React, { useContext, useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { useToast } from "hooks";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Form,
  Offcanvas,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";

import { put } from "utils/DeApi";
import { UserContext } from "contexts/UserProvider";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

import MemberFacilityList from "../MemberFacilityList";
import MemberPortfolioList from "../MemberPortfolioList";

const MemberUpdate = ({
  member = {},
  onMemberUpdated,
  isNewMember = false,
}) => {
  const toast = useToast();
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(isNewMember);

  const [isLoadingRolesUpdate, setIsLoadingRolesUpdate] = useState(false);
  const [isSuccessRolesUpdate, setIsSuccessRolesUpdate] = useState(false);
  const [error, setError] = useState();
  const [key, setKey] = useState("facilities");

  // Portfolio component related items
  const portfolioListRef = useRef(null);
  const [isLoadingPortfolioUpdate, setIsLoadingPortfolioUpdate] =
    useState(false);
  const [isErrorPortfolioUpdate, setIsErrorPortfolioUpdate] = useState(false);
  const [isSuccessPortfolioUpdate, setIsSuccessPortfolioUpdate] =
    useState(false);

  // Facility component related items
  const facilityListRef = useRef(null);
  const [isLoadingFacilityUpdate, setIsLoadingFacilityUpdate] = useState(false);
  const [isErrorFacilityUpdate, setIsErrorFacilityUpdate] = useState(false);
  const [isSuccessFacilityUpdate, setIsSuccessFacilityUpdate] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUpdateButton = (values) => {
    if (!(values.role === 1 || values.role === 2)) {
      portfolioListRef.current.updateMemberPortfolios();
      facilityListRef.current.updateMemberFacilities();
    }
    updateMember(values);
  };

  const updateMember = (values) => {
    setError(null);
    setIsLoadingRolesUpdate(true);
    const auditPromise = put(
      `/organizations/${organization?.id}/members/${member.subscriberId}`,
      {
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role,
      }
    );
    auditPromise.promise
      .then(({ data }) => {
        handleClose();
        onMemberUpdated(data);
        setIsSuccessRolesUpdate(true);
        toast.success("Success", "The member has been upddated successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoadingRolesUpdate(false));

    subscribedPromises.current.push(auditPromise);
  };

  const schema = yup.object().shape({
    firstName: yup.string().min(2).max(255).required("First name is required"),
    lastName: yup.string().min(2).max(255).required("Last name is required"),
    role: yup.number().required("Role is required"),
  });

  const assignRoleId = (role) => {
    if (role === "Admin") return 1;
    if (role === "Member") return 0;
    if (role === "Certifier") return 3;
    if (role === "Contributor") return 4;
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  if (member.invitation.role === "Owner") return <span />;
  if (member.subscriberId === user?.de?.subscriberId) return <span />;

  return (
    <>
      <Button size="sm" onClick={handleShow} variant="outline-primary">
        <span translate="no" className="material-symbols-outlined md-16">
          edit
        </span>
      </Button>

      <Offcanvas
        show={show}
        placement="end"
        onHide={handleClose}
        className="w-fixed-640"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Update Member</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleUpdateButton(values)}
            initialValues={{
              firstName: member?.firstName,
              lastName: member?.lastName,
              role: assignRoleId(member?.invitation.role),
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldValue,
              handleBlur,
              handleChange,
              touched,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="d-flex flex-column justify-content-between h-100"
              >
                <div>
                  <Form.Group controlId="firstName" className="mb-3">
                    <Form.Label className="mb-1">
                      First Name <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={values.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isValid={values.firstName && !errors.firstName}
                      isInvalid={
                        !(values.firstName && !errors.firstName) &&
                        touched.firstName
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName && touched.firstName ? (
                        <small>{errors.firstName}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="lastName" className="mb-3">
                    <Form.Label className="mb-1">
                      Last Name <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isValid={values.lastName && !errors.lastName}
                      isInvalid={
                        !(values.lastName && !errors.lastName) &&
                        touched.lastName
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName && touched.lastName ? (
                        <small>{errors.lastName}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="mb-3">
                    <div className="p-3 mb-3 rounded bg-light">
                      <p>
                        Manage member ·{" "}
                        <small className="text-muted">{member.email}</small>
                      </p>

                      <Form.Group controlId="risk" className="my-3">
                        <Form.Label>
                          Members Roles <RequiredAsterisk />
                        </Form.Label>
                        <br />

                        <Form.Check
                          inline
                          label={"Admin"}
                          name="role"
                          value={1}
                          checked={values.role === 1}
                          onChange={(event) => setFieldValue("role", 1)}
                          type="radio"
                          id={`checkbox-member`}
                        />
                        <Form.Check
                          inline
                          label={"Certifier"}
                          name="role"
                          value={3}
                          checked={values.role === 3}
                          onChange={(event) => setFieldValue("role", 3)}
                          type="radio"
                          id={`checkbox-member`}
                        />
                        <Form.Check
                          inline
                          label={"Member"}
                          name="role"
                          value={0}
                          checked={values.role === 0}
                          onChange={(event) => setFieldValue("role", 0)}
                          type="radio"
                          id={`checkbox-member`}
                        />
                        <Form.Check
                          inline
                          label={"Contributor"}
                          name="role"
                          value={4}
                          checked={values.role === 4}
                          onChange={(event) => setFieldValue("role", 4)}
                          type="radio"
                          id={`checkbox-member`}
                        />
                      </Form.Group>
                    </div>
                    {error && <ErrorHandler error={error} />}
                    {values.role === 1 || values.role === 2 ? (
                      <Alert variant="info" className={`my-3 d-flex flex-row`}>
                        <div className="me-3">
                          <span
                            translate="no"
                            className="material-symbols-outlined md-18 text-primary text-opacity-75"
                          >
                            info
                          </span>
                        </div>
                        <div>
                          <h5 className="mb-1">
                            <small>Admin access</small>
                          </h5>
                          <p className="mb-1">
                            <small>
                              Admins and owner have access to all entities and
                              portfolios
                            </small>
                          </p>
                        </div>
                      </Alert>
                    ) : (
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                      >
                        <Tab eventKey="facilities" title="Entities Access">
                          <>
                            <p className="text-muted">
                              <small>
                                Select <strong>entities</strong> to provide
                                access to the member.
                              </small>
                            </p>
                            {member?.subscriberId && (
                              <MemberFacilityList
                                memberId={member?.subscriberId}
                                setIsExpanding={setIsLoadingFacilityUpdate}
                                setIsError={setIsErrorFacilityUpdate}
                                setIsSuccess={setIsSuccessFacilityUpdate}
                                ref={facilityListRef}
                                role={member?.invitation.role}
                              />
                            )}
                          </>
                        </Tab>
                        <Tab eventKey="portfolios" title="Portfolios Access">
                          <>
                            <p className="text-muted">
                              <small>
                                Select <strong>portfolios</strong> to provide
                                access to the member.
                              </small>
                            </p>
                            {member?.subscriberId && (
                              <MemberPortfolioList
                                memberId={member?.subscriberId}
                                setIsExpanding={setIsLoadingPortfolioUpdate}
                                setIsError={setIsErrorPortfolioUpdate}
                                setIsSuccess={setIsSuccessPortfolioUpdate}
                                ref={portfolioListRef}
                                role={member?.invitation.role}
                              />
                            )}
                          </>
                        </Tab>
                      </Tabs>
                    )}
                  </div>
                  <div className="text-end align-bottom">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      className="float-end px-3 ms-3"
                      disabled={
                        Object.keys(errors).length > 0 ||
                        isLoadingRolesUpdate ||
                        isLoadingPortfolioUpdate ||
                        isLoadingFacilityUpdate ||
                        isErrorFacilityUpdate ||
                        isErrorPortfolioUpdate
                      }
                    >
                      {isLoadingRolesUpdate && (
                        <Spinner
                          className="me-2"
                          animation="border"
                          size="sm"
                          variant="light"
                        />
                      )}{" "}
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

MemberUpdate.propTypes = {
  onMemberUpdated: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
  isNewMember: PropTypes.bool.isRequired,
};

export default MemberUpdate;
