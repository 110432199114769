import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { setAccentColor } from "utils/EntityTagUtils";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import TagUpdate from "../TagUpdate";
import TagDelete from "../TagDelete";

const AssessmentTagList = ({ error, isLoading, auditTags, setAuditTags }) => {
  if (isLoading)
    return (
      <tr>
        <td colSpan={5}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={5}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (!auditTags.length)
    return (
      <tr>
        <td colSpan={5}>
          <EmptyStateHandler
            title="No tags"
            message="Tags will appear here once added"
          />
        </td>
      </tr>
    );

  return auditTags.map((tag) => {
    return (
      <tr key={tag.auditLabelId}>
        <td>{tag.name}</td>
        <td>
          <small
            className="px-3 py-1"
            style={{
              backgroundColor: tag?.color || "#C1C1C1",
              color: setAccentColor(tag?.color),
            }}
          >
            {tag?.color || "#C1C1C1"}
          </small>
        </td>
        <td>{tag.description}</td>
        <td>
          <small>
            {new Date(tag.updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        <td>
          <TagUpdate
            tag={tag}
            onTagUpdated={(tag) =>
              setAuditTags((prevStatus) =>
                prevStatus.map((prevTag) =>
                  prevTag.auditLabelId === tag.auditLabelId ? tag : prevTag
                )
              )
            }
          />{" "}
          <TagDelete
            tag={tag}
            onTagDeleted={(tag) =>
              setAuditTags((prevStatus) =>
                prevStatus.filter(
                  ({ auditLabelId }) => auditLabelId !== tag.auditLabelId
                )
              )
            }
          />
        </td>
      </tr>
    );
  });
};

export default AssessmentTagList;
