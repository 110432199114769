import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useFetchSubscribers } from "features/organization";
import { useFormikContext } from "formik";
import { uniqBy } from "lodash";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import PropTypes from "prop-types";

const ActionItemAssignToField = ({
  show,
  taskableType,
  taskableId,
  facilityId,
  setError,
  members = [],
  isDashboard = false,
}) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const subscribersUrl = () => {
    if (isDashboard && values?.entity?.length)
      return `facilities/${values?.entity[0]?.facilityId}/subscribers`;
    if (taskableType === "facility")
      return `facilities/${taskableId}/subscribers`;
    if (taskableType === "question")
      return `facilities/${facilityId}/subscribers`;
    return "subscribers";
  };

  const { subscribers, isLoading, error, setSubscribers } = useFetchSubscribers(
    {
      path: show ? subscribersUrl() : "",
    }
  );

  useEffect(() => {
    setError(error);
  }, [error, setError]);

  return (
    <Form.Group controlId="assigned-to" className="mb-3">
      <Form.Label className="mb-1">
        Assigned To <RequiredAsterisk />
      </Form.Label>
      <Typeahead
        id="subscribers-typeahead"
        clearButton
        placeholder="Select a user..."
        isLoading={isLoading}
        disabled={isDashboard && !values?.entity?.length}
        labelKey={(option) => `${option.firstName} ${option.lastName}`}
        isValid={
          !!values.assignedTo.length && !errors.assignedTo && touched.assignedTo
        }
        isInvalid={
          !(values.assignedTo && !errors.assignedTo) && touched.assignedTo
        }
        onChange={(assignedTo) => {
          setFieldValue("assignedTo", assignedTo);
          setSubscribers((prev) =>
            uniqBy([...prev, ...members], "subscriberId")
          );
        }}
        options={subscribers.filter(
          (s) =>
            !values.assignedTo
              .map(({ subscriberId }) => subscriberId)
              .includes(s.subscriberId)
        )}
        selected={values.assignedTo}
        multiple
        renderToken={(option, props) => {
          return (
            <Token
              key={option.subscriberId}
              onRemove={() => {
                setFieldValue(
                  "assignedTo",
                  values.assignedTo.filter(
                    (a) => a.subscriberId !== option.subscriberId
                  )
                );
                setSubscribers((prev) =>
                  uniqBy([...prev, option], "subscriberId")
                );
              }}
            >
              {option?.firstName} {option?.lastName}
            </Token>
          );
        }}
      />
    </Form.Group>
  );
};

ActionItemAssignToField.propTypes = {
  show: PropTypes.bool,
  taskableType: PropTypes.string,
  taskableId: PropTypes.string,
  facilityId: PropTypes.string,
  setError: PropTypes.func,
  members: PropTypes.array,
  isDashboard: PropTypes.bool,
};

export default ActionItemAssignToField;
