import React, { Fragment } from "react";

import { Link, useParams } from "react-router-dom";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { useFetchContentByCategory } from "features/contents/services";

const RelatedContentList = ({ categories = [] }) => {
  const { contentId } = useParams();

  const categoryId = categories[0]?.categoryId;
  const { isLoading, error, contentSummaryList } =
    useFetchContentByCategory(categoryId);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!contentSummaryList) return <></>;

  return (
    <>
      <h3>Related Articles</h3>
      <hr />
      <ul className="ps-0">
        {contentSummaryList?.map(({ contentId: id, title }) => {
          if (id === contentId) {
            return <Fragment key={id} />;
          }

          return (
            <li key={id} className="my-3 list-group list-group-flush">
              <Link to={`/contents/${id}`}>
                <span translate="no" className="material-symbols-outlined me-2">
                  article
                </span>{" "}
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default RelatedContentList;
