import React, { useEffect, useRef } from "react";

import * as echarts from "echarts";
import { Col, Row } from "react-bootstrap";

import "./RadioFieldPiechart.scss";

const CHART_HEIGHT = 270;

function RadioFieldPiechart({ data }) {
  const el = useRef(null);

  useEffect(() => {
    if (!data?.stats?.length) {
      return;
    }

    const chartData = data?.stats?.map(({ option, count }) => ({
      name: option,
      value: count,
    }));

    const sum = chartData?.reduce((prev, current) => prev + current.value, 0);

    const dimension = {
      width: el.current.offsetWidth || 800,
      height: CHART_HEIGHT,
    };

    const option = {
      title: {
        textStyle: {
          color: "grey",
          fontSize: 15,
          fontWeight: "lighter",
        },

        text: `Total: ${sum} `,
        left: "center",
        top: "bottom",
      },
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />{d0}%",
      },

      series: [
        {
          name: "",
          type: "pie",
          radius: "50%",
          label: {
            show: true,
            formatter: "{b},{c} ({d}%)",

            edgeDistance: "1%",
          },
          labelLine: {
            length: 10,
            length2: 10,
          },

          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          percentPrecision: 1,
          data: chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    if (!data?.stats) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
    }

    const chart = echarts.init(el.current, null, dimension);

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: CHART_HEIGHT,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data]);

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="mb-4">
        <p className="text-center w-100 text-truncate">
          Distribution of Findings by {data?.fieldName}
        </p>
        <hr />
        <div className="d-flex justify-content-center">
          <div ref={el} />
        </div>
      </Col>
    </Row>
  );
}

export default RadioFieldPiechart;
