import { useEffect, useRef, useState } from "react";
import { destroy } from "utils/DeApi";

const useDeleteTag = ({ tag, onTagDeleted }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const deleteTag = () => {
    setError(null);
    setIsLoading(true);

    const tagPromise = destroy(`/labels/${tag?.labelId}`);

    tagPromise.promise
      .then(({ data }) => onTagDeleted(tag))
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(tagPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return {
    isLoading,
    error,
    deleteTag,
  };
};

export default useDeleteTag;
