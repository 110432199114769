import { OrganizationContext } from "contexts/OrganizationProvider";
import { useRoles } from "hooks";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchFacilities = ({ protocolId }) => {
  const organization = useContext(OrganizationContext);
  const { isMember } = useRoles();

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [entities, setEntities] = useState();

  const fetchFacilities = useCallback(() => {
    setError(null);
    setIsLoading(true);
    const facilitiesPromise = get(
      `organizations/${organization?.id}/facilities`,
      {
        params: {
          ...(protocolId
            ? {
                "filter[jurisdiction]": protocolId,
              }
            : {}),
          perPage: 1000000000000,
        },
      }
    );
    facilitiesPromise.promise
      .then(({ data: facilities }) => setEntities(facilities ?? []))
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  }, [organization?.id, protocolId]);

  useEffect(() => {
    if (protocolId && isMember) fetchFacilities();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchFacilities, isMember, protocolId]);

  return {
    entities,
    isLoading,
    error,
  };
};

export default useFetchFacilities;
