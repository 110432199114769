import { useEffect, useState } from "react";
import {
  showRisk,
  showNotes,
  showCompliance,
  showCasualFactor,
  showRecommendation,
  showConformityLevel,
  showCorrectiveAction,
  showConformityInapplicableReasons,
} from "features/assessment/components/Audit/helper";

const useShowObservationProperties = ({ protocol }) => {
  const [visibility, setVisibility] = useState({
    isRisk: false,
    isNotes: false,
    isCompliance: false,
    isCasualFactor: false,
    isConformity: false,
    isRecommendation: false,
    isCorrectiveAction: false,
    isConformityInapplicableReasons: false,
  });

  useEffect(() => {
    function applyVisibility() {
      const { conformity, questionOptions } = protocol || {};

      setVisibility((prevState) => ({
        ...prevState,
        isRisk: showRisk(conformity, questionOptions),
        isNotes: showNotes(conformity, questionOptions),
        isCompliance: showCompliance(conformity, questionOptions),
        isCasualFactor: showCasualFactor(conformity, questionOptions),
        isConformity: showConformityLevel(conformity, questionOptions),
        isRecommendation: showRecommendation(conformity, questionOptions),
        isConformityInapplicableReasons: showConformityInapplicableReasons(
          conformity,
          questionOptions
        ),
        isCorrectiveAction: showCorrectiveAction(conformity, questionOptions),
      }));
    }

    if (protocol) {
      applyVisibility();
    }
  }, [protocol]);
  return { ...visibility };
};

export default useShowObservationProperties;
