import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";

import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import PropTypes from "prop-types";
import { get, put } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { Alert, Form, Table } from "react-bootstrap";

const MemberFacilityList = forwardRef(
  ({ memberId, setIsExpanding, setIsError, setIsSuccess, role }, ref) => {
    const organization = useContext(OrganizationContext);
    const subscribedPromises = useRef([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [facilities, setFacilities] = useState([]);
    const [accessibleFacilities, setAccessibleFacilities] = useState([]);
    const [filterTerm, setFilterTerm] = useState("");
    const [displayedFacilities, setDisplayedFacilities] = useState([]);

    useImperativeHandle(ref, () => {
      return {
        updateMemberFacilities() {
          const facilityIds = accessibleFacilities.map(
            ({ facilityId }) => facilityId
          );
          setError(null);
          setIsError(false);
          setIsExpanding(true);
          const auditPromise = put(`/members/${memberId}/facilities`, {
            organization_id: organization?.id,
            facility_ids: facilityIds || [],
          });
          auditPromise.promise
            .then((response) => {
              setIsExpanding(false);
              setIsSuccess(true);
            })
            .catch((error) => {
              !error.isCanceled && setError(error) && setIsError(true);
              setIsExpanding(false);
            });

          subscribedPromises.current.push(auditPromise);
        },
      };
    });

    useEffect(() => {
      const fetchMemberFacilities = () => {
        setError(null);
        setIsError(false);
        setIsLoading(true);
        const memberFacilitiesPromise = get(`members/${memberId}/facilities`, {
          params: {
            organization_id: organization?.id,
          },
        });

        const facilitiesPromise = get(`facilities`, {
          params: {
            organizationId: organization?.id,
          },
        });

        Promise.all([
          memberFacilitiesPromise.promise,
          facilitiesPromise.promise,
        ])
          .then((response) => {
            const accessible = response[0].data;
            const facilities = response[1].data;
            setIsLoading(false);
            setAccessibleFacilities(role === "Admin" ? [] : accessible);
            setFacilities(facilities);
          })
          .catch((error) => {
            if (!error.isCanceled) {
              setIsError(true);
              setError(error);
              setIsLoading(false);
            }
          });
        subscribedPromises.current.push(
          facilitiesPromise,
          memberFacilitiesPromise
        );
      };

      if (
        typeof organization?.id === "string" &&
        typeof memberId === "string"
      ) {
        fetchMemberFacilities();
      }

      const promises = subscribedPromises.current;
      return () => {
        promises.forEach((promise) => {
          promise.cancel();
        });
      };
    }, [memberId, organization?.id]);

    useEffect(() => {
      const tmpFilteredFacilities = facilities.filter((tmpFacility) =>
        tmpFacility.name.toLowerCase().includes(filterTerm.toLowerCase())
      );

      setDisplayedFacilities(
        tmpFilteredFacilities.length > 0 ? tmpFilteredFacilities : facilities
      );
    }, [filterTerm, facilities]);

    const handleFilterFacilities = ({ target: { value } }) => {
      setFilterTerm(value);
    };

    return (
      <>
        <Form.Control
          type="text"
          placeholder="Search By Entity"
          onChange={handleFilterFacilities}
          className="mb-3"
        />
        <Table>
          <thead>
            <tr>
              <th>Entities</th>
              <th className="text-end">Can Access</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {isLoading && (
              <tr>
                <td colSpan={2}>
                  <Loader />
                </td>
              </tr>
            )}

            {error && (
              <tr>
                <td colSpan={2}>
                  <ErrorHandler error={error} />
                </td>
              </tr>
            )}

            {!displayedFacilities.length && !isLoading && (
              <tr>
                <td colSpan={2}>
                  <Alert variant="info" className={`my-3 d-flex flex-row`}>
                    <div className="me-3">
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18 text-primary text-opacity-75"
                      >
                        info
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-1">
                        <small>No entities</small>
                      </h5>
                      <p className="mb-1">
                        <small>There are currently no entities to show.</small>
                      </p>
                    </div>
                  </Alert>
                </td>
              </tr>
            )}

            {displayedFacilities.length > 0 && (
              <>
                <tr className="border text-primary bg-light">
                  <td>All entities</td>
                  <td>
                    <Form.Check
                      type="switch"
                      className="float-end"
                      checked={facilities.every(
                        ({ facilityId }) =>
                          !!accessibleFacilities.find(
                            (f) => facilityId === f.facilityId
                          )
                      )}
                      onChange={(e) => {
                        setAccessibleFacilities((prevState) => {
                          const allAccessible = facilities.every(
                            ({ facilityId }) =>
                              !!accessibleFacilities.find(
                                (f) => facilityId === f.facilityId
                              )
                          );
                          const allFacilities = allAccessible ? [] : facilities;
                          return allFacilities;
                        });
                      }}
                    />
                  </td>
                </tr>
                {displayedFacilities.map((f) => {
                  const isAccessible = !!accessibleFacilities.find(
                    ({ facilityId }) => facilityId === f.facilityId
                  );
                  return (
                    <tr key={f.facilityId}>
                      <td>{f.name}</td>
                      <td>
                        <Form.Check
                          className="float-end"
                          onChange={(e) => {
                            setAccessibleFacilities((prevState) => {
                              const facilities = isAccessible
                                ? prevState.filter(
                                    ({ facilityId }) =>
                                      facilityId !== f.facilityId
                                  )
                                : prevState.concat(f);
                              return facilities;
                            });
                          }}
                          checked={isAccessible}
                          type="switch"
                          id="custom-switch"
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      </>
    );
  }
);

MemberFacilityList.propTypes = {
  memberId: PropTypes.string.isRequired,
  setIsExpanding: PropTypes.bool.isRequired,
  setIsError: PropTypes.bool.isRequired,
  setIsSuccess: PropTypes.bool.isRequired,
};

export default MemberFacilityList;
