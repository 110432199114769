import { Badge } from "react-bootstrap";

import "./ConfidentialBadge.scss";

const ConfidentialBadge = ({ confidential }) => {
  if (!confidential) return <></>;
  return (
    <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
      <small className="text-danger">
        <span translate="no" className="material-symbols-outlined md-13">
          block
        </span>{" "}
        Confidential
      </small>
    </Badge>
  );
};

export default ConfidentialBadge;
