import { useEffect, useRef, useState, useContext } from "react";
import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useFetchPortfolioById = ({ portfolioId }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [portfolio, setPortfolio] = useState();

  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    const fetchPortfolio = () => {
      setError(null);
      setIsLoading(true);

      const portfolioPromise = get(
        `organizations/${organization?.id}/portfolios/${portfolioId}`
      );
      portfolioPromise.promise
        .then(({ data: portfolio }) => {
          setPortfolio(portfolio);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      subscribedPromises.current.push(portfolioPromise);
    };

    if (portfolioId && organization?.id) fetchPortfolio();
  }, [organization?.id, portfolioId]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);
  return { error, isLoading, portfolio, setPortfolio };
};

export default useFetchPortfolioById;
