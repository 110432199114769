import { truncate } from "lodash";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { removeHtmlTags } from "features/actionItems/utils";

const ActionItemCollapseDescription = ({ description = "" }) => {
  const [seeMore, setSeeMore] = useState(false);

  const stringTruncate = (length) => {
    return truncate(removeHtmlTags(description), {
      length,
    });
  };

  const cleanDescription = removeHtmlTags(description || "");

  return (
    !!cleanDescription?.length && (
      <Col xs={12} className="mb-3">
        <span className="fw-semibold">Description</span>
        <p className="mt-1">
          {!seeMore ? stringTruncate(200) : cleanDescription}
        </p>
        {cleanDescription?.length > 200 && (
          <p
            className="text-decoration-underline cursor-pointer"
            onClick={() => setSeeMore(!seeMore)}
          >
            {!seeMore ? "See more" : "See less"}
          </p>
        )}
      </Col>
    )
  );
};

ActionItemCollapseDescription.propTypes = {
  description: PropTypes.string,
};

export default ActionItemCollapseDescription;
