import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";
import PropTypes from "prop-types";

import { post } from "utils/DeApi";

const useCreateFacilityAuditGroup = ({ facilityId, onGroupCreated }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const createFacilityAuditGroup = (group) => {
    setError(null);
    setIsLoading(true);
    const facilityGroupPromise = post(
      `/facilities/${facilityId}/audit-groups`,
      {
        ...group,
      }
    );
    facilityGroupPromise.promise
      .then(({ data }) => {
        if (typeof onGroupCreated === "function") onGroupCreated(data);
        toast.success("Sucess", "Entity assessment group created successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(facilityGroupPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    createFacilityAuditGroup,
  };
};

useCreateFacilityAuditGroup.propTypes = {
  onGroupCreated: PropTypes.func.isRequired,
};

export default useCreateFacilityAuditGroup;
