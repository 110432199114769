import React, { useState } from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";

import { first } from "utils/ArrayUtils";
import { LEVELS } from "features/files/constants";
import BreadCrumbs from "components/ui/BreadCrumbs";
import { FacilityTypeahead } from "features/entity";
import { useFetchFiles } from "features/files/services";
import MetaPagination from "components/ui/MetaPagination";
import { FacilityAuditsTypeahead } from "features/assessment";
import { createBreadcrumb } from "features/files/utils/breadcrumpUtil";

import FileUpload from "./FileUpload";
import FileListItems from "./FileListItems";
import FilterLabel from "../ui/FilterLabel";
import ZipFileDownload from "./ZipFileDownload";

const Files = ({ noBreadCrumbs = false }) => {
  const { isCertifier } = useRoles();
  const [entity, setEntity] = useState([]);
  const [level, setLevel] = useState(LEVELS[0]);
  const [assessment, setAssessment] = useState([]);
  const [expandableId, setExpandableId] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { isLoading, error, meta, files, setFiles } = useFetchFiles({
    page,
    level,
    entity,
    assessment,
  });

  const onFileUploaded = (uploadedFiles) => {
    setFiles((prevFiles) => [...uploadedFiles, ...prevFiles]);
  };

  const handleEntityChange = (entity) => {
    setPage(1);
    setAssessment(null);
    setEntity(first(entity));
  };

  const handleAssessmentChange = (assessment) => {
    setPage(1);
    setAssessment(first(assessment));
  };

  return (
    <>
      <Helmet>
        <title>{"Files & Photos"}</title>
      </Helmet>
      {!noBreadCrumbs && <BreadCrumbs breadcrumbs={createBreadcrumb()} />}
      <div className="d-flex flex-row mb-3 align-items-center">
        <div className="flex-grow-1">
          <h1 className="mb-0">Files & Photos</h1>
        </div>
        <ZipFileDownload
          page={page}
          level={level}
          entity={entity}
          assessmen={assessment}
        />
        <FileUpload onFileUploaded={onFileUploaded} />
      </div>
      <Row>
        <Col xs={12} className="mb-3">
          <Form as={Row}>
            <Form.Group
              as={Col}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="mb-3"
            >
              <FilterLabel>Filter by Level</FilterLabel>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="dropdown-sorting"
                  className="w-100 border border-gray-900 bg-white text-secondary"
                >
                  {level?.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {LEVELS.map((s) => (
                    <Dropdown.Item
                      key={s.index}
                      active={s.value === level?.value}
                      onClick={() => setLevel(s)}
                    >
                      {s?.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
              <FilterLabel>Filter by Entity</FilterLabel>
              <FacilityTypeahead
                onChange={handleEntityChange}
                placeholder="Select an Entity..."
              />
            </Form.Group>
            <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
              <FilterLabel>Filter by Assessment</FilterLabel>
              <FacilityAuditsTypeahead
                facilityId={entity?.facilityId}
                onChange={handleAssessmentChange}
                placeholder="Select an Assessment..."
                selected={assessment?.auditId ? [assessment] : null}
              />
            </Form.Group>
          </Form>
          <Card>
            <Card.Body className="table-responsive">
              <Table hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Level</th>
                    <th>Updated</th>
                    {!isCertifier && <th>Action</th>}
                  </tr>
                </thead>
                <tbody className="table-assessment-divider">
                  <FileListItems
                    error={error}
                    files={files}
                    setFiles={setFiles}
                    isLoading={isLoading}
                    expandableId={expandableId}
                    setExpandableId={setExpandableId}
                  />
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <MetaPagination
                meta={meta}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

Files.propTypes = {
  noBreadCrumbs: PropTypes.bool,
};

export default Files;
