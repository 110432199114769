const createBreadcrumb = ({
  pathname = "",
  portfolios = [],
  portfolioId = "",
}) => {
  const rootCrump = { name: "Dashboard", link: "/" };
  if (pathname?.includes("portfolios")) {
    const portfolio = portfolios.find(({ id }) => id === portfolioId);
    return [
      rootCrump,
      {
        name: portfolio ? portfolio.name : "portfolios",
        active: false,
      },
    ];
  }

  if (pathname?.includes("files")) {
    return [
      rootCrump,
      {
        name: "Files",
        active: false,
      },
    ];
  }

  return [{ name: "Dashboard", active: false }];
};

export { createBreadcrumb };
