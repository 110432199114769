const createCrumpTitle = ({ pathname = "", organizationId = "" }) => {
  const URLSnippet = `/organizations/${organizationId}`;
  if (pathname === `${URLSnippet}/members`) return "Members";
  if (pathname === `${URLSnippet}/entity-tags`) return "Entity tags";
  if (pathname === `${URLSnippet}/action-item-tags`) return "Action Item tags";

  return "System Settings";
};

export default createCrumpTitle;
