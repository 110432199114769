import { useEffect, useState } from "react";

const useQuestion = (questionId, questions = []) => {
  const [question, setQuestion] = useState(null);
  useEffect(() => {
    function fetchQuestion() {
      const found = Array.isArray(questions)
        ? questions.find(({ questionId: id }) => id === questionId)
        : null;
      if (found) setQuestion(found);
    }

    fetchQuestion();
  }, [questionId, questions]);

  return { question };
};

export default useQuestion;
