import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import TagsTypeahead from "features/entity/components/ui/TagsTypeahead";

const GeneralInfomationFields = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  return (
    <>
      <Form.Group controlId="name" className="mb-3">
        <Form.Label className="mb-1 w-100">
          Name
          <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          isValid={values.name && !errors.name}
          isInvalid={!!errors.name && touched.name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name && touched.name ? <small>{errors.name}</small> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="address" className="mb-3">
        <Form.Label className="mb-1">Address</Form.Label>
        <Form.Control
          type="text"
          name="address"
          onBlur={handleBlur}
          value={values.address}
          onChange={handleChange}
          isValid={values.address && !errors.address}
          isInvalid={!!errors.address && touched.address}
        />
        <Form.Control.Feedback type="invalid">
          {errors.address && touched.address ? (
            <small>{errors.address}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="entityTags" className="mb-3">
        <Form.Label className="mb-1">Select Tags</Form.Label>
        <TagsTypeahead
          selected={values.entityTags}
          onChange={(tags) => setFieldValue("entityTags", tags)}
          isValid={values.entityTags.length && !errors.entityTags}
          isInvalid={!!errors.entityTags && touched.entityTags}
          onBlur={() => setFieldTouched("entityTags", true, true)}
        />
        <Form.Control.Feedback type="invalid">
          {errors.entityTags && touched.entityTags ? (
            <small>{errors.entityTags}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="customId" className="mb-3">
        <Form.Label className="mb-1">Facility ID</Form.Label>
        <Form.Control
          type="text"
          name="customId"
          value={values.customId}
          onBlur={handleBlur}
          onChange={handleChange}
          isValid={values.customId && !errors.customId}
          isInvalid={!!errors.customId && touched.customId}
        />
        <Form.Control.Feedback type="invalid">
          {errors.customId && touched.customId ? (
            <small>{errors.customId}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="description" className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          rows={3}
          as="textarea"
          name="description"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.description}
          isValid={values.description && !errors.description}
          isInvalid={!!errors.description && touched.description}
        />
        <Form.Control.Feedback type="invalid">
          {errors.description && touched.description ? (
            <small>{errors.description}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default GeneralInfomationFields;
