import { Badge } from "react-bootstrap";

import "./AuditStatusBadge.scss";

const AuditStatusBadge = ({ status }) => {
  if (status !== "Done") return <></>;
  return (
    <Badge className="bg-primary me-2 mb-1">
      <small>
        <span translate="no" className="material-symbols-outlined md-13">
          check
        </span>{" "}
        {status}
      </small>
    </Badge>
  );
};

export default AuditStatusBadge;
