import React, { useContext, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import {
  OrganizationContext,
  OrganizationsContext,
  OrganizationDispatchContext,
} from "contexts/OrganizationProvider";

import "./SwitchOrganization.scss";

const SwitchOrganization = () => {
  const activeOrganization = useContext(OrganizationContext);
  const organizations = useContext(OrganizationsContext);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const organizationId = urlParams.get("organizationId");
  const setOrganization = useContext(OrganizationDispatchContext);
  const navigate = useNavigate();

  let pageName = "Action Item";
  if (location.pathname.includes("files")) {
    pageName = "File";
  }

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    navigate(location?.pathname || "/");
  };

  const organization =
    organizations.find(({ id }) => id === organizationId) || {};

  const switchOrganization = () => {
    setOrganization({
      ...organization,
      documents: [],
    });
  };

  if (!organization?.id)
    return (
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="material-symbols-outlined md-16">error</span> Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            <span className="fw-bold text-muted">
              Sorry, we couldn't find that {pageName}
            </span>
            <br />
            <small className="">
              It may be private, deleted or you don't have access to it yet.
            </small>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            className="px-3"
            onClick={handleClose}
            variant="outline-primary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Switch Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The <strong>{pageName}</strong> does not belong to current
          organization{" "}
          <strong className="text-warning">{activeOrganization?.name}</strong>,
          switch to{" "}
          <strong className="text-primary">{organization?.name}</strong> to see
          the {pageName}.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="px-3"
          onClick={handleClose}
          variant="outline-primary"
        >
          Cancel
        </Button>
        <Button className="px-3" onClick={() => switchOrganization()} size="sm">
          Switch
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SwitchOrganization;
