const levels = [
  {
    index: 2,
    name: "Organization Level",
    filter: "organization",
  },
  {
    index: 3,
    name: "Entity Level",
    filter: "facility",
  },
  {
    index: 4,
    name: "Question Level",
    filter: "question",
  },
];

export default levels;
