function SlickArrowPrev(props) {
  const { onClick } = props;
  return (
    <div
      className="custom-prev-arrow bg-primary bg-opacity-10 rounded"
      onClick={onClick}
    >
      <span className="material-symbols-outlined md-18 text-muted">
        {" "}
        arrow_back
      </span>
    </div>
  );
}

export default SlickArrowPrev;
