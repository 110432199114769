const sortBy = [
  {
    index: 0,
    name: "A - Z",
    value: "name",
  },
  {
    index: 1,
    name: "Z - A",
    value: "-name",
  },
];

export default sortBy;
