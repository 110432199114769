import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import GroupUpdate from "../GroupUpdate";
import GroupDelete from "../GroupDelete";

const GroupListItem = ({ isLoading, error, groups, setGroups }) => {
  if (isLoading)
    return (
      <tr>
        <td colSpan={4}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr className="border-none">
        <td colSpan={4}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (!groups) return <></>;

  if (!groups.length)
    return (
      <tr>
        <td colSpan={4}>
          <EmptyStateHandler
            title="No assessment groups"
            description="Assessment groups will appear here one added"
          />
        </td>
      </tr>
    );

  return groups.map((group) => {
    return (
      <tr key={group.id}>
        <td>{group.title}</td>
        <td className="text-center">{group?.auditsCount || 0}</td>
        <td>
          <small>
            {new Date(group.updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        <td>
          <GroupUpdate
            onGroupUpdated={(updatedGroup) => {
              setGroups((prevStatus) =>
                prevStatus.map((item) => {
                  if (item.id === updatedGroup.id)
                    return {
                      auditsCount: item.auditsCount,
                      ...updatedGroup,
                    };
                  return item;
                })
              );
            }}
            group={group}
          />{" "}
          <GroupDelete
            onGroupDeleted={(group) =>
              setGroups((prevStatus) =>
                prevStatus.filter(({ id }) => id !== group.id)
              )
            }
            group={group}
          />
        </td>
      </tr>
    );
  });
};

export default GroupListItem;
