import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { saveAs } from "file-saver";
import VERTICALS from "config/VERTICALS";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useDownloadListResponse = ({ audit }) => {
  const { name, auditId } = audit;
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const organization = useContext(OrganizationContext);

  const VERTICAL_ID = process.env.REACT_APP_VERTICAL_ID;
  const generatedFileNameShouldOnlyHaveAssessmentName =
    VERTICALS[VERTICAL_ID]?.generatedFileNameShouldOnlyHaveAssessmentName ??
    false;

  const downloadListResponse = () => {
    setError(null);
    setIsLoading(true);
    const downloadListResponsePromise = get(`/audits/${auditId}/csv`);

    downloadListResponsePromise.promise
      .then((resp) => {
        setIsLoading(false);
        const fileName = generatedFileNameShouldOnlyHaveAssessmentName
          ? `${name}.csv`
          : `${organization.name} ${name} Assessment List Response.csv`;
        let type = "data:text/csv;charset=UTF-8,%EF%BB%BF;";
        let CSVBlob = new Blob([resp], { type: type });

        saveAs(CSVBlob, fileName);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(downloadListResponsePromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    isLoading,
    error,
    downloadListResponse,
  };
};

export default useDownloadListResponse;
