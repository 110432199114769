import React, { useState } from "react";

import { useRoles } from "hooks";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Col, Dropdown, Row, Table } from "react-bootstrap";

import DataExport from "components/DataExport";
import SortColumn from "components/ui/SortColumn";
import BreadCrumbs from "components/ui/BreadCrumbs";
import MetaPagination from "components/ui/MetaPagination";
import { useFetchOrganizationAudits } from "features/assessment/services";
import { organizationBreadcrumb } from "features/assessment/utils/breadcrumpsUtils";
import { OrganizationAuditTableFilter } from "features/assessment/constants/auditTablesFilters";

import AuditSearchLink from "./AuditSearchLink";
import OrganizationAuditCreate from "./OrganizationAuditCreate";
import AuditListExport from "../common/AuditList/AuditListExport";
import OrganizationAuditListItem from "./OrganizationAuditListItem";
import OrganizationAuditListFilters from "./OrganizationAuditListFilters";

import "./OrganizationAuditList.scss";

const OrganizationAuditList = () => {
  const navigate = useNavigate();
  const { isContributor } = useRoles();
  const [searchParam] = useSearchParams();

  const facilityId = searchParam.get("facilityId") || "";

  const [tableSortState, setTableSortState] = useState({
    sortAsc: false,
    updatedAt: true,
    sortColumn: "updated_at",
  });

  const [filters, setFilters] = useState({
    ...OrganizationAuditTableFilter,
    ...(facilityId
      ? {
          entity: { facilityId: facilityId, name: facilityId },
        }
      : {}),
  });

  const { meta, error, assessments, isLoading } = useFetchOrganizationAudits({
    page: filters?.page,
    perPage: filters?.perPage,
    search: filters?.search,
    sortState: tableSortState,
    dueDate: filters?.dueDate,
    updatedAt: filters?.updatedAt,
    locked: filters?.lockStatus?.locked,
    status: filters?.lockStatus?.status,
    facilityId: filters?.entity?.facilityId,
    protocolId: filters?.protocols?.protocolId,
  });

  const sortAssessments = (sortingvalue) => {
    if (isLoading) return false;
    setTableSortState({
      sortAsc: !tableSortState.sortAsc,
      sortColumn: sortingvalue,
    });
  };

  return (
    <>
      <Helmet>
        <title>Assessments</title>
      </Helmet>
      <BreadCrumbs breadcrumbs={organizationBreadcrumb()} />
      <div className="d-flex flex-row mb-3 align-items-center">
        <div className="flex-fill">
          <h1>Assessments</h1>
        </div>
        <div className="d-grid me-2">
          <Button
            size="sm"
            as={Link}
            to="/reports"
            variant="outline-primary"
            className="px-4 py-1.5"
          >
            Reports{" "}
            <span translate="no" className="material-symbols-outlined md-18">
              arrow_forward
            </span>
          </Button>
        </div>
        <AuditSearchLink />
        {!isContributor && (
          <div>
            <Dropdown translate="no" autoClose="outside" className="me-2">
              <Dropdown.Toggle
                size="sm"
                variant="outline-primary"
                className="w-100 text-start"
              >
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 me-2"
                >
                  download
                </span>{" "}
                Export
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <DataExport variant="" />
                <Dropdown.Divider className="my-2" />
                <AuditListExport filters={filters} />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <OrganizationAuditCreate
          onAssessmentCreated={(assessment) => {
            navigate(`/audits/${assessment?.auditId}`);
          }}
        />
      </div>
      <div>
        <OrganizationAuditListFilters
          filters={filters}
          setFilters={setFilters}
          tableSortState={tableSortState}
          sortAssessments={sortAssessments}
        />
      </div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
          <Card>
            <Card.Body className="table-responsive">
              <Table hover>
                <thead>
                  <tr>
                    <th
                      className={`w-30 pointer`}
                      onClick={() => sortAssessments("name")}
                    >
                      Name
                      <SortColumn
                        column={"name"}
                        tableSortState={tableSortState}
                      />
                    </th>
                    <th>Entity</th>
                    <th>Protocol</th>
                    <th
                      className={`pointer`}
                      onClick={() => sortAssessments("updated_at")}
                    >
                      Updated
                      <SortColumn
                        column={"updated_at"}
                        tableSortState={tableSortState}
                      />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  <OrganizationAuditListItem
                    error={error}
                    isLoading={isLoading}
                    assessments={assessments}
                  />
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <MetaPagination
                meta={meta}
                perPage={filters?.perPage}
                setPage={(page) => setFilters({ ...filters, page })}
                setPerPage={(perPage) => setFilters({ ...filters, perPage })}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

OrganizationAuditList.propTypes = {};

export default OrganizationAuditList;
