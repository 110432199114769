import * as Sentry from "@sentry/react";

const sandbox = ["sandbox.ermassess.com"];
const develop = ["dev.ermassess.com"];
const staging = ["staging.ermassess.com"];

const production = [
  "ermassess.com",
  "www.ermassess.com",
  "eia.ermassess.com",
  "demo.ermassess.com",
  "csrd.ermassess.com",
  "ermcvs.ermassess.com",
  "stormwater.ermassess.com",
  "health-and-safety.ermassess.com",
];

const getEnv = () => {
  const { hostname } = window.location;
  if (sandbox.includes(hostname)) return "sandbox";
  if (develop.includes(hostname)) return "develop";
  if (staging.includes(hostname)) return "staging";
  if (production.includes(hostname)) return "production";

  return "";
};

const initSentryConfig = {
  environment: getEnv(),
  release: process.env.REACT_APP_RELEASE_VERSION,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/ermassess\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

export { initSentryConfig };
