import { useState } from "react";

import PropTypes from "prop-types";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

import { useFetchProtocols } from "features/organization";
import ItemWithTooltip from "components/ui/ItemWithTooltip";

const ProtocolTypeahead = ({
  onChange,
  isValid,
  isInvalid,
  selected,
  placeholder,
}) => {
  const [protocol, setProtocol] = useState(
    Array.isArray(selected) ? selected : []
  );

  const { isLoading, protocols } = useFetchProtocols({});

  return (
    <Typeahead
      id="protocol-typeahead"
      clearButton
      placeholder={placeholder || "Filter by protocol"}
      isLoading={isLoading}
      labelKey="name"
      isInvalid={Boolean(isInvalid)}
      onChange={(data) => {
        onChange(data);
        setProtocol(data);
      }}
      isValid={Boolean(isValid)}
      options={protocols}
      selected={protocol}
      renderMenu={(results, menuProps) => (
        <Menu className="" {...menuProps}>
          {results.map((result, index) => (
            <MenuItem key={result.protocolId} option={result} position={index}>
              <ItemWithTooltip displayText={result.name} />
            </MenuItem>
          ))}
        </Menu>
      )}
    />
  );
};

ProtocolTypeahead.propTypes = {
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.any,
  selected: PropTypes.array,
};

export default ProtocolTypeahead;
