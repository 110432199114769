import React from "react";
import "./BreadCrumbs.scss";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ className = "", breadcrumbs }) => {
  if (!breadcrumbs || !breadcrumbs.length) return <span />;

  return (
    <div className={`${className || "my-3"}`}>
      <Breadcrumb>
        {breadcrumbs.map((crumb, index) => {
          if (crumb.link)
            return (
              <Breadcrumb.Item
                key={index}
                linkAs={Link}
                linkProps={{ to: crumb.link }}
                active={!!crumb.active}
              >
                {crumb.name}
              </Breadcrumb.Item>
            );

          return (
            <Breadcrumb.Item key={index} linkAs={"span"}>
              {crumb.name}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbs;
