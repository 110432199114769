import { useContext, useEffect, useRef, useState } from "react";

import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useFetchOrganizationAnswerTypeAndDynamicFields = ({
  protocolId,
  isBestPracticeAndFindings,
  onSuccess,
  onError,
}) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [answerTypeStats, setAnswerTypeStats] = useState();
  const [dynamicFieldStats, setDynamicFieldStats] = useState();

  const onErrorRef = useRef(onError);
  const onSuccessRef = useRef(onSuccess);
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    function fetchBestPracticesAndFindings() {
      setIsLoading(true);
      setError(null);
      setAnswerTypeStats();
      const answerTypeStatsPromise = get(
        `/organizations/${organization?.id}/protocols/${protocolId}/answer-type-stats`
      );

      answerTypeStatsPromise.promise
        .then(({ data }) => {
          setAnswerTypeStats(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
          if (typeof onErrorRef.current === "function")
            onErrorRef.current(error);
        });
      subscribedPromises.current.push(answerTypeStatsPromise);
    }
    const fetchFacilityAnswerTypeAndDynamicFields = () => {
      setError(null);
      setIsLoading(true);

      const dynamicFieldsStatsPromise = get(
        `search/organizations/${organization?.id}/protocols/${protocolId}/stats`
      );
      subscribedPromises.current.push(dynamicFieldsStatsPromise);

      dynamicFieldsStatsPromise.promise
        .then(({ data }) => {
          setDynamicFieldStats(data);

          if (typeof onSuccessRef.current === "function")
            onSuccessRef.current(data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            if (typeof onErrorRef.current === "function")
              onErrorRef.current(error);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (protocolId && isBestPracticeAndFindings) {
      fetchBestPracticesAndFindings();
    }
    if (protocolId && organization?.id && !isBestPracticeAndFindings)
      fetchFacilityAnswerTypeAndDynamicFields();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [protocolId, organization?.id, isBestPracticeAndFindings]);
  return { isLoading, error, answerTypeStats, dynamicFieldStats };
};

export default useFetchOrganizationAnswerTypeAndDynamicFields;
