import React from "react";
import { getURLFileExtension } from "utils/StringUtils";

const useRenderedIcon = () => {
  function getPreRenderedIcon(fileUrl) {
    const extension = getURLFileExtension(fileUrl || "");
    const origin = window.location.origin.includes("localhost")
      ? "https://sandbox.ermassess.com"
      : window.location.origin;

    const extensionMap = {
      pdf: "pdf",
      docx: "word",
      doc: "word",
      csv: "excel",
      ppt: "powerpoint",
      xlsx: "excel",
      pptx: "powerpoint",
      bin: "bin",
    };

    if (!extensionMap.hasOwnProperty(extension))
      return `${origin}/placeholder.png`;

    return `${origin}/${extensionMap[extension]}.png`;
  }

  return {
    getPreRenderedIcon,
  };
};

export default useRenderedIcon;
