import { capitalize } from "lodash";
import moment from "moment";

const getActionItemsTrends = (actionItemStats, filterPeriod) => {
  if (!actionItemStats.length) return {};
  const trends = actionItemStats.reduce(
    (acc, { actionItems }) => {
      const obj = { ...acc };
      for (let key in actionItems) {
        obj[capitalize(key)] = Array(filterPeriod?.timeline?.length ?? 0).fill(
          0
        );
      }
      return obj;
    },
    {
      timeline: filterPeriod?.timeline ?? [],
    }
  );

  trends.timeline.forEach((month, index) => {
    const aggregationMonth =
      Array.isArray(actionItemStats) &&
      actionItemStats.find(({ period }) => {
        return moment(period, filterPeriod?.remoteDateFormat).isSame(
          moment(month),
          filterPeriod?.aggregationPeriod
        );
      });
    if (aggregationMonth) {
      const { actionItems } = aggregationMonth;
      Object.keys(actionItems)
        .map((key) => capitalize(key))
        .forEach((key) => {
          if (trends[key]) trends[key][index] = actionItems[key.toLowerCase()];
        });
    }
  });

  return {
    ...trends,
    timeline: trends.timeline.map((date) =>
      moment(date, "YYYY-MM-DD").format(filterPeriod?.localDateFormat)
    ),
  };
};

export default getActionItemsTrends;
