import React, { useState } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import { COLS } from "utils/EntityTagUtils";
import ColorPicker from "components/ui/ColorPicker";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useCreateAuditTag } from "features/account/services";
import { tagValidationSchema } from "features/account/schemas";

const TagCreate = ({ onTagCreated }) => {
  const [show, setShow] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const handleClose = () => {
    setShow(false);
    setColorPickerOpen(false);
  };
  const handleShow = () => setShow(true);

  const { error, isLoading, createAuditTag } = useCreateAuditTag({
    onTagCreated: (data) => {
      onTagCreated(data);
      handleClose();
    },
  });

  const handleToggleColorPicker = (e) => {
    e.preventDefault();
    setColorPickerOpen(!colorPickerOpen);
  };

  return (
    <>
      <Button size="sm" className="px-3" variant="primary" onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        Add Tag
      </Button>

      <Offcanvas
        show={show}
        placement="end"
        onHide={handleClose}
        className="w-fixed-640"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Tag</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          <Formik
            validationSchema={tagValidationSchema}
            onSubmit={(values) => createAuditTag(values)}
            initialValues={{
              name: "",
              color: "#000000",
              description: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="d-flex flex-column justify-content-between h-100">
                <div onClick={colorPickerOpen ? handleToggleColorPicker : null}>
                  <Form.Group controlId="name" className="mb-3">
                    <Form.Label className="mb-1">
                      Name <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isValid={values.name && !errors.name}
                      isInvalid={!(values.name && !errors.name) && touched.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name && touched.name ? (
                        <small>{errors.name}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="color" className="mb-3">
                    <Form.Label className="mb-1">
                      Tag color <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="color"
                      name="color"
                      style={{ width: COLS * 40 }}
                      onBlur={handleBlur}
                      value={values.color}
                      onChange={handleChange}
                      onClick={handleToggleColorPicker}
                      isValid={values.color && !errors.color}
                      isInvalid={
                        !(values.color && !errors.color) && touched.color
                      }
                    />
                    <ColorPicker
                      name="color"
                      value={values.color}
                      handleChange={handleChange}
                      visible={colorPickerOpen}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.color && touched.color ? (
                        <small>{errors.color}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mb-1">Description</Form.Label>
                    <Form.Control
                      as={"textarea"}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      placeholder="Enter your description here"
                      isValid={values.description && !errors.description}
                      isInvalid={!!errors.description && touched.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description && touched.description ? (
                        <small>{errors.description}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {error && <ErrorHandler error={error} />}
                </div>

                <div className="text-end mt-auto">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    className="float-end px-3 ms-3"
                    onClick={handleSubmit}
                    disabled={
                      Object.keys(errors).length > 0 || isLoading || !isValid
                    }
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Add Tag
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

TagCreate.propTypes = {
  onTagCreated: PropTypes.func.isRequired,
};

export default TagCreate;
