import { useNavigate, Link } from "react-router-dom";
import FacilityUpdate from "../../common/FacilityUpdate";
import FacilityDelete from "../../common/FacilityDelete";

const FacilityLeftSidebar = ({ facility, setFacility }) => {
  const navigate = useNavigate();

  return (
    <>
      <h1>{facility?.name}</h1>
      <p>
        <span translate="no" className="material-symbols-outlined md-18 me-1">
          place
        </span>
        <span>{facility?.address}</span>
      </p>

      {facility?.facilitySuppliesTo?.name ? (
        <>
          <span className="d-flex align-items-center">
            <h6 className="me-1 text-muted">Supplies To</h6>
            <span
              translate="no"
              className="material-symbols-outlined text-muted mb-1"
            >
              swipe_right_alt
            </span>
          </span>
          <h3
            className={`text-primary${
              facility?.facilitySuppliesFrom?.name ? ` pb-3` : ``
            }`}
          >
            {facility?.facilitySuppliesTo?.name}
          </h3>
        </>
      ) : null}
      {facility?.facilitySuppliesFrom?.name ? (
        <>
          <span className="d-flex align-items-center">
            <h6 className="me-1 text-muted">Supplied From</h6>
            <span
              translate="no"
              className="material-symbols-outlined mb-1 text-danger"
            >
              swipe_left_alt
            </span>
          </span>
          <h3 className="text-primary">
            {facility?.facilitySuppliesFrom?.name}
          </h3>
        </>
      ) : null}
      {facility?.facilitySuppliesTo?.name ||
      facility?.facilitySuppliesFrom?.name ? (
        <hr />
      ) : null}
      <p>
        <small>
          Last updated at{" "}
          {new Date(facility?.updatedAt).toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          })}
        </small>
      </p>
      <h5 className="mb-3">
        <Link
          data-cy="files-photos"
          className="text-decoration-none"
          to={`files`}
        >
          Files & Photos
          <span
            translate="no"
            className="float-end material-symbols-outlined md-20"
          >
            drive_folder_upload
          </span>
        </Link>
      </h5>
      <hr />

      <div className="mt-3">
        <FacilityUpdate
          facility={facility || {}}
          onFacilityUpdated={(f) =>
            setFacility((prevState) => {
              return {
                ...f,
                auditCount: prevState.auditCount || 0,
              };
            })
          }
        />
        <FacilityDelete
          facility={facility || {}}
          onFacilityDeleted={() => navigate("/", { replace: true })}
        />
      </div>
    </>
  );
};

export default FacilityLeftSidebar;
