import React, { useContext } from "react";
import { Dropdown, Form } from "react-bootstrap";
import "./ActionItemShowColumn.scss";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context";

const ActionItemShowColumn = () => {
  const { tableData } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);
  return (
    <Dropdown className="ms-2 show-column-dropdown">
      <Dropdown.Toggle size="sm" variant="outline-primary" id="dropdown-basic">
        Show Column
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {tableData.map(({ key, name, hidden }) => (
          <Form.Check
            checked={!hidden}
            key={key}
            className="mx-2 my-1"
            onChange={(e) => {
              const newData = tableData.map((d) => {
                if (d.key === e.target.name)
                  return { ...d, hidden: !e.target.checked };
                return d;
              });
              dispatch({ type: "SET_TABLE_DATA", payload: newData });
              localStorage.setItem("tableData", JSON.stringify(newData));
            }}
            type={"checkbox"}
            name={key}
            label={name}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionItemShowColumn;
