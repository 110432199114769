import * as yup from "yup";
const auditValidationSchema = yup.object({
  name: yup
    .string()
    .min(2, "Assessment name is too Short!")
    .max(100, "Assessment name is too Long!")
    .required("Assessment name is required"),
  description: yup
    .string()
    .min(2, "Description is too Short!")
    .max(255, "Description is too Long!")
    .notRequired(),
  executiveSummary: yup.string().nullable(),
  protocol: yup
    .array()
    .length(1, "Protocol is required")
    .required("Protocol is required"),
  group: yup.array(),
  startedAt: yup.date(),
  completedAt: yup.date(),
  dueDate: yup.date().nullable(),
});
export default auditValidationSchema;
