import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { put } from "utils/DeApi";

const useUpdateSectionsInapplicable = ({ auditId, onSuccess }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateSectionsInapplicable = (responseCollection = []) => {
    setIsLoading(true);
    const sectionPromises = responseCollection.map(
      ({ sectionId, isInapplicable }) => {
        const sectionPromise = put(
          `/audits/${auditId}/sections/${sectionId}/is-inapplicable`,
          {
            isInapplicable: isInapplicable,
          }
        );
        subscribedPromises.current.push(sectionPromise);
        return sectionPromise.promise;
      }
    );

    Promise.all(sectionPromises)
      .then((response) => {
        const observations = response.flatMap(({ data }) => data);
        if (typeof onSuccess === "function") {
          onSuccess(observations);
        }
        toast.success("Success", "Applicability screening has been saved");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          toast.error("Error", "Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, updateSectionsInapplicable };
};

export default useUpdateSectionsInapplicable;
