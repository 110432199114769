import React from "react";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";

const ActionItemActionType = ({
  type,
  subjectType,
  isStatusChanged = false,
  isDueDateChanged = false,
}) => {
  const isCommentChange = subjectType === "comment";
  const isAssigneeChange = subjectType === "action_item_assignee";
  const isFileChange = subjectType === "media";

  const logName = () => {
    if (isCommentChange) return "Comment";
    if (isAssigneeChange) return "Assignee";
    if (isFileChange) return "File";
    if (isDueDateChanged && isStatusChanged) return "Due Date & Status";
    if (isDueDateChanged) return "Due Date";
    if (isStatusChanged) return "Status";
    return "Status"; // Default
  };

  if (type === "created") {
    return (
      <Badge className="bg-primary bg-opacity-10 text-primary">
        {logName()} - {isAssigneeChange || isFileChange ? "Added" : "Created"}
      </Badge>
    );
  }
  if (type === "updated") {
    return (
      <Badge className="bg-warning bg-opacity-10 text-warning">
        {logName()} - Updated
      </Badge>
    );
  }
  if (type === "deleted") {
    return (
      <Badge className="bg-danger bg-opacity-10 text-danger">
        {logName()} - {isAssigneeChange || isFileChange ? "Removed" : "Deleted"}
      </Badge>
    );
  }
  return "-";
};

ActionItemActionType.propTypes = {
  type: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
  isStatusChanged: PropTypes.bool.isRequired,
  isDueDateChanged: PropTypes.bool.isRequired,
};

export default ActionItemActionType;
