import { useState, useContext, useRef, useEffect } from "react";

import { UserContext } from "contexts/UserProvider";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { get } from "utils/DeApi";

import { useRoles } from "hooks";

const useFetchCorrectiveActionItems = ({
  auditId,
  questionId,
  page = 1,
  perPage = 5,
  isEnabled = true,
}) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [actionItems, setActionItems] = useState([]);
  const [meta, setMeta] = useState();

  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const { isCertifier } = useRoles();

  useEffect(() => {
    const fetchActionItems = () => {
      setIsLoading(true);
      setError(null);
      const subscribersPromise = get(
        `subscribers/${user?.de?.subscriberId}/action-items`,
        {
          params: {
            page,
            perPage,
            organizationId: organization?.id,
            "filter[type]": "question",
            "filter[question_id]": questionId ? questionId : undefined,
            "filter[audit_id]": auditId,
            "filter[status]": "0, 1, 2",
          },
        }
      );

      subscribersPromise.promise
        .then(({ data, meta }) => {
          setMeta(meta);
          setActionItems(data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (!isCertifier && isEnabled) fetchActionItems();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [
    organization,
    user,
    isCertifier,
    auditId,
    questionId,
    page,
    perPage,
    isEnabled,
  ]);

  return {
    meta,
    setMeta,
    error,
    isLoading,
    actionItems,
    setActionItems,
  };
};

export default useFetchCorrectiveActionItems;
