import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { upload } from "utils/DeApi";

const useUploadActionItemFiles = ({ actionItemId, onEvidenceFileUploaded }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const uploadFile = (values) => {
    const { file } = values;
    setError(null);
    setIsLoading(true);

    const formData = new FormData();

    formData.append("evidence_file", file);

    const auditPromise = upload(
      `/action-items/${actionItemId}/upload`,
      formData
    );
    auditPromise.promise
      .then((response) => {
        onEvidenceFileUploaded(response.data);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization]);

  return {
    uploadFile,
    isLoading,
    error,
  };
};

export default useUploadActionItemFiles;
