import { useEffect, useRef, useState } from "react";
import { post } from "utils/DeApi";

const useCreateActionItemComment = ({
  actionItemId,
  onActionItemCommentCreated,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const createActionItemComment = (body) => {
    setError("");
    setIsLoading(true);
    const actionItemPromise = post(`/action-items/${actionItemId}/comments`, {
      body,
    });

    actionItemPromise.promise
      .then(({ data: comment }) => {
        onActionItemCommentCreated(comment);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(actionItemPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    createActionItemComment,
  };
};

export default useCreateActionItemComment;
