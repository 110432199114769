import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { UserContext } from "contexts/UserProvider";

const MemberDelete = ({ member, onMemberDeleted }) => {
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [isConfirmationChecked, setIsConfirmationChecked] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteAudit = () => {
    setError(null);
    setIsLoading(true);

    const memberPromise = destroy(
      `/organizations/${organization?.id}/members?member_id=${member.subscriberId}`
    );

    memberPromise.promise
      .then(({ data }) => onMemberDeleted(member))
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(memberPromise);
  };

  if (member.invitation.role === "Owner") return <span />;
  if (member.subscriberId === user?.de?.subscriberId) return <span />;

  return (
    <>
      <Button
        size="sm"
        className="ms-1"
        onClick={handleShow}
        variant="outline-danger"
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        {member && (
          <>
            <Modal.Body className="mt-2">
              <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
                <span className="material-symbols-outlined md-24">warning</span>
              </div>
              <h2 className="text-center pt-2">
                Delete Member -{" "}
                <small translate="no" className="text-muted">
                  {member.firstName} {member.lastName}
                </small>
              </h2>
              <p className="mx-4 mt-2">
                <Form.Group>
                  <Form.Check
                    label={
                      <small>
                        By checking the box, I confirm that I want to delete
                        this Member. I understand that this cannot be undone.
                      </small>
                    }
                    onClick={() => {
                      setIsConfirmationChecked(!isConfirmationChecked);
                    }}
                  />
                </Form.Group>
              </p>
              {error && <ErrorHandler error={error} />}
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="outline-secondary"
                className="me-2"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={deleteAudit}
                size="sm"
                disabled={!isConfirmationChecked || isLoading}
              >
                {isLoading && (
                  <Spinner
                    className="me-2"
                    animation="border"
                    size="sm"
                    variant="light"
                  />
                )}{" "}
                Delete
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

MemberDelete.propTypes = {
  member: PropTypes.object.isRequired,
  onMemberDeleted: PropTypes.func.isRequired,
};

export default MemberDelete;
