import { ORGS, STORMWATER_ID } from "config/ORGS";
import VERTICALS from "config/VERTICALS";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext } from "react";

const VERTICAL_ID = process.env.REACT_APP_VERTICAL_ID;

const useVertical = () => {
  const organization = useContext(OrganizationContext);
  const { id: verticalId = null } = organization?.vertical || {};
  let defaultViewInReport =
    VERTICALS[VERTICAL_ID]?.defaultViewInReport ?? "list";
  let hideAssessmentLevelAttachment =
    VERTICALS[VERTICAL_ID]?.hideAssessmentLevelAttachment ?? false;
  let hideAttachmentDescAndName =
    VERTICALS[VERTICAL_ID]?.hideAttachmentDescAndName ?? false;
  let generatedFileNameShouldOnlyHaveAssessmentName =
    VERTICALS[VERTICAL_ID]?.generatedFileNameShouldOnlyHaveAssessmentName ??
    false;
  let domain = VERTICALS[VERTICAL_ID]?.domain ?? false;
  let gridLayout = VERTICALS[VERTICAL_ID]?.gridLayout ?? 1;

  /**
   * If the organization is stormwater, accessed from ermassss.com
   */

  if (VERTICALS[verticalId]) {
    const STORMWATER_VERTICAL_ID = verticalId;
    defaultViewInReport =
      VERTICALS[STORMWATER_VERTICAL_ID]?.defaultViewInReport ?? "list";
    hideAssessmentLevelAttachment =
      VERTICALS[STORMWATER_VERTICAL_ID]?.hideAssessmentLevelAttachment ?? false;
    hideAttachmentDescAndName =
      VERTICALS[STORMWATER_VERTICAL_ID]?.hideAttachmentDescAndName ?? false;
    generatedFileNameShouldOnlyHaveAssessmentName =
      VERTICALS[STORMWATER_VERTICAL_ID]
        ?.generatedFileNameShouldOnlyHaveAssessmentName ?? false;
    domain = VERTICALS[STORMWATER_VERTICAL_ID]?.domain ?? false;
    gridLayout = VERTICALS[STORMWATER_VERTICAL_ID]?.gridLayout ?? 1;
  }

  return {
    defaultViewInReport,
    hideAssessmentLevelAttachment,
    hideAttachmentDescAndName,
    generatedFileNameShouldOnlyHaveAssessmentName,
    domain,
    gridLayout,
  };
};

export default useVertical;
