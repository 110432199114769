import { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./ActionItemNotificationDropdown.scss";
import EmailNotificationSettings from "features/actionItems/notifications/emails/Settings";
import {
  useFetchActionItemNotification,
  useUpdateActionItemNotification,
} from "features/actionItems/services";
import { ActionItemsDispatchContext } from "features/actionItems/context";

const ActionItemNotificationDropdown = () => {
  const dispatch = useContext(ActionItemsDispatchContext);

  const [isFieldToggled, setIsFieldToggled] = useState(false);
  const [notification, setNotification] = useState({});

  const {
    isLoading,
    error,
    notification: data,
  } = useFetchActionItemNotification();

  const {
    isLoading: updateLoading,
    error: updateError,
    updatedNotification,
    updateActionItemNotification,
  } = useUpdateActionItemNotification({
    notification,
    setIsFieldToggled,
  });

  const onUpdateNotification = (val, notificationType) => {
    setIsFieldToggled(true);
    if (notificationType === "all") {
      setNotification(() => ({
        actionItemCommentAlerts: val,
        actionItemStatusAlerts: val,
        all: val,
        actionItemCreateAlerts: val,
        actionItemOverDueAlerts: val,
      }));
    }

    if (notificationType === "actionItemCommentAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
          all:
            prevNotification.actionItemStatusAlerts &&
            prevNotification.actionItemCreateAlerts &&
            prevNotification.actionItemOverDueAlerts &&
            val,
        };
      });
    }
    if (notificationType === "actionItemCreateAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
          all:
            prevNotification.actionItemStatusAlerts &&
            prevNotification.actionItemCommentAlerts &&
            prevNotification.actionItemOverDueAlerts &&
            val,
        };
      });
    }

    if (notificationType === "actionItemStatusAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
          all:
            prevNotification.actionItemCommentAlerts &&
            prevNotification.actionItemCreateAlerts &&
            prevNotification.actionItemOverDueAlerts &&
            val,
        };
      });
    }

    if (notificationType === "actionItemOverDueAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
          all:
            prevNotification.actionItemCommentAlerts &&
            prevNotification.actionItemCreateAlerts &&
            prevNotification.actionItemStatusAlerts &&
            val,
        };
      });
    }

    if (notificationType === "actionItemEmailsAlert") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
        };
      });
    }

    if (notificationType === "actionItemDigestAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
        };
      });
    }
  };

  useEffect(() => {
    dispatch({ type: "SET_ERROR", payload: error });
    dispatch({ type: "SET_ERROR", payload: updateError });
  }, [error, dispatch, updateError]);

  useEffect(() => {
    if (!!Object.keys(data)?.length) setNotification(data);
    if (!!Object.keys(updatedNotification)?.length)
      setNotification(updatedNotification);
  }, [data, updatedNotification]);

  useEffect(() => {
    if (isFieldToggled) updateActionItemNotification();
  }, [isFieldToggled, updateActionItemNotification]);

  return (
    <>
      <Dropdown autoClose="outside" className="mx-2">
        <Dropdown.Toggle
          variant="outline-secondary"
          id="protocol-filter"
          className="w-100 text-start"
          size="sm"
        >
          <span translate="no" className="material-symbols-outlined md-18 me-2">
            notifications
          </span>
          Notification Settings
        </Dropdown.Toggle>
        <Dropdown.Menu className="notification-menu p-2">
          <EmailNotificationSettings
            settings={notification}
            handleSettingsChange={onUpdateNotification}
            isLoading={isLoading || updateLoading}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ActionItemNotificationDropdown;
