import React from "react";

const Copyright = () => (
  <>
    {!!process.env.REACT_APP_VERTICAL && (
      <>
        Powered by ERM Assess{" "}
        <span className="px-2">
          <span className="vr align-middle"></span>
        </span>
      </>
    )}{" "}
    Copyright © {new Date().getFullYear()} ERM{" "}
  </>
);

export default Copyright;
