import { useContext } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useRoles = () => {
  const organization = useContext(OrganizationContext);
  const roleName = organization?.invitation?.role ?? "";

  return {
    isOwner: roleName === "Owner",
    isAdmin: roleName === "Admin" || roleName === "Owner",
    isMember: roleName === "Member",
    isCertifier: roleName === "Certifier",
    isContributor: roleName === "Contributor",
    roleName: roleName,
  };
};

export default useRoles;
