import { OrganizationsContext } from "contexts/OrganizationProvider";
import { UserContext } from "contexts/UserProvider";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context";
import { isFilterActive } from "features/actionItems/utils";
import { truncate } from "lodash";

import React, { useContext } from "react";
import { Col, Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const OrganizationFilter = () => {
  const { filters: { organization } = {} } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);
  const organizations = useContext(OrganizationsContext);
  const { userId } = useContext(UserContext);
  const location = useLocation();

  const href = `/profile/${userId}/action-items`;

  if (href !== location.pathname) return <></>;

  return (
    <Col lg={2} className="mb-3">
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-done"
          className={`w-100 text-truncate border border-gray-900 ${isFilterActive(
            organization?.name
          )}`}
        >
          {truncate(organization?.name || "All Organization", {
            length: 10,
          })}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            active={!organization}
            onClick={() => {
              dispatch({ type: "SET_FILTERS", payload: { organization: "" } });
            }}
          >
            All
          </Dropdown.Item>
          {Array.isArray(organizations) &&
            organizations.map((org) => (
              <Dropdown.Item
                key={org?.id}
                active={organization?.id === org?.id}
                onClick={() => {
                  dispatch({
                    type: "SET_FILTERS",
                    payload: { organization: org },
                  });
                }}
              >
                {org.name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  );
};

export default OrganizationFilter;
