import React, { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { ActionItemUpdate } from "features/actionItems/components/common";

const ActionItemCollapseComments = ({
  actionItem,
  actionItemComments,
  onActionItemUpdated,
}) => {
  return (
    <>
      <div className="d-flex ">
        <ActionItemUpdate
          step={2}
          collapseView={true}
          actionItem={actionItem}
          onActionItemUpdated={onActionItemUpdated}
          count={actionItemComments?.length || 0}
        />
      </div>
      {!actionItemComments?.length ? (
        <EmptyStateHandler
          title="No Comments"
          description="Comments will appear here once added"
        />
      ) : (
        <Card className="p-3">
          {actionItemComments.map((comment, index, arr) => {
            const { id, subscriber, body, createdAt, updatedAt } = comment;
            return (
              <Fragment key={id}>
                <Row>
                  <Col xs={1} className="me-2">
                    <div className="avatar d-flex align-items-center justify-content-center rounded-circle bg-primary bg-opacity-10 text-primary text-uppercase">
                      <strong>
                        {subscriber?.firstName?.[0]}
                        {subscriber?.lastName?.[0]}
                      </strong>
                    </div>
                  </Col>
                  <Col xs={10}>
                    <p className="text-break text-wrap mb-0 mt-1">{body}</p>
                    <p className="text-muted">
                      <small className="me-1">
                        {new Date(updatedAt).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </small>
                      {createdAt !== updatedAt && <span> · edited</span>}
                    </p>
                  </Col>
                </Row>
                {arr.length > ++index && <hr className="mt-3" />}
              </Fragment>
            );
          })}
        </Card>
      )}
    </>
  );
};

ActionItemCollapseComments.propTypes = {
  actionItem: PropTypes.object.isRequired,
  actionItemComments: PropTypes.array,
  onActionItemUpdated: PropTypes.func,
};

export default ActionItemCollapseComments;
