import { OrganizationContext } from "contexts/OrganizationProvider";
import { useToast } from "hooks";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { formatDateRange } from "utils/StringUtils";

const useCreateActionItemDataLink = ({ filters }) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = (link) => {
    copyTextToClipboard(link)
      .then(() =>
        toast.success("Success", "Your link has been copied to the clipboard")
      )
      .catch((err) => setError(err));
  };

  const params = useCallback(() => {
    const { createdAt, updatedDate } = filters || {};
    return {
      ...(filters?.organization?.organizationId !== "all"
        ? {
            "filter[organization_id]": filters?.organization?.organizationId,
          }
        : {}),
      ...(filters?.level?.filter !== "all"
        ? { "filter[type]": filters?.level?.filter }
        : {}),

      ...(filters?.assignedBy?.subscriberId
        ? {
            "filter[assigned_by]": filters?.assignedBy?.subscriberId,
          }
        : {}),
      ...(filters?.assignedTo?.subscriberId
        ? {
            "filter[assigned_to]": filters?.assignedTo?.subscriberId,
          }
        : {}),
      ...(!!filters?.updatedDate?.length
        ? {
            "filter[updated_at]": formatDateRange(updatedDate),
          }
        : {}),
      ...(!!filters?.createdAt?.length
        ? {
            "filter[created_at]": formatDateRange(createdAt),
          }
        : {}),
      ...(!!filters?.entity?.facilityId && !filters?.audit?.auditId
        ? {
            "filter[facility_id]": filters?.entity?.facilityId,
          }
        : {}),
      ...(!!filters?.questionId && !!filters?.auditIdFromParam
        ? {
            "filter[question_id]": filters?.questionId,
          }
        : {}),
      ...(!!filters?.audit?.auditId
        ? {
            "filter[audit_id]": filters?.audit?.auditId,
          }
        : {}),
      ...(!!filters?.search
        ? {
            "filter[search]": filters?.search,
          }
        : {}),
      ...(!!filters?.id
        ? {
            "filter[id]": filters?.id,
          }
        : {}),
      ...(filters?.type?.filter !== "all"
        ? { "filter[action_item_type]": filters?.type?.filter }
        : {}),
    };
  }, [filters]);

  const createActionItemDataLink = () => {
    setError(null);
    setIsLoading(true);

    const actionItemGetLinkPromise = get(
      `organizations/${organization?.id}/action-items-data-link`,
      {
        params: {
          ...params(),
        },
      }
    );

    actionItemGetLinkPromise.promise
      .then(({ data }) => handleCopyClick(data))
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(actionItemGetLinkPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    isLoading,
    error,
    createActionItemDataLink,
  };
};

export default useCreateActionItemDataLink;
