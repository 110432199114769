import React from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";

import { getURLFileExtension } from "utils/StringUtils";
import { useDownloadFileFromURL } from "features/entity/services";
import { useFetchMediaById } from "features/files";

const FacilityFileDownload = ({ file }) => {
  const { isCertifier } = useRoles();

  const { id, name } = file;
  const fileURL = file.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  const { isLoading, downloadFileFromURL } = useDownloadFileFromURL({
    fileURL: fileURL,
    fileName: `${name || id}.${fileEXT}`,
  });

  const { isLoading: isExpanding, fetchMedia } = useFetchMediaById({
    mediaId: file?.id,
    fetchByDefault: false,
    onSuccess: (file) => {
      const { id, name } = file;
      const fileURL = file.url || "";
      const fileEXT = getURLFileExtension(fileURL);

      downloadFileFromURL(fileURL, `${name || id}.${fileEXT}`);
    },
  });

  if (isCertifier) return <></>;

  return (
    <Button
      size="sm"
      title="download"
      disabled={isLoading || isExpanding}
      variant="outline-secondary"
      onClick={fetchMedia}
    >
      {isLoading || isExpanding ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <span translate="no" className="material-symbols-outlined md-16">
          download
        </span>
      )}
    </Button>
  );
};

FacilityFileDownload.propTypes = {
  file: PropTypes.object.isRequired,
};

export default FacilityFileDownload;
