import { setAccentColor } from "utils/EntityTagUtils";

const AuditLabels = ({ labels }) => {
  if (!Array.isArray(labels)) return <></>;
  return labels.map(({ auditLabelId, name, color }) => (
    <small
      key={auditLabelId}
      className="me-2 rounded-2 badge fw-normal"
      style={{
        borderWidth: 1,
        borderColor: color,
        backgroundColor: `${color}`,
        borderStyle: "solid",
        color: setAccentColor(color),
      }}
    >
      {name}
    </small>
  ));
};

export default AuditLabels;
