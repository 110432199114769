import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import * as serviceWorkerRegistration from "../../../../serviceWorkerRegistration";

export default function NewVersionUpdate() {
  const [show, setShow] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState();

  useEffect(() => {
    function onServiceWorkerUpdate(registration) {
      setWaitingWorker(registration && registration.waiting);
      setShow(true);
    }

    serviceWorkerRegistration.register({
      onUpdate: onServiceWorkerUpdate,
    });
  }, []);

  function handleUpdate() {
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setShow((prevState) => {
      window.location.reload();
      return !prevState;
    });
  }

  return (
    <Offcanvas
      show={show}
      backdrop="static"
      placement="bottom"
      className="h-fixed-300"
    >
      <Offcanvas.Body className="my-5 d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h3>Updates Available</h3>
          <p className="mb-3">A new version of ERMAssess is available.</p>
          <Button
            variant="primary"
            className="px-5"
            size="sm"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
