import React from "react";
import { Button, Dropdown, NavDropdown } from "react-bootstrap";

const DataExportButton = ({ variant, level, handleShow }) => {
  return variant ? (
    <Button className="me-2" variant={variant} size="sm" onClick={handleShow}>
      <span translate="no" className="material-symbols-outlined md-18">
        download
      </span>{" "}
      Data Export
    </Button>
  ) : level === "entity" ? (
    <Dropdown.Item onClick={handleShow}>
      Data Export{" "}
      <span translate="no" className="material-symbols-outlined md-18 me-2">
        download
      </span>
    </Dropdown.Item>
  ) : (
    <NavDropdown.Item className="postion-relative" onClick={handleShow}>
      Data Export{" "}
      <span className="badge rounded-pill bg-danger mt-0 p-1 position-absolute ms-1">
        <span className="visually-hidden">unread messages</span>
      </span>
    </NavDropdown.Item>
  );
};

export default DataExportButton;
