import React from "react";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";

const ActionItemStatus = ({ status = "Assigned" }) => {
  if (status === "Assigned")
    return <Badge className="bg-info bg-opacity-10 text-info">Assigned</Badge>;
  if (status === "Submitted")
    return (
      <Badge className="bg-primary bg-opacity-10 text-primary">Submitted</Badge>
    );
  if (status === "Incomplete")
    return (
      <Badge className="bg-warning bg-opacity-10 text-warning">
        Incomplete
      </Badge>
    );
  if (status === "Accepted")
    return (
      <Badge className="bg-white border border-dark text-dark">Accepted</Badge>
    );

  return <></>;
};

ActionItemStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ActionItemStatus;
