import { compressImage } from "utils/UploadUtils";
const uploadFile = async (files) => {
  for (let i = 0; i < files.length; i++) {
    if (files[i].type.startsWith("image")) {
      files[i] = await compressImage(files[i]);
    }
  }

  return [...files];
};
export { uploadFile };
