import { Col, Row, Table } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { useFetchFacilityStats } from "features/entity/services";

import "./FacilityStats.scss";

function FacilityStats({ facilityId }) {
  const { error, isLoading, stats } = useFetchFacilityStats({
    facilityId,
  });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <Table borderless className="mb-3">
      <thead>
        <tr>
          <th className="border-end fw-normal">Assessments</th>
          <th className="border-end px-2 fw-normal">Observations</th>
          <th className="px-2 fw-normal">Action Items</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border-end w-25">
            <Row>
              <Col md={4} className="">
                <h3>{stats?.audits ?? 0}</h3>
                <div>
                  <p>Total</p>
                </div>
              </Col>
              <Col md={4} className="">
                <h3>{stats?.completedAudits ?? 0}</h3>
                <div>
                  <p>Done</p>
                </div>
              </Col>
              <Col md={4} className="">
                <h3>{stats?.lockedAudits ?? 0}</h3>
                <div>
                  <p>Locked</p>
                </div>
              </Col>
            </Row>
          </td>
          <td className="border-end w-10">
            <Row>
              <Col md={12} className="">
                <h3>{stats?.observations ?? 0}</h3>
                <div>
                  <p>Total</p>
                </div>
              </Col>
            </Row>
          </td>
          <td className="w-35">
            <Row>
              <Col md={4} className="">
                <h3>{stats?.actionItemsByStatus?.complete ?? 0}</h3>
                <div>
                  <p>Submitted</p>
                </div>
              </Col>
              <Col md={4} className="">
                <h3>{stats?.actionItemsByStatus?.accepted ?? 0}</h3>
                <div>
                  <p>Accepted</p>
                </div>
              </Col>
              <Col md={4} className="">
                <h3>{stats?.actionItemsByStatus?.overdue ?? 0}</h3>
                <div>
                  <p>Overdue</p>
                </div>
              </Col>
            </Row>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default FacilityStats;
