import { useContext, useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { download } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { formatDate } from "features/actionItems/utils";

const useDownloadAuditListCSV = ({
  locked,
  status,
  search,
  dueDate,
  updatedAt,
  facilityId,
  protocolId,
}) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const organization = useContext(OrganizationContext);

  const downloadAuditListCSV = () => {
    setError(null);
    setIsLoading(true);

    const auditListExportCSVPromise = download("audits-list-export", {
      params: {
        organizationId: organization?.id,
        ...(facilityId
          ? {
              "filter[facility_id]": facilityId,
            }
          : {}),
        ...(!!updatedAt
          ? {
              "filter[updated_at]": formatDate(updatedAt),
            }
          : {}),
        ...(!!dueDate
          ? {
              "filter[due_date]": formatDate(dueDate),
            }
          : {}),
        ...(protocolId
          ? {
              "filter[protocol_id]": protocolId,
            }
          : {}),
        ...(locked
          ? {
              "filter[locked]": locked,
            }
          : {}),
        ...(status
          ? {
              "filter[status]": status,
            }
          : {}),
        ...(search
          ? {
              "filter[search]": search,
            }
          : {}),
      },
    });

    let fileName = `${organization?.name} - Assessments.csv`;

    auditListExportCSVPromise.promise
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Success", "The file has been downloaded successfully");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          toast.danger(
            "Error",
            "Something went wrong while downloading the file"
          );
        }
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditListExportCSVPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, downloadAuditListCSV };
};

export default useDownloadAuditListCSV;
