import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useFetchOrganizationProtocolStats = ({ period, option, protocolId }) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [error, setError] = useState();
  const [organizationStats, setOrganizationStats] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrganizationProtocolStats = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const statsPromise = get(
      `organizations/${organization?.id}/protocols/${protocolId}/stats`,
      {
        params: {
          option: option,
          period: period,
        },
      }
    );
    statsPromise.promise
      .then(({ data }) => {
        setOrganizationStats(data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));
    subscribedPromises.current.push(statsPromise);
  }, [period, option, organization?.id, protocolId]);

  useEffect(() => {
    fetchOrganizationProtocolStats();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchOrganizationProtocolStats]);
  return {
    error,
    isLoading,
    organizationStats,
    fetchOrganizationProtocolStats,
  };
};

export default useFetchOrganizationProtocolStats;
