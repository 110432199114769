import React from "react";
import DOMPurify from "dompurify";
import { useParams, useSearchParams } from "react-router-dom";

import { Card, Button, Spinner } from "react-bootstrap";
import { useDownloadFileFromURL } from "features/entity/services";
import { mimeTypes } from "features/files/utils/mimeTypeUtil";

import { useFetchFileById, useFetchMediaById } from "features/files/services";

import { getURLFileExtension } from "utils/StringUtils";

import "./PreviewEngine.scss";

function PreviewEngine({
  fileName,
  description,
  fileSrcURL,
  mimeType,
  fileEXT,
}) {
  const { isLoading: isDownloading, downloadFileFromURL } =
    useDownloadFileFromURL({
      fileURL: fileSrcURL,
      fileName: fileName,
    });

  const { fileId } = useParams();
  const [searchParams] = useSearchParams();
  const fileType = searchParams.get("fileType");

  const { isLoading: isExpanding, fetchMedia } = useFetchMediaById({
    mediaId: fileId,
    fetchByDefault: false,
    onSuccess: (file) => {
      const { id, name } = file;
      const fileURL = file.url || "";
      const fileEXT = getURLFileExtension(fileURL);

      downloadFileFromURL(fileURL, `${name || id}.${fileEXT}`);
    },
  });

  const { isLoading: isSynching, fetchFile } = useFetchFileById({
    fileId: fileId,
    fetchByDefault: false,
    onSuccess: (file) => {
      const { id, name } = file;
      const fileURL = file.url || "";
      const fileEXT = getURLFileExtension(fileURL);

      downloadFileFromURL(fileURL, `${name || id}.${fileEXT}`);
    },
  });

  const downloadFile = () => {
    if (fileType === "file") fetchFile();
    else fetchMedia();
  };

  const isLoading = isDownloading || isExpanding || isSynching;

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <span className="float-end">
            <Button
              variant="primary"
              size="sm"
              onClick={downloadFile}
              disabled={isLoading}
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : null}
              <span className="material-symbols-outlined md-16">
                download
              </span>{" "}
              Download
            </Button>
          </span>
          <span
            className={`float-start pt-2 fiv-sqo fiv-size-md fiv-icon-${fileEXT} me-2`}
          ></span>
          <h1 className="mb-0">{fileName} preview </h1>
        </div>
      </div>
      {description ? (
        <div className="row mb-3">
          <div className="col-12">
            <p>{description}</p>
          </div>
        </div>
      ) : null}
      <Card className="border-0 px-3 w-100">
        <Card.Body>
          <div
            className="row w-100 align-items-center justify-content-center"
            style={{ minHeight: "30em" }}
          >
            <div className="col-12 text-center">
              <PreviewRenderer fileSrcURL={fileSrcURL} mimeType={mimeType} />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

const PreviewRenderer = ({ fileSrcURL, mimeType }) => {
  if (mimeType.startsWith("image/")) {
    // Image preview
    return <img src={fileSrcURL} alt="File Preview" className="img-fluid" />;
  } else if (mimeType.startsWith("video/")) {
    // Video preview
    return (
      <video width="100%" controls>
        <source src={fileSrcURL} type={mimeType} />
        Your browser does not support the video tag.
      </video>
    );
  } else if (mimeType === mimeTypes.pdf) {
    // PDF preview
    return (
      <iframe
        src={fileSrcURL}
        title="PDF Preview"
        width={"100%"}
        height={"500em"}
      ></iframe>
    );
  } else if (
    mimeType === mimeTypes.doc ||
    mimeType === mimeTypes.docx ||
    mimeType === mimeTypes.xls ||
    mimeType === mimeTypes.xlsx ||
    mimeType === mimeTypes.ppt ||
    mimeType === mimeTypes.pptx
  ) {
    // Use ms office docs viewer
    return (
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          fileSrcURL
        )}`}
        width={"100%"}
        title="Doc Preview"
        height={"600em"}
      ></iframe>
    );
  } else if (
    mimeType === mimeTypes.html ||
    mimeType === mimeTypes.css ||
    mimeType === mimeTypes.js ||
    mimeType === mimeTypes.txt
  ) {
    // Text file preview
    fetch(fileSrcURL)
      .then((r) => r.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          document.getElementById("textPreview").textContent =
            DOMPurify.sanitize(e.target.result);
        };
        reader.readAsText(blob);
      });
    return <pre id="textPreview"></pre>;
  } else {
    return (
      <>
        <span className="material-symbols-outlined md-24">question_mark</span>
        <p>
          Preview not available for this file type. <br /> Please download to
          view it on device.
        </p>
      </>
    );
  }
};

export default PreviewEngine;
