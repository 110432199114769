import { useEffect, useState, useRef } from "react";
import { get } from "utils/DeApi";
import { sortBy } from "lodash";

const useFetchFacilityMedia = (facilityId) => {
  const [error, setError] = useState(null);
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const subscribedPromises = useRef([]);

  useEffect(() => {
    const fetchFiles = () => {
      setFiles([]);
      setError(null);
      setIsLoading(true);

      const filePromise = get(`facilities/${facilityId}/documents`, {
        params: {
          sort: "updated_at",
        },
      });
      filePromise.promise
        .then(({ data }) => setFiles(sortBy(data || [], "updatedAt").reverse()))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setTimeout(() => setIsLoading(false), 500));

      subscribedPromises.current.push(filePromise);
    };

    if (facilityId) fetchFiles();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [facilityId]);

  return {
    error,
    files,
    setFiles,
    isLoading,
  };
};

export default useFetchFacilityMedia;
