import React, { useEffect, useRef } from "react";

import * as echarts from "echarts";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import "./CheckboxFieldBarchart.scss";

const CHART_HEIGHT = 270;

function CheckboxFieldBarchart({ data }) {
  const el = useRef(null);

  useEffect(() => {
    const xAxisData = data?.stats.map(({ option }) => option);
    const yAxisData = data?.stats.map(({ count }) => count);

    const option = {
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />",
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        axisTick: {
          show: true,
          alignWithLabel: true,
          length: 8,
        },
        axisLabel: {
          rotate: 90,
          showMaxLabel: true,
          width: 72,
          overflow: "truncate",
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: yAxisData,
          type: "bar",
          barMaxWidth: 32,
        },
      ],
    };

    if (!data?.stats) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
    }

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth === 0 ? 750 : el.current.offsetWidth,
      height: CHART_HEIGHT,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: CHART_HEIGHT,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data]);
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="mb-4">
          <p className="text-center w-100 text-truncate">
            Distribution of Findings by {data?.fieldName}
          </p>
          <hr />
          <div className="d-flex justify-content-center">
            <div ref={el} />
          </div>
        </Col>
      </Row>
    </>
  );
}

CheckboxFieldBarchart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CheckboxFieldBarchart;
