import PropTypes from "prop-types";
import { useSetResponsiveness } from "hooks";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import useFetchContent from "features/contents/services/useFetchContent";

import "./ContentDetails.scss";
import { sanitizeHTML } from "utils/StringUtils";

const ContentDetails = ({ contentId }) => {
  const { isLoading, error, content } = useFetchContent(contentId);
  const { setResponsiveness } = useSetResponsiveness();
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!content) return <></>;

  return (
    <div
      className="ContentDetails"
      ref={setResponsiveness}
      dangerouslySetInnerHTML={{
        __html: sanitizeHTML(content?.contentBody),
      }}
    ></div>
  );
};

ContentDetails.propTypes = {
  contentId: PropTypes.string.isRequired,
};

export default ContentDetails;
