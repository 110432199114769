import React from "react";

import { useRoles } from "hooks";
import { Navigate, useLocation } from "react-router-dom";

/**
 * Role Base Middleware
 * @param {children} param0
 * @param {allowedRoles} ["Owner", "Admin", "Member", "Certifier", "Contributor"]
 * @param {redirectPath} "/audits"
 * @returns
 */
const RolebaseMiddleware = ({
  children,
  allowedRoles = [],
  redirectPath = "/audits",
}) => {
  const location = useLocation();
  const { isAdmin, roleName } = useRoles();

  if (!isAdmin && !allowedRoles.includes(roleName)) {
    return <Navigate replace to={redirectPath} state={{ from: location }} />;
  }

  return children;
};

export default RolebaseMiddleware;
