import { useContext, useEffect, useRef, useState } from "react";

import { OrganizationContext } from "contexts/OrganizationProvider";
import { get } from "utils/DeApi";

const useFetchAudit = ({ auditId, onSuccess }) => {
  const subscribedPromises = useRef([]);
  const onSuccessRef = useRef(onSuccess);
  const organization = useContext(OrganizationContext);

  const [error, setError] = useState("");
  const [audit, setAudit] = useState(null);
  const [isLoading, setIsLoading] = useState("");

  useEffect(() => {
    setError(null);
    setIsLoading(true);

    const mergeCommentsCountsWithObservations = (observations, comments) => {
      return observations.map((observation) => {
        const commentsForObservation = comments.filter(
          (comment) =>
            comment?.observation?.observationId === observation.observationId
        );
        return {
          ...observation,
          commentsCount: commentsForObservation.length,
        };
      });
    };

    const mergeSectionsWithSummaries = (sections, summaries) => {
      const summaryMap = summaries.reduce((map, summary) => {
        const sectionId = summary?.section?.sectionId;
        if (sectionId) {
          if (map.has(sectionId)) {
            console.warn(
              `Duplicate summary found for sectionId ${sectionId}. Using the first encountered summary.`
            );
          } else {
            map.set(sectionId, summary);
          }
        }
        return map;
      }, new Map());

      return sections.map((section) => ({
        ...section,
        summary: summaryMap.has(section.sectionId)
          ? summaryMap.get(section.sectionId)
          : null,
      }));
    };

    const findLatestComments = (comments) => {
      return comments.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
    };

    const fetchAudit = () => {
      const auditsPromise = get(`audits/${auditId}`);
      const responsesPromise = get(`audits/${auditId}/responses`);
      const observationPromise = get(`audits/${auditId}/observations`);
      const metadataPromise = get(`audits/${auditId}/metadata-responses`);
      const commentsPromise = get(
        `/audits/${auditId}/observation-report-comments`
      );
      const signaturePromise = get(`audits/${auditId}/signatures`);
      const sectionFieldsPromise = get(
        `audits/${auditId}/sections-availability`
      );
      const applicabilityScreeningPromise = get(
        `/audits/${auditId}/screening-questions`
      );
      const sectionSummariesPromise = get(
        `/audits/${auditId}/section-summaries`
      );

      subscribedPromises.current.push(auditsPromise);
      subscribedPromises.current.push(metadataPromise);
      subscribedPromises.current.push(responsesPromise);
      subscribedPromises.current.push(observationPromise);
      subscribedPromises.current.push(commentsPromise);
      subscribedPromises.current.push(signaturePromise);
      subscribedPromises.current.push(sectionFieldsPromise);
      subscribedPromises.current.push(applicabilityScreeningPromise);
      subscribedPromises.current.push(sectionSummariesPromise);

      Promise.all([
        auditsPromise.promise,
        responsesPromise.promise,
        observationPromise.promise,
        metadataPromise.promise,
        commentsPromise.promise,
        signaturePromise.promise,
        sectionFieldsPromise.promise,
        applicabilityScreeningPromise.promise,
        sectionSummariesPromise.promise,
      ])
        .then(
          ([
            { data: audit },
            { data: responses },
            { data: observations },
            { data: metadata },
            { data: comments },
            { data: signatures },
            { data: sectionFormFields },
            { data: applicabilityScreening },
            { data: sectionSummaries },
          ]) => {
            const observationsWithComments =
              mergeCommentsCountsWithObservations(observations, comments);
            const latestComments = findLatestComments(comments);

            const sectionsWithSummaries = mergeSectionsWithSummaries(
              audit.protocol.sections,
              sectionSummaries
            );

            const auditData = {
              ...audit,
              protocol: {
                ...audit.protocol,
                sections: sectionsWithSummaries,
              },
              metadata: metadata || [],
              responses: responses || [],
              observations: observationsWithComments,
              signatures: signatures || [],
              sectionFormFields: sectionFormFields || [],
              latestComments: latestComments,
              applicabilityScreening: applicabilityScreening || [],
              showInapplicableSections: !!!applicabilityScreening?.length,
            };

            setAudit(auditData);

            if (typeof onSuccessRef.current === "function") {
              onSuccessRef.current(auditData);
            }
          }
        )
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setTimeout(() => setIsLoading(false), 2000);
        });
    };

    if (auditId && organization.id) fetchAudit();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [auditId, organization.id]);

  return { error, isLoading, audit };
};

export default useFetchAudit;
