import React from "react";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useSetResponsiveness } from "hooks";
import { Card, Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import { sanitizeHTML } from "utils/StringUtils";
import BreadCrumbs from "components/ui/BreadCrumbs";
import ErrorHandler from "components/ui/ErrorHandler";
import { useFetchContent } from "features/contents/services";
import { createBreadcrumb } from "features/contents/utils/breadcrumpUtil";

import RelatedContentList from "./RelatedContentList";

import "./Content.scss";
const Content = () => {
  const { contentId } = useParams();
  const { setResponsiveness } = useSetResponsiveness();
  const { isLoading, error, content } = useFetchContent(contentId);
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!content) return <></>;

  return (
    <>
      <Helmet>
        <title>{content?.title}</title>
      </Helmet>
      <BreadCrumbs breadcrumbs={createBreadcrumb(content?.title)} />
      <Row>
        <Col xs={12} lg={9} className="mb-3">
          <Card className="py-3">
            <Card.Body>
              <h1>{content?.title}</h1>
              <hr />
              <div
                className="ContentDetails"
                ref={setResponsiveness}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(content?.contentBody),
                }}
              ></div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={3} className="mb-3">
          <RelatedContentList
            contentId={contentId}
            categories={content?.categories}
          />
        </Col>
      </Row>
    </>
  );
};

Content.propTypes = {};

export default Content;
