import React, { useContext, useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { post } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { OrganizationContext } from "contexts/OrganizationProvider";

const PortfolioCreate = ({ onPortfolioCreated }) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const { isAdmin } = useRoles();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createPortfolio = (name, description) => {
    setError(null);
    setIsLoading(true);
    const facilityPromise = post(
      `organizations/${organization?.id}/portfolios`,
      {
        name,
        description,
        organizationId: organization?.id,
      }
    );
    facilityPromise.promise
      .then(({ data }) => {
        handleClose();
        onPortfolioCreated(data);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(facilityPromise);
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Name is too Short!")
      .max(100, "Name is Long!")
      .required("Name is required"),
    description: yup
      .string()
      .min(2, "Description is too Short!")
      .max(255, "Description is too Long!")
      .notRequired(),
  });

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (!isAdmin) return <></>;

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShow}
        className={`py-0`}
        size="sm"
      >
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        New
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Portfolio</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { name, description } = values;
            createPortfolio(name, description);
          }}
          initialValues={{
            name: "",
            description: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label className="mb-1">
                    Portfolio Name <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                    isValid={values.name && !errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label className="mb-1">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.description && touched.description}
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  className="px-3"
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  className="px-3"
                  disabled={!values.name || !isValid || isLoading}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Create Portfolio
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

PortfolioCreate.propTypes = {
  onPortfolioCreated: PropTypes.func.isRequired,
};

export default PortfolioCreate;
