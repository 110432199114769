import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";

import { useFetchFacilityAudits } from "features/assessment";

import "./FacilityAuditsTypeahead.scss";

const FacilityAuditsTypeahead = ({
  onChange,
  isValid,
  selected,
  facilityId,
  placeholder = "Filter by Assessment",
}) => {
  const [assessment, setAssessment] = useState([]);

  const { isLoading, audits } = useFetchFacilityAudits({
    facilityId: facilityId,
    fetchByDefault: true,
    perPage: 1000,
  });

  useEffect(() => {
    setAssessment(Array.isArray(selected) ? selected : []);
  }, [selected]);

  return (
    <Typeahead
      id="assessment-typeahead"
      clearButton
      placeholder={placeholder}
      isLoading={isLoading}
      labelKey="name"
      disabled={!facilityId}
      onChange={(data) => {
        onChange(data);
        setAssessment(data);
      }}
      isValid={Boolean(isValid)}
      options={audits}
      selected={assessment}
    />
  );
};

FacilityAuditsTypeahead.propTypes = {
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.any,
  selected: PropTypes.array,
  facilityId: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FacilityAuditsTypeahead;
