import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchActionItemComment = ({ actionItemId, expand = false }) => {
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [actionItemComments, setActionItemComments] = useState([]);

  useEffect(() => {
    const fetchActionItemComment = () => {
      setError("");
      setIsLoading(true);
      const actionItemCommentsPromise = get(
        `/action-items/${actionItemId}/comments`
      );
      actionItemCommentsPromise.promise
        .then(({ data: comments }) => {
          setActionItemComments(
            comments?.sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt);
            })
          );
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(actionItemCommentsPromise);
    };

    if (expand && actionItemId) fetchActionItemComment();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [actionItemId, expand]);

  return {
    isLoading,
    error,
    actionItemComments,
  };
};

export default useFetchActionItemComment;
