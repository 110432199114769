import { useContext, useRef, useEffect, useState, useCallback } from "react";
import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

function useFetchEntityTags({ fetchByDefault = true }) {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const fetchEntityTags = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const tagsPromise = get(`organizations/${organization?.id}/labels`, {
      params: {},
    });
    tagsPromise.promise
      .then(({ data: tags }) => {
        tags.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setTags(tags);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(tagsPromise);
  }, [organization?.id]);

  useEffect(() => {
    if (fetchByDefault) fetchEntityTags();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchEntityTags, fetchByDefault]);

  return {
    error,
    isLoading,
    tags,
    setTags,
    fetchEntityTags,
  };
}

export default useFetchEntityTags;
