import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context";
import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import TypeAheadInput from "../TypeAheadInput";
import { isFilterActive } from "features/actionItems/utils";
import { useFetchSubscribers, useFetchMembers } from "features/organization";
import { useRoles } from "hooks";

const AssignByFilter = () => {
  const {
    filters: { assignedBy },
    query: { assignedBy: query },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);
  const { isMember } = useRoles();

  const { subscribers: subscribersList, isLoading: subscriberLoading } =
    useFetchSubscribers({
      path: isMember ? "" : "subscribers",
    });

  const { members, isLoading: membersLoading } = useFetchMembers();

  const isLoading = isMember ? membersLoading : subscriberLoading;
  const subscribers = isMember ? members : subscribersList;

  const onClear = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: { assignedBy: "" },
    });
    dispatch({
      type: "SET_QUERY",
      payload: { assignedBy: "" },
    });
  };

  return (
    <Col lg={2} className="mb-3 mt-2 ">
      <small className="text-muted">Assigned By</small>
      <Typeahead
        className={`mt-2 ${isFilterActive(assignedBy?.subscriberId, true)}`}
        id="assigned-by"
        name="assignedBy"
        isLoading={isLoading}
        selected={assignedBy ? [assignedBy] : []}
        labelKey={(option) => `${option.firstName} ${option.lastName}`}
        onInputChange={(val) =>
          dispatch({ type: "SET_QUERY", payload: { assignedBy: val } })
        }
        placeholder="Select an assignor..."
        onChange={(a) =>
          dispatch({ type: "SET_FILTERS", payload: { assignedBy: a[0] } })
        }
        options={subscribers}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
          const { firstName, lastName } = assignedBy;
          const name = firstName && lastName ? `${firstName} ${lastName}` : "";
          return (
            <TypeAheadInput
              {...inputProps}
              value={name || query || ""}
              onClear={onClear}
            />
          );
        }}
        renderMenu={(results, { style, id }) => {
          return (
            <Menu className="typehead-menu-alignment" style={style} id={id}>
              {results.map((result, index) => (
                <MenuItem
                  key={result.subscriberId}
                  option={result}
                  position={index}
                >
                  {result.firstName
                    ? `${result.firstName} ${result.lastName}`
                    : "Load more..."}{" "}
                  <br />
                  <small className="text-muted">{result.email}</small>
                </MenuItem>
              ))}
            </Menu>
          );
        }}
      />
    </Col>
  );
};

export default AssignByFilter;
