import { useContext } from "react";
import { AuditContext } from "../contexts/AuditProvider";

const useObservations = () => {
  const { audit } = useContext(AuditContext);
  const questions = audit?.questions;
  const observations = questions.flatMap(
    ({ observations = [] }) => observations
  );

  const responses = observations.flatMap(({ responses = [] }) => responses);

  const applicableObservations = observations.filter(
    ({ isInapplicable }) => !isInapplicable
  );

  const applicableResponses = applicableObservations.flatMap(
    ({ responses = [] }) => responses
  );

  const hasRiskResponses = !!applicableObservations.find(
    ({ risk }) => risk !== null
  );
  const hasComplianceResponses = !!applicableObservations.find(
    ({ compliance }) => compliance !== null
  );
  const hasConformityResponses = !!applicableObservations.find(
    ({ conformityLevel }) => conformityLevel !== null
  );

  const getObservationsByPrinciple = (principleId) => {
    const principleSections = audit.protocol.sections.filter((section) => {
      return !!section.principles.find((p) => p.principleId === principleId);
    });
    return audit.questions
      .filter(
        ({ sectionId }) =>
          !!principleSections.find((p) => p.sectionId === sectionId)
      )
      .flatMap(({ observations = [] }) => observations)
      .filter(({ isInapplicable }) => !isInapplicable);
  };

  return {
    responses: responses,
    questions: questions,
    observations: observations,
    applicableResponses: applicableResponses,
    applicableObservations: applicableObservations,

    hasRiskResponses: hasRiskResponses,
    hasComplianceResponses: hasComplianceResponses,
    hasConformityResponses: hasConformityResponses,

    // functions
    getObservationsByPrinciple: getObservationsByPrinciple,
  };
};

export default useObservations;
