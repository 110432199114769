import { useCallback, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchMediaById = ({
  mediaId,
  fetchByDefault = true,
  onSuccess,
  basePath = "media",
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState();

  const subscribedPromises = useRef([]);
  const onSuccessRef = useRef(onSuccess);

  const fetchMedia = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const mediaPromise = get(`${basePath}/${mediaId}`);

    mediaPromise.promise
      .then(({ data }) => {
        setMedia(data);
        if (typeof onSuccessRef?.current === "function") {
          onSuccessRef.current(data);
        }
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(mediaPromise);
  }, [mediaId]);

  useEffect(() => {
    if (fetchByDefault) {
      fetchMedia();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchMedia, fetchByDefault]);

  return {
    error,
    media,
    isLoading,
    fetchMedia,
  };
};

export default useFetchMediaById;
