import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import {
  DateField,
  EntityField,
  LinkField,
  ProtocolField,
} from "../DataExportFields";
import * as yup from "yup";
import { Formik } from "formik";
import { Form, Offcanvas } from "react-bootstrap";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useParams } from "react-router-dom";
import DataExportFooter from "./DataExportFooter";
import Assessments from "./Assessments";
import DataExportButton from "./DataExportButton";

const DataExportForm = ({ level = "", variant }) => {
  const { facilityId } = useParams();
  const organization = useContext(OrganizationContext);

  const [links, setLinks] = useState([]);

  const [show, setShow] = useState(false);

  const reset = () => setLinks([]);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    reset();
  };

  const schema = yup.object().shape({
    protocolId: yup.string().required("Protocol is required"),
    facilityId: yup.string().required("Facility is required"),
    assessments: yup.array().min(1).required("Assessment is required"),
  });

  return (
    <>
      <DataExportButton
        variant={variant}
        level={level}
        handleShow={handleShow}
      />
      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Formik
          validationSchema={schema}
          initialValues={{
            protocol: [],
            facilityId: facilityId || "",
            protocolId: "",
            assessments: [],
            search: "",
            sortValue: {
              index: 0,
              name: "A - Z",
              value: "name",
            },
            level,
            isLoading: false,
            error: null,
            reset,
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  Data Export - {organization?.name}{" "}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="vh-100">
                <p className="">
                  Data Export allows you to export observations level data
                  either as CSV or a Data Link for integration with Business
                  Intelligence Tools. The Data links provides data in a JSON
                  format.
                </p>
                <>
                  <ProtocolField />
                  <EntityField />
                  <DateField />
                  <Assessments />
                  <LinkField links={links} />
                </>
              </Offcanvas.Body>
              <DataExportFooter setLinks={setLinks} />
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

DataExportForm.propTypes = {
  level: PropTypes.string,
  variant: PropTypes.string,
};

export default DataExportForm;
