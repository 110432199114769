import { useCallback, useContext, useEffect, useState } from "react";

import { useRoles } from "hooks";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { ActionItemCreate, ActionItemCard } from "features/actionItems";
import { useFetchActionItems } from "features/actionItems";

function OrganizationActionItem() {
  const organization = useContext(OrganizationContext);
  const [actionItems, setActionItems] = useState([]);
  const { isCertifier } = useRoles();

  const params = useCallback(() => {
    return {
      perPage: 5,
      organizationId: organization?.id,
      "filter[type]": "organization",
      "filter[status]": "0,1,2",
    };
  }, [organization?.id]);

  const {
    isLoading,
    actionItems: data,
    error,
    meta,
    setMeta,
  } = useFetchActionItems({
    params,
  });

  useEffect(() => {
    if (!!data?.length) setActionItems(data);
  }, [data]);

  if (isCertifier) return <span />;

  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex-fill">
          <h2>
            Action Items ·{" "}
            <small className="text-muted" translate="no">
              {!isLoading && <span> {meta?.total ?? 0}</span>}
            </small>{" "}
          </h2>
          <p>Organization Level</p>
        </div>
        <div>
          <ActionItemCreate
            taskableId={null}
            taskableType={null}
            onActionItemCreated={(newActionItem) => {
              setMeta((prev) => ({ ...prev, total: ++prev.total }));
              setActionItems((prevActionItems) => [
                newActionItem,
                ...prevActionItems,
              ]);
            }}
          />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : actionItems?.length ? (
        <ActionItemCard
          setMeta={setMeta}
          className="w-75 mb-2"
          actionItems={actionItems?.filter(({ status }) => status !== 3)}
          setActionItems={setActionItems}
          to={`/action-items?taskableType=organization&taskableId=${organization?.id}`}
        />
      ) : (
        <EmptyStateHandler
          title="No action items found"
          description="There are currently no action items to show at the Organization level."
        />
      )}
    </>
  );
}

OrganizationActionItem.propTypes = {};

export default OrganizationActionItem;
