import { Alert } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import TagUpdate from "../TagUpdate";
import TagDelete from "../TagDelete";
import { setAccentColor } from "utils/EntityTagUtils";
import { useFetchFacilities } from "features/entity/services";

const EntityTagList = ({ error, isLoading, tags, setTags }) => {
  const {
    error: isExpandError,
    isLoading: isExpanding,
    facilities,
  } = useFetchFacilities({
    page: 1,
    perPage: 10000,
    query: "",
    startDate: null,
    endDate: null,
    sortState: {
      sortColumn: "updated_at",
      updatedAt: true,
      sortAsc: true,
    },
  });

  const facilityTags = Array.isArray(facilities)
    ? facilities?.map(({ labels }) => labels || []).flat()
    : [];

  const organizationTagNames = [
    ...new Set(facilityTags.map(({ name }) => name)),
  ];

  const handleTagUpdated = (tag) => {
    setTags((prevStatus) =>
      prevStatus.map((prevTag) =>
        prevTag.organizationLabelId === tag.organizationLabelId ? tag : prevTag
      )
    );
  };

  if (isLoading || isExpanding)
    return (
      <tr>
        <td colSpan={5}>
          <Loader />
        </td>
      </tr>
    );

  if (error || isExpandError)
    return (
      <tr>
        <td colSpan={5}>
          <ErrorHandler error={error || isExpandError} />
        </td>
      </tr>
    );

  if (Array.isArray(tags) && !tags.length)
    return (
      <tr>
        <td colSpan={5}>
          <Alert variant="info" className={`my-3 d-flex flex-row`}>
            <div className="me-3">
              <span
                translate="no"
                className="material-symbols-outlined md-18 text-primary text-opacity-75"
              >
                info
              </span>
            </div>
            <div>
              <h5 className="mb-1">
                <small>No tags </small>
              </h5>
              <p className="mb-1">
                <small>Tags will appear here once added</small>
              </p>
            </div>
          </Alert>
        </td>
      </tr>
    );

  return tags.map((tag) => {
    return (
      <tr key={tag.organizationLabelId}>
        <td>{tag.name}</td>
        <td>
          <small
            className="px-2 py-1"
            style={{
              backgroundColor: tag?.color || "#C1C1C1",
              color: setAccentColor(tag?.color),
            }}
          >
            {tag?.color || "#C1C1C1"}
          </small>
        </td>
        <td>{tag.description}</td>
        <td>
          <small>
            {new Date(tag.updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        <td>
          <TagUpdate tag={tag} onTagUpdated={handleTagUpdated} />{" "}
          <TagDelete
            tag={tag}
            organizationTagNames={organizationTagNames}
            onTagDeleted={(tag) =>
              setTags((prevStatus) =>
                prevStatus.filter(
                  ({ organizationLabelId }) =>
                    organizationLabelId !== tag.organizationLabelId
                )
              )
            }
          />
        </td>
      </tr>
    );
  });
};
export default EntityTagList;
