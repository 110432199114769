import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import ActionItemReport from "../../ActionItems/ActionItemReport";

const ActionItemStats = ({ isTableView }) => {
  if (isTableView) return <span />;

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
        <ActionItemReport />
      </Col>
    </Row>
  );
};

ActionItemStats.propTypes = {
  isTableView: PropTypes.bool,
};

export default ActionItemStats;
