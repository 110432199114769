import axios from "axios";
import { MakeCancelable } from "./CancelablePromise";

/**
 * Handles API access to DE.
 * Builds http requests via axios.
 * Adds Authorization headers and http errors interceptors.
 * Converts http requests to cancelable promises to allow cancellation of promises for unmounted components.
 */

const END_POINT = process.env.REACT_APP_HTML_TO_PDF;
const xsrfToken = Math.random().toString(36).substring(2, 15);

var instance = axios.create({
  baseURL: END_POINT,
  headers: { common: { "XSRF-TOKEN": xsrfToken } },
  withCredentials: false,
  transformRequest: [
    (data) => {
      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

registerInterceptor(instance);

// Add a response interceptor for error handling
function registerInterceptor(instance) {
  instance.interceptors.response.use(
    function (response) {
      // Success
      return Promise.resolve(response.data);
    },
    function (error) {
      // Do something with response error
      // @TODO --reauth
      if (error.response) {
        // The request was made and the server responded with a status code
        return Promise.reject({
          data: error.response.data,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return Promise.reject({
          data: "",
          status: 0,
          statusText: "No Response",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject({
          data: "",
          status: 0,
          statusText: error.message,
        });
      }
    }
  );
}
function post(url, data, type = "blob") {
  return MakeCancelable(
    instance.post(
      url,
      data,
      type === "blob" && {
        responseType: "blob",
      }
    )
  );
}
export { post };
