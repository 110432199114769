import React from "react";
import { getURLFileExtension } from "utils/StringUtils";

import { useFetchMediaById } from "features/files/services";
import FilePreviewLayout from "../FilePreviewLayout";

import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";

export default function MediaPreview({ mediaId }) {
  const {
    media,
    isLoading: isMediaLoading,
    error: fetchMediaError,
  } = useFetchMediaById({
    mediaId,
  });

  if (fetchMediaError) return <ErrorHandler error={fetchMediaError} />;
  if (isMediaLoading || !media) return <Loader />;

  const { id, name, url, description, mimeType, model, modelType } = media;
  const fileEXT = getURLFileExtension(url);
  const fileName = name || `${id}.${fileEXT}`;

  return (
    <FilePreviewLayout
      fileName={fileName}
      description={description}
      fileSrcURL={url}
      mimeType={mimeType}
      fileEXT={fileEXT}
      facility={modelType === "facility" ? model : null}
      audit={modelType === "assessment" ? model : null}
      question={modelType === "question" ? model : null}
      organization={modelType === "organization" ? model : null}
    />
  );
}
