import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ReportAggregation from "components/common/ReportAggregation";
import { useFetchFacilityProtocolStats } from "features/entity/services";
import { RISK_OPTION } from "features/entity/constants/statsFilterOptions";
import {
  aggregationOfRisk,
  aggregationOfRiskTrends,
  defaultRisk,
  dynamicLabels,
} from "helper/reporting";

import "./FacilityReportRisk.scss";

function FacilityReportRisk({ protocol, filterPeriod, facility }) {
  const riskLevels =
    protocol?.riskLevels && protocol?.riskLevels.length
      ? protocol?.riskLevels
      : defaultRisk;

  const { error, isLoading, facilityStats } = useFetchFacilityProtocolStats({
    period: filterPeriod?.period,
    option: RISK_OPTION,
    facilityId: facility?.facilityId,
    protocolId: protocol?.protocolId,
  });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!facilityStats) return <></>;
  if (!facilityStats.length)
    return (
      <Col>
        <EmptyStateHandler
          title="No data to visualize"
          description="You have no data to visualize."
        />
      </Col>
    );

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={4} className="mb-3 border-end">
        <p className="text-center w-100 text-truncate text-capitalize">
          Distribution of {protocol?.questionOptions[0]?.name ?? "Risk Rating"}
        </p>
        <hr />
        <ReportAggregation
          size="square-container"
          labels={dynamicLabels(riskLevels)}
          data={aggregationOfRisk(facilityStats, riskLevels)}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={8} className="mb-3">
        <p className="text-center w-100 text-truncate text-capitalize">
          {protocol?.questionOptions[0]?.name ?? "Risk Rating"} trends across ·{" "}
          <small className="text-muted">{facility?.name || "Entity"}</small>
        </p>
        <hr />
        <ReportTrend
          size="square-container"
          labels={dynamicLabels(riskLevels)}
          data={aggregationOfRiskTrends(
            facilityStats,
            riskLevels,
            filterPeriod
          )}
        />
      </Col>
    </Row>
  );
}

FacilityReportRisk.propTypes = {
  protocol: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  filterPeriod: PropTypes.object.isRequired,
};

export default FacilityReportRisk;
