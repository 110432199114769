import React, { useState } from "react";

import * as yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useCreateAudit } from "features/assessment/services";
import { auditValidationSchema } from "features/assessment/schemas";

import GroupTypeahead from "../../common/GroupTypeahead";
import ProtocolTypeahead from "../../common/ProtocolTypeahead";

import "./OrganizationAuditCreate.scss";
import { FacilityTypeahead } from "features/entity";

export default function OrganizationAuditCreate({ onAssessmentCreated }) {
  const { isCertifier, isContributor } = useRoles();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const extendedValidationSchema = auditValidationSchema.shape({
    entity: yup
      .array()
      .length(1, "Entity is required")
      .required("Entity is required"),
  });

  const { error, isLoading, createAudit } = useCreateAudit({
    onAuditCreated: (data) => {
      onAssessmentCreated(data);
    },
  });

  if (isCertifier || isContributor) return <></>;

  return (
    <div>
      <Button size="sm" variant="primary" onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        New Assessment
      </Button>
      <Offcanvas
        scroll
        show={show}
        placement="end"
        onHide={handleClose}
        className="w-fixed-640 overflow-auto"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>New Assessment</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="overflow-auto">
          <Formik
            validateOnMount={true}
            initialValues={{
              entity: [],
              name: "",
              description: "",
              protocol: [],
              group: [],
              dueDate: null,
            }}
            validationSchema={extendedValidationSchema}
            onSubmit={(values) => {
              createAudit({
                name: values.name,
                description: values.description,
                auditGroupId:
                  Array.isArray(values.group) && values.group.length
                    ? values.group.pop().id
                    : "",
                protocolId: values?.protocol?.pop()?.protocolId,
                facilityId: values?.entity?.pop()?.facilityId,
                dueDate: values.dueDate
                  ? moment(values.dueDate).format("YYYY-MM-DD hh:mm:ss")
                  : "",
              });
            }}
          >
            {({
              isValid,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Offcanvas.Body className="h-100vh">
                  <Form.Group controlId="entity" className="mb-3">
                    <Form.Label>
                      Entity Name <RequiredAsterisk />
                    </Form.Label>
                    <FacilityTypeahead
                      onChange={(entity) => {
                        setFieldTouched("entity", true);
                        setFieldValue("entity", entity);
                      }}
                      selected={values.entity}
                      placeholder="Select an entity"
                      isInvalid={!!errors.entity && touched.entity}
                      isValid={!errors.entity && touched.entity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.entity}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="protocol" className="mb-3">
                    <Form.Label>
                      Assessment Protocol <RequiredAsterisk />
                    </Form.Label>
                    <ProtocolTypeahead
                      name="protocol"
                      isInvalid={!!errors.protocol && touched.protocol}
                      isValid={!errors.protocol && touched.protocol}
                      onChange={(protocol) => {
                        setFieldTouched("protocol", true);
                        setFieldValue("protocol", protocol);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.protocol}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>
                      Assessment Name <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder="Assessment Name"
                      onBlur={handleBlur}
                      isInvalid={touched.name && !!errors.name}
                      isValid={touched.name && !errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="description">
                    <Form.Label>Assessment Description (optional)</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Assessment Description"
                      isValid={
                        touched.description &&
                        !errors.description &&
                        values.description
                      }
                      isInvalid={touched.description && !!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {Boolean(values?.entity?.length) && (
                    <Form.Group className="mb-3" controlId="group">
                      <Form.Label>
                        Group Assignment (optional) <br />
                        <small>
                          An assessment can only be assigned to{" "}
                          <strong>1</strong> group
                        </small>
                      </Form.Label>
                      <GroupTypeahead
                        name="group"
                        allowNew={true}
                        selected={values?.group}
                        placeholder="Select or create a group"
                        isValid={
                          values?.group && !errors?.group && touched.group
                        }
                        facilityId={values?.entity[0]?.facilityId}
                        onChange={(group) => setFieldValue("group", group)}
                      />
                    </Form.Group>
                  )}
                  <Form.Group controlId="dueDate" className="mb-3">
                    <Form.Label className="mb-1">Due Date</Form.Label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      type="date"
                      name="dueDate"
                      popperPlacement="top-start"
                      selected={values.dueDate}
                      onChange={(date) => setFieldValue("dueDate", date)}
                      onBlur={handleBlur}
                      isValid={values.dueDate && !errors.dueDate}
                      isInvalid={
                        !(values.dueDate && !errors.dueDate) && touched.dueDate
                      }
                      minDate={new Date()}
                      isClearable
                      placeholderText="Enter Due Date"
                      className="form-control"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dueDate && touched.dueDate ? (
                        <small>{errors.dueDate}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {error && <ErrorHandler error={error} />}
                </Offcanvas.Body>
                <div className="sticky-bottom p-3 bg-light text-end">
                  <Button
                    size="sm"
                    onClick={handleClose}
                    variant="outline-secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    variant="primary"
                    disabled={isLoading || !isValid}
                    className="ms-2"
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    <span>Create Assessment</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Offcanvas>
    </div>
  );
}

OrganizationAuditCreate.propTypes = {
  onAssessmentCreated: PropTypes.func.isRequired,
};
