import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { colorOptions, setAccentColor, COLS } from "utils/EntityTagUtils";

const ColorPicker = ({ name, value, handleChange, visible }) => {
  const chunkArray = (array, size) => {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
      array.slice(index * size, index * size + size)
    );
  };

  const colorRows = chunkArray(colorOptions, COLS);

  const [selectedColor, setSelectedColor] = useState(value);

  const handleColorClick = (color) => {
    setSelectedColor(color);
    handleChange({ target: { name, value: color } });
  };

  const cardWidth = COLS * 40;

  return (
    <div>
      {visible && (
        <Card
          border="medium"
          style={{ width: `${cardWidth}px` }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Card.Body>
            {colorRows.map((row, rowIndex) => (
              <div key={rowIndex} className="d-flex">
                {row.map((colorOption, index) => (
                  <Button
                    key={index}
                    id={colorOption}
                    variant="secondary"
                    onClick={() => handleColorClick(colorOption)}
                    style={{
                      backgroundColor: colorOption,
                      width: "25px",
                      height: "25px",
                    }}
                    className={`rounded-circle m-1 border-0`}
                  >
                    {selectedColor === colorOption ? (
                      <span
                        translate="no"
                        className="material-symbols-outlined md-24"
                        style={{
                          color: setAccentColor(selectedColor),
                          marginTop: "-10px",
                          marginLeft: "-11px",
                        }}
                      >
                        check
                      </span>
                    ) : (
                      <></>
                    )}
                  </Button>
                ))}
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ColorPicker;
