import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { put } from "utils/DeApi";

const useUpdateQuestionAssignees = ({ auditId, questionId, onSuccess }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const updateQuestionAssignees = (formData) => {
    setError(null);
    setIsLoading(true);
    const questionAssigneesPromise = put(
      `/audits/${auditId}/questions/${questionId}/members`,
      {
        ...formData,
      }
    );

    questionAssigneesPromise.promise
      .then(({ data }) => {
        if (typeof onSuccess === "function") onSuccess(data);
        toast.success("Success", "Question Assignees saved successfully");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          toast.error(
            "Error",
            "An error occurred while save question Assignees"
          );
        }
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(questionAssigneesPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, updateQuestionAssignees };
};

export default useUpdateQuestionAssignees;
