import { useToast } from "hooks";
import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
import { put } from "utils/DeApi";

const useUpdateFacilityFile = ({ fileId, onFacilityFileUpdated }) => {
  const toast = useToast();

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const subscribedPromises = useRef([]);

  const updateFacilityFile = (file) => {
    setError(null);
    setIsLoading(true);
    const filePromise = put(`media/${fileId}`, {
      ...file,
    });
    filePromise.promise
      .then(({ data }) => {
        if (typeof onFacilityFileUpdated === "function")
          onFacilityFileUpdated(data);
        toast.success("Sucess", "File details updated successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(filePromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    updateFacilityFile,
  };
};

useUpdateFacilityFile.propTypes = {
  fileId: PropTypes.string.isRequired,
  onFacilityFileUpdated: PropTypes.func.isRequired,
};

export default useUpdateFacilityFile;
