import { OrganizationContext } from "contexts/OrganizationProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchAudits = ({
  protocolId,
  facilityId,
  search,
  updatedAt,
  sortValue,
  isfetch,
}) => {
  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [audits, setAudits] = useState([]);

  const fetchAudits = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const auditsPromise = get(`/audits`, {
      params: {
        organizationId: organization?.id,
        ...(protocolId
          ? {
              "filter[protocol_id]": protocolId,
            }
          : {}),
        ...(facilityId
          ? {
              "filter[facility_id]": facilityId,
            }
          : {}),
        ...(search ? { "filter[search]": search } : {}),
        ...(updatedAt ? { "filter[updated_at]": updatedAt } : {}),
        include: "facility",
        sort: sortValue?.value ?? "name",
        perPage: 1000000000000000,
      },
    });
    auditsPromise.promise
      .then(({ data }) =>
        setAudits(
          Array.isArray(data)
            ? data.filter(
                ({ organizationId }) => organizationId === organization?.id
              )
            : []
        )
      )
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditsPromise);
  }, [
    facilityId,
    organization?.id,
    protocolId,
    search,
    sortValue?.value,
    updatedAt,
  ]);

  useEffect(() => {
    if (isfetch) fetchAudits();
  }, [fetchAudits, isfetch]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    isLoading,
    error,
    audits,
    fetchAudits,
  };
};

export default useFetchAudits;
