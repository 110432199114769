import { useContext } from "react";

import { useRoles } from "hooks";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { OrganizationContext } from "contexts/OrganizationProvider";

import "./AuditSearchLink.scss";

const AuditSearchLink = () => {
  const { isContributor } = useRoles();
  const organization = useContext(OrganizationContext);

  if (isContributor) return <></>;
  if (!organization?.searchIndexExists) return <></>;
  return (
    <div className="d-grid">
      <Button
        variant="outline-primary"
        as={Link}
        to={"/search"}
        size="sm"
        className="me-2"
      >
        <span translate="no" className="material-symbols-outlined md-18">
          search
        </span>{" "}
        Search in Assessments
      </Button>
    </div>
  );
};
export default AuditSearchLink;
