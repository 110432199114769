import { OrganizationContext } from "contexts/OrganizationProvider";
import { UserContext } from "contexts/UserProvider";
import { useRoles } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchMembers = () => {
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const { isMember } = useRoles();

  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchMembers = () => {
      setIsLoading(true);
      setError(null);
      const subscribersPromise = get(
        `/organizations/${organization?.id}/member-list`
      );
      subscribersPromise.promise
        .then((response) => setMembers(response.data ?? []))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (isMember && !members?.length) fetchMembers();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [user, isMember, organization?.id, members?.length]);

  return {
    members,
    isLoading,
    error,
  };
};

export default useFetchMembers;
