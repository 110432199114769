import React from "react";
import { Button } from "react-bootstrap";

const QuestionResponseTableRows = ({
  id = "",
  question,
  response,
  copyTextToClipboard,
}) => {
  const handleResponse = (response) => {
    if (Array.isArray(response))
      return response
        .map(({ props: { children } = {} } = {}) => children)
        .join(", ");
    return response;
  };
  return (
    <tr id={id}>
      <td className="d-flex justify-content-between align-items-start">
        <div>
          <span className="fw-bold">{question}:</span>{" "}
          <span className="text-break">{response}</span>
        </div>
        <Button
          size="sm"
          variant="outline-secondary border-0"
          onClick={() => {
            copyTextToClipboard(question, handleResponse(response));
          }}
        >
          <span className="material-symbols-outlined md-16 cursor-pointer">
            content_copy
          </span>
        </Button>
      </td>
    </tr>
  );
};

export default QuestionResponseTableRows;
