import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { get } from "utils/DeApi";
import { destroy } from "utils/DeApi";
import UpdateCredentials from "./UpdateCredentials";
import AssessCredentialsTable from "./AssessCredentialsTable";
import AddNewCredentials from "./AddNewCredentials";
import DeleteCredentials from "./DeleteCredentials";

export default function AssessCredentials({ organization }) {
  const [assessApiKeys, setAssessApiKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    tokenName: "",
    tokenValue: "",
    isActive: false,
    id: "",
  });
  const isUserAdmin = ["Admin", "Owner"].includes(
    organization?.invitation?.role
  );
  const subscribedPromises = useRef([]);
  const fetchAssessApiKeys = useCallback(async () => {
    setLoading(true);
    const findingsPromise = get(`/organizations/${organization?.id}/api-keys`);
    findingsPromise.promise
      .then(async ({ data }) => {
        if (data && data.length < 1) {
          setLoading(false);
          setAssessApiKeys([]);
          return;
        }
        setAssessApiKeys(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setShowError(error.data.message);
      });
    subscribedPromises.current.push(findingsPromise);
  }, [organization?.id]);
  useEffect(() => {
    fetchAssessApiKeys();
  }, [fetchAssessApiKeys]);
  const deleteCredentials = async () => {
    setLoading(true);
    const findingsPromise = destroy(
      `/organizations/${organization?.id}/api-keys/${initialValues?.id}`
    );
    findingsPromise.promise
      .then(async ({ data }) => {
        setAssessApiKeys((prevAssessApiKeys) =>
          prevAssessApiKeys.filter((apiKey) => apiKey.id !== initialValues?.id)
        );
        setInitialValues({
          tokenName: "",
          tokenValue: "",
          isActive: false,
          id: "",
        });
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setShowError(error.data.message);
        setLoading(false);
      });
    subscribedPromises.current.push(findingsPromise);
  };
  const updateAssessAPIKeysAfterApi = (newAssessApiKeys, action) => {
    if (action === "update") {
      setAssessApiKeys((prevAssessApiKeys) =>
        prevAssessApiKeys.map((apiKey) =>
          apiKey.id === newAssessApiKeys.id ? newAssessApiKeys : apiKey
        )
      );
    } else {
      setAssessApiKeys([...assessApiKeys, newAssessApiKeys]);
    }
  };

  const updateInitialValues = (element) => {
    setInitialValues({
      isActive: element.isActive,
      tokenName: element.name,
      tokenValue: element.token,
      id: element.id,
    });
  };
  const onEditClick = (element) => {
    updateInitialValues(element);
    setShowEditModal(true);
  };
  const onDeleteClick = (element) => {
    updateInitialValues(element);
    setShowDeleteModal(true);
  };
  const dataNotAvailable = assessApiKeys.length < 1;
  return (
    <>
      <UpdateCredentials
        onHide={() => setShowEditModal(false)}
        showEditModal={showEditModal}
        organizationId={organization.id}
        subscribedPromises={subscribedPromises}
        assessApiKeys={initialValues}
        updateAssessAPIKeysAfterApi={updateAssessAPIKeysAfterApi}
      />
      <AddNewCredentials
        showAddModal={showAddModal}
        onHide={() => setShowAddModal(false)}
        organizationId={organization.id}
        updateAssessAPIKeysAfterApi={updateAssessAPIKeysAfterApi}
        subscribedPromises={subscribedPromises}
      />
      <DeleteCredentials
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        assessApiKeys={initialValues}
        deleteCredentials={deleteCredentials}
      />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p>
          <strong>Assess API keys</strong>
        </p>
        {isUserAdmin && (
          <Button
            size="md"
            variant="primary"
            onClick={() => setShowAddModal(true)}
            className="px-3"
          >
            Add New
          </Button>
        )}
      </div>
      {showError !== "" ? (
        <Alert variant="info" className={`my-3 d-flex flex-row`}>
          <div className="me-3">
            <span
              translate="no"
              className="material-symbols-outlined md-18 text-primary text-opacity-75"
            >
              info
            </span>
          </div>
          <div>
            <h5 className="mb-1">
              <small>Error</small>
            </h5>
            <p className="mb-1">
              <small>{showError}</small>
            </p>
          </div>
        </Alert>
      ) : (
        <AssessCredentialsTable
          assessApiKeys={assessApiKeys}
          isUserAdmin={isUserAdmin}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
        />
      )}
      {dataNotAvailable && !loading && (
        <Alert variant="info" className="d-flex flex-row mb-5">
          <div className="me-3">
            <span
              translate="no"
              className="material-symbols-outlined md-18 text-primary text-opacity-75"
            >
              info
            </span>
          </div>
          <div>
            <h5 className="mb-1">
              <small>No data to visualize</small>
            </h5>
            <p className="mb-1">
              <small>{showError || "You have no keys setup."}</small>
            </p>
          </div>
        </Alert>
      )}
    </>
  );
}
