import * as yup from "yup";

const actionItemFileValidationSchema = yup.object().shape({
  file: yup
    .mixed()
    .required("A file is required")
    .test(
      "fileFormat",
      "Invalid file format, file must be  image or pdf",
      (value) => value //&& SUPPORTED_FORMATS.includes(value.type)
    ),
});

export default actionItemFileValidationSchema;
