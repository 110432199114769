import { useEffect, useRef, useState } from "react";

import { Button, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";

const JoinOrganization = ({ invitationId, onJoined }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const joinOrganization = () => {
    setError(null);
    setIsLoading(true);
    const invitationPromise = put(`/invitations/${invitationId}`);
    invitationPromise.promise
      .then(({ data }) => onJoined(data))
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(invitationPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isLoading) return <Spinner animation="border" size="sm" />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <Button
      size="sm"
      onClick={joinOrganization}
      variant="primary"
      className="p-0 mb-1 px-3 text-primary bg-primary bg-opacity-25 border-0"
    >
      Join
    </Button>
  );
};

export default JoinOrganization;
