import React, { useEffect } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import "./ActionItemCommentUpdate.scss";
import ErrorHandler from "components/ui/ErrorHandler";
import { useUpdateActionItemComment } from "features/actionItems/services";

const ActionItemCommentUpdate = ({ comment, onActionItemCommentUpdated }) => {
  const { handleChange, handleBlur, values, touched, errors, setFieldValue } =
    useFormikContext();
  const { body } = comment;

  const { isLoading, error, updateActionItemComment } =
    useUpdateActionItemComment({
      comment: comment,
      onActionItemCommentUpdated,
    });

  useEffect(() => {
    setFieldValue("updateComment", body || "");
  }, [body, setFieldValue]);

  return (
    <>
      <Form.Group className="mb-3 mt-1" controlId="updateComment">
        <Form.Control
          onChange={handleChange}
          onBlur={handleBlur}
          name="updateComment"
          type="text"
          placeholder="Write a comment"
          as="textarea"
          rows={3}
          value={values?.updateComment}
          isValid={values?.updateComment && !error?.updateComment}
          isInvalid={
            !(values?.updateComment && !errors?.updateComment) &&
            touched?.updateComment
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors?.updateComment && touched?.updateComment ? (
            <small>{errors?.updateComment}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>

      {error && <ErrorHandler error={error} />}
      <Col xs={12} className="text-end">
        <Button
          onClick={() => updateActionItemComment(values?.updateComment)}
          size="sm"
          disabled={isLoading || !values?.updateComment}
        >
          {isLoading && (
            <Spinner
              className="me-2"
              animation="border"
              size="sm"
              variant="light"
            />
          )}
          <span>Update</span>
          <span translate="no" className="material-symbols-outlined md-18 ms-2">
            send
          </span>
        </Button>
      </Col>
    </>
  );
};

ActionItemCommentUpdate.propTypes = {
  actionItemId: PropTypes.string,
  onActionItemCommentUpdated: PropTypes.func.isRequired,
};

export default ActionItemCommentUpdate;
