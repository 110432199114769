import { useRoles } from "hooks";
import PropTypes from "prop-types";

import DataExportForm from "./DataExportForm";

const DataExport = ({ variant, level = "" }) => {
  const { isCertifier } = useRoles();

  if (isCertifier) return <></>;

  return <DataExportForm variant={variant} level={level} />;
};

DataExport.propTypes = {
  variant: PropTypes.string,
  level: PropTypes.string,
};

export default DataExport;
