import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import "./PrinciplesScoresGuage.scss";
import PropTypes from "prop-types";

const PrinciplesScoresGuage = ({
  title,
  score,
  size,
  setChartImage,
  observationTitle,
}) => {
  const el = useRef(null);

  useEffect(() => {
    const gaugeData = [
      {
        value: score,
        title: {
          offsetCenter: ["0%", "0%"],
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ["0%", "0"],
        },
      },
    ];

    const option = {
      toolbox: {
        showTitle: false,
        left: 85,
        feature: !!setChartImage
          ? null
          : {
              saveAsImage: {
                name: `Scores By Principles`,
              },
            },
      },

      series: [
        {
          type: "gauge",
          startAngle: 90,
          endAngle: -270,
          radius: "70%",
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,

            clip: false,
            itemStyle: {
              color: "#018219",
            },
          },
          axisLine: {
            lineStyle: {
              width: 10,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 20,
          },
          data: gaugeData,
          title: {
            fontSize: setChartImage ? 30 : 14,
          },
          detail: {
            width: 10,
            height: 14,
            fontSize: 14,
            color: "#018219",
            formatter: "{value}",
          },
        },
      ],
    };

    const dimension = {
      width: el.current.offsetWidth || 108,
      height: el.current.offsetWidth || 108,
    };

    const chart = echarts.init(el.current, null, dimension);
    if (setChartImage) {
      chart.resize({
        width: 200,
        height: 200,
      });

      chart.on("finished", () => {
        var img = new Image(200, 200);
        img.src = chart.getDataURL({
          pixelRatio: 1,
          backgroundColor: "#fff",
        });
        setChartImage((prev) => [
          ...prev,
          { img: img.src, title, observationTitle },
        ]);
        chart.dispose();
      });
    }

    chart.setOption(option);

    function handleResize() {
      if (el.current.offsetWidth) {
        chart.resize({
          width: el.current.offsetWidth,
          height: el.current.offsetWidth,
        });
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, score, size]);

  return (
    <div className="ScoreGuages">
      <p className="text-center text-nowrap text-truncate">
        <small>{!setChartImage && title}</small>
      </p>
      <div className={`principle-score-container mx-auto ${size}`} ref={el} />
    </div>
  );
};

PrinciplesScoresGuage.propTypes = {
  title: PropTypes.string,
  score: PropTypes.string || PropTypes.number,
  size: PropTypes.string,
};

export default PrinciplesScoresGuage;
