import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useRef, useEffect, useState } from "react";
import { get } from "utils/DeApi";
import { uniqBy } from "lodash";

const useFetchPortfolios = () => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [error, setError] = useState();

  const [isLoading, setIsLoading] = useState();
  const [portfolios, setPortfolios] = useState();

  useEffect(() => {
    const fetchPortfolios = () => {
      setError(null);
      setIsLoading(true);

      const statsPromise = get(`organizations/${organization?.id}/portfolios`);
      statsPromise.promise
        .then(({ data: portfolios }) => {
          setPortfolios(
            uniqBy(
              portfolios.map((p) => ({
                ...p,
                facilities: [],
              })),
              "id"
            ) ?? []
          );
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(statsPromise);
    };

    if (organization?.id) fetchPortfolios();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id]);

  return { error, isLoading, portfolios, setPortfolios };
};

export default useFetchPortfolios;
