import * as yup from "yup";
const auditFilesUpdateValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name is too Short!")
    .max(255, "Name is too Long!"),
  description: yup
    .string()
    .required("Description is required")
    .min(2, "Description is too Short!")
    .max(255, "Description is too Long!"),
});
export default auditFilesUpdateValidationSchema;
