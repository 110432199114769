import React, { useState } from "react";

import PropTypes from "prop-types";
import { Button, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";
import { useDeleteActionItemFiles } from "features/actionItems/services";

const EvidenceFileDelete = ({ file, onEvidenceFileDeleted }) => {
  const [show, setShow] = useState(false);

  const fileURL = file.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { isLoading, error, deleteFile } = useDeleteActionItemFiles({
    file,
    onEvidenceFileDeleted: (val) => {
      onEvidenceFileDeleted(val);
      handleClose();
    },
  });

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        className="border"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-18">
          delete
        </span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete evidence file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this item?</p>
          <div className="d-flex flex-row">
            <div className="me-2">
              <a href={fileURL} target="_blank" rel="noreferrer">
                <span
                  className={`fiv-sqo fiv-size-md fiv-icon-${fileEXT}`}
                ></span>
              </a>
            </div>
            <div className="flex-fill">
              <small>
                <a
                  href={file.url}
                  target="_blank"
                  rel="noreferrer"
                  className=" text-trancate"
                >
                  {file.fileName}
                </a>
              </small>
            </div>
          </div>
          {error && show && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={deleteFile}
            size="sm"
            disabled={isLoading}
            variant="danger"
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EvidenceFileDelete.propTypes = {
  file: PropTypes.object.isRequired,
  onEvidenceFileDeleted: PropTypes.func.isRequired,
};

export default EvidenceFileDelete;
