import { truncate } from "lodash";
import { Dropdown } from "react-bootstrap";

const ProtocolFilterDropdown = ({
  selected,
  handleOnClick,
  options = [],
  isLoading = false,
}) => {
  return (
    <Dropdown translate="no">
      <Dropdown.Toggle
        variant="outline-secondary"
        id="time-interval-filter"
        className="border btn-block w-100 text-start"
        size="sm"
      >
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          date_range
        </span>
        {isLoading
          ? "Loading..."
          : truncate(selected?.name) ?? "Filter by protocol"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((p) => (
          <Dropdown.Item
            active={p.protocolId === selected?.protocolId}
            key={p.protocolId}
            onClick={() => {
              if (typeof handleOnClick === "function") handleOnClick(p);
            }}
          >
            {p?.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProtocolFilterDropdown;
