import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useFormikContext } from "formik";
import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";

const ActionItemTitleField = forwardRef(function ActionItemTitleField(
  props,
  ref
) {
  const { handleBlur, handleChange, values, errors, touched } =
    useFormikContext();
  return (
    <Form.Group controlId="item" className="mb-3">
      <Form.Label className="mb-1">
        Title <RequiredAsterisk />
      </Form.Label>
      <Form.Control
        ref={ref}
        name="item"
        value={values.item}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={values.item && !errors.item}
        isInvalid={!(values.item && !errors.item) && touched.item}
        placeholder="Enter action item"
      />
      <Form.Control.Feedback type="invalid">
        {errors.item && touched.item ? <small>{errors.item}</small> : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

export default ActionItemTitleField;
