import { useContext } from "react";

import { useRoles } from "hooks";
import TagManager from "react-gtm-module";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { UserContext } from "contexts/UserProvider";
import Footer from "layouts/Footer";
import Header from "layouts/Header";

const pmfGroupId = process.env.REACT_APP_PMF_GROUP_ID;

/** Middleware for private routes. */
const PrivateRoute = () => {
  const location = useLocation();
  const { roleName } = useRoles();
  const user = useContext(UserContext);

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const { categories } = user ?? [];

  let categoryId = "";
  let categoryName = "";

  const categoryFound = categories.find(({ groups }) =>
    groups.some((tmpGroup) => tmpGroup.groupId === pmfGroupId)
  );

  if (categoryFound) {
    categoryId = categoryFound.categoryId;
    categoryName = categoryFound.categoryName;

    const segmentationDataLayer = {
      dataLayer: {
        visitorType: categoryName,
        visitorTypeId: categoryId,
        visitorRole: roleName,
      },
      dataLayerName: "SegmentationDataLayer",
    };
    TagManager.dataLayer(segmentationDataLayer);
  }

  return (
    <>
      <Header isProfileComponent={location?.pathname?.includes("profile/")} />
      <Outlet />
      <Footer />
    </>
  );
};

export default PrivateRoute;
