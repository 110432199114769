import React, { useEffect, useRef } from "react";
import "./ReportTrend.scss";
import * as echarts from "echarts";
import PropTypes from "prop-types";

const ReportTrend = ({ data = {}, labels = {}, chartHeight }) => {
  const el = useRef(null);

  useEffect(() => {
    const configSeries = Object.keys(data)
      .map((key) => {
        return key;
      })
      .map((key) => {
        if (key === "timeline") return null;
        return {
          name: labels[key] ? labels[key] : key,
          type: "bar",
          barMaxWidth: 32,
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: data[key],
        };
      })
      .filter(Boolean);

    const dataAvailable = configSeries.filter(
      ({ data }) => data.filter((v) => v > 0).length
    );

    const option = {
      title: {
        show: dataAvailable.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "30%",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },

      legend: {
        orient: "horizontal",
        show: true,
        itemStyle: {
          borderCap: "butt",
          borderJoin: "round",
        },
        icon: "circle",
        textStyle: {
          fontSize: 14,
        },
      },
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      xAxis: [
        {
          type: "category",
          data: data.timeline,
          axisTick: {
            show: true,
            alignWithLabel: true,
            length: 8,
          },
          axisLabel: {
            showMaxLabel: true,
            width: 96,
            // interval: 0,
            ellipsis: true,
            overflow: "truncate",
            rotate: 90, //If the label names are too long you can manage this by rotating the label.
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
        },
      ],
      series: Array.isArray(configSeries) ? configSeries : [],
    };

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: chartHeight || 270,
    });
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: chartHeight || 270,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data, labels]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
};

ReportTrend.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReportTrend;
