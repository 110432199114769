import React, { useEffect, useRef } from "react";
import "./ReportAggregation.scss";
import * as echarts from "echarts";
import PropTypes from "prop-types";

const CHART_HEIGHT = 270;

const ReportAggregation = ({
  data = [],
  size,
  labels = {},
  piechartRef,
  tooltipTitle = "",
}) => {
  const el = useRef(null);

  useEffect(() => {
    const hasNoData = data.filter(({ value }) => value > 0).length === 0;
    const option = {
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />{d0}%",
      },
      legend: {
        type: "scroll",
        orient: "horizontal",
        icon: "circle",
        padding: 0,
        textStyle: {
          fontSize: 14,
        },
      },
      series: [
        {
          name: tooltipTitle ?? "Risk Aggregation",
          type: "pie",
          radius: "50%",
          label: {
            show: true,
            formatter: "{b}: {c}\n({d}%)",
            fontSize: 10,
          },
          labelLine: {
            show: true,
            length: 15,
            length2: 10,
          },
          percentPrecision: 1,
          data: data.map((data) => {
            return {
              ...(hasNoData ? {} : data),
              name: labels[data?.name] ? labels[data?.name] : data?.name,
            };
          }),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    // If there's no data, display No data available
    if (hasNoData) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
    } else {
      // Show sum of all data at the bottom
      const sum = data.reduce((prev, current) => prev + current.value, 0);

      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 15,
        },
        text: `Total: ${sum}`,
        left: "center",
        top: "bottom",
      };
    }

    // echarts.registerTheme("wonderland", wonderland);

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: CHART_HEIGHT,
    });
    chart.setOption(option);
    if (piechartRef) piechartRef.current = chart;
    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: CHART_HEIGHT,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data, piechartRef, tooltipTitle, labels]);

  return (
    <div className="chart-container" ref={piechartRef}>
      <div className="w-100" ref={el} />
    </div>
  );
};

ReportAggregation.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ReportAggregation;
