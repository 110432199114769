import React, { useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Button, Col, Dropdown, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { useFetchActionItemComment } from "features/actionItems/services";
import { useRoles } from "hooks";
import { sortBy } from "features/actionItems/utils";
import {
  ActionItemCommentDelete,
  ActionItemCommentUpdate,
} from "../../ActionItemComment";
import "./ActionItemUpdateComment.scss";

const ActionItemUpdateComment = ({
  actionItemComments,
  setActionItemComments,
}) => {
  const user = useContext(UserContext);
  const {
    values: { step, actionItem },
  } = useFormikContext();

  const [showUpdateForm, setShowUpdateForm] = useState("");
  const [sortValue, setSortValue] = useState(sortBy[0]);

  const {
    isLoading,
    error,
    actionItemComments: data,
  } = useFetchActionItemComment({
    expand: step === 2,
    actionItemId: actionItem?.actionItemId,
  });

  const { isAdmin } = useRoles();

  const commentCreator = (user, subscriber) => {
    return user?.subscriberId === subscriber?.subscriberId;
  };

  const onActionItemCommentUpdated = (updatedComment) => {
    setActionItemComments((prevComments) => {
      return prevComments.map((comment) => {
        if (updatedComment?.id === comment?.id) return updatedComment;
        return comment;
      });
    });

    setShowUpdateForm("");
  };

  useEffect(() => {
    if (!!data?.length) setActionItemComments(data);
  }, [data, setActionItemComments]);

  return (
    <div className="vh-100">
      {!isLoading &&
        !error &&
        Array.isArray(actionItemComments) &&
        !actionItemComments.length && (
          <EmptyStateHandler
            title="No Action Item Comments"
            description="There are currently no comments to display."
          />
        )}
      {isLoading && <Loader />}
      {error && <ErrorHandler error={error} />}

      {!!actionItemComments?.length && (
        <Row className="mb-3 text-secondary fs-5">
          <Col className="d-flex align-items-center justify-content-end">
            Sort By:
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-done"
                className="ms-2 text-start border border-gray-900 bg-white text-secondary"
              >
                {sortValue?.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortBy.map((s) => (
                  <Dropdown.Item
                    key={s.index}
                    active={s.index === sortValue.index}
                    onClick={() => {
                      setActionItemComments((prevActionItemComments) => {
                        setSortValue(s);
                        return prevActionItemComments?.sort((a, b) => {
                          return s?.value === "oldestToNewest"
                            ? new Date(a.createdAt) - new Date(b.createdAt)
                            : new Date(b.createdAt) - new Date(a.createdAt);
                        });
                      });
                    }}
                  >
                    {s.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      )}
      <Row className="comments-overflow">
        {Array.isArray(actionItemComments) &&
          actionItemComments.map((comment) => {
            const { id, subscriber, body, createdAt, updatedAt } = comment;
            return (
              <Row className="mb-3" key={id}>
                <Col xs={1} className="me-2">
                  <div className="avatar d-flex align-items-center justify-content-center rounded-circle bg-primary bg-opacity-10 text-primary text-uppercase">
                    <strong>
                      {subscriber?.firstName?.[0]}
                      {subscriber?.lastName?.[0]}
                    </strong>
                  </div>
                </Col>
                <Col>
                  <Col className="d-flex justify-content-between align-items-end">
                    <h5 className="text-secondary text-capitalize">
                      {subscriber?.firstName} {subscriber?.lastName}
                    </h5>
                    <div>
                      {commentCreator(user, subscriber) && (
                        <>
                          {showUpdateForm !== id ? (
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => {
                                setShowUpdateForm(id);
                              }}
                            >
                              <span
                                translate="no"
                                className="material-symbols-outlined md-18"
                              >
                                edit
                              </span>
                            </Button>
                          ) : (
                            <Button
                              size="sm"
                              variant="outline-secondary"
                              onClick={() => {
                                setShowUpdateForm("");
                              }}
                            >
                              <span
                                translate="no"
                                className="material-symbols-outlined md-18"
                              >
                                close
                              </span>
                            </Button>
                          )}
                        </>
                      )}
                      {(isAdmin || commentCreator(user, subscriber)) && (
                        <ActionItemCommentDelete
                          setActionItemComments={setActionItemComments}
                          commentId={comment?.id}
                          actionItemId={actionItem?.actionItemId}
                        />
                      )}
                    </div>
                  </Col>
                  {showUpdateForm !== id ? (
                    <>
                      <p className="mb-2 text-break text-wrap">{body}</p>
                      <p className="text-muted">
                        <small className="me-1">
                          {new Date(updatedAt).toLocaleString([], {
                            dateStyle: "short",
                            timeStyle: "short",
                          })}
                        </small>
                        {createdAt !== updatedAt && <span> · edited</span>}
                      </p>
                    </>
                  ) : (
                    <ActionItemCommentUpdate
                      comment={comment}
                      onActionItemCommentUpdated={onActionItemCommentUpdated}
                    />
                  )}
                  <hr />
                </Col>
              </Row>
            );
          })}
      </Row>
    </div>
  );
};

ActionItemUpdateComment.propTypes = {
  actionItemComments: PropTypes.array,
  setActionItemComments: PropTypes.func,
};

export default ActionItemUpdateComment;
