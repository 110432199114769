import { Link } from "react-router-dom";
import { Button, Navbar, Container, Row, Col } from "react-bootstrap";

function ErrorBoundary() {
  const isVertical = !!process.env.REACT_APP_VERTICAL;

  return (
    <>
      <Navbar expand="xl" fixed="top" className="MainHeader bg-white py-3">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img
              src={`${
                isVertical ? "/erm-logo-green-empty.svg" : "/logo-online.svg"
              }`}
              alt="Logo"
            />
            {isVertical && (
              <span className="align-middle ms-2">
                {process.env.REACT_APP_VERTICAL}
              </span>
            )}
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Container
        className="d-flex flex-column min-vh-100 justify-content-md-center mt-5 mt-md-0 pt-5"
        style={{ paddingBottom: "15rem" }}
      >
        <Row className="align-items-center justify-content-center">
          <Col xs={12} md={{ span: 5, offset: 0 }} className="text-center px-5">
            <img
              src={`/error-screen.svg`}
              alt="Illustration of Trees Blowing in the Wind. Image taken from https://storyset.com/people"
              className="img-fluid"
              style={{ maxWidth: "300px", height: "auto" }}
            />
          </Col>
          <Col
            xs={12}
            md={5}
            className="text-center text-md-start mt-4 mt-md-0 px-0"
          >
            <p className="mb-0 fs-1 lh-sm">
              We cannot find the page you're looking for.
            </p>
            <p className="mb-4 fs-1 lh-sm">
              Click the button below to refresh.
            </p>
            <Button
              variant="primary"
              size="sm"
              onClick={() => window.location.reload()}
              className="mb-5 px-5"
            >
              Refresh
            </Button>
            <p>
              <small>
                If the issue persists, contact{" "}
                <a
                  href={`mailto:support.assess@erm.com?subject=Page crashed due to unexpected error&body=Hello,%0A%0AI%20have%20experienced%20the%20following%20issue%20while%20using%20ERM%20Assess:%0A%0A`}
                >
                  ERM Support & Sustain
                </a>{" "}
                for help.
              </small>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ErrorBoundary;
