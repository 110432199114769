import { useFormikContext } from "formik";
import { useRoles } from "hooks";
import React, { useEffect } from "react";
import { Col, Form, Spinner } from "react-bootstrap";
import { useFetchFacilities } from "services";

const EntityField = () => {
  const { isMember } = useRoles();

  const {
    values: { protocolId, facilityId, reset },
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const { isLoading, error, entities } = useFetchFacilities({
    protocolId,
  });

  const handleEntityChange = (facilityId) => {
    setFieldValue("facilityId", facilityId);
    setFieldValue("sortValue", {
      index: 0,
      name: "A - Z",
      value: "name",
    });
    setFieldValue("assessments", []);
    setFieldTouched("assessments", false, false);
    setFieldValue("audits", []);
    reset();
  };

  useEffect(() => {
    setFieldValue("error", error);
  }, [error, setFieldValue]);

  if (!isMember) return <></>;

  return (
    <Col>
      <Form.Group controlId="facilityId" className="mt-0 mb-3">
        <Form.Label className="mb-1 me-2 fw-semibold ">
          Select Entity
          {isLoading && (
            <Spinner
              className="ms-2"
              animation="border"
              size="sm"
              variant="dark"
            />
          )}
        </Form.Label>
        <Form.Select
          disabled={!protocolId}
          onChange={(e) => handleEntityChange(e.target.value)}
          onBlur={handleBlur}
          name="facilityId"
          value={facilityId}
          isValid={facilityId && !errors.facilityId}
          isInvalid={!(facilityId && !errors.facilityId) && touched.facilityId}
        >
          <option key="" hidden value>
            Select Entity
          </option>
          {entities?.map((f) => (
            <option key={f.facilityId} value={f.facilityId}>
              {f.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  );
};

export default EntityField;
