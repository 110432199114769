import { useCopyToClipboard } from "hooks";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

const LinkField = ({ links = [] }) => {
  const { handleCopy, setIsCopied } = useCopyToClipboard();
  const [copiedLink, setCopiedLink] = useState("");

  const onCopyLink = (link) => {
    handleCopy(link);
    setCopiedLink(link);
  };

  useEffect(() => {
    if (!links?.length) {
      setCopiedLink("");
      setIsCopied(false);
    }
  }, [links?.length, setIsCopied]);

  if (!Array.isArray(links) || !links?.length) return <></>;
  return (
    <div className="mb-2">
      <Form.Group>
        <Form.Label className="mb-1 me-2 fw-semibold ">
          Data Link{" "}
          <p className="text-muted mb-0 fw-normal">
            <small>
              <span translate="no" className="material-symbols-outlined md-18">
                info
              </span>{" "}
              Assessments are divided into multiple links. Single link contains
              maximum 50 assessments.
            </small>
          </p>
        </Form.Label>
        {links?.map((link, ind) => (
          <div key={ind} className="d-flex flex-row mb-2">
            <div className="flex-fill">
              <Form.Control type="text" readOnly disabled value={link} />
            </div>
            <div>
              <Button
                onClick={() => onCopyLink(link)}
                size="sm"
                className="ms-2"
                variant="outline-secondary"
              >
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 me-2"
                >
                  content_copy
                </span>
                {copiedLink === link ? "Copied!" : "Copy"}
              </Button>
            </div>
          </div>
        ))}
      </Form.Group>
    </div>
  );
};

export default LinkField;
