import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { destroy } from "utils/DeApi";

const useDeleteMemo = ({ memoId, onMemoDeleted }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const deleteMemo = (values) => {
    setIsLoading(true);
    const { key, value } = values;
    const memoPromise = destroy(`/memos/${memoId}`, {
      key,
      value,
    });

    memoPromise.promise
      .then(({ data }) => {
        if (typeof onMemoDeleted === "function") onMemoDeleted(data);
        toast.success("Success", "Notepad has been deleted successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(memoPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, deleteMemo };
};

export default useDeleteMemo;
