import { useContext } from "react";
import {
  OrganizationContext,
  OrganizationsContext,
} from "contexts/OrganizationProvider";
import { NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "contexts/UserProvider";
import { Invitations } from "features/organization";
import SwitchOrganization from "../SwitchOrganization";
import { usePostOrganizationSelected } from "features/organization/services";

function OrganizationToggler() {
  const { postOrganizationSelected } = usePostOrganizationSelected();
  const user = useContext(UserContext);
  // The Organization Toggler receives not only the organization
  // but also a toggleOrganization function from the context
  const organization = useContext(OrganizationContext);
  const organizations = useContext(OrganizationsContext);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const orgId = urlParams.get("organizationId");

  if (!!orgId && orgId !== organization.id) return <SwitchOrganization />;
  if (!organization?.id) return <></>;

  return (
    <>
      <NavDropdown
        title={<span>{organization.name}</span>}
        className="OrganizationToggler px-2 rounded-2 mt-3 mt-lg-0"
      >
        {Array.isArray(organizations) &&
          organizations
            .slice()
            .sort((a, b) =>
              a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
            )
            .map((org) => (
              <NavDropdown.Item
                className="org-item"
                key={org.id}
                active={org.id === organization.id}
                as={Link}
                to={`/organizations/${org?.id}`}
                onClick={() => postOrganizationSelected(org?.id)}
              >
                {org?.name}
              </NavDropdown.Item>
            ))}{" "}
        <NavDropdown.Divider />
        <NavDropdown.Item
          as={Link}
          to={`/profile/${user?.userId}/organizations`}
        >
          All Organizations
        </NavDropdown.Item>
      </NavDropdown>
      <Invitations />
    </>
  );
}

export default OrganizationToggler;
