import getActionItemsStats from "./getActionItemsStats";

const getChartData = (data, filterStatus) => {
  const visualData = data.map((item) => {
    const values = { period: item.period, month: item.month, year: item.year };

    const dueAndOverDue = {
      ...values,
      actionItems: {
        due: Number.parseInt(item.due),
        overdue: Number.parseInt(item.overdue),
      },
    };
    const status = !!filterStatus.key
      ? {
          ...values,
          actionItems: {
            [filterStatus.key]: Number.parseInt(item[filterStatus.key]),
          },
        }
      : {
          ...values,
          actionItems: {
            assigned: Number.parseInt(item.assigned),
            submitted: Number.parseInt(item.submitted),
            incomplete: Number.parseInt(item.incomplete),
            accepted: Number.parseInt(item.accepted),
          },
        };

    return {
      status,
      dueAndOverDue,
    };
  });

  const actionItemStats = visualData.map(({ status }) => status);

  const dueAndOverDueChartData = getActionItemsStats(
    visualData.map(({ dueAndOverDue }) => dueAndOverDue)
  );
  const statusChartData = getActionItemsStats(actionItemStats);
  return {
    dueAndOverDueChartData,
    statusChartData,
    actionItemStats,
  };
};

export default getChartData;
