import { useRef, useState, useEffect } from "react";
import { get } from "utils/DeApi";

const useFetchCertifierComments = ({
  observationId,
  fetchByDefault = true,
}) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchComments = () => {
      setError("");
      setIsLoading(true);

      const commentPromise = get(`observations/${observationId}/comments`, {});
      commentPromise.promise
        .then((response) => {
          setComments(response.data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
      subscribedPromises.current.push(commentPromise);
    };

    if (observationId && fetchByDefault) fetchComments();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [observationId, fetchByDefault]);

  return {
    error,
    isLoading,
    comments,
    setComments,
  };
};

export default useFetchCertifierComments;
