import { UserContext } from "contexts/UserProvider";
import { useToast } from "hooks";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { put } from "utils/DeApi";
import { sanitizeHTML } from "utils/StringUtils";

const useUpdateActionItem = ({ actionItem, onActionItemUpdate }) => {
  const user = useContext(UserContext);

  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const updateActionItem = (values) => {
    const { item, description, assignedTo, tags, dueDate, done, status, type } =
      values;
    setError("");
    setIsLoading(true);

    const actionItemPromise = put(`action-items/${actionItem.actionItemId}`, {
      ...actionItem,
      item: item,
      status: +status ?? 0,
      description: sanitizeHTML(description),
      assignedTo: assignedTo?.length
        ? assignedTo.map(({ subscriberId }) => subscriberId)
        : [],
      assignedBy: actionItem?.assignedBy?.subscriberId,
      tags: tags?.length ? tags.map(({ name = "" }) => name.toLowerCase()) : [],
      done: done,
      doneBy: done
        ? actionItem?.doneBy?.subscriberId ?? user?.de?.subscriberId
        : actionItem?.doneBy?.subscriberId
        ? actionItem?.doneBy?.subscriberId
        : user?.de?.subscriberId,
      dueDate: moment(dueDate).format("YYYY-MM-DD HH:mm:ss"),
      taskableType: actionItem?.taskableType ?? null,
      taskableId: actionItem?.taskableId ?? null,
      ...(actionItem?.audit
        ? {
            audit_id: actionItem?.audit?.auditId,
          }
        : {}),
      path: actionItem?.path,
      action_item_type: parseInt(type),
    });
    actionItemPromise.promise
      .then((response) => {
        onActionItemUpdate(response.data);
        toast.success(
          "Success",
          "The action Item has been updated successfully"
        );
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(actionItemPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    updateActionItem,
    setError,
  };
};

export default useUpdateActionItem;
