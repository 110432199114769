import { useState, useRef, useEffect } from "react";
import { destroy } from "utils/DeApi";
const useDeleteAudit = ({ audit, onAuditDeleted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const deleteAudit = () => {
    setError("");
    setIsLoading(true);

    const auditPromise = destroy(`audits/${audit.auditId}`);

    auditPromise.promise
      .then(() => {
        onAuditDeleted(audit);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    isLoading,
    error,
    deleteAudit,
  };
};

export default useDeleteAudit;
