import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";
import PropTypes from "prop-types";

import { destroy } from "utils/DeApi";

const useDeleteFacilityAuditGroup = ({ groupId, onGroupDeleted }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const deleteFacilityAuditGroup = (group) => {
    setError(null);
    setIsLoading(true);
    const facilityGroupPromise = destroy(`/audit-groups/${groupId}`);
    facilityGroupPromise.promise
      .then(({ data }) => {
        if (typeof onGroupDeleted === "function") onGroupDeleted(data);
        toast.success("Sucess", "Entity assessment group deleted successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(facilityGroupPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    deleteFacilityAuditGroup,
  };
};

useDeleteFacilityAuditGroup.propTypes = {
  onGroupDeleted: PropTypes.func.isRequired,
};

export default useDeleteFacilityAuditGroup;
