import React, { useContext, useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import { post } from "utils/DeApi";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

const MemberCreate = ({ onMemberCreated }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setError(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const createMember = (values) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = post(`/organizations/${organization?.id}/members`, {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email.toLowerCase(),
      role: values.role,
    });
    auditPromise.promise
      .then(({ data: member }) => {
        handleClose();
        onMemberCreated(member);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  const schema = yup.object().shape({
    firstName: yup.string().min(2).max(255).required("First name is required"),
    lastName: yup.string().min(2).max(255).required("Last name is required"),
    email: yup.string().email().required("Member email is required"),
    role: yup.number().required("Role is required"),
  });

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm" className="px-3">
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        Add Member
      </Button>

      <Offcanvas
        show={show}
        placement="end"
        onHide={handleClose}
        className="w-fixed-640"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Member</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          <Formik
            validationSchema={schema}
            onSubmit={(values) => createMember(values)}
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              role: 0,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="d-flex flex-column justify-content-between h-100"
              >
                <div>
                  <Form.Group controlId="firstName" className="mb-3">
                    <Form.Label className="mb-1">
                      First name <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="John"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        !(values.firstName && !errors.firstName) &&
                        touched.firstName
                      }
                      isValid={values.firstName && !errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName && touched.firstName ? (
                        <small>{errors.firstName}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="lastName" className="mb-3">
                    <Form.Label className="mb-1">
                      Last name <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Doe"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        !(values.lastName && !errors.lastName) &&
                        touched.lastName
                      }
                      isValid={values.lastName && !errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName && touched.lastName ? (
                        <small>{errors.lastName}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="email" className="mb-3">
                    <Form.Label className="mb-1">
                      Member Email <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="example@email.com"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        !(values.email && !errors.email) && touched.email
                      }
                      isValid={values.email && !errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email && touched.email ? (
                        <small>{errors.email}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="risk" className="my-3">
                    <Form.Label>
                      Members Roles <RequiredAsterisk />
                    </Form.Label>
                    <br />
                    <Form.Check
                      inline
                      label={"Admin"}
                      name="role"
                      value={1}
                      checked={values.role === 1}
                      onChange={(event) => setFieldValue("role", 1)}
                      type="radio"
                      id={`checkbox-member`}
                    />
                    <Form.Check
                      inline
                      label={"Certifier"}
                      name="role"
                      value={3}
                      checked={values.role === 3}
                      onChange={(event) => setFieldValue("role", 3)}
                      type="radio"
                      id={`checkbox-member`}
                    />
                    <Form.Check
                      inline
                      label={"Member"}
                      name="role"
                      value={0}
                      checked={values.role === 0}
                      onChange={(event) => setFieldValue("role", 0)}
                      type="radio"
                      id={`checkbox-member`}
                    />
                    <Form.Check
                      inline
                      label={"Contributor"}
                      name="role"
                      value={4}
                      checked={values.role === 4}
                      onChange={(event) => setFieldValue("role", 4)}
                      type="radio"
                      id={`checkbox-member`}
                    />
                  </Form.Group>

                  {error && <ErrorHandler error={error} />}
                </div>

                <div className="text-end mt-auto">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    className="float-end px-3 ms-3"
                    disabled={
                      Object.keys(errors).length > 0 || isLoading || !isValid
                    }
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Add Member
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

MemberCreate.propTypes = {
  onMemberCreated: PropTypes.func.isRequired,
};

export default MemberCreate;
