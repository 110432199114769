import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { destroy } from "utils/DeApi";
import * as yup from "yup";
import { put, get } from "utils/DeApi";
import React, { useCallback, useEffect, useRef, useState } from "react";

export default function PowerBiCredentials({ organization }) {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState("");
  const [initialValues, setInitialValues] = useState({
    power_bi_client_id: "",
    client_secret: "",
  });
  const [credentialReport, setReportCredentials] = useState(null);

  const subscribedPromises = useRef([]);
  function updateReportsObject(requestBody) {
    setLoading(true);
    try {
      const findingsPromise = put(
        `/organizations/${organization?.id}/power-bi-credentials`,
        { ...requestBody }
      );
      findingsPromise.promise
        .then(async ({ data }) => {
          if (data && data.length < 1) {
            setReportCredentials(null);
            setInitialValues({
              power_bi_client_id: "",
              client_secret: "",
            });
          } else {
            setInitialValues({
              power_bi_client_id: data["power bi client id"] || "",
              client_secret: data["client secret"] || "",
            });
            setReportCredentials(data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setShowError(error.data.message);
        });
      subscribedPromises.current.push(findingsPromise);
    } catch (err) {
      setLoading(false);
      setShowError(err.data.message);
    }
  }

  const handleSave = (values, { setSubmitting }) => {
    setSubmitting(true);
    const requestBody = {
      power_bi_client_id: values.power_bi_client_id,
      client_secret: values.client_secret,
    };
    updateReportsObject(requestBody);
    setSubmitting(false);
  };
  const validationSchema = yup.object().shape({
    power_bi_client_id: yup.string().required("Power Bi client Id is required"),
    client_secret: yup.string().required("Client Secret is required"),
  });
  const deleteCredentials = async () => {
    setLoading(true);
    const findingsPromise = destroy(
      `/organizations/${organization?.id}/power-bi-credentials`
    );
    findingsPromise.promise
      .then(async ({ data }) => {
        setReportCredentials(null);
        setInitialValues({
          power_bi_client_id: "",
          client_secret: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        setShowError(error.data.message);
        setLoading(false);
      });
    subscribedPromises.current.push(findingsPromise);
  };

  useEffect(() => {
    if (credentialReport) {
      setInitialValues({
        power_bi_client_id: credentialReport["power bi client id"] || "",
        client_secret: credentialReport["client secret"] || "",
      });
    }
  }, [credentialReport]);

  const fetchEmbedReport = useCallback(async () => {
    setLoading(true);
    const findingsPromise = get(
      `/organizations/${organization?.id}/power-bi-credentials`
    );
    findingsPromise.promise
      .then(async ({ data }) => {
        if (data && data.length < 1) {
          setReportCredentials(null);
          setLoading(false);
          return;
        }
        setReportCredentials(data);
        setLoading(false);
      })
      .catch((error) => {
        setShowError(error.data.message);
        setLoading(false);
      });
    subscribedPromises.current.push(findingsPromise);
  }, [organization?.id]);

  useEffect(() => {
    fetchEmbedReport();
  }, [fetchEmbedReport]);
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div>
              <p className="mb-3">
                <strong>Power Bi Credentials</strong>
              </p>
              <Form.Group controlId="title" className="mb-3">
                <Form.Label>Power Bi Client Id</Form.Label>
                <Form.Control
                  type="text"
                  name="power_bi_client_id"
                  value={values.power_bi_client_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.power_bi_client_id && !!errors.power_bi_client_id
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.power_bi_client_id}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label>Client Secret</Form.Label>
                <Form.Control
                  type="text"
                  name="client_secret"
                  value={values.client_secret}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.client_secret && !!errors.client_secret}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.client_secret}
                </Form.Control.Feedback>
              </Form.Group>
              <p className="text-muted">
                Caution: Changing the credentials will not allow access to the
                reports from that workspace.
              </p>
              <div className="sticky-bottom p-3 text-end">
                <Button
                  size="md"
                  variant="outline-danger"
                  disabled={isSubmitting || loading || !credentialReport}
                  className="ms-2"
                  onClick={deleteCredentials}
                >
                  Delete
                </Button>
                <Button
                  size="md"
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting || loading}
                  className="ms-2"
                >
                  {isSubmitting ? "Saving..." : "Add Credentials"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showError !== "" && (
        <Alert variant="info" className={`my-3 d-flex flex-row`}>
          <div className="me-3">
            <span
              translate="no"
              className="material-symbols-outlined md-18 text-primary text-opacity-75"
            >
              info
            </span>
          </div>
          <div>
            <h5 className="mb-1">
              <small>Error</small>
            </h5>
            <p className="mb-1">
              <small>{showError}</small>
            </p>
          </div>
        </Alert>
      )}
    </>
  );
}
