import { OrganizationContext } from "contexts/OrganizationProvider";
import React, { useContext } from "react";
import { AssessCredentials, PowerBiCredentials } from "./index";

export default function OrganizationCredentials() {
  const organization = useContext(OrganizationContext);

  const extendedProps = { organization };
  return (
    <div className="overflow-auto p-3">
      <AssessCredentials {...extendedProps} />
      <PowerBiCredentials {...extendedProps} />
    </div>
  );
}
