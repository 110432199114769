import React, { useContext, useState } from "react";

import DOMPurify from "dompurify";
import { Tab, Tabs } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";

import QuestionResponseTable from "./QuestionResponseTable";

const ActionItemQuestionResponse = ({ question, handleCurrentPrompt }) => {
  const { audit } = useContext(AuditContext);
  const [key, setKey] = useState();
  const [open, setOpen] = useState({});

  const { questionId, prompt, observations = [] } = question || {};
  const { hasObservationSets } = audit?.protocol || {};

  const handleRowCollapse = () => {
    setOpen((prevStatus) => ({
      ...Object.keys(prevStatus).reduce(
        (attrs, key) => ({
          ...attrs,
          [key]: false,
        }),
        {}
      ),
      [questionId]: !prevStatus[questionId],
    }));

    setKey(observations[0]?.observationId);
  };

  return (
    <>
      <div
        className="cursor-pointer d-flex text-primary text-decoration-underline mb-3"
        onClick={() => {
          handleRowCollapse();
          if (typeof handleCurrentPrompt === "function")
            handleCurrentPrompt(prompt);
        }}
      >
        <div className="me-2">
          {!!open[questionId] ? (
            <span className="material-symbols-outlined md-20">expand_less</span>
          ) : (
            <span className="material-symbols-outlined md-20">expand_more</span>
          )}
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(prompt),
          }}
        />
      </div>

      {!!open[questionId] ? (
        <>
          {hasObservationSets ? (
            <Tabs
              activeKey={key}
              className="mb-3"
              onSelect={(k) => setKey(k)}
              id="actionItem-tab-multiple"
            >
              {observations?.map((observation, index) => (
                <Tab
                  key={observation?.observationId}
                  eventKey={observation?.observationId}
                  title={observation?.label || `Observation ${index + 1}`}
                >
                  <div className="bg-light border-start border-end border-top my-3">
                    <QuestionResponseTable observation={observation} />
                  </div>
                </Tab>
              ))}
            </Tabs>
          ) : (
            observations?.map((observation) => (
              <div
                key={observation?.observationId}
                className="bg-light border-start border-end border-top my-3"
              >
                <QuestionResponseTable observation={observation} />
              </div>
            ))
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActionItemQuestionResponse;
