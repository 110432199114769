import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { formatDate } from "features/actionItems/utils";

const DateField = () => {
  const {
    values: { protocolId, reset },
    setFieldValue,
    errors,
    touched,
  } = useFormikContext();

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
    range: null,
  });
  const { startDate, endDate, range } = date;

  const onDateChange = (range) => {
    const [startDate, endDate] = range;
    setDate((prev) => ({
      ...prev,
      startDate,
      endDate,
      range,
    }));
    setFieldValue("audits", []);
    setFieldValue("assessments", []);
    reset();
  };

  useEffect(() => {
    if (!startDate && !endDate) setFieldValue("updatedAt", "");
    if (startDate && endDate) setFieldValue("updatedAt", formatDate(range));
  }, [endDate, range, setFieldValue, startDate]);

  return (
    <Form.Group className="mb-3">
      <Form.Label className="mb-1 me-2 fw-semibold ">Select Dates</Form.Label>
      <DatePicker
        disabled={!protocolId}
        isClearable
        dateFormat="MM/dd/yyyy"
        selected={endDate}
        onChange={(date) => onDateChange(date)}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        placeholderText="Select Dates"
        monthsShown={2}
        className="form-control"
      >
        <Button
          disabled={!startDate && !endDate}
          size="sm"
          className="mb-1"
          onClick={() => {
            setDate({
              startDate: null,
              endDate: null,
              range: null,
            });
          }}
        >
          Clear
        </Button>
      </DatePicker>
      <Form.Control.Feedback type="invalid">
        {errors.updatedAt && touched.updatedAt ? (
          <small>{errors.updatedAt}</small>
        ) : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default DateField;
