import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { debounce, uniqBy } from "lodash";
import {
  Alert,
  Badge,
  Col,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import SortColumn from "components/ui/SortColumn";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

import MemberCreate from "./MemberCreate";
import MemberUpdate from "./MemberUpdate";
import MemberDelete from "./MemberDelete";
import MemberExport from "./MemberExport";
import ResendInvitation from "./ResendInvitation";
import "./Members.scss";

function Members() {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");
  const [perPage, setPerPage] = useState(25);

  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updated_at",
    sortAsc: false,
  });

  const [isNewMember, setIsNewMember] = useState(false);

  const handleError = (err) => setError(err);

  const sendSearchQuery = useRef(
    debounce((q) => {
      setPage(1);
      setDebouncedQuery(q);
    }, 1000)
  );

  const sortActionItems = (sortingvalue) => {
    setError(null);
    setIsLoading(true);
    setTableSortState({
      sortAsc: !tableSortState.sortAsc,
      sortColumn: sortingvalue,
    });
  };

  const fetchMembers = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const { sortAsc, sortColumn } = tableSortState;

    const membersPromise = get(`organizations/${organization?.id}/members`, {
      params: {
        page: page ? page : 1,
        perPage: perPage || 10,
        sort: !!sortAsc ? sortColumn : `-${sortColumn}`,
        "filter[search]": debouncedQuery ? debouncedQuery : "",
      },
    });
    membersPromise.promise
      .then(({ data, meta }) => {
        setMeta(meta);
        setMembers(uniqBy(data, "subscriberId"));
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(membersPromise);
  }, [tableSortState, organization?.id, page, perPage, debouncedQuery]);

  useEffect(() => {
    fetchMembers();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchMembers]);

  const handleMemberCreated = (member) => {
    fetchMembers();
    setIsNewMember(true);
  };

  const handleMemberUpdated = (member) => {
    fetchMembers();
    setIsNewMember(false);
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="mb-4">
        <Row>
          <Form.Group as={Col} lg={4} md={12} sm={12} xs={12} className="mb-3">
            <Form.Label>
              <small>Search by name or email</small>
            </Form.Label>
            <Form.Control
              type="text"
              value={query}
              onChange={(e) => {
                const q = e.target.value;
                setQuery(q);
                sendSearchQuery.current(q);
              }}
              placeholder="Search"
            />
          </Form.Group>
          <Col className="text-end pt-lg-4 mb-3">
            <MemberExport
              handleError={handleError}
              tableSortState={tableSortState}
              debouncedQuery={debouncedQuery}
            />
            <MemberCreate
              onMemberCreated={(member) => handleMemberCreated(member)}
            />
          </Col>
        </Row>
        <Table hover responsive>
          <thead>
            <tr>
              <th
                className="w-15"
                onClick={() => !isLoading && sortActionItems("first_name")}
              >
                First Name
                <SortColumn
                  tableSortState={tableSortState}
                  column={"first_name"}
                />
              </th>
              <th
                className="w-15"
                onClick={() => !isLoading && sortActionItems("last_name")}
              >
                Last Name
                <SortColumn
                  tableSortState={tableSortState}
                  column={"last_name"}
                />
              </th>
              <th
                className="w-15"
                onClick={() => !isLoading && sortActionItems("email")}
              >
                Email
                <SortColumn tableSortState={tableSortState} column={"email"} />
              </th>
              <th className="w-10">Role</th>
              <th className="w-7">Entities</th>
              <th
                className="w-15"
                onClick={() =>
                  !isLoading && sortActionItems("subscriber_joined_at")
                }
              >
                Joined
                <SortColumn
                  tableSortState={tableSortState}
                  column={"subscriber_joined_at"}
                />
              </th>
              <th
                className="w-15"
                onClick={() => !isLoading && sortActionItems("updated_at")}
              >
                Updated
                <SortColumn
                  tableSortState={tableSortState}
                  column={"updated_at"}
                />
              </th>
              <th className="w-15">Action</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {isLoading ? (
              <tr>
                <td colSpan={7}>
                  <Loader />
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={7}>
                  <ErrorHandler error={error} />
                </td>
              </tr>
            ) : Array.isArray(members) && !members.length ? (
              <tr>
                <td colSpan={7}>
                  <Alert variant="info" className={`my-3 d-flex flex-row`}>
                    <div className="me-3">
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18 text-primary text-opacity-75"
                      >
                        info
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-1">
                        <small>No account members </small>
                      </h5>
                      <p className="mb-1">
                        <small>
                          Account members will appear here one added
                        </small>
                      </p>
                    </div>
                  </Alert>
                </td>
              </tr>
            ) : (
              <Fragment />
            )}
            {members.map((subscriber) => {
              return (
                <tr key={subscriber.subscriberId}>
                  <td>{subscriber.firstName}</td>
                  <td>{subscriber.lastName}</td>
                  <td>{subscriber.email}</td>
                  <td>
                    {subscriber.invitation.role === "Owner" ? (
                      <Badge className="p-1 px-2 bg-warning bg-opacity-10 text-warning">
                        {subscriber.invitation.role}
                      </Badge>
                    ) : subscriber.invitation.role === "Admin" ? (
                      <Badge className="p-1 px-2 bg-primary bg-opacity-10 text-primary">
                        {subscriber.invitation.role}
                      </Badge>
                    ) : subscriber.invitation.role === "Certifier" ? (
                      <Badge className="p-1 px-2 bg-danger bg-opacity-10 text-danger">
                        {subscriber.invitation.role}
                      </Badge>
                    ) : (
                      <Badge className="p-1 px-2 bg-info bg-opacity-10 text-dark">
                        {subscriber.invitation.role}
                      </Badge>
                    )}
                  </td>
                  <td>{subscriber.facilitiesCount}</td>
                  <td>
                    {subscriber?.invitation?.subscriberJoinedAt ? (
                      <small>
                        {new Date(
                          subscriber?.invitation?.subscriberJoinedAt
                        ).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </small>
                    ) : (
                      <Badge
                        bg="warning"
                        className="p-1 px-2 bg-warning bg-opacity-10 text-warning"
                      >
                        Pending Invitation
                      </Badge>
                    )}
                  </td>
                  <td>
                    <small>
                      {new Date(subscriber.updatedAt).toLocaleString([], {
                        dateStyle: "short",
                        timeStyle: "short",
                      })}
                    </small>
                  </td>
                  <td>
                    <span className="d-flex">
                      {!subscriber?.invitation?.subscriberJoinedAt && (
                        <ResendInvitation subscriber={subscriber} />
                      )}
                      <MemberUpdate
                        onMemberUpdated={(member) =>
                          handleMemberUpdated(member)
                        }
                        member={subscriber}
                        isNewMember={isNewMember}
                      />{" "}
                      <MemberDelete
                        onMemberDeleted={(member) =>
                          setMembers((prevStatus) =>
                            prevStatus.filter((m) => {
                              return m.subscriberId !== member.subscriberId;
                            })
                          )
                        }
                        member={subscriber}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {meta && meta?.total > 0 && (
          <div className="d-flex flex-column">
            <div className="d-flex gap-2 align-items-end justify-content-between">
              <Pagination className="mb-1">
                {Array.isArray(meta?.links) &&
                  meta?.links.map((link, index) => {
                    return (
                      <Pagination.Item
                        key={crypto.randomUUID()}
                        active={link?.active}
                        disabled={!link?.url || isLoading}
                        onClick={() => {
                          if (link?.url) {
                            const searchableURL = new URL(link?.url);
                            const URLParams = new URLSearchParams(
                              searchableURL.search
                            );
                            setPage(URLParams.get("page"));
                          }
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: link?.label,
                          }}
                        />
                      </Pagination.Item>
                    );
                  })}
              </Pagination>
              <div className="d-flex flex-row mb-1">
                <div className="me-3 pt-1">
                  <small>
                    Showing {meta?.from} - {meta?.to} of {meta?.total}
                  </small>
                </div>
                <Form.Group className="mb-1">
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      setPerPage(e.target.value);
                    }}
                    disabled={isLoading}
                    value={perPage}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default Members;
