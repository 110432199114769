import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { put } from "utils/DeApi";

const useUpdateAuditArchive = ({ auditId, onAuditUpdated }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateAuditArchived = (isAchived) => {
    setError("");
    setIsLoading(true);

    const auditPromise = put(`/audits/${auditId}/archive`, {
      archived: !isAchived,
    });
    subscribedPromises.current.push(auditPromise);

    auditPromise.promise
      .then(({ data }) => {
        if (typeof onAuditUpdated === "function") {
          onAuditUpdated(data);
        }
        toast.success("Success", "Assessment was updated successfully");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    updateAuditArchived,
  };
};

export default useUpdateAuditArchive;
