import { useToast } from "hooks";
import PropTypes from "prop-types";
import { useContext, useRef, useState, useEffect } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { post } from "utils/DeApi";

const useCreateFacility = ({ onFacilityCreated }) => {
  const toast = useToast();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const createFacility = (facility) => {
    setError(null);
    setIsLoading(true);
    const facilityPromise = post("facilities", {
      ...facility,
      organizationId: organization?.id,
    });
    facilityPromise.promise
      .then(({ data }) => {
        if (typeof onFacilityCreated === "function") onFacilityCreated(data);
        toast.success("Success", "Entity created successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(facilityPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    createFacility,
  };
};

useCreateFacility.propTypes = {
  onFacilityCreated: PropTypes.func.isRequired,
};

export default useCreateFacility;
