const LEVELS = [
  {
    index: 0,
    name: "All",
    value: "",
  },
  {
    index: 1,
    name: "Organization Level",
    value: "organization",
  },
  {
    index: 2,
    name: "Entity Level",
    value: "facility",
  },
  {
    index: 3,
    name: "Assessment Level",
    value: "assessment",
  },
];

export default LEVELS;
