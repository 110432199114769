import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchObservationMemos = ({ observationId, fetchByDefault = true }) => {
  const subscribedPromises = useRef([]);
  const [memos, setMemos] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMemos = () => {
      setError("");
      setIsLoading(true);
      const memoPromise = get(`/observations/${observationId}/memos`);
      memoPromise.promise
        .then(({ data }) => {
          setMemos(data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(memoPromise);
    };

    if (observationId && fetchByDefault) fetchMemos();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [observationId, fetchByDefault]);

  return { error, isLoading, memos, setMemos };
};

export default useFetchObservationMemos;
