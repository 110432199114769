import { Alert, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";

const PortfoliosNav = ({ isLoading, error, portfolios, isAscOrder }) => {
  const { pathname = "" } = useLocation();

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!portfolios) return null;
  if (portfolios && !portfolios.length)
    return (
      <Alert variant="info" className={`my-3 d-flex flex-row`}>
        <div className="me-3">
          <span
            translate="no"
            className="material-symbols-outlined md-18 text-primary text-opacity-75"
          >
            info
          </span>
        </div>
        <div>
          <h5 className="mb-1">
            <small>No portfolios found</small>
          </h5>
          <p className="mb-1">
            <small>You have no portfolio to organize entities</small>
          </p>
        </div>
      </Alert>
    );

  return (
    <Nav defaultActiveKey={pathname} variant="pills">
      {portfolios
        .sort((a, b) =>
          isAscOrder
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        )
        .map((p) => {
          const href = `/portfolios/${p.id}`;
          const isActive = pathname === href;
          return (
            <Nav.Link
              key={p.id}
              as={Link}
              to={href}
              active={isActive}
              className="w-100"
            >
              <div className="d-flex flex-row" key={p.id}>
                <span translate="no" className=" w-100 text-truncate">
                  {p.name}
                </span>
                <div className="ms-2">
                  <span className="float-end">{p.facilitiesCount ?? 0}</span>
                </div>
              </div>
            </Nav.Link>
          );
        })}
    </Nav>
  );
};

export default PortfoliosNav;
