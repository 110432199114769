import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const ActionItemFileHistory = ({ removedFile, addedFile, type }) => {
  return (
    <Row className="">
      {type === "deleted" ? (
        <Col className="border-0">
          <span className="text-secondary fw-semibold">Removed File:</span>
          <span className="ms-2 fw-semibold">
            <Badge className="bg-info bg-opacity-10 text-info">
              {removedFile || "-"}
            </Badge>
          </span>
        </Col>
      ) : null}
      {type === "created" ? (
        <Col className="border-0">
          <span className="text-secondary fw-semibold">Added File:</span>
          <span className="ms-2 fw-semibold">
            <Badge className="bg-info bg-opacity-10 text-info">
              {addedFile}
            </Badge>
          </span>
        </Col>
      ) : null}
    </Row>
  );
};

ActionItemFileHistory.propTypes = {
  removedFile: PropTypes.string.isRequired,
  addedFile: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ActionItemFileHistory;
