import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

const LocationAndContactFields = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext();

  return (
    <>
      <Form.Group controlId="county" className="mb-3">
        <Form.Label className="mb-1">County</Form.Label>
        <Form.Control
          type="text"
          name="county"
          value={values.county}
          onBlur={handleBlur}
          onChange={handleChange}
          isValid={values.county && !errors.county}
          isInvalid={!!errors.county && touched.county}
        />
        <Form.Control.Feedback type="invalid">
          {errors.county && touched.county ? (
            <small>{errors.county}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="latitude" className="mb-3">
        <Form.Label className="mb-1">Latitude</Form.Label>
        <Form.Control
          type="text"
          name="latitude"
          onBlur={handleBlur}
          value={values.latitude}
          onChange={handleChange}
          isValid={values.latitude && !errors.latitude}
          isInvalid={!!errors.latitude && touched.latitude}
        />
        <Form.Control.Feedback type="invalid">
          {errors.latitude && touched.latitude ? (
            <small>{errors.latitude}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="longitude" className="mb-3">
        <Form.Label className="mb-1">Longitude</Form.Label>
        <Form.Control
          type="text"
          name="longitude"
          onBlur={handleBlur}
          value={values.longitude}
          onChange={handleChange}
          isValid={values.longitude && !errors.longitude}
          isInvalid={!!errors.longitude && touched.longitude}
        />
        <Form.Control.Feedback type="invalid">
          {errors.longitude && touched.longitude ? (
            <small>{errors.longitude}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="mailingAddress" className="mb-3">
        <Form.Label className="mb-1">Mailing Address</Form.Label>
        <Form.Control
          type="text"
          name="mailingAddress"
          onBlur={handleBlur}
          value={values.mailingAddress}
          onChange={handleChange}
          isValid={values.mailingAddress && !errors.mailingAddress}
          isInvalid={!!errors.mailingAddress && touched.mailingAddress}
        />
        <Form.Control.Feedback type="invalid">
          {errors.mailingAddress && touched.mailingAddress ? (
            <small>{errors.mailingAddress}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="entityPhoneNumber" className="mb-3">
        <Form.Label className="mb-1">Entity Phone Number</Form.Label>
        <Form.Control
          type="text"
          name="entityPhoneNumber"
          onBlur={handleBlur}
          value={values.entityPhoneNumber}
          onChange={handleChange}
          isValid={values.entityPhoneNumber && !errors.entityPhoneNumber}
          isInvalid={!!errors.entityPhoneNumber && touched.entityPhoneNumber}
        />
        <Form.Control.Feedback type="invalid">
          {errors.entityPhoneNumber && touched.entityPhoneNumber ? (
            <small>{errors.entityPhoneNumber}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="entity24hrPhoneNumber" className="mb-3">
        <Form.Label className="mb-1">Entity 24hrs Phone Number</Form.Label>
        <Form.Control
          type="text"
          name="entity24hrPhoneNumber"
          onBlur={handleBlur}
          value={values.entity24hrPhoneNumber}
          onChange={handleChange}
          isValid={
            values.entity24hrPhoneNumber && !errors.entity24hrPhoneNumber
          }
          isInvalid={
            !!errors.entity24hrPhoneNumber && touched.entity24hrPhoneNumber
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors.entity24hrPhoneNumber && touched.entity24hrPhoneNumber ? (
            <small>{errors.entity24hrPhoneNumber}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default LocationAndContactFields;
