import { Form } from "react-bootstrap";
import "./FilterLabel.scss";

const FilterLabel = ({ children }) => {
  return (
    <Form.Label>
      <small className="text-muted">{children}</small>
    </Form.Label>
  );
};

export default FilterLabel;
