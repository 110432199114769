import { useEffect, useRef, useState } from "react";
import { destroyWithBody } from "utils/DeApi";

const useDeleteActionItemComment = ({
  actionItemId,
  commentId,
  onActionItemCommentDeleted,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const deleteActionItemComment = () => {
    setError(null);
    setIsLoading(true);
    const actionItemCommentDeletePromise = destroyWithBody(
      `/action-items/${actionItemId}/comments`,
      {
        commentId,
      }
    );

    actionItemCommentDeletePromise.promise
      .then((response) => {
        setIsLoading(false);
        onActionItemCommentDeleted(commentId);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(actionItemCommentDeletePromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return {
    isLoading,
    error,
    deleteActionItemComment,
  };
};

export default useDeleteActionItemComment;
