import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchTags = ({ show = false }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [tags, setTags] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchTags = () => {
      setIsLoading(true);
      setError(null);
      const subscribersPromise = get(`organizations/${organization?.id}/tags`, {
        params: {
          organizationId: organization?.id,
        },
      });
      subscribersPromise.promise
        .then((response) => setTags(response.data ?? []))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (show && !tags.length) fetchTags();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id, show, tags.length]);

  return { tags, isLoading, error };
};

export default useFetchTags;
