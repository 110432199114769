import { useCallback, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchAudits = ({ facilityId }) => {
  const subscribedPromises = useRef([]);

  const [audits, setAudits] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAudits = useCallback((facilityId) => {
    setError(null);
    setIsLoading(true);
    const subscribersPromise = get(`facilities/${facilityId}/audits`, {
      params: {
        perPage: 100000000000,
      },
    });
    subscribersPromise.promise
      .then((response) => setAudits(response.data ?? []))
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(subscribersPromise);
  }, []);

  useEffect(() => {
    if (facilityId) fetchAudits(facilityId);
  }, [facilityId, fetchAudits]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    audits,
    error,
    isLoading,
  };
};

export default useFetchAudits;
