import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal, Button, Alert, Navbar, Form } from "react-bootstrap";
import "./Feedback.scss";
import { post } from "utils/BeeApi";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";

const Feedback = () => {
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [body, setBody] = useState("");
  const [bodyError, setBodyError] = useState("");

  function handleCloseModal() {
    setShow(false);
    setSuccess(false);
    setError("");
    setBody("");
    setBodyError("");
  }

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    setBody(value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    setIsLoading(true);
    setBodyError("");

    if (!body) {
      setIsLoading(false);
      setBodyError(true);
      return;
    }

    let message = `${body} \r\n \r\n Page URL: ${window.location.href}`;
    const contactPromise = post("/forms", {
      name: user && user.firstName,
      email: user && user.email,
      subject: `Feedback - ${
        window.location.pathname.split("/")[1] || "dashboard"
      }`,
      body: message,
    });

    contactPromise.promise
      .then((data) => setSuccess(true))
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(contactPromise);
  }

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      <Navbar.Text
        className="Feedback px-2 me-1 mt-1"
        onClick={() => setShow(true)}
      >
        <span
          translate="no"
          className="material-symbols-outlined md-18 me-2 mt-0"
        >
          chat
        </span>
        Feedback
      </Navbar.Text>
      <Modal show={show} onHide={handleCloseModal} backdrop="static">
        <form
          className="FeedbackForm"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Modal.Header closeButton>
            <Modal.Title>Send us your feedback!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                Do you have a question, comment or suggestion? Let us know.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="body"
                onChange={handleInputChange}
                value={body}
                isInvalid={bodyError ? "error" : null}
                placeholder="Describe your experience here..."
                required
              />
            </Form.Group>
            {error && <ErrorHandler error={error} />}
            {isLoading && <Loader error={isLoading} />}
            {success && (
              <Alert variant="success" className="my-3 d-flex flex-row">
                <div className="me-3">
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18"
                  >
                    check_circle
                  </span>
                </div>
                <div>
                  <h5 className="mb-1">
                    <small>Thanks</small>
                  </h5>
                  <p className="mb-1">
                    <small>
                      We appreciate that you’ve taken the time to help us
                      improve ERMAssess.
                    </small>
                  </p>
                </div>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            {success ? (
              <Button variant="primary" size="sm" onClick={handleCloseModal}>
                Close
              </Button>
            ) : (
              <>
                <Button variant="link" size="sm" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Feedback;
