import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { destroy } from "utils/DeApi";

const useDeleteActionItemFiles = ({ file, onEvidenceFileDeleted }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const deleteFile = () => {
    setError("");
    setIsLoading(true);

    const filePromise = destroy(`/media/${file?.id}`);

    filePromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        onEvidenceFileDeleted(file);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(filePromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization]);

  return {
    error,
    isLoading,
    deleteFile,
  };
};

export default useDeleteActionItemFiles;
