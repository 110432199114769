import React from "react";
import ActionItemLink from "./ActionItemLink";
import { useFetchQuestions } from "features/assessment";

const ActionItemLevelRedirect = (props) => {
  const { taskableType, path, facility, organizationId } = props || {};
  const { questionData } = useFetchQuestions({ ...props });

  const facilityData = [
    {
      taskableType: "Entity",
      path,
      name: facility || "N/A",
    },
  ];

  if (taskableType === "facility")
    return (
      <ActionItemLink data={facilityData} organizationId={organizationId} />
    );
  if (taskableType === "question")
    return (
      <ActionItemLink data={questionData} organizationId={organizationId} />
    );
};

export default ActionItemLevelRedirect;
