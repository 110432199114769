import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import ActionItemActionType from "../ActionItemActionType";
import ActionItemStatusHistory from "../ActionItemStatusHistory";
import ActionItemCommentsHistory from "../ActionItemCommentsHistory";
import ActionItemAssigneesHistory from "../ActionItemAssigneesHistory";
import ActionItemFileHistory from "../ActionItemFileHistory";
import ActionItemDueDateHistory from "../ActionItemDueDateHistory";

const ActionItemHistoryCard = ({
  causer: { firstName, lastName, email } = {},
  createdAt,
  type,
  subjectType,
  oldProperties: {
    dueDate: previousDueDate,
    done: previousStatus,
    body: previousComment,
    subscriberIdName: removedAssignee,
    fileName: removedFile,
  } = {},
  newProperties: {
    dueDate: currentDueDate,
    done: currentStatus,
    body: currentComment,
    subscriberIdName: addedAssignee,
    fileName: addedFile,
  } = {},
}) => {
  const isDueDateChanged = previousDueDate !== currentDueDate;
  const isStatusChanged = previousStatus !== currentStatus;

  const getHistoryComponent = () => {
    if (subjectType === "comment") {
      return (
        <ActionItemCommentsHistory
          previousComment={previousComment ?? ""}
          currentComment={currentComment}
          type={type}
        />
      );
    }
    if (subjectType === "action_item_assignee") {
      return (
        <ActionItemAssigneesHistory
          removedAssignee={removedAssignee}
          addedAssignee={addedAssignee}
          type={type}
        />
      );
    }
    if (subjectType === "media") {
      return (
        <ActionItemFileHistory
          removedFile={removedFile}
          addedFile={addedFile}
          type={type}
        />
      );
    }
    if (isDueDateChanged && isStatusChanged) {
      return (
        <>
          <ActionItemDueDateHistory
            previousDueDate={previousDueDate}
            currentDueDate={currentDueDate}
          />
          <ActionItemStatusHistory
            previousStatus={previousStatus}
            currentStatus={currentStatus}
          />
        </>
      );
    }
    if (isDueDateChanged) {
      return (
        <ActionItemDueDateHistory
          previousDueDate={previousDueDate}
          currentDueDate={currentDueDate}
        />
      );
    }
    return (
      <ActionItemStatusHistory
        previousStatus={previousStatus}
        currentStatus={currentStatus}
      />
    );
  };

  return (
    <div>
      <Row className="py-2">
        <Col className="border-0">
          <span className="fw-semibold">
            {firstName ?? "-"} {lastName ?? "-"}
          </span>
          <br />
          <small className="text-secondary fw-semibold">{email ?? "-"}</small>
        </Col>
      </Row>
      <Row className="py-2">
        <Col className="border-0">
          <span className="text-secondary fw-semibold">Action:</span>
          <span className="ms-2 fw-semibold">
            <ActionItemActionType
              type={type}
              subjectType={subjectType}
              isStatusChanged={isStatusChanged}
              isDueDateChanged={isDueDateChanged}
            />
          </span>
        </Col>
        <Col className="border-0">
          <span className="text-secondary fw-semibold">Time Stamp:</span>
          <span className="ms-2 fw-semibold">
            {new Date(createdAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </span>
        </Col>
      </Row>
      {getHistoryComponent()}
    </div>
  );
};

ActionItemHistoryCard.propTypes = {
  causer: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
  previousStatus: PropTypes.number,
  currentStatus: PropTypes.number,
};

export default ActionItemHistoryCard;
