import React, { useRef, useState } from "react";

import { useRoles } from "hooks";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button, Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";

import DataExport from "components/DataExport";
import SortColumn from "components/ui/SortColumn";
import MetaPagination from "components/ui/MetaPagination";
import { useFetchFacilities } from "features/entity/services";

import FacilityCreate from "../FacilityCreate";
import FacilityExport from "../FacilityExport";
import FacilityListItem from "../../ui/FacilityListItem";

const FacilityList = () => {
  const { isCertifier } = useRoles();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updated_at",
    updatedAt: true,
    sortAsc: true,
  });

  const sortByColumn = (column = "updated_at") => {
    if (isLoading) return;
    setTableSortState((prevState) => {
      return {
        updatedAt: false,
        sortColumn: column,
        sortAsc: !prevState.sortAsc,
      };
    });
  };

  const sortingColumns = [
    {
      index: 0,
      name: "Sort By",
      value: "",
    },
    {
      index: 1,
      name: "Name",
      value: "name",
    },
    {
      index: 2,
      name: "Address",
      value: "address",
    },
    {
      index: 3,
      name: "Updated",
      value: "updated_at",
    },
  ];

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const sendSearchQuery = useRef(
    debounce((q) => {
      setDebouncedQuery(q);
    }, 1000)
  );

  const { error, isLoading, facilities, meta, setFacilities } =
    useFetchFacilities({
      page: page,
      perPage: perPage,
      endDate: endDate,
      startDate: startDate,
      query: debouncedQuery,
      sortState: tableSortState,
    });

  return (
    <Card className="border">
      <Card.Body>
        <div className="d-flex flex-row mb-3">
          <div className="flex-grow-1">
            <h2 className="pt-1 mb-0">Entities</h2>
          </div>
          <span>
            <Button
              as={Link}
              to="/audits"
              variant="outline-primary"
              className="mx-2"
              size="sm"
            >
              Assessments{" "}
              <span translate="no" className="material-symbols-outlined md-18">
                arrow_forward
              </span>
            </Button>
            <FacilityExport />
            <DataExport variant="outline-primary" level={"organization"} />
            <FacilityCreate
              onFacilityCreated={(f) =>
                setFacilities((prevState) => [f, ...prevState])
              }
            />
          </span>
        </div>
        <Form as={Row} className="mt-3">
          <Form.Group as={Col} lg={4} md={12} sm={12} xs={12} className="mb-3">
            <Form.Control
              type="text"
              value={query}
              onChange={(e) => {
                const q = e.target.value;
                setQuery(q);
                setPage(1);
                sendSearchQuery.current(q);
              }}
              placeholder="Search"
            />
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              placeholderText="Filter by date range"
              monthsShown={2}
              className="form-control w-100"
              isClearable
            >
              <Button
                size="sm"
                className="mb-1"
                onClick={() => {
                  setStartDate();
                  setEndDate();
                }}
              >
                Clear
              </Button>
            </DatePicker>
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={4} md={3} lg={3} className="mb-3">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-sorting"
                className="w-100 text-start border border-gray-900 bg-white text-secondary"
              >
                {tableSortState.sortColumn.includes("name")
                  ? "Name"
                  : tableSortState.sortColumn.includes("address")
                  ? "Address"
                  : "Updated"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortingColumns.map((s) => (
                  <Dropdown.Item
                    key={s.index}
                    disabled={s.index === 0}
                    active={s.value === tableSortState.sortColumn}
                    onClick={() => sortByColumn(s.value)}
                  >
                    {s.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
        <Table responsive hover>
          <thead>
            <tr>
              <th
                className={`w-30 pointer ${
                  tableSortState.sortColumn === "name" ? "pb-1" : ""
                }`}
                onClick={() => sortByColumn("name")}
              >
                Name
                <SortColumn tableSortState={tableSortState} column="name" />
              </th>
              <th
                className={`w-25 pointer ${
                  tableSortState.sortColumn === "address" ? "pb-1" : ""
                }`}
                onClick={() => sortByColumn("address")}
              >
                Address
                <SortColumn tableSortState={tableSortState} column="address" />
              </th>
              <th className="text-center">Assessments</th>
              <th className="text-center">Action Items</th>
              <th
                className={`pointer ${
                  tableSortState.sortColumn === "updated_at" ? "pb-1" : ""
                }`}
                onClick={() => sortByColumn("updated_at")}
              >
                Updated
                <SortColumn
                  tableSortState={tableSortState}
                  column="updated_at"
                />
              </th>
              {!isCertifier && <th>Action</th>}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            <FacilityListItem
              error={error}
              isLoading={isLoading}
              facilities={facilities}
              setFacilities={setFacilities}
            />
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <MetaPagination
          meta={meta}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      </Card.Footer>
    </Card>
  );
};

FacilityList.propTypes = {};

export default FacilityList;
