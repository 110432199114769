import { useContext, useEffect, useRef, useState } from "react";

import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useFetchProtocols = ({ onSuccess, onError }) => {
  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const [error, setError] = useState("");
  const [protocols, setProtocols] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onErrorRef = useRef(onError);
  const onSuccessRef = useRef(onSuccess);

  useEffect(() => {
    function fetchProtocols() {
      setIsLoading(true);
      const protocolsPromise = get("protocols", {
        params: { organizationId: organization?.id, excludeRetired: true },
      });
      protocolsPromise.promise
        .then((response) => {
          const sortedProtocols = response.data.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
          );
          setProtocols(sortedProtocols);
          if (typeof onSuccessRef.current === "function")
            onSuccessRef.current(sortedProtocols);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            if (typeof onErrorRef.current === "function")
              onErrorRef.current(error);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
      subscribedPromises.current.push(protocolsPromise);
    }

    fetchProtocols();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id]);

  return {
    error,
    isLoading,
    protocols,
    setProtocols,
  };
};

export default useFetchProtocols;
