import React from "react";
import { Badge, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const doneStatus = [
  {
    index: 0,
    name: "Assigned",
    filter: 0,
  },
  {
    index: 1,
    name: "Submitted",
    filter: 1,
  },
  {
    index: 2,
    name: "Incomplete",
    filter: 2,
  },
  {
    index: 3,
    name: "Accepted",
    filter: 3,
  },
];
const ActionItemStatusHistory = ({ previousStatus, currentStatus }) => {
  return (
    <Row className="py-2">
      <Col className="border-0">
        <span className="text-secondary fw-semibold">Previous Status:</span>
        <span className="ms-2 fw-semibold">
          <Badge className="bg-danger bg-opacity-10 text-danger">
            {doneStatus[previousStatus]?.name || "-"}
          </Badge>
        </span>
      </Col>
      <Col className="border-0">
        <span className="text-secondary fw-semibold">Current Status:</span>
        <span className="ms-2 fw-semibold">
          <Badge className="bg-primary bg-opacity-10 text-primary">
            {doneStatus[currentStatus || 0]?.name}
          </Badge>
        </span>
      </Col>
    </Row>
  );
};

ActionItemStatusHistory.propTypes = {
  previousStatus: PropTypes.number,
  currentStatus: PropTypes.number,
};

export default ActionItemStatusHistory;
