import React from "react";

import TagManager from "react-gtm-module";

import App from "components/App";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { initSentryConfig } from "config/sentryConfigs";

import reportWebVitals from "./reportWebVitals";

import "./styles/index.scss";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANGER,
};

TagManager.initialize(tagManagerArgs);
Sentry.init(initSentryConfig);

const container = document.getElementById("root");

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
