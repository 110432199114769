import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Offcanvas } from "react-bootstrap";
import { put } from "utils/DeApi";
import * as yup from "yup";

export default function UpdateCredentials({
  showEditModal,
  onHide,
  organizationId,
  subscribedPromises,
  assessApiKeys,
  updateAssessAPIKeysAfterApi,
}) {
  const [initialValues, setInitialValues] = useState({
    tokenName: "",
  });
  const [showError, setShowError] = useState("");
  const validationSchema = yup.object().shape({
    tokenName: yup.string().required("Token Name is Required"),
  });

  const updateAssessAPIKeys = (requestBody) => {
    const findingsPromise = put(
      `/organizations/${organizationId}/api-keys/${assessApiKeys.id}`,
      {
        ...requestBody,
      }
    );
    findingsPromise.promise
      .then(async ({ data }) => {
        if (data && data.length < 1) {
          return;
        }
        updateAssessAPIKeysAfterApi(data, "update");
      })
      .catch((error) => {
        setShowError(error.data.message);
      });
    subscribedPromises.current.push(findingsPromise);
  };
  const handleSave = (values, { setSubmitting }) => {
    setSubmitting(true);
    const requestBody = {
      name: values.tokenName,
    };
    updateAssessAPIKeys(requestBody);
    setSubmitting(false);
    onHide();
  };
  useEffect(() => {
    if (assessApiKeys) {
      setInitialValues({
        tokenName: assessApiKeys.tokenName,
      });
    }
  }, [assessApiKeys]);
  return (
    <Offcanvas
      className="w-fixed-640 overflow-auto"
      show={showEditModal}
      onHide={onHide}
      placement="end"
      scroll
    >
      <Offcanvas.Header className="border-bottom" closeButton>
        <Offcanvas.Title>Edit Assess API Keys</Offcanvas.Title>
      </Offcanvas.Header>
      <div className="overflow-auto">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Body>
                <Form.Group controlId="tokenName" className="mb-3">
                  <Form.Label>Token Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="tokenName"
                    value={values.tokenName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.tokenName && !!errors.tokenName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tokenName}
                  </Form.Control.Feedback>
                </Form.Group>
                <p className="text-muted mt-5">
                  Caution: Only Admin and Owner can change these credentials.
                </p>
              </Offcanvas.Body>
              <div className="sticky-bottom p-3 bg-light text-end">
                <Button size="sm" variant="outline-secondary" onClick={onHide}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                  className="ms-2"
                >
                  {isSubmitting ? "Saving..." : "Update"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {showError !== "" && (
          <Alert variant="info" className={`my-3 d-flex flex-row`}>
            <div className="me-3">
              <span
                translate="no"
                className="material-symbols-outlined md-18 text-primary text-opacity-75"
              >
                info
              </span>
            </div>
            <div>
              <h5 className="mb-1">
                <small>Error</small>
              </h5>
              <p className="mb-1">
                <small>{showError}</small>
              </p>
            </div>
          </Alert>
        )}
      </div>
    </Offcanvas>
  );
}
