import React, { useState } from "react";
import { Button, Card, Col, Table } from "react-bootstrap";
import GroupCreate from "./GroupCreate";
import { Link, useParams } from "react-router-dom";
import { useRoles } from "hooks";
import MetaPagination from "components/ui/MetaPagination";
import GroupListItem from "./GroupListItem";
import { useFetchFacilityAuditGroups } from "features/entity/services";

function AssessmentGroups() {
  const { isCertifier } = useRoles();
  const { facilityId } = useParams();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sortState, setSortState] = useState("asc");

  const { error, isLoading, groups, meta, setGroups } =
    useFetchFacilityAuditGroups({
      page,
      perPage,
      sortState,
      facilityId,
    });

  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={8} className="mb-4">
      <div className="d-flex flex-row pb-3">
        <h4 className="flex-fill">Assessment Groups</h4>
        <div>
          <Button
            size="sm"
            as={Link}
            to={`/facilities/${facilityId}`}
            className="me-2"
            variant="outline-primary"
          >
            <span
              translate="no"
              className="material-symbols-outlined md-18 me-1"
            >
              arrow_back
            </span>
            Entity Overview
          </Button>
          <GroupCreate
            onGroupCreated={(group) =>
              setGroups((prevStatus) => [group, ...prevStatus])
            }
          />
        </div>
      </div>
      <Card>
        <Card.Body>
          <Table hover responsive>
            <thead>
              <tr>
                <th>Title</th>
                <th className="text-center">Assessments</th>
                <th
                  onClick={() =>
                    setSortState((prevSort) =>
                      prevSort === "asc" ? "desc" : "asc"
                    )
                  }
                >
                  Updated
                  <span
                    translate="no"
                    className="material-symbols-outlined mx-1 md-18"
                  >
                    {sortState === "desc" ? "arrow_upward" : "arrow_downward"}
                  </span>
                </th>
                {!isCertifier && <th>Action</th>}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              <GroupListItem
                error={error}
                groups={groups}
                isLoading={isLoading}
                setGroups={setGroups}
              />
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <MetaPagination
            meta={meta}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Card.Footer>
      </Card>
    </Col>
  );
}

export default AssessmentGroups;
