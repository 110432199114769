import { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

import "./TagsTypeahead.scss";
import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useToast } from "hooks";

const TagsTypeahead = ({
  onChange,
  isValid,
  isInvalid,
  selected,
  onBlur,
  placeholder = "",
}) => {
  const toast = useToast();
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLading] = useState(false);
  const [tag, setTag] = useState(Array.isArray(selected) ? selected : []);

  const handleOnChange = (data) => {
    onChange(data);
    setTag(data);
  };

  useEffect(() => {
    function fetchEntityTags() {
      setError(null);
      setIsLading(true);
      const tagsPromise = get(`organizations/${organization?.id}/labels`, {});
      tagsPromise.promise
        .then(({ data: tags }) => {
          tags.sort((a, b) => (a.name > b.name ? 1 : -1));
          setTags(tags);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            toast.error(
              "Error",
              error?.data?.message || "Failed to fetch tags"
            );
          }
        })
        .finally(() => setIsLading(false));
      subscribedPromises.current.push(tagsPromise);
    }

    fetchEntityTags();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization]);

  error && console.error(error);

  return (
    <Typeahead
      multiple
      clearButton
      labelKey="name"
      selected={tag}
      options={tags}
      id="tagsTypeahead"
      isLoading={isLoading}
      onChange={handleOnChange}
      placeholder={placeholder}
      isValid={Boolean(isValid)}
      isInvalid={Boolean(isInvalid)}
      onBlur={onBlur}
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps}>
          {results.map((result, index) => (
            <MenuItem
              option={result}
              position={index}
              className="d-flex flex-row"
            >
              <span
                className="me-3 rounded mt-1"
                style={{
                  backgroundColor: result?.color,
                  height: 15,
                  width: 15,
                }}
              ></span>
              <span className="flex-fill">{result.name}</span>
            </MenuItem>
          ))}
        </Menu>
      )}
    />
  );
};

TagsTypeahead.propTypes = {
  isValid: PropTypes.any,
  selected: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TagsTypeahead;
