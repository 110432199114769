import { OrganizationContext } from "contexts/OrganizationProvider";
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";
import { download } from "utils/DeApi";
import { useRoles, useToast } from "hooks";

const MemberExport = ({ handleError, tableSortState, debouncedQuery }) => {
  const toast = useToast();
  const { isCertifier } = useRoles();
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleMemberExport = () => {
    handleError(null);
    setIsLoading(true);

    const { sortAsc, sortColumn } = tableSortState;

    const membersCsvPromise = download(
      `/organizations/${organization?.id}/members/export`,
      {
        params: {
          sort: !!sortAsc ? sortColumn : `-${sortColumn}`,
          "filter[search]": debouncedQuery ? debouncedQuery : "",
        },
      }
    );

    let fileName = `${organization?.name} - Members.csv`;

    membersCsvPromise.promise
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Success", "The file has been downloaded successfully");
      })
      .catch((error) => {
        !error.isCanceled && handleError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(membersCsvPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        size="sm"
        variant="outline-primary"
        className="me-2"
        disabled={isLoading}
        onClick={() => handleMemberExport()}
      >
        {isLoading ? (
          <Spinner className="me-1" animation="border" size="sm" />
        ) : (
          <span translate="no" className="material-symbols-outlined md-18">
            file_download
          </span>
        )}{" "}
        Export Members
      </Button>
    </>
  );
};

MemberExport.propTypes = {
  handleError: PropTypes.func,
  tableSortState: PropTypes.object,
  debouncedQuery: PropTypes.string,
};

export default MemberExport;
