import { OrganizationContext } from "contexts/OrganizationProvider";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context";
import { isFilterActive } from "features/actionItems/utils";
import { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const SearchFilter = () => {
  const organization = useContext(OrganizationContext);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const orgId = urlParams.get("organizationId");
  const actionItemId = urlParams.get("actionItemId");
  const currentOrg = orgId === organization?.id;
  const {
    query: { search },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);

  const sendSearchQuery = useRef(
    debounce(({ q, key }) => {
      dispatch({ type: "SET_FILTERS", payload: { [key]: q } });
    }, 1000)
  );

  const onSearchChange = useCallback(
    (q) => {
      dispatch({ type: "SET_QUERY", payload: { search: q } });
      sendSearchQuery.current({ q, key: "search" });
    },
    [dispatch]
  );

  useEffect(() => {
    if (actionItemId && currentOrg) onSearchChange(actionItemId);
  }, [actionItemId, currentOrg, dispatch, onSearchChange]);

  return (
    <Col lg={2} className="mb-3 mt-2 text-truncate">
      <small className="text-muted">Search By ID, Title or Description</small>
      <Form.Control
        type="text"
        value={search}
        onChange={(e) => onSearchChange(e.target.value)}
        className={`mt-2 ${isFilterActive(search)}`}
        placeholder="Search"
      />
    </Col>
  );
};

export default SearchFilter;
