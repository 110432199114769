import {
  OrganizationDispatchContext,
  OrganizationsContext,
} from "contexts/OrganizationProvider";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const OrganizationRedirect = () => {
  const organizations = useContext(OrganizationsContext);
  const setOrganization = useContext(OrganizationDispatchContext);
  const { organizationId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (organizationId) {
      const org = organizations.find((o) => o.id === organizationId);
      if (org) {
        setOrganization({ ...org, documents: [] });
        navigate(`/`, { replace: true });
      }
    }
  }, [organizations, organizationId, setOrganization, navigate]);

  return <></>;
};

export default OrganizationRedirect;
