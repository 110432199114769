import React, { useCallback, useEffect, useRef, useState } from "react";

import { groupBy } from "lodash";
import { useRoles } from "hooks";
import { Badge, Offcanvas, Table } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import OverlayTriggerButton from "components/ui/OverlayTriggerButton";

import AuditUpdatedHistory from "./AuditUpdatedHistory/AuditUpdatedHistory";
import AudtiCreatedHistory from "./AudtiCreatedHistory/AudtiCreatedHistory";

const AuditHistory = ({ audit }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activityLog, setActivityLog] = useState();

  const { isAdmin } = useRoles();

  const activityGroupDetails = groupBy(activityLog, (item) => item?.type);

  const handleCanvasClose = () => setShow(false);
  const handleCanvasShow = () => setShow(true);

  const fetchActivityLog = useCallback(() => {
    setError(null);
    setIsLoading(true);
    const activityLogPromise = get(`audits/${audit?.auditId}/activity-logs`);
    activityLogPromise.promise
      .then(({ data: activityLog }) => setActivityLog(activityLog))
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(activityLogPromise);
  }, [audit?.auditId]);

  useEffect(() => {
    if (show && isAdmin) fetchActivityLog();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchActivityLog, isAdmin, show]);

  if (!isAdmin) return <></>;

  return (
    <div>
      <OverlayTriggerButton
        toolTipMessage="History"
        className="ms-2"
        variant="outline-secondary"
        onClickHandler={handleCanvasShow}
        label={
          <span translate="no" className="material-symbols-outlined md-16">
            history
          </span>
        }
      />

      <Offcanvas
        className="history-offcanvas-style"
        show={show}
        onHide={handleCanvasClose}
        placement="end"
        scroll
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Assessment History</Offcanvas.Title>
        </Offcanvas.Header>

        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}

        <Offcanvas.Body>
          {activityLog &&
            activityGroupDetails?.updated &&
            activityGroupDetails?.updated.map((history) => {
              return (
                <>
                  <Table>
                    <tbody>
                      <tr>
                        <td className="border-0">
                          <span className="fw-semibold">
                            {history.causer?.firstName ?? "-"}{" "}
                            {history.causer?.lastName ?? "-"}
                          </span>
                          <br />
                          <small className="text-secondary fw-semibold">
                            {history.causer?.email ?? "-"}
                          </small>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          <span className="text-secondary fw-semibold">
                            Action:
                          </span>
                          <span className="ms-2 fw-semibold">
                            <Badge className="bg-warning rounded-0 py-2 px-3 bg-opacity-10 text-warning">
                              UPDATED
                            </Badge>
                          </span>
                        </td>
                        <td className="border-0">
                          <span className="text-secondary fw-semibold">
                            Time Stamp:
                          </span>
                          <span className="ms-2 fw-semibold">
                            {new Date(history.createdAt).toLocaleString([], {
                              dateStyle: "short",
                              timeStyle: "short",
                            })}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          <AuditUpdatedHistory history={history} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                </>
              );
            })}
          {activityLog &&
            activityGroupDetails?.created &&
            activityGroupDetails?.created.map((history) => {
              return (
                <>
                  <Table>
                    <tbody>
                      <tr>
                        <td className="border-0">
                          <span className="fw-semibold">
                            {history.causer?.firstName ?? "-"}{" "}
                            {history.causer?.lastName ?? "-"}
                          </span>
                          <br />
                          <small className="text-secondary fw-semibold">
                            {history.causer?.email ?? "-"}
                          </small>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          <span className="text-secondary fw-semibold">
                            Action:
                          </span>
                          <span className="ms-2 fw-semibold">
                            <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
                              CREATED
                            </Badge>
                          </span>
                        </td>
                        <td className="border-0">
                          <span className="text-secondary fw-semibold">
                            Time Stamp:
                          </span>
                          <span className="ms-2 fw-semibold">
                            {new Date(history.createdAt).toLocaleString([], {
                              dateStyle: "short",
                              timeStyle: "short",
                            })}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          <AudtiCreatedHistory history={history} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                </>
              );
            })}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AuditHistory;
