import React, { useContext, useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";

import { get, put } from "utils/DeApi";
import Loader from "components/ui/Loader";
import TextLogo from "components/ui/TextLogo";
import Copyright from "components/ui/Copyright";
import { UserContext } from "contexts/UserProvider";

const OrganizationOnboarding = ({ onJoined }) => {
  const subscribedPromises = useRef([]);

  const [invitations, setInvitations] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const user = useContext(UserContext);

  const joinOrganization = (invitation) => {
    setError(null);
    setIsLoading(true);
    const invitationPromise = put(`/invitations/${invitation.id}`);
    invitationPromise.promise
      .then(({ data }) => onJoined())
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(invitationPromise);
  };

  const fetchInvitations = () => {
    setError(null);
    setIsLoading(true);
    const invitationPromise = get(`invitations`, {
      params: { verticalId: process.env.REACT_APP_VERTICAL_ID },
    });
    invitationPromise.promise
      .then(({ data: invitations }) => {
        setIsLoading(false);
        setInvitations(invitations);
      })
      .catch((error) => {
        setIsLoading(false);
        !error.isCanceled && setError(error);
      });
    subscribedPromises.current.push(invitationPromise);
  };

  useEffect(() => {
    fetchInvitations();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isLoading) return <Loader />;

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xl={7} lg={8} md={9} sm={10}>
          <TextLogo />
          <Card className="my-3">
            <Card.Body className="text-center">
              <h4>Hi {user.firstName}!</h4>
              <p>
                Welcome to {process.env.REACT_APP_VERTICAL || "Assess by ERM"}.
              </p>

              {error && (
                <Alert variant="warning" className={`mt-3 mb-3`}>
                  <h4>{error.statusText || "Oh snap! You got an error!"}</h4>
                  <p>
                    {error.data?.error?.message ||
                      "Something unexpected happened. Please try again."}
                  </p>
                  <small>{error.status || ""}</small>
                </Alert>
              )}

              {invitations && !!invitations.length && (
                <>
                  <p>
                    <i>{user.email}</i> has been invited to access the following
                    accounts.
                  </p>

                  {invitations.map((invitation) => (
                    <div className="text-start" key={invitation.id}>
                      <hr />
                      <span className="float-end">
                        <Button
                          size="sm"
                          variant="primary"
                          className="px-3"
                          onClick={() => joinOrganization(invitation)}
                        >
                          Join Now
                        </Button>{" "}
                      </span>
                      {invitation.organization.name}
                    </div>
                  ))}
                </>
              )}
              {invitations && !invitations.length && (
                <>
                  <hr />
                  <p>
                    An action from your administrator is needed to complete your
                    set up. Please ask your administrator to grant you access to
                    your organizations' account.
                  </p>
                </>
              )}

              <hr />
              <p>
                <Button size="sm" className="px-4" as={Link} to="/logout">
                  Logout
                </Button>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="text-center text-muted my-3">
        <small>
          <Copyright />
          <br />v {process.env.REACT_APP_RELEASE_VERSION}
        </small>
      </div>
    </Container>
  );
};

export default OrganizationOnboarding;
