import React from "react";

import { useRoles } from "hooks";
import { Table } from "react-bootstrap";

import { useFetchEntityTags } from "features/account/services";

import TagCreate from "./TagCreate";
import EntityTagList from "./EntityTagList";

function EntityTags() {
  const { isAdmin } = useRoles();
  const { error, isLoading, tags, setTags, fetchEntityTags } =
    useFetchEntityTags({ fetchByDefault: isAdmin });

  const handleTagCreated = (tag) => {
    fetchEntityTags();
  };

  if (!isAdmin) return <></>;

  return (
    <>
      <div className="d-flex flex-row mt-5">
        <div className="flex-fill">
          <h4>Entity tags</h4>
        </div>
        <div>
          <TagCreate onTagCreated={(tag) => handleTagCreated(tag)} />
        </div>
      </div>
      <hr />
      <Table hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Color</th>
            <th>Description</th>
            <th>Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          <EntityTagList
            tags={tags}
            error={error}
            setTags={setTags}
            isLoading={isLoading}
          />
        </tbody>
      </Table>
    </>
  );
}

export default EntityTags;
