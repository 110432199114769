import { useContext } from "react";
import { UserContext } from "contexts/UserProvider";

const useAuthorizeDomain = () => {
  const user = useContext(UserContext);

  return {
    isAuthorizedDomain:
      user?.email?.endsWith("@erm.com") ||
      user?.email?.endsWith("@pixeledge.io"),
  };
};

export default useAuthorizeDomain;
