import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ReportAggregation from "components/common/ReportAggregation";
import { useFetchFacilityProtocolStats } from "features/entity/services";
import { COMPLIANCE_OPTION } from "features/entity/constants/statsFilterOptions";
import {
  aggregationOfComlianceTrends,
  aggregationOfCompliance,
} from "helper/reporting";

import "./FacilityReportCompliance.scss";
function FacilityReportCompliance({ protocol, filterPeriod, facility }) {
  const { error, isLoading, facilityStats } = useFetchFacilityProtocolStats({
    period: filterPeriod?.period,
    option: COMPLIANCE_OPTION,
    facilityId: facility?.facilityId,
    protocolId: protocol?.protocolId,
  });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!facilityStats) return <></>;
  if (!facilityStats.length)
    return (
      <Col>
        <EmptyStateHandler
          title="No data to visualize"
          description="You have no data to visualize."
        />
      </Col>
    );

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={4} className="mb-4 border-end">
        <p className="text-center w-100 text-truncate text-capitalize">
          Distribution of{" "}
          {protocol?.questionOptions[1]?.name ?? "Conformity Level"}
        </p>
        <hr />
        <ReportAggregation
          size="square-container"
          labels={{
            compliant: "Yes",
            nonCompliant: "No",
          }}
          data={aggregationOfCompliance(facilityStats)}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={8} className="mb-3">
        <p className="text-center w-100 text-truncate text-capitalize">
          {protocol?.questionOptions[1]?.name ?? "Conformity Level"} trends
          across · <small className="text-muted">{facility?.name}</small>
        </p>
        <hr />
        <ReportTrend
          size="square-container"
          labels={{
            compliant: "Yes",
            nonCompliant: "No",
          }}
          data={aggregationOfComlianceTrends(facilityStats, filterPeriod)}
        />
      </Col>
    </Row>
  );
}

FacilityReportCompliance.propTypes = {
  protocol: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  filterPeriod: PropTypes.object.isRequired,
};

export default FacilityReportCompliance;
