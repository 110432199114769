import { useToast } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { download } from "utils/DeApi";
import { formatDateRange } from "utils/StringUtils";

const useExportMyActionItem = ({ filters }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const params = useCallback(() => {
    const { createdAt, updatedDate } = filters || {};
    return {
      ...(filters?.organization?.organizationId !== "all"
        ? {
            "filter[organization_id]": filters?.organization?.organizationId,
          }
        : {}),
      ...(filters?.level?.filter !== "all"
        ? { "filter[type]": filters?.level?.filter }
        : {}),

      ...(filters?.assignedBy?.subscriberId
        ? {
            "filter[assigned_by]": filters?.assignedBy?.subscriberId,
          }
        : {}),
      ...(filters?.assignedTo?.subscriberId
        ? {
            "filter[assigned_to]": filters?.assignedTo?.subscriberId,
          }
        : {}),
      ...(!!filters?.updatedDate?.length
        ? {
            "filter[updated_at]": formatDateRange(updatedDate),
          }
        : {}),
      ...(!!filters?.createdAt?.length
        ? {
            "filter[created_at]": formatDateRange(createdAt),
          }
        : {}),
      ...(!!filters?.entity?.facilityId && !filters?.audit?.auditId
        ? {
            "filter[facility_id]": filters?.entity?.facilityId,
          }
        : {}),
      ...(!!filters?.questionId && !!filters?.auditIdFromParam
        ? {
            "filter[question_id]": filters?.questionId,
          }
        : {}),
      ...(!!filters?.audit?.auditId
        ? {
            "filter[audit_id]": filters?.audit?.auditId,
          }
        : {}),
      ...(!!filters?.search
        ? {
            "filter[search]": filters?.search,
          }
        : {}),
      ...(!!filters?.id
        ? {
            "filter[id]": filters?.id,
          }
        : {}),
      ...(filters?.type?.filter !== "all"
        ? { "filter[action_item_type]": filters?.type?.filter }
        : {}),
    };
  }, [filters]);

  const exportMyActionItems = () => {
    setIsLoading(true);
    setError(null);

    const actionItemCsvPromise = download(`my-action-items-export`, {
      params: {
        ...params(),
      },
    });

    actionItemCsvPromise.promise
      .then((resp) => {
        let fileName = "All  Action Items.xlsx";
        if (
          filters?.organization?.name !== "all" &&
          filters?.organization?.name
        )
          fileName = `${filters?.organization?.name} - Action Items.xlsx`;
        const url = window.URL.createObjectURL(new Blob([resp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        toast.success("Success", "Your file has been downloaded successfully");
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(actionItemCsvPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return {
    exportMyActionItems,
    isLoading,
    error,
  };
};

export default useExportMyActionItem;
