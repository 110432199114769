import PropTypes from "prop-types";
import { Alert, Button, Form, InputGroup, Table } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";

const DefaultHelpView = ({
  handleSetSearchTearm,
  handleSearchContents,
  handleSetContentId,
  isLoading,
  error,
  isSearching,
  searchTerm,
  searchContents,
}) => (
  <>
    <InputGroup className="mb-4">
      <Form.Control
        type="search"
        placeholder="Search Help Center"
        aria-label="Search"
        value={searchTerm}
        onChange={handleSetSearchTearm}
        disabled={isLoading}
      />
      <Button
        variant="outline-primary"
        id="searchContent"
        onClick={handleSearchContents}
        disabled={isLoading}
      >
        Search
      </Button>
    </InputGroup>

    {isLoading && <Loader />}
    {error && <ErrorHandler error={error} />}

    {isSearching && !isLoading && !error && (
      <h6>
        Showing search results for <b>{searchTerm}</b>
      </h6>
    )}

    {!isLoading && !error && (
      <Table borderless hover>
        <tbody>
          {searchContents?.map((content) => (
            <tr
              key={content?.contentId}
              className="pointer-feedback"
              onClick={() => handleSetContentId(content?.contentId)}
            >
              <td>
                <span translate="no" className="material-symbols-outlined ">
                  article
                </span>
              </td>
              <td>
                <span>{content.title}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}

    {!isLoading && !searchContents?.length && !error && (
      <Alert variant="primary" className="my-2">
        <p>No results found</p>
      </Alert>
    )}
  </>
);

DefaultHelpView.propTypes = {
  handleSetSearchTearm: PropTypes.func.isRequired,
  handleSearchContents: PropTypes.func.isRequired,
  handleSetContentId: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searchContents: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.object,
};

export default DefaultHelpView;
