import { useState, useCallback } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";

import ReportAggregation from "components/common/ReportAggregation";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import { dateFilter } from "constants";
import { truncate } from "lodash";
import Loader from "components/ui/Loader";
import { useFetchActionItemStats } from "features/actionItems/services";
import { chartStatus } from "features/actionItems/constants";

function ActionItemReport() {
  const [status, setStatus] = useState(chartStatus[0]);
  const [filters, setFilters] = useState({
    filterPeriod: dateFilter[3],
    filterStatus: {
      key: null,
      value: null,
    },
  });

  const { filterPeriod } = filters;

  const {
    isLoading,
    error,
    statusChartData,
    dueAndOverDueChartData,
    reportTrend,
  } = useFetchActionItemStats({
    filters,
  });

  const onUpdateStatus = useCallback((s) => {
    setStatus(s);
    setFilters((prev) => ({
      ...prev,
      filterStatus: {
        key: s.filter,
        value: s.value,
      },
    }));
  }, []);

  if (error) return <ErrorHandler error={error} />;
  return (
    <>
      <Row>
        <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
          <small className="text-muted">Status</small>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-done"
              className="w-100 text-start border border-gray-900 bg-white text-secondary"
            >
              {status?.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {chartStatus.map((s) => (
                <Dropdown.Item
                  key={s.index}
                  active={s.index === status.index}
                  onClick={() => onUpdateStatus(s)}
                >
                  {s.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={12} sm={4} md={3} lg={3} xl={3} className="mb-3">
          <small className="text-muted">Period</small>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-done"
              className="w-100 text-start border border-gray-900 bg-white text-secondary"
            >
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                date_range
              </span>
              {truncate(filterPeriod.text)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dateFilter.map((interval) => (
                <Dropdown.Item
                  key={interval.index}
                  active={interval.index === filterPeriod.index}
                  onClick={() => {
                    setFilters((prev) => ({
                      ...prev,
                      filterPeriod: interval,
                    }));
                  }}
                >
                  {interval.text}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} className="border-end">
              <p className="text-center w-100 text-truncate text-capitalize">
                Distribution of Action Items
              </p>
              <hr />
              {isLoading ? (
                <Loader />
              ) : (
                <ReportAggregation
                  size="square-container"
                  data={statusChartData}
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <p className="text-center w-100 text-truncate text-capitalize">
                Comparision between Overdue and Due Action Items
              </p>
              <hr />
              {isLoading ? (
                <Loader />
              ) : (
                <ReportAggregation
                  size="square-container"
                  data={dueAndOverDueChartData}
                  labels={{
                    Overdue: "Overdue Action Items",
                    Due: "Due Action Items",
                  }}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <p className="text-center w-100 text-truncate text-capitalize">
                Action items trends across
              </p>
              <hr />
              {isLoading ? (
                <Loader />
              ) : (
                <ReportTrend size="square-container" data={reportTrend} />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default ActionItemReport;
