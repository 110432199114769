import React, { isValidElement, cloneElement, useState } from "react";

import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Offcanvas, Spinner, Tab, Tabs } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { useUpdateFacility } from "features/entity/services";
import { entityValidationSchema } from "features/entity/schemas";

import GeneralInfomationFields from "./GeneralInfomationFields";
import LocationAndContactFields from "./LocationAndContactFields";
import AdvancedInfomationFields from "./AdvancedInfomationFields";

const FacilityUpdate = ({ facility, children, onFacilityUpdated }) => {
  const { isAdmin } = useRoles();
  const [show, setShow] = useState();
  const [step, setStep] = useState("step-1");

  const handleShow = () => {
    setShow(true);
    setStep("step-1");
  };
  const handleClose = () => setShow(false);

  const { error, isLoading, updateFacility } = useUpdateFacility({
    facilityId: facility.facilityId,
    onFacilityUpdated: (data) => {
      handleClose();
      onFacilityUpdated(data);
    },
  });

  if (!isAdmin) return <></>;

  return (
    <>
      {isValidElement(children) ? (
        cloneElement(children, { onClickHandler: handleShow })
      ) : (
        <Button
          size="sm"
          className="me-2"
          onClick={handleShow}
          variant="outline-primary"
        >
          <span translate="no" className="material-symbols-outlined md-18">
            edit
          </span>{" "}
          Update
        </Button>
      )}

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="w-fixed-640"
        placement="end"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Update Entity</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            validationSchema={entityValidationSchema}
            onSubmit={(values) => {
              updateFacility({
                //General Information
                name: values.name,
                address: values.address,
                custom_id: values.customId,
                description: values.description,
                labels: values.entityTags.map(
                  ({ organizationLabelId: id }) => id
                ),

                //Location & Contact Information
                county: values.county,
                latitude: values.latitude,
                longitude: values.longitude,
                mailing_address: values.mailingAddress,
                facility_phone_number: values.entityPhoneNumber,
                facility_24_hr_phone_number: values.entity24hrPhoneNumber,

                //Advanced Information
                lepc: values.lepc,
                naics_code: values.naicsCode,
                facility_type: values.entityType,
                max_occupancy: values.maxOccupancy,
                fire_department: values.fireDepartment,
                number_of_employees: values.numberOfEmployees,
                dun_and_bradstreet_number: values.dunAndBradstreetNumber,
                facility_supplies_to:
                  values.facilitySuppliesTo[0]?.facilityId ?? "",
                facility_supplies_from:
                  values.facilitySuppliesFrom[0]?.facilityId ?? "",
              });
            }}
            initialValues={{
              //General Information
              name: facility.name || "",
              address: facility.address || "",
              customId: facility.customId || "",
              description: facility.description || "",
              entityTags: facility.labels || [],

              //Location & Contact Information
              county: facility.county || "",
              latitude: facility.latitude || "",
              longitude: facility.longitude || "",
              mailingAddress: facility.mailing_address || "",
              entityPhoneNumber: facility.facility_phone_number || "",
              entity24hrPhoneNumber: facility.facility_24_hr_phone_number || "",

              //Advanced Information
              lepc: facility.lepc || "",
              naicsCode: facility.naics_code || "",
              entityType: !isNaN(parseInt(facility.facility_type))
                ? parseInt(facility.facility_type)
                : "",
              maxOccupancy: facility.max_occupancy || "",
              fireDepartment: facility.fire_department || "",
              numberOfEmployees: facility.number_of_employees || "",
              dunAndBradstreetNumber: facility.dun_and_bradstreet_number || "",
              facilitySuppliesTo: facility?.facilitySuppliesTo
                ? [facility.facilitySuppliesTo]
                : [],
              facilitySuppliesFrom: facility.facilitySuppliesFrom
                ? [facility.facilitySuppliesFrom]
                : [],
            }}
          >
            {({ errors, touched, isValid, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={step}
                    onSelect={(k) => setStep(k)}
                    className="mb-3"
                  >
                    <Tab
                      eventKey="step-1"
                      title={
                        <>
                          General Information{" "}
                          {((errors.name && touched.name) ||
                            (errors.address && touched.address) ||
                            (errors.customId && touched.customId) ||
                            (errors.description && touched.description)) && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                        </>
                      }
                    >
                      <GeneralInfomationFields />
                    </Tab>
                    <Tab
                      eventKey="step-2"
                      title={
                        <>
                          Location & Contact Info{" "}
                          {((errors.county && touched.county) ||
                            (errors.latitude && touched.latitude) ||
                            (errors.longitude && touched.longitude) ||
                            (errors.mailingAddress && touched.mailingAddress) ||
                            (errors.entityPhoneNumber &&
                              touched.entityPhoneNumber) ||
                            (errors.entity24hrPhoneNumber &&
                              touched.entity24hrPhoneNumber)) && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                        </>
                      }
                    >
                      <LocationAndContactFields />
                    </Tab>
                    <Tab
                      eventKey="step-3"
                      style={{
                        marginBottom: "430px",
                      }}
                      title={
                        <>
                          Advanced Information
                          {((errors.maxOccupancy && touched.maxOccupancy) ||
                            (errors.entityType && touched.entityType) ||
                            (errors.numberOfEmployees &&
                              touched.numberOfEmployees) ||
                            (errors.fireDepartment && touched.fireDepartment) ||
                            (errors.lepc && touched.lepc) ||
                            (errors.dunAndBradstreetNumber &&
                              touched.dunAndBradstreetNumber) ||
                            (errors.naicsCode && touched.naicsCode)) && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                        </>
                      }
                    >
                      <AdvancedInfomationFields />
                    </Tab>
                  </Tabs>
                  {error && <ErrorHandler error={error} />}
                </div>
                <div className="d-flex fflex-column justify-content-between">
                  <div className="flex-fill">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="me-2"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    {step === "step-1" && (
                      <Button
                        variant="outline-dark"
                        size="sm"
                        className="px-4"
                        onClick={() => setStep("step-2")}
                      >
                        Next
                      </Button>
                    )}

                    {step === "step-2" && (
                      <>
                        <Button
                          variant="outline-dark"
                          size="sm"
                          onClick={() => setStep("step-1")}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="outline-dark"
                          size="sm"
                          className="px-4 ms-2"
                          onClick={() => setStep("step-3")}
                        >
                          Next
                        </Button>
                      </>
                    )}

                    {(step === "step-3" || step === "step-4") && (
                      <>
                        <Button
                          variant="outline-dark"
                          className="float-start px-4"
                          size="sm"
                          onClick={() =>
                            setStep(step === "step-3" ? "step-2" : "step-3")
                          }
                        >
                          Previous
                        </Button>
                        <Button
                          type="submit"
                          size="sm"
                          className="ms-2"
                          disabled={!isValid || isLoading}
                        >
                          {isLoading && (
                            <Spinner
                              className="me-2"
                              animation="border"
                              size="sm"
                              variant="light"
                            />
                          )}{" "}
                          Update Entity
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

FacilityUpdate.propTypes = {
  facility: PropTypes.object.isRequired,
  action: PropTypes.any,
  onFacilityUpdated: PropTypes.func.isRequired,
};

export default FacilityUpdate;
