import { useRef, useState, useEffect } from "react";
import { get } from "utils/DeApi";

const useFetchFacilitySubscribers = ({ facilityId, fetchByDefault = true }) => {
  const subscribedPromises = useRef([]);
  const [subscribers, setSubscribers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchSubscribers = () => {
      setIsLoading(true);
      const subscribersPromise = get(`/facilities/${facilityId}/subscribers`);
      subscribersPromise.promise
        .then((response) => {
          setSubscribers(response?.data ?? []);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    if (facilityId && fetchByDefault) fetchSubscribers();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [facilityId, fetchByDefault]);

  return { error, isLoading, subscribers, setSubscribers };
};

export default useFetchFacilitySubscribers;
