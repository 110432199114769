import { useRef, useState, useEffect } from "react";
import { get } from "utils/DeApi";

const useFetchFacilityStats = ({ facilityId }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [stats, setStats] = useState();

  const subscribedPromises = useRef([]);

  useEffect(() => {
    function fetchFacilityStats() {
      setError(null);
      setIsLoading(true);
      const statsPromise = get(`facilities/${facilityId}/stats`);
      statsPromise.promise
        .then((stats) => {
          setStats(stats);
        })
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setTimeout(() => setIsLoading(false), 500));
      subscribedPromises.current.push(statsPromise);
    }

    if (facilityId) fetchFacilityStats();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [facilityId]);
  return { error, isLoading, stats };
};

export default useFetchFacilityStats;
