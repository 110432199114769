import { useState } from "react";

const useActiveTab = () => {
  const [key, setKey] = useState("risk-tab");
  const setActiveTab = (questionOptions) => {
    if (!questionOptions || !questionOptions?.length) return false;
    questionOptions
      .filter(({ id }) => [0, 1, 2].includes(id))
      .every(({ id, value }) => {
        if (!value) return true;
        if (id === 0) setKey("risk-tab");
        if (id === 1) setKey("compliance-tab");
        if (id === 2) setKey("conformity-tab");
        return false;
      });
  };

  return { key, setKey, setActiveTab };
};

export default useActiveTab;
