import { useEffect, useRef, useState } from "react";
import { put } from "utils/DeApi";

const useUpdateActionItemComment = ({
  comment,
  onActionItemCommentUpdated,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const updateActionItemComment = (body) => {
    setError("");
    setIsLoading(true);
    const actionItemPromise = put(`/comments/${comment?.id}`, {
      body,
    });

    actionItemPromise.promise
      .then(({ data: comment }) => {
        setIsLoading(false);
        onActionItemCommentUpdated(comment);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(actionItemPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return {
    updateActionItemComment,
    isLoading,
    error,
  };
};

export default useUpdateActionItemComment;
