import { useFetchTags } from "features/organization";
import { useFormikContext } from "formik";
import { uniqBy } from "lodash";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import PropTypes from "prop-types";

const ActionItemTagsField = ({ show, setError }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const { tags, isLoading, error } = useFetchTags({ show });

  useEffect(() => {
    setError(error);
  }, [error, setError]);

  return (
    <Form.Group controlId="tags" className="mb-3">
      <Form.Label className="mb-1">Tags</Form.Label>
      <Typeahead
        id="tags-typeahead"
        clearButton
        placeholder="Select tags ..."
        newSelectionPrefix="Add: "
        isLoading={isLoading}
        labelKey={`name`}
        isValid={!!values.tags.length && !errors.tags && touched.tags}
        isInvalid={!(values.tags && !errors.tags) && touched.tags}
        onChange={(tags) => {
          setFieldValue(
            "tags",
            uniqBy(
              tags.map((tag) => ({
                ...tag,
                name: tag?.name.toLowerCase(),
              })),
              "name"
            )
          );
        }}
        options={tags}
        selected={values.tags}
        multiple
      />
    </Form.Group>
  );
};

ActionItemTagsField.propTypes = {
  show: PropTypes.bool,
  setError: PropTypes.func,
};

export default ActionItemTagsField;
